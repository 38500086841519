import { Form, Radio, Space, Tooltip } from "antd";
import React from "react";
import moment from "moment";
import { FormInstance, Rule } from "antd/es/form";
import { InfoCircleOutlined } from "@ant-design/icons";
import { span } from "../../../utils/style";
import { InputNumber } from "../../../components";
import { preventNonNumericalInput } from "../../../utils/preventNonNumericalInput";
import EndDateInput from "./EndDateInput";
import { VoucherFormValues } from "./index";
import { VoucherGroupDetailResponse } from "../../../types/dto/response/voucher";
import styles from "./EntitlementDeal.module.scss";

export default function EntitlementDeal({
  data,
  form,
}: {
  form: FormInstance<VoucherFormValues>;
  data?: VoucherGroupDetailResponse;
}) {
  const validateEntitlementExpirationDate = () => {
    const { entitlementExpirationDate, validPeriod, voucherDeal } = form.getFieldsValue();
    const { expirationDate } = validPeriod ?? {};

    if (entitlementExpirationDate == null) {
      return Promise.reject("Invalid date");
    }
    if (voucherDeal === "FIXED_TERM") {
      if (expirationDate == null) {
        return Promise.reject("Please choose voucher valid duration first");
      }
      if (entitlementExpirationDate < expirationDate) {
        return Promise.reject("Invalid date");
      }
    }
    return Promise.resolve("");
  };

  const rules: Rule[] = [{ validator: validateEntitlementExpirationDate }];

  return (
    <Form.Item noStyle shouldUpdate>
      {() => {
        const { entitlementDeal, validPeriod, voucherDeal } = form.getFieldsValue();
        const { expirationDate } = validPeriod ?? {};

        return (
          <Form.Item
            name="entitlementDeal"
            label="Entitlement deal"
            initialValue={data?.entitlementDeal ?? "FIXED_ENDED"}
            style={span(12)}
            className="entitlementDeal"
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="OPEN_ENDED">Open ended</Radio>
                <Radio value="FIXED_TERM">
                  Fixed term
                  {entitlementDeal === "FIXED_TERM" && (
                    <>
                      <Form.Item
                        className={styles.entitlementDays}
                        label="Entitlement days"
                        name="entitlementDays"
                        initialValue={data?.entitlementDays}
                        rules={[{ required: true, type: "number", min: 1, max: 3650 }]}
                      >
                        <InputNumber
                          style={{ marginLeft: "10px", width: "100px" }}
                          type={"integer"}
                          onKeyPress={preventNonNumericalInput}
                        />
                      </Form.Item>
                      <Tooltip title="Validity time will start to count after the voucher is redeemed.">
                        <InfoCircleOutlined style={{ paddingLeft: "4px" }} />
                      </Tooltip>
                    </>
                  )}
                </Radio>
                <Radio value="FIXED_ENDED">
                  Fixed expiration date
                  {entitlementDeal === "FIXED_ENDED" && (
                    <Form.Item
                      noStyle
                      name="entitlementExpirationDate"
                      initialValue={data?.entitlementExpirationDate ?? moment().add(1, "year").valueOf()}
                      rules={rules}
                    >
                      <EndDateInput
                        style={{ width: "130px", marginLeft: "10px" }}
                        startDate={voucherDeal === "FIXED_TERM" && expirationDate ? expirationDate : Date.now()}
                      />
                    </Form.Item>
                  )}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}
