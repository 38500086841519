import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { camelCase } from "lodash";
import { Col, Form, FormInstance, Row, Select } from "antd";
import { DropDown } from "../../../../assets/icons";
import { BasePriceModel } from "../../../../types/model/price";
import { BillingPlan } from "../../../../types/model/eComProduct";
import { AccessTypeContext } from "./UpsellDowngradeForm";
import { PRODUCT_TYPE_UPSELL } from "../../../../types/model/upsellDowngrade";
import {
  basicProductTypeOptions,
  multiLevelCourseLevelOptions,
  singleLevelCourseLevelOptions,
} from "./UpsellDowngrade";

interface Props {
  labelPrefix: string;
  basePriceList: BasePriceModel[];
  billingPlans: BillingPlan[];
  form: FormInstance;
  onOriginalProductTypeChange?: (value: string) => void;
}

function ProductBasicRow({ labelPrefix, basePriceList, billingPlans, form, onOriginalProductTypeChange }: Props) {
  const [productType, setProductType] = useState("");
  const { originalProductType } = useContext(AccessTypeContext);
  const [isOptionsFieldsRequired, setIsOptionsFieldsRequired] = useState(false);
  const fieldNamePrefix = camelCase(labelPrefix);
  const isOriginal = labelPrefix.includes("Original");

  useEffect(() => {
    if (!isOriginal) {
      setProductType("");
    }
  }, [originalProductType, isOriginal]);

  useEffect(() => {
    const formProductType = form.getFieldValue(`${fieldNamePrefix}ProductType`);
    if (formProductType) {
      setProductType(formProductType);
    }
  }, [form, fieldNamePrefix]);

  useEffect(() => {
    const isBasePriceSet = !!form.getFieldValue(`${fieldNamePrefix}BasePriceId`);
    setIsOptionsFieldsRequired(isBasePriceSet || !!productType);
  }, [productType, form, fieldNamePrefix]);

  const courseLevelOptions = useMemo(
    () =>
      productType === PRODUCT_TYPE_UPSELL.SINGLE_LEVEL ? singleLevelCourseLevelOptions : multiLevelCourseLevelOptions,
    [productType]
  );

  const productTypeOptions = useMemo(() => {
    if (originalProductType === "All Access" && !isOriginal) {
      return basicProductTypeOptions.filter((option) => option.value === PRODUCT_TYPE_UPSELL.ALL_ACCESS);
    } else {
      return basicProductTypeOptions;
    }
  }, [originalProductType, isOriginal]);

  const handleProductTypeChange = useCallback(
    (value: string) => {
      setProductType(value);
      form.resetFields([`${fieldNamePrefix}CourseLevel`, `${fieldNamePrefix}BillingPlanId`]);
      onOriginalProductTypeChange?.(value);
    },
    [fieldNamePrefix, form, onOriginalProductTypeChange]
  );

  const handleBasePriceChange = useCallback(
    (value: string) => {
      setIsOptionsFieldsRequired(!!value || !!productType);
    },
    [productType, setIsOptionsFieldsRequired]
  );

  const renderProductTypeSpecificForm = useCallback(() => {
    if (productType === PRODUCT_TYPE_UPSELL.SINGLE_LEVEL || productType === PRODUCT_TYPE_UPSELL.MULTI_LEVEL) {
      return (
        <Col span={6}>
          <Form.Item
            label={`${labelPrefix} course level`}
            name={`${fieldNamePrefix}CourseLevel`}
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              optionFilterProp="label"
              getPopupContainer={(trigger) => trigger.parentNode}
              showArrow
              showSearch
              suffixIcon={<DropDown />}
              placeholder="Please select course level"
              options={courseLevelOptions}
            />
          </Form.Item>
        </Col>
      );
    } else if (productType) {
      return (
        <Col span={6}>
          <Form.Item
            label={`${labelPrefix} billing plan`}
            name={`${fieldNamePrefix}BillingPlanId`}
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              optionFilterProp="label"
              getPopupContainer={(trigger) => trigger.parentNode}
              showArrow
              showSearch
              suffixIcon={<DropDown />}
              placeholder="Please select billing plan"
              options={billingPlans.map(({ id, name }) => ({
                label: name,
                value: String(id),
              }))}
            />
          </Form.Item>
        </Col>
      );
    }
    return null;
  }, [productType, courseLevelOptions, billingPlans, labelPrefix]);

  return (
    <Row gutter={32}>
      <Col span={12}>
        <Form.Item
          label={`${labelPrefix} base price`}
          name={`${fieldNamePrefix}BasePriceId`}
          rules={[{ required: isOptionsFieldsRequired || isOriginal }]}
        >
          <Select
            allowClear
            optionFilterProp="label"
            getPopupContainer={(trigger) => trigger.parentNode}
            showArrow
            showSearch
            suffixIcon={<DropDown />}
            onChange={handleBasePriceChange}
            placeholder="Please choose base price name"
            options={basePriceList.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label={`${labelPrefix} product type`}
          name={`${fieldNamePrefix}ProductType`}
          rules={[{ required: isOptionsFieldsRequired || isOriginal }]}
        >
          <Select
            allowClear
            optionFilterProp="label"
            getPopupContainer={(trigger) => trigger.parentNode}
            showArrow
            showSearch
            suffixIcon={<DropDown />}
            placeholder="Please select product type"
            options={productTypeOptions}
            onChange={handleProductTypeChange}
          />
        </Form.Item>
      </Col>
      {renderProductTypeSpecificForm()}
    </Row>
  );
}

export default ProductBasicRow;
