import React, { useState } from "react";
import moment from "moment/moment";
import { message } from "antd";
import { EComModal } from "../../../../components/EComModal";
import { ISubscriptionModalProps } from "./types";
import { cancelSubscription } from "../../../../services/subscription";

export default function CancelSubscriptionModal({
  auth0Id,
  subscription,
  visible,
  closeModal,
  onFinish,
}: ISubscriptionModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit() {
    setIsLoading(true);
    try {
      await cancelSubscription(subscription.subscriptionId, auth0Id, subscription.source);
      message.success("Canceled successfully");
      if (onFinish) {
        onFinish();
      }
      closeModal();
    } catch (e) {
      message.error(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <EComModal
      title="Cancel subscription"
      visible={visible}
      okText="Cancel Subscription"
      cancelText="Keep learning"
      onCancel={closeModal}
      onOk={onSubmit}
      confirmLoading={isLoading}
    >
      <p>{getCancelText()}</p>
    </EComModal>
  );

  function getCancelText() {
    const cancelEndOfCycleStatus = ["Active", "Pending Pause"];
    const cancelImmediatelyStatus = ["Free Trial", "Grace Period", "Paused"];

    if (cancelEndOfCycleStatus.includes(subscription.status)) {
      return `Are you sure you want to cancel ${subscription.title} Subscription? It’s will take effect on ${moment(
        subscription.status === "Pending Pause" ? subscription.pauseStartDate : subscription.nextBilling?.date
      ).format("MM/DD/YYYY")}. The user will still be able to learn until then.`;
    }

    if (cancelImmediatelyStatus.includes(subscription.status)) {
      return `Are you sure you want to cancel ${subscription.title} Subscription? The user will lose access to the course.`;
    }

    return "";
  }
}
