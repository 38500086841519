import React, { createContext, useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { Button, Card, Col, Divider, Row, Spin } from "antd";
import { EMPTY, PRODUCT_STATUS_VIEW_MAP } from "constants/common";
import { ProductStatus } from "types/model/product";
import {
  EComProductInfo,
  EComProductStatus,
  EComProductStatusType,
  EComProductStatusUrl,
  NewPlanFormFieldValues,
  ECOM_PRODUCT_TAB,
  PRODUCT_TYPE,
  ProductPlanDetails,
} from "../../../../types/model/eComProduct";
import { message } from "../../../../components";
import * as eComProductAPI from "../../../../services/eComProduct";
import { EComModal } from "../../../../components/EComModal";
import modalStyles from "../../basePrice/index.module.scss";
import PlanList from "../PlanList";
import styles from "./index.module.scss";
import AddNewPlan from "../components/AddNewPlan";
import EditPlan from "../components/EditPlan";
import * as productAPI from "../../../../services/product";
import useAppAuth0 from "../../../../hooks/useAppAuth0";
import EComProductLogTable from "./ProductLogTable";
import { getCourseLevels } from "../../../../utils/ecomProductUtils";

interface ProductDetail {
  language: string;
  courseType: string;
  languageId: number;
  plans: ProductPlanDetails[];
}

export const ProductDetailContext = createContext<EComProductInfo | null>(null);

const tabList = [
  {
    key: ECOM_PRODUCT_TAB.PRODUCT_INFO,
    tab: ECOM_PRODUCT_TAB.PRODUCT_INFO,
  },
  {
    key: ECOM_PRODUCT_TAB.PRODUCT_LOG,
    tab: ECOM_PRODUCT_TAB.PRODUCT_LOG,
  },
];

function ProductDetails() {
  const history = useHistory();
  const { user } = useAppAuth0();
  const { productId } = useParams<{
    productId: string;
  }>();
  const location = useLocation<{
    pid: string;
  }>();
  const [deleteProductModalOpen, setProductDeleteModalOpen] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [product, setProduct] = useState<ProductDetail>();
  const [loading, setLoading] = useState(false);
  const [productInfo, setProductInfo] = useState<EComProductInfo>({} as EComProductInfo);
  const [activeKey, setActiveKey] = useState<string>(location.state?.pid);
  const [activePlan, setActivePlan] = useState<NewPlanFormFieldValues>();

  const [editPlanModalVisible, setEditPlanModalVisible] = useState(false);
  const [editingPlanIndex, setEditingPlanIndex] = useState(-1);

  const [handlePlanId, setHandlePlanId] = useState<string>("");
  const [isActivateModalVisible, setIsActivateModalVisible] = useState(false);
  const [isDeactivateModalVisible, setIsDeactivateModalVisible] = useState(false);
  const [productDeletable, setProductDeletable] = useState(false);

  const [activeTabKey, setActiveTabKey] = useState<string>(ECOM_PRODUCT_TAB.PRODUCT_INFO);

  const fetchData = useCallback(
    (pid?: string) => {
      let ignore = false;
      setLoading(true);
      eComProductAPI
        .fetchProductById(productId)
        .then((product) => {
          if (!ignore) {
            setProduct(product);
            pid && setActiveKey(pid);
          }
        })
        .finally(() => {
          setLoading(false);
        });
      return () => {
        ignore = true;
      };
    },
    [productId]
  );

  useEffect(() => {
    if (activeTabKey === ECOM_PRODUCT_TAB.PRODUCT_INFO) {
      fetchData();
    }
  }, [productId, fetchData]);

  useEffect(() => {
    if (product) {
      const { languageId, language, courseType } = product;
      const isPremium = courseType === "Premium";
      const isAllAccess = language === PRODUCT_TYPE.ALL_ACCESS;

      setProductInfo({
        languageId,
        languageName: language,
        isPremium,
        isAllAccess,
        productId,
      });
      setProductDeletable(
        !product.plans.some((plan: ProductPlanDetails) => plan.status === "Active" || plan.status === "Inactive")
      );
    }
  }, [product, productId]);

  const handleProductDelete = () => {
    setConfirmLoading(true);
    eComProductAPI
      .deleteProduct(productId)
      .then(() => {
        setProductDeleteModalOpen(false);
        message.success("Product deleted successfully.");
        history.push("/products/ecom");
      })
      .finally(() => setConfirmLoading(false));
  };

  const handlePlanDelete = () => {
    if (activePlan?.pid) {
      setConfirmLoading(true);
      eComProductAPI
        .deleteProductPlan(productId, handlePlanId!)
        .then(() => {
          setIsDeleteModalVisible(false);
          message.success("Plan deleted successfully.");
          if (product) {
            setProduct({
              ...product,
              plans: product.plans.filter((plan) => plan.planId !== handlePlanId),
            });
            setHandlePlanId("");
          }
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    }
  };

  const handlePlanStatusChange = (status: EComProductStatusType, planId: string) => {
    setConfirmLoading(true);
    eComProductAPI
      .updateStatusPlan(productId, planId, EComProductStatusUrl[status])
      .then(() => {
        switch (status) {
          case EComProductStatus.Active:
            message.success("Plan activated successfully.");
            break;
          case EComProductStatus.Inactive:
            message.success("Plan deactivated successfully.");
            break;
          default:
            message.success("Plan updated successfully.");
        }
        fetchData();
      })
      .finally(() => {
        setHandlePlanId("");
        setConfirmLoading(false);
        setIsActivateModalVisible(false);
        setIsDeactivateModalVisible(false);
      });
  };

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const language = product?.language ?? "";
  const courseType = product?.courseType ?? "";
  const title = product ? `${language} ${courseType}` : EMPTY;

  return (
    <Spin spinning={loading} size={"large"}>
      <Card
        title={title}
        extra={
          user.canDeleteEcomProduct && (
            <div className={styles.deleteButton}>
              <Button
                type="default"
                shape="round"
                onClick={() => setProductDeleteModalOpen(true)}
                disabled={!productDeletable}
              >
                Delete
              </Button>
            </div>
          )
        }
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        className="page-container"
      >
        {activeTabKey === ECOM_PRODUCT_TAB.PRODUCT_INFO && (
          <>
            <section>
              <header className={styles.planHeader}>
                <p className={styles.secondTitle}>Product info</p>
              </header>
              <Row gutter={24}>
                <Col span={12}>
                  <p>Language :</p>
                  <p className={styles.productField}>{product?.language ?? "-"}</p>
                </Col>
                <Col span={12}>
                  <p>Course Type :</p>
                  <p className={styles.productField}>{product?.courseType ?? "-"}</p>
                </Col>
              </Row>
            </section>
            <Divider className={styles.divider} />
            <ProductDetailContext.Provider value={productInfo}>
              <section>
                <header className={styles.planHeader}>
                  <p className={styles.secondTitle}>Plan</p>
                  {product && <AddNewPlan fetchData={fetchData} />}
                </header>
                <PlanList
                  plans={product?.plans ?? []}
                  activeKey={activeKey}
                  onEditPlan={async (detail, index) => {
                    const product = await productAPI.fetchProduct(`${detail.contentProductId}`);
                    detail.checkoutDeeplink =
                      (detail.status === EComProductStatus.ReadyForPublish ||
                        detail.status === EComProductStatus.Active) &&
                      detail.pid
                        ? `${process.env.REACT_APP_ECOM_BASE_URL}/checkout?pid=${detail.pid}`
                        : "-";
                    if (product.productType === "SINGLE_LEVEL") {
                      const course = product.course!;
                      setActivePlan({
                        ...detail,
                        bundledProductIds: [],
                        courseLevel: course.courseLevel,
                        checkoutDeeplink: detail.checkoutDeeplink,
                        version: course.courseVersion,
                        contentProductStatus: PRODUCT_STATUS_VIEW_MAP.get(
                          product.status as ProductStatus
                        ) as ProductStatus,
                        selectedCourse: {
                          lessonStart: product.lessonFrom ?? 0,
                          lessonEnd: product.lessonTo ?? 0,
                        },
                        courseLevels: getCourseLevels(product),
                      });
                      setEditPlanModalVisible(true);
                      setEditingPlanIndex(index);
                      return;
                    }
                    if (
                      product.productType === "MULTI_LEVEL" ||
                      product.productType === "ALL_ACCESS" ||
                      product.productType === "UPGRADE" ||
                      product.productType === "SUBSCRIPTION"
                    ) {
                      setActivePlan({
                        ...detail,
                        courseLevel: 0,
                        checkoutDeeplink: detail.checkoutDeeplink,
                        version: "-",
                        contentProductStatus: PRODUCT_STATUS_VIEW_MAP.get(
                          product.status as ProductStatus
                        ) as ProductStatus,
                        selectedCourse: {
                          lessonStart: 0,
                          lessonEnd: 0,
                        },
                        bundledProductIds: product.bundledProducts!.map(({ name, isbn }) =>
                          name && isbn ? `${isbn} ${name}` : "-"
                        ),
                        courseLevels: getCourseLevels(product),
                      });
                      setEditPlanModalVisible(true);
                      setEditingPlanIndex(index);
                    }
                  }}
                  onDeletePlan={(detail) => {
                    if (product) {
                      if (product.plans?.length <= 1) {
                        message.error({
                          message: "For a product, at least one plan is needed.",
                        });
                      } else {
                        setIsDeleteModalVisible(true);
                        setActivePlan(detail as NewPlanFormFieldValues);
                        if (detail.planId) {
                          setHandlePlanId(detail.planId);
                        }
                      }
                    }
                  }}
                  onPublishPlan={async (detail) => {
                    if (detail.planId) {
                      handlePlanStatusChange(EComProductStatus.ReadyForPublish, detail.planId);
                    }
                  }}
                  onRevertToDraftPlan={async (detail) => {
                    if (detail.planId) {
                      handlePlanStatusChange(EComProductStatus.Draft, detail.planId);
                    }
                  }}
                  onActivatePlan={(detail) => {
                    setActivePlan(detail as NewPlanFormFieldValues);
                    if (detail.planId) {
                      setHandlePlanId(detail.planId);
                      setIsActivateModalVisible(true);
                    }
                  }}
                  onDeactivatePlan={(detail) => {
                    setActivePlan(detail as NewPlanFormFieldValues);
                    if (detail.planId) {
                      setHandlePlanId(detail.planId);
                      setIsDeactivateModalVisible(true);
                    }
                  }}
                  onPreviewPlan={(detail) => {
                    window.open(`${process.env.REACT_APP_ECOM_BASE_URL}/preview/checkout?pid=${detail.pid}`, "_blank");
                  }}
                  keyExtractor={(plan) => plan.pid}
                />
                <EditPlan
                  visible={editPlanModalVisible}
                  initialValues={activePlan}
                  productPlanId={product?.plans[editingPlanIndex]?.planId}
                  closeModal={() => {
                    setEditPlanModalVisible(false);
                    setActivePlan(undefined);
                    setEditingPlanIndex(-1);
                  }}
                  fetchData={fetchData}
                  status={product?.plans[editingPlanIndex]?.status}
                />
              </section>
            </ProductDetailContext.Provider>
          </>
        )}
        {activeTabKey === ECOM_PRODUCT_TAB.PRODUCT_LOG && <EComProductLogTable productId={productId} />}
      </Card>
      <EComModal
        visible={deleteProductModalOpen && productDeletable}
        title="Delete Product"
        okText="Confirm"
        onCancel={() => {
          setProductDeleteModalOpen(false);
        }}
        onOk={handleProductDelete}
        confirmLoading={confirmLoading}
      >
        <p>
          Are you sure you want to delete the <span className={modalStyles.fontBold}>{title}</span> and its plans?
        </p>
      </EComModal>
      <EComModal
        visible={isDeleteModalVisible}
        title="Delete Plan"
        okText="Confirm"
        onCancel={() => {
          setIsDeleteModalVisible(false);
          setActivePlan(undefined);
          setHandlePlanId("");
        }}
        onOk={handlePlanDelete}
        confirmLoading={confirmLoading}
      >
        <p>
          Are you sure you want to delete the &nbsp;
          <span className={modalStyles.fontBold}>{activePlan?.name}</span>?
        </p>
      </EComModal>
      <EComModal
        visible={isActivateModalVisible}
        title="Activate Plan"
        okText="Confirm"
        onCancel={() => {
          setIsActivateModalVisible(false);
          setActivePlan(undefined);
          setHandlePlanId("");
        }}
        onOk={() => {
          handlePlanStatusChange(EComProductStatus.Active, handlePlanId);
        }}
        confirmLoading={confirmLoading}
      >
        <>
          <p>
            You are about to activate the &nbsp;
            <span className={modalStyles.fontBold}>{activePlan?.name}</span>.
          </p>
          <p>
            This action will change the plan status to <span className={modalStyles.fontBold}>active</span>&nbsp; and
            customers will be able to purchase it.
          </p>
          <p> Please confirm that you want to proceed with the activation.</p>
        </>
      </EComModal>
      <EComModal
        visible={isDeactivateModalVisible}
        title="Deactivate Plan"
        okText="Confirm"
        onCancel={() => {
          setIsDeactivateModalVisible(false);
          setActivePlan(undefined);
          setHandlePlanId("");
        }}
        onOk={() => {
          handlePlanStatusChange(EComProductStatus.Inactive, handlePlanId);
        }}
        confirmLoading={confirmLoading}
      >
        <>
          <p>
            You are about to deactivate the &nbsp;
            <span className={modalStyles.fontBold}>{activePlan?.name}</span>.
          </p>
          <p>
            This action will change the plan status to &nbsp;
            <span className={modalStyles.fontBold}>inactive</span>&nbsp; and customers will not be able to purchase it
            anymore.
          </p>
          <p>Please confirm that you want to proceed with the deactivation.</p>
        </>
      </EComModal>
    </Spin>
  );
}

export default ProductDetails;
