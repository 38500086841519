interface Props {
  currentPriceList: Record<string, unknown>;
  originalData: Record<string, unknown>;
  text: string;
  footerText: string;
}

const priceDisplay = (price: unknown) => {
  return price === "-" || price === "" ? 0 : price;
};

const LinkProduct = ({ currentPriceList, originalData, text, footerText }: Props) => {
  return (
    <>
      <p>
        {text} <br />
        {
          // @ts-ignore
          originalData.plans?.map((item, index) => (
            <span key={index}>
              - {item.title}
              <br />
            </span>
          ))
        }
      </p>
      <p>
        {Object.entries(currentPriceList).map(([key, currentPrice], index) => {
          const originalPrice = (originalData as Record<string, string>)[key];
          if (key !== "name" && originalPrice !== currentPrice) {
            return (
              <span key={index}>
                {`Original price ${key.toUpperCase()} ${priceDisplay(
                  originalPrice
                )} -> Current price ${key.toUpperCase()} ${priceDisplay(currentPrice)}`}
                <br />
              </span>
            );
          }
          return null;
        })}
      </p>

      <span>{footerText}</span>
    </>
  );
};

export default LinkProduct;
