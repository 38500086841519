import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { DropDown } from "../../../../../assets/icons";
import styles from "./index.module.scss";
import { BillingPlan } from "../../../../../types/model/eComProduct";

interface EComBillingPlanSelectProps {
  billingPlanList: BillingPlan[];
  onChange?: (value: number) => void;
  disabled?: boolean;
}

export default function EComBillingPlanSelect({
  billingPlanList,
  onChange,
  disabled,
  ...restProps
}: EComBillingPlanSelectProps) {
  const handleInputKeyDown: SelectProps<any>["onInputKeyDown"] = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Select
      {...restProps}
      allowClear
      showSearch={true}
      listHeight={160}
      onInputKeyDown={handleInputKeyDown}
      onChange={onChange}
      getPopupContainer={(trigger) => trigger.parentNode}
      suffixIcon={<DropDown />}
      placeholder={"Please choose billing plan"}
      optionFilterProp={"label"}
      disabled={disabled}
      options={billingPlanList.map(({ name, id }) => ({
        label: name,
        value: id,
      }))}
      className={styles.selectDropdown}
    />
  );
}
