import React, { createContext, useEffect, useMemo, useState } from "react";
import { Col, ConfigProvider, Divider, Form, FormInstance, Input, Row, Spin } from "antd";
import ProductBasicRow from "./ProductBasicRow";
import { BasePriceModel, PriceStatus } from "../../../../types/model/price";
import { BillingPlan } from "../../../../types/model/eComProduct";
import * as eComProductAPI from "../../../../services/eComProduct";
import * as basePriceAPI from "../../../../services/basePrice";
import { DEFAULT_MAX_PAGINATION, validateMessages } from "../../../../constants/common";
import styles from "./UpsellDowngradeForm.module.scss";

export const AccessTypeContext = createContext<{
  originalProductType: string | undefined;
}>({ originalProductType: undefined });

interface Props {
  form: FormInstance;
}

export function UpsellDowngradeForm({ form }: Props) {
  const [basePriceList, setBasePriceList] = useState<BasePriceModel[]>([]);
  const [billingPlans, setBillingPlans] = useState<BillingPlan[]>([]);
  const [originalProductType, setOriginalProductType] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      basePriceAPI.fetchBasePriceList({
        status: PriceStatus.ACTIVE,
        page: 1,
        limit: DEFAULT_MAX_PAGINATION.size,
      }),
      eComProductAPI.fetchBillingPlans(),
    ])
      .then(([basePriceList, billingPlans]) => {
        setBasePriceList(basePriceList.list);
        setBillingPlans(billingPlans);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const clearUpsellFields = () => {
    form.resetFields([
      "primaryUpsellProductType",
      "secondaryUpsellProductType",
      "primaryUpsellCourseLevel",
      "primaryUpsellCourseLevel",
      "primaryUpsellBillingPlanId",
      "secondaryUpsellCourseLevel",
      "secondaryUpsellBillingPlanId",
      "downgradeProductType",
      "downgradeBillingPlanId",
      "downgradeCourseLevel",
    ]);
  };

  const handleOriginalProductTypeChange = (value: string) => {
    clearUpsellFields();
    setOriginalProductType(value);
  };

  const contextValue = useMemo(() => ({ originalProductType }), [originalProductType]);

  return (
    <Spin spinning={isLoading} size="large">
      <ConfigProvider form={{ validateMessages }}>
        <Form form={form} validateTrigger={"onBlur"} layout="vertical" className={styles.Form}>
          <div className={styles.subtitle}>
            <span>Original base price</span>
          </div>
          <AccessTypeContext.Provider value={contextValue}>
            <ProductBasicRow
              form={form}
              labelPrefix="Original"
              basePriceList={basePriceList}
              billingPlans={billingPlans}
              onOriginalProductTypeChange={handleOriginalProductTypeChange}
            />
            <Divider className={styles.divider} />
            <div className={styles.subtitle}>
              <span>Upsell</span>
            </div>
            <ProductBasicRow
              form={form}
              labelPrefix="Primary Upsell"
              basePriceList={basePriceList}
              billingPlans={billingPlans}
            />
            <Row gutter={32}>
              <Col span={12}>
                <Form.Item label="Primary upsell tag" name="primaryUpsellTag" rules={[{ type: "string", max: 25 }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Primary upsell promotional slogan"
                  name="primaryUpsellSlogan"
                  rules={[{ type: "string", max: 40 }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Divider className={styles.divider} />
            <ProductBasicRow
              form={form}
              labelPrefix="Secondary Upsell"
              basePriceList={basePriceList}
              billingPlans={billingPlans}
            />
            <Row gutter={32}>
              <Col span={12}>
                <Form.Item label="Secondary upsell tag" name="secondaryUpsellTag" rules={[{ type: "string", max: 25 }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Secondary upsell promotional slogan"
                  name="secondaryUpsellSlogan"
                  rules={[{ type: "string", max: 40 }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Divider className={styles.divider} />
            <div className={styles.subtitle}>
              <span>Downgrade</span>
            </div>
            <ProductBasicRow
              form={form}
              labelPrefix="Downgrade"
              basePriceList={basePriceList}
              billingPlans={billingPlans}
            />
          </AccessTypeContext.Provider>
        </Form>
      </ConfigProvider>
    </Spin>
  );
}
