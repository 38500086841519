import React from "react";
import { useSelector } from "react-redux";
import { Col, Divider, Form, Row } from "antd";
import { isEmpty, join } from "lodash";
import { DisplayInput } from "components";
import ProductModel, { ProductUpsell } from "types/model/product";
import { PRODUCT_TYPE_VIEW_MAPPING, SEPARATOR } from "constants/common";
import NamiSupportCheckbox from "../../../../components/NamiSupportCheckbox";
import { RootState } from "../../../../app/store";

function ProductUpsellDetail({ product }: { product: ProductModel }) {
  const {
    productUpsells,
    upgradeIsbn,
    upgradeName,
    isUpsellSupportedByNami,
    namiGroups,
    upgradeEcomPid,
    upgradePromotionCode,
  } = product;

  const { eComStandardProducts, eComPremiumProducts, eComAllAccessProducts, productInfo, allAccessInfo } = useSelector(
    (state: RootState) => state.addProduct
  );

  const combinedEComProducts = [...eComAllAccessProducts, ...eComStandardProducts, ...eComPremiumProducts];
  const combinedProductInfo = [...allAccessInfo, ...productInfo];

  return (
    <>
      <Row style={{ width: "1056px" }}>
        <Col span={24}>
          <h3>Upsell info: (upsell from the end of a level)</h3>
        </Col>
      </Row>
      {productUpsells?.map((productUpsell: ProductUpsell, index: number) => {
        const { upsellType, upsellInAppIsbn, upsellInAppName, upsellEcomPid, upsellPromotionCode } = productUpsell;
        return (
          <React.Fragment key={index}>
            <h4>Upsell Card {index + 1}</h4>
            <Row style={{ width: "1056px" }}>
              <Col span={12}>
                <Form.Item label={"Upsell Type"}>
                  <DisplayInput value={PRODUCT_TYPE_VIEW_MAPPING[upsellType]} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12} style={{ width: "1056px" }}>
              <Col span={11}>
                <Form.Item label="In App Upsell ISBN">
                  <DisplayInput value={upsellInAppIsbn && join([upsellInAppIsbn, upsellInAppName], SEPARATOR)} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ width: "1056px" }}>
              <Col span={16}>
                <Form.Item label="eCom Upsell PID">
                  <DisplayInput
                    value={
                      upsellEcomPid &&
                      join(
                        [
                          upsellEcomPid,
                          combinedProductInfo.find((product) => product.isbn === upsellEcomPid.split("_")[0])
                            ?.courseName,
                          combinedEComProducts.find((product) => product.pid === upsellEcomPid)?.planName,
                        ],
                        SEPARATOR
                      )
                    }
                  />
                </Form.Item>
              </Col>
              {upsellPromotionCode && (
                <Col span={8}>
                  <Form.Item label="eCom Promotion Code">
                    <DisplayInput value={upsellPromotionCode} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Divider />
          </React.Fragment>
        );
      })}

      <Row>
        <Form.Item name={"isUpsellSupportedByNami"} noStyle>
          {productUpsells && productUpsells.length > 0 && (
            <NamiSupportCheckbox checked={isUpsellSupportedByNami} disabled={true} />
          )}
        </Form.Item>
      </Row>

      {isUpsellSupportedByNami && !isEmpty(namiGroups) && (
        <Row>
          <Form.Item label="Nami Paywall Product Group:">
            {namiGroups.map((groupId, index) => (
              <Row key={index}>
                <DisplayInput value={groupId} />
              </Row>
            ))}
          </Form.Item>
        </Row>
      )}

      <Divider />

      <Row style={{ width: "1056px" }}>
        <Col span={24}>
          <h3>One-shot Upgrade info: (upgrade from locked premium features)</h3>
        </Col>
        <Col span={11}>
          <Form.Item label="Upgrade ISBN">
            <DisplayInput value={upgradeIsbn && join([upgradeIsbn, upgradeName], SEPARATOR)} />
          </Form.Item>
        </Col>
        <Row style={{ width: "1056px" }}>
          <Col span={16}>
            <Form.Item label="eCom Upgrade PID">
              <DisplayInput
                value={
                  upgradeEcomPid &&
                  join(
                    [
                      upgradeEcomPid,
                      combinedProductInfo.find((product) => product.isbn === upgradeEcomPid.split("_")[0])?.courseName,
                      combinedEComProducts.find((product) => product.pid === upgradeEcomPid)?.planName,
                    ],
                    SEPARATOR
                  )
                }
              />
            </Form.Item>
          </Col>
          {upgradePromotionCode && (
            <Col span={8}>
              <Form.Item label="eCom Promotion Code">
                <DisplayInput value={upgradePromotionCode} />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Row>
    </>
  );
}

export default ProductUpsellDetail;
