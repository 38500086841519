import { message, Radio, RadioChangeEvent } from "antd";
import React, { useState } from "react";
import { EComModal } from "../../../../../components/EComModal";
import modalStyles from "../../../../../components/EComModal/index.module.scss";
import { updateUserSubscriberInfo } from "../../../../../services/customer";
import style from "../../CustomerBasicInfo.module.scss";

interface IEditOptInModalProps {
  optInInput: string;
  email: string;
  visible: boolean;
  onFinish: () => void;
  closeModal: () => void;
}

export default function EditMarketingOptInModal({
  optInInput,
  email,
  visible,
  onFinish,
  closeModal,
}: IEditOptInModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [optIn, setOptIn] = useState(optInInput === "Yes" ? "True" : "False");

  async function onSubmit() {
    setIsLoading(true);
    try {
      await updateUserSubscriberInfo(email, optIn);

      message.success("Request sent");
      onFinish();
      closeModal();
    } catch (e) {
      message.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  const onChange = (e: RadioChangeEvent) => {
    setOptIn(e.target.value);
  };

  return (
    <EComModal
      title="Edit SFMC Marketing Opt-in"
      visible={visible}
      okText="Save"
      onCancel={closeModal}
      onOk={onSubmit}
      style={modalStyles.modal}
      confirmLoading={isLoading}
    >
      <div className={style.marketingOptIn}>
        <div className={style.subtitle}>
          <span>Please allow some time to synchronize the data with the SFMC subscriber.</span>
        </div>
        <span>* SFMC Marketing Opt-in:</span>
      </div>
      <Radio.Group onChange={onChange} value={optIn}>
        <Radio value="True">Yes</Radio>
        <Radio value="False">No</Radio>
      </Radio.Group>
    </EComModal>
  );
}
