import { ColumnType } from "antd/es/table";
import moment from "moment";
import { DATETIME_FORMAT, EMPTY } from "../../../../constants/common";
import { AppliedStage, SaveOfferModel, SaveOfferType } from "../../../../types/model/saveOffer";

export default function getSaveOfferTableColumns(): ColumnType<SaveOfferModel>[] {
  return [
    {
      title: "Applied cancel reason",
      key: "cancelReason",
      dataIndex: "cancelReason",
      width: 240,
      ellipsis: true,
      render: (_, val) => {
        return val.cancelReason?.description || EMPTY;
      },
    },
    {
      title: "Applied stage",
      key: "appliedStages",
      dataIndex: "appliedStages",
      width: 160,
      ellipsis: true,
      render: (text: AppliedStage[]) => text.join(", "),
    },
    {
      title: "Applied base price",
      key: "appliedBasePrices",
      dataIndex: "appliedBasePrices",
      width: 326,
      ellipsis: true,
      render: (_, { appliedBasePrices }) => appliedBasePrices?.map((basePrice) => basePrice.name).join(", "),
    },
    {
      title: "Save offer type",
      key: "type",
      dataIndex: "type",
      width: 160,
      render: (text: SaveOfferType) => text,
    },
    {
      title: "Modified at",
      key: "updatedAt",
      dataIndex: "updatedAt",
      width: 176,
      ellipsis: true,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (value: string) => moment(value).format(DATETIME_FORMAT),
    },
  ];
}
