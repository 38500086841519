import { PRODUCT_TYPE_UPSELL } from "../../../../types/model/upsellDowngrade";

export const basicProductTypeOptions = Object.values(PRODUCT_TYPE_UPSELL).map((type) => ({
  label: type,
  value: type,
}));

export const multiLevelCourseLevelOptions = [
  { label: "Complete course", value: "-1" },
  { label: "Level 1-2", value: "1,2" },
  { label: "Level 1-3", value: "1,2,3" },
  { label: "Level 1-4", value: "1,2,3,4" },
  { label: "Level 1-5", value: "1,2,3,4,5" },
  { label: "Level 2-3", value: "2,3" },
  { label: "Level 2-4", value: "2,3,4" },
  { label: "Level 2-5", value: "2,3,4,5" },
  { label: "Level 3-4", value: "3,4" },
  { label: "Level 3-5", value: "3,4,5" },
  { label: "Level 4-5", value: "4,5" },
];

export const singleLevelCourseLevelOptions = [
  { label: "Level 1", value: "1" },
  { label: "Level 2", value: "2" },
  { label: "Level 3", value: "3" },
  { label: "Level 4", value: "4" },
  { label: "Level 5", value: "5" },
];
