import { message } from "antd";
import { useState } from "react";
import { EComModal } from "../../../../components/EComModal";
import * as CustomerAPI from "../../../../services/customer";

interface IUpdateRecipientEmailModalProps {
  visible: boolean;
  givenEmail: {
    id: string;
    email: string;
  };
  onFinish: () => void;
  closeModal: () => void;
}

export default function SendEmailModal({
  visible,
  givenEmail: { email, id },
  onFinish,
  closeModal,
}: IUpdateRecipientEmailModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  async function onSend() {
    setIsLoading(true);
    CustomerAPI.sendGivenGiftEmail(id)
      .then(() => {
        message.success("Email send successfully.");
        onFinish();
        closeModal();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <EComModal
      title="Send email"
      visible={visible}
      okText="Send"
      onCancel={closeModal}
      onOk={onSend}
      confirmLoading={isLoading}
    >
      Send gift redemption email to {email}?
    </EComModal>
  );
}
