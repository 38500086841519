import React, { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Modal, Radio } from "antd";
import ProductModel from "../../../../types/model/product";
import { updateProduct } from "../../productSlice";
import { message } from "../../../../components";
import { useAppDispatch } from "../../../../app/store";
import styles from "./ProductDetail.module.scss";

interface IProps {
  isVisible: boolean;
  values: ProductModel;
  onCancel: () => void;
  onConfirm: () => void;
}

function SaveChangesPopup({ isVisible, values, onCancel, onConfirm }: IProps) {
  const [isEffectiveImmediately, setIsEffectiveImmediately] = useState(false);
  const dispatch = useAppDispatch();

  return (
    <Modal
      title="Confirm"
      visible={isVisible}
      onCancel={onCancel}
      centered
      className={styles.confirmModal}
      okButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
      cancelButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
      okText="Confirm"
      onOk={() => {
        const { deepLinkPromotion, namiGroups } = values;
        if (deepLinkPromotion) {
          values.deepLinkPromotion = {
            promotionTitle: deepLinkPromotion.promotionTitle?.trim(),
            promotionContent: deepLinkPromotion.promotionContent?.trim(),
            promotionComment: deepLinkPromotion.promotionComment?.trim(),
          };
        }
        if (!namiGroups) {
          values.namiGroups = [];
        }
        dispatch(updateProduct({ ...values, isEffectiveImmediately }))
          .then(unwrapResult)
          .then(() => {
            onConfirm();
          })
          .catch(message.error);
      }}
    >
      <div>
        <div className={styles.bold}>How would you like to save the changes on portal?</div>
        <Radio.Group
          onChange={(e) => setIsEffectiveImmediately(e.target.value)}
          value={isEffectiveImmediately}
          className={styles.radios}
        >
          <Radio value={false}>Save the changes for later review</Radio>
          <Radio value={true}>Publish the changes on portal immediately</Radio>
        </Radio.Group>
      </div>
      <div className={styles.bold}>Once published, all the changes will be available to all the end users.</div>
    </Modal>
  );
}

export default SaveChangesPopup;
