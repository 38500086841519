import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { useHistory, useRouteMatch } from "react-router-dom";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Row, Space, Table, Tooltip } from "antd";
import DropdownInput from "components/DropdownInput";
import * as basePriceAPI from "services/basePrice";
import { Prompt } from "react-router";
import { ArrayParam, NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { EComModal } from "components/EComModal";
import styles from "./index.module.scss";
import ConfigureModal from "../../../components/ConfigureModal";
import {
  BasePriceFieldValue,
  BasePriceListModel,
  BasePriceModel,
  CurrencyModel,
  PriceAction,
  PriceStatus,
} from "../../../types/model/price";
import { message } from "../../../components";
import { BasePriceListRequest } from "../../../types/dto/request/basePrice";
import { getTotalDisplay } from "../../../utils/getTotalDisplay";
import LinkProduct from "./LinkProduct";
import { EMPTY, LEAVE_CONFIRMATION } from "../../../constants/common";
import useAppAuth0 from "../../../hooks/useAppAuth0";
import CustomDot from "../../../components/CustomDot";
import CurrencyConfigureForm from "./CurrencyConfigureForm";
import EditableCell from "../../../components/EditableCell";

const statusOptions = [
  { label: "Active", value: PriceStatus.ACTIVE },
  { label: "Inactive", value: PriceStatus.INACTIVE },
];

const generateBasePriceTableData = (basePriceList: BasePriceListModel, currencyAssociation: CurrencyModel[]) =>
  basePriceList?.list?.map(({ id, name, plans, status, currencies, validActions }) => ({
    id,
    name,
    status,
    plans,
    ...currencyAssociation.reduce(
      (acc, currency) => ({
        ...acc,
        [currency.code]: currencies.find((item) => item.code === currency.code)?.amount ?? "-",
      }),
      {}
    ),
    validActions,
  }));

function BasePricePage() {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
    status: withDefault(ArrayParam, undefined),
    name: withDefault(StringParam, undefined),
  });
  const { user } = useAppAuth0();
  const [selectStatus, setSelectStatus] = useState<PriceStatus[]>([]);
  const [currencyAssociation, setCurrencyAssociation] = useState<CurrencyModel[]>([]);
  const [basePriceList, setBasePriceList] = useState<BasePriceListModel>({} as BasePriceListModel);
  const basePriceTableData = useMemo(() => generateBasePriceTableData(basePriceList, currencyAssociation), [
    basePriceList,
    currencyAssociation,
  ]);
  const [basePriceName, setBasePriceName] = useState(query.name);
  const [editForm] = Form.useForm();
  const [addPriceForm] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [isCurrencyAssociationPending, setIsCurrencyAssociationPending] = useState(false);
  const [isBasicPriceListPending, setIsBasicPriceListPending] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [originalData, setOriginalData] = useState<BasePriceFieldValue>({
    id: "",
    name: "",
    status: "",
    plans: [],
  });
  const [currentPrice, setCurrentPrice] = useState<BasePriceFieldValue>({
    id: "",
    name: "",
    status: "",
    plans: [],
  });
  const [deletePrice, setDeletePrice] = useState<BasePriceFieldValue>({
    id: "",
    name: "",
    status: "",
    plans: [],
  });
  const tableRef = useRef<{
    validate: () => Promise<boolean>;
    update: () => Promise<void>;
    refresh: () => {};
  }>(null);

  useEffect(() => {
    refreshCurrencyAssociation();
  }, []);

  const refreshBasicPriceList = useCallback(() => {
    setIsBasicPriceListPending(true);
    basePriceAPI
      .fetchBasePriceList(query as BasePriceListRequest)
      .then((res) => {
        setBasePriceList(res);
      })
      .finally(() => {
        setIsBasicPriceListPending(false);
      });
  }, [query]);

  useEffect(() => {
    refreshBasicPriceList();
    setBasePriceName(query.name);
    if (query.status && query.status.length) {
      setSelectStatus(query.status as PriceStatus[]);
    }
  }, [query, refreshBasicPriceList]);

  const refreshCurrencyAssociation = () => {
    setIsCurrencyAssociationPending(true);
    basePriceAPI
      .fetchCurrencyAssociation()
      .then((res) => {
        setCurrencyAssociation(res);
      })
      .finally(() => {
        setIsCurrencyAssociationPending(false);
      });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBasePriceName(e.target.value);
  };

  const handleInputPressEnter = () => {
    setQuery({ ...query, name: basePriceName?.trim(), page: 1 });
  };

  const handleStatusChange = (statuses: PriceStatus[]) => {
    setSelectStatus(statuses);
    setQuery({ ...query, status: statuses, page: 1 });
  };

  const changeStatus = (val: BasePriceFieldValue) => {
    if (val.status === PriceStatus.INACTIVE && val.usd === "-") {
      message.error(new Error("USD must be set before activating the base price."));
      return;
    }
    val.status = val.status === PriceStatus.ACTIVE ? PriceStatus.INACTIVE : PriceStatus.ACTIVE;
    basePriceAPI.updateBasePrice(val).then(() => {
      refreshBasicPriceList();
    });
  };

  const isEditing = (val: BasePriceFieldValue) => val.id === editingKey;
  const edit = (val: BasePriceFieldValue) => {
    editForm.setFieldsValue(val);
    setOriginalData(val);
    setEditingKey(val.id);
  };
  const history = useHistory();
  const { path } = useRouteMatch();

  const save = () => {
    editForm.validateFields().then((row) => {
      row.name = row.name.trim();
      if (!row.usd || row.usd === "-") {
        message.error(new Error("USD must be set before saving the base price."));
        return;
      }
      setCurrentPrice(row);
      setIsUpdateModalOpen(true);
    });
  };

  const deleteBasePrice = (id: string) => () => {
    setConfirmLoading(true);
    basePriceAPI
      .deleteBasePrice(id)
      .then(() => {
        message.success("Base price deleted successfully.");
        refreshBasicPriceList();
      })
      .finally(() => {
        setConfirmLoading(false);
        setDeleteModalOpen(false);
      });
  };

  const getTableColumns = [
    {
      title: "Name",
      key: "Name",
      dataIndex: "name",
      width: 248,
      fixed: "left",
      inputType: "text",
      editable: true,
      render: (text: string, record: any) => {
        return (
          <div className={styles.basePriceNameButton}>
            <a
              onClick={() => {
                history.push({
                  pathname: `${path}/${record.id}`,
                  search: "?name=" + record.name,
                });
              }}
            >
              <Tooltip title={text}>{text}</Tooltip>
            </a>
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "Status",
      dataIndex: "status",
      width: 95,
      fixed: "left",
      editable: false,
      render: (val: PriceStatus) => <CustomDot color={val} />,
    },
    ...currencyAssociation.map((currency) => {
      return {
        title: currency.code.toUpperCase(),
        key: currency.code,
        dataIndex: currency.code,
        width: 100,
        inputType: "number",
        editable: true,
        // minWidth: 100,
        filterDropdown: true,
        filterIcon: () => (
          <Tooltip
            title={
              <div>
                Applied Country/Region:
                {currency.countries.map(({ id, displayName }) => {
                  return <div key={id}>{displayName}</div>;
                })}
              </div>
            }
          >
            <InfoCircleOutlined style={{ color: "#858585" }} />
          </Tooltip>
        ),
      };
    }),
    {
      title: "Actions",
      key: "Actions",
      width: 195,
      fixed: "right",
      render: (val: any) => {
        if (!user.canUpdateBasePrice && !user.canDeleteBasePrice) {
          return EMPTY;
        }
        const editable = isEditing(val);
        return editable ? (
          <span>
            <Button type="link" style={{ padding: "0", marginRight: "6px" }} size="small" onClick={save}>
              Save
            </Button>
            <Button type="link" style={{ padding: "0" }} size="small" onClick={() => setEditingKey("")}>
              Cancel
            </Button>
          </span>
        ) : (
          <div style={{ display: "flex" }}>
            {user.canUpdateBasePrice && (
              <>
                <Button
                  type="link"
                  style={{ padding: "0", marginRight: "6px" }}
                  size="small"
                  disabled={!val.validActions.includes(PriceAction.EDIT)}
                  onClick={() => edit(val)}
                >
                  Edit
                </Button>
                <Button
                  type="link"
                  style={{ padding: "0", marginRight: "6px" }}
                  size="small"
                  disabled={
                    val.status === PriceStatus.INACTIVE
                      ? !val.validActions.includes(PriceAction.ACTIVATE)
                      : !val.validActions.includes(PriceAction.DEACTIVATE)
                  }
                  onClick={() => {
                    changeStatus(val);
                  }}
                >
                  {val.status === PriceStatus.INACTIVE ? "Activate" : "Deactivate"}
                </Button>
              </>
            )}
            {user.canDeleteBasePrice && (
              <Button
                type="link"
                style={{ padding: "0" }}
                size="small"
                disabled={!val.validActions.includes(PriceAction.DELETE)}
                onClick={() => {
                  setDeletePrice(val);
                  setDeleteModalOpen(true);
                }}
              >
                Delete
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const EditColumns = getTableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: BasePriceModel) => ({
        key: record.id,
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const addBasePrice = () => {
    addPriceForm.validateFields().then(() => {
      let { name } = addPriceForm.getFieldsValue(true);
      name = name.trim();
      setConfirmLoading(true);
      basePriceAPI
        .createBasePrice(name)
        .then(() => {
          setIsAddModalOpen(false);
          addPriceForm.resetFields();
          message.success("Base price added successfully.");
          refreshBasicPriceList();
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    });
  };

  const updateBasePrice = () => {
    setConfirmLoading(true);
    basePriceAPI
      .updateBasePrice(_.assign(currentPrice, { id: editingKey }))
      .then(() => {
        message.success("Base price saved successfully.");
        refreshBasicPriceList();
      })
      .finally(() => {
        setEditingKey("");
        setConfirmLoading(false);
        setIsUpdateModalOpen(false);
      });
  };

  return (
    <>
      <Card
        title="Base Price List"
        extra={
          user.canCreateBasePrice && (
            <Button type="primary" shape="round" onClick={() => setIsAddModalOpen(true)}>
              + Add new base price
            </Button>
          )
        }
        className="page-container"
      >
        <Row justify={"space-between"} className={styles.filter}>
          <Space>
            <DropdownInput
              options={statusOptions}
              placeholder="All statuses"
              value={selectStatus}
              onChange={(statuses) => handleStatusChange(statuses as PriceStatus[])}
            />
            <Input
              allowClear
              value={basePriceName}
              onChange={handleInputChange}
              onBlur={() => setBasePriceName((prevState) => prevState?.trim())}
              onPressEnter={handleInputPressEnter}
              className={styles.basePriceNameInput}
              prefix={<SearchOutlined />}
              placeholder="Search Base Price Name"
            />
          </Space>
          {user.canConfigureCurrency && (
            <ConfigureModal
              tooltipTitle={"Configure Currency"}
              title={"Configure Currency"}
              tableRef={tableRef}
              onChange={refreshCurrencyAssociation}
            >
              <CurrencyConfigureForm ref={tableRef} />
            </ConfigureModal>
          )}
        </Row>
        <Prompt when={editingKey !== ""} message={LEAVE_CONFIRMATION} />
        <Form form={editForm} component={false}>
          <Table
            loading={{
              spinning: isCurrencyAssociationPending || isBasicPriceListPending,
            }}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            // @ts-ignore
            columns={EditColumns}
            // @ts-ignore
            dataSource={basePriceTableData}
            pagination={{
              showTotal: getTotalDisplay,
              pageSize: query.limit,
              defaultCurrent: 1,
              total: basePriceList.total,
              current: query.page,
              onChange: (page, pageSize) => {
                setQuery({ ...query, page, limit: pageSize });
              },
            }}
            scroll={{ x: 500 }}
            className={styles.basePriceTable}
          />
        </Form>
      </Card>
      <EComModal
        visible={isAddModalOpen}
        title="Add new price type"
        okText="Confirm"
        onCancel={() => {
          setIsAddModalOpen(false);
          addPriceForm.resetFields();
        }}
        onOk={addBasePrice}
        confirmLoading={confirmLoading}
      >
        <Form form={addPriceForm} layout="vertical">
          <Form.Item label="Base price type name" name="name" rules={[{ required: true, max: 100 }]}>
            <Input data-testid={"basePriceName"} />
          </Form.Item>
        </Form>
      </EComModal>
      <EComModal
        visible={isUpdateModalOpen}
        title={"Confirm Change"}
        okText="Confirm"
        onCancel={() => {
          setIsUpdateModalOpen(false);
        }}
        onOk={updateBasePrice}
        confirmLoading={confirmLoading}
      >
        <LinkProduct
          originalData={originalData}
          currentPriceList={currentPrice}
          text={"Your changes will applies to the following products:"}
          footerText={"The changes will be live instantly."}
        />
      </EComModal>
      <EComModal
        visible={isDeleteModalOpen}
        title="Delete Confirmation"
        okText="Confirm"
        onCancel={() => {
          setDeleteModalOpen(false);
        }}
        onOk={deleteBasePrice(deletePrice.id)}
        confirmLoading={confirmLoading}
      >
        <p>
          Are you sure you want to delete <span className={styles.fontBold}>{deletePrice.name}</span>? This will
          permanently delete the base price.
        </p>
      </EComModal>
    </>
  );
}

export default BasePricePage;
