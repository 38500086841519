import React from "react";
import { Tooltip } from "antd";
import classnames from "classnames";
import styles from "./Indicator.module.scss";

interface Props {
  hasExpired?: boolean;
}

export default function Indicator({ hasExpired = false }: Props) {
  return (
    <Tooltip placement="top" title={hasExpired ? "Expired" : "Active"}>
      <span className={classnames(styles.indicator, hasExpired && styles.hasExpired)} />
    </Tooltip>
  );
}
