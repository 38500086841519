import { eComInstance } from "../utils/instance";
import { DisclosureListResponse } from "../types/dto/response/disclosure";
import {
  transformDisclosureDetail,
  transformDisclosureFormDataRequest,
  transformDisclosureList,
} from "../types/transformers/disclosure";
import DisclosureListRequest from "../types/dto/request/disclosure";
import { DisclosureFormData, DisclosureStatus } from "../types/model/disclosure";

export async function fetchDisclosureList(params: DisclosureListRequest) {
  const url = "/disclosures";
  return eComInstance
    .get<DisclosureListResponse>(url, { params })
    .then((res) => transformDisclosureList(res));
}

export async function fetchDisclosureById(id: string) {
  const url = `/disclosures/${id}`;
  return eComInstance.get(url).then((res) => transformDisclosureDetail(res));
}

export async function createDisclosure(disclosure: DisclosureFormData) {
  const url = "/disclosures";
  return eComInstance.post(url, transformDisclosureFormDataRequest(disclosure)).then((res) => res);
}

export async function deleteDisclosure(id: string) {
  const url = `/disclosures/${id}`;
  return eComInstance.delete(url);
}

export async function updateDisclosure(disclosure: DisclosureFormData, id: string) {
  const url = `/disclosures/${id}`;
  return eComInstance
    .put(url, transformDisclosureFormDataRequest(disclosure))
    .then((res) => transformDisclosureDetail(res));
}

export async function switchDisclosureStatus(id: string, status: DisclosureStatus) {
  const url = `/disclosures/switch-status`;
  return eComInstance.put(url, { id, status }).then((res) => transformDisclosureDetail(res));
}
