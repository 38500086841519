import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Input, Popover, Space, Radio } from "antd";
import Icon from "@ant-design/icons";
import { FormProps } from "antd/lib/form";
import { CheckboxOptionType } from "antd/lib/checkbox/Group";
import { DropdownInput } from "components";
import StartDateInput from "components/StartDateInput";
import EndDateInput from "components/EndDateInput";
import ProductListRequest from "types/dto/request/ProductListRequest";
import { ProductType } from "types/model/product";
import { OptionType } from "types/helper";
import { DropDown } from "assets/icons";
import LanguageSelect from "../LanguageSelect";
import styles from "./ProductAdvancedSearch.module.scss";

export const statusOptions = [
  { label: "Active", value: "ACTIVE" },
  { label: "In Review", value: "READY_FOR_REVIEW" },
  { label: "Staging", value: "STAGING" },
];

export const productTypeOptions: OptionType<ProductType>[] = [
  { label: "Single level", value: "SINGLE_LEVEL" },
  { label: "Multi-level", value: "MULTI_LEVEL" },
  { label: "Subscription", value: "SUBSCRIPTION" },
  { label: "Lesson pack", value: "LESSON_PACK" },
  { label: "All Access", value: "ALL_ACCESS" },
  { label: "Free", value: "FREE" },
  { label: "Upgrade", value: "UPGRADE" },
  { label: "Upsell", value: "UPSELL" },
  { label: "DVD", value: "DVD" },
];

const courseTypeOptions: CheckboxOptionType[] = [
  { label: "Standard", value: false },
  { label: "Premium", value: true },
];

export interface ProductAdvancedSearchProps {
  initialValues: ProductListRequest;
  onSubmit: (values: ProductListRequest) => void;
}

function ProductAdvancedSearch({ initialValues, onSubmit }: ProductAdvancedSearchProps) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const handleSearch: FormProps<ProductListRequest>["onFinish"] = (values) => {
    onSubmit(values);
    setVisible(!visible);
  };

  useEffect(() => form.resetFields(), [form, initialValues]);

  /** toggle tooltip and reset form value to initial search condition. */
  function toggleAdvanceSearchButton() {
    setVisible((prevState) => {
      form.resetFields();
      return !prevState;
    });
  }

  const handleBlur = () => {
    const isbn = form.getFieldValue("isbn");
    form.setFieldsValue({
      ...form?.getFieldsValue(),
      isbn: isbn && isbn.trim(),
    });
  };
  const content = (
    <Form<ProductListRequest>
      form={form}
      layout="inline"
      initialValues={initialValues}
      className={styles.searchForm}
      onFinish={handleSearch}
    >
      <Space direction="vertical" align="end" size={24}>
        <Form.Item label="Modified at">
          <Form.Item noStyle name="lastModifiedDateFrom">
            <StartDateInput form={form} endFieldName="lastModifiedDateTo" />
          </Form.Item>
          <span style={{ margin: "0 20px" }}>to</span>
          <Form.Item noStyle name="lastModifiedDateTo">
            <EndDateInput form={form} startFieldName="lastModifiedDateFrom" />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Language" name="languageId">
          <LanguageSelect />
        </Form.Item>
        <Form.Item label="Status" name="statuses">
          <DropdownInput width={408} options={statusOptions} placeholder="All statuses" />
        </Form.Item>
      </Space>

      <Space direction="vertical" align="end" size={24}>
        <Form.Item label="Product type" name="productTypes">
          <DropdownInput width={408} options={productTypeOptions} placeholder="All product types" />
        </Form.Item>

        <Form.Item
          label="Course type"
          name="isPremium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Radio.Group options={courseTypeOptions} />
        </Form.Item>

        <Form.Item label="ISBN" name="isbn">
          <Input onBlur={handleBlur} />
        </Form.Item>
      </Space>

      <Divider />

      <Space className={styles.buttonGroup}>
        <Button type="default" shape="round" onClick={toggleAdvanceSearchButton}>
          Cancel
        </Button>
        <Button type="primary" shape="round" htmlType="submit">
          Search
        </Button>
      </Space>
    </Form>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      visible={visible}
      overlayClassName={styles.popover}
      onVisibleChange={toggleAdvanceSearchButton}
    >
      <Button type="default" shape="round">
        Advanced Search
        <Icon component={DropDown} />
      </Button>
    </Popover>
  );
}

export default ProductAdvancedSearch;
