import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { range } from "lodash";
import { Button, Col, Divider, Form, Input, Radio, Row, Select } from "antd";
import Icon from "@ant-design/icons";
import { RootState } from "app/store";
import ProductModel, { COURSES_FOR_SINGLE_LEVEL, PRODUCT_CODE, PRODUCT_TYPES, ProductType } from "types/model/product";
import { preventNonNumericalInput } from "utils/preventNonNumericalInput";
import { DropDown, Minus, Plus } from "assets/icons";
import { DisplayInput, InputNumber, LessonRangeInput } from "components";
import { PRODUCT_TYPE_VIEW_MAPPING } from "constants/common";
import { productsSelectors } from "../../productSlice";
import styles from "./ProductDetail.module.scss";

const rules: Record<string, React.ComponentProps<typeof Form.Item>["rules"]> = {
  lessonRange: [
    {
      validator: (_, lessonRange: ProductModel["lessonRange"]) => {
        let lessonFrom = lessonRange?.from;
        let lessonTo = lessonRange?.to;
        if (lessonFrom == null || lessonTo == null) {
          return Promise.reject("'Lesson range' is required");
        }
        if (![lessonFrom, lessonTo].every((x) => range(1, 31).includes(x))) {
          return Promise.reject("'Lesson range' must be between 1 and 30");
        } else if (lessonFrom > lessonTo) {
          return Promise.reject("start lesson must be smaller than end lesson");
        } else {
          return Promise.resolve();
        }
      },
    },
  ],
};

const visibilityConfig: Record<
  "lessonRange" | "version" | "premiumISBN" | "courseLevel" | "isPremium",
  ProductType[]
> = {
  lessonRange: ["SINGLE_LEVEL", "LESSON_PACK", "FREE"],
  version: ["SINGLE_LEVEL"],
  isPremium: PRODUCT_TYPES.filter((item) => item !== "ALL_ACCESS"),
  premiumISBN: ["UPGRADE"],
  courseLevel: ["SINGLE_LEVEL", "FREE", "LESSON_PACK", "MULTI_LEVEL", "UPSELL"],
};

export function getVisibility(productType?: ProductType) {
  return Object.entries(visibilityConfig).reduce(
    (prev, [key, val]) => ({ ...prev, [key]: val.includes(productType!) }),
    {} as Record<keyof typeof visibilityConfig, boolean>
  );
}

function ProductBasicDetailForm() {
  const { productId } = useParams<{ productId: string }>();
  const product = useSelector((state: RootState) => productsSelectors.selectById(state, productId));
  const { singleLevelProducts, premiumProducts } = useSelector((state: RootState) => state.addProduct);

  const productType = product?.productType;
  const isBundled = !!(productType && ["MULTI_LEVEL", "SUBSCRIPTION"].includes(productType));
  const isActive = product?.status === "ACTIVE" || product?.hasHistoricalActiveStatus;
  const wasActive = product?.hasHistoricalActiveStatus;
  const visibility = getVisibility(productType);

  let courseLevelOptions = [] as { label: any; value: any }[];
  switch (productType) {
    case "SINGLE_LEVEL":
      courseLevelOptions = COURSES_FOR_SINGLE_LEVEL?.map((course) => ({
        label: course.courseLevelName,
        value: course.courseLevelValue,
      }));
      break;
    case "FREE":
    case "LESSON_PACK":
      courseLevelOptions = singleLevelProducts?.map((singleLevelProduct) => ({
        label: `Level ${singleLevelProduct.course.courseLevel} ${singleLevelProduct.course.courseVersion}`,
        value: singleLevelProduct.course.courseId,
        coursename: singleLevelProduct.course.courseName,
      }));
      break;
    case "SUBSCRIPTION":
    case "MULTI_LEVEL":
      courseLevelOptions = singleLevelProducts?.map((singleLevelProduct) => ({
        label: `${singleLevelProduct.productCode} | ${singleLevelProduct.course.courseName} ${singleLevelProduct.course.courseVersion}`,
        value: singleLevelProduct.productId,
        courselevel: singleLevelProduct.course.courseLevel,
      }));
      break;
  }

  return (
    <div className={styles.basicForm}>
      <Form.Item name="id" hidden>
        <DisplayInput />
      </Form.Item>
      <Row gutter={16} style={{ width: "1056px" }}>
        <Col span={6}>
          <Form.Item name="productType" label="Product type">
            <Radio disabled checked>
              {productType && PRODUCT_TYPE_VIEW_MAPPING[productType]}
            </Radio>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="isbn" label="ISBN">
            <Input disabled={isActive} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="languageName" label="Language" required>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={16} style={{ width: "1056px" }}>
        {visibility.isPremium && (
          <Col span={6}>
            <Form.Item label="Course type" name="isPremium">
              <DisplayInput<ProductModel["isPremium"]>
                render={(x) =>
                  x !== undefined && (
                    <Radio checked disabled>
                      {x ? "Premium" : "Standard"}
                    </Radio>
                  )
                }
              />
            </Form.Item>
          </Col>
        )}

        {visibility.premiumISBN && (
          <Col span={12}>
            <Form.Item name={["premiumISBN", "value"]} label="Premium ISBN" rules={[{ required: true }]}>
              <Select
                allowClear
                showSearch
                listHeight={160}
                disabled={wasActive}
                getPopupContainer={(target) => target.parentNode}
                optionFilterProp={"label"}
                suffixIcon={<DropDown />}
                placeholder={"Please choose ISBN"}
                options={premiumProducts?.map((premiumProduct) => ({
                  label: `${premiumProduct.productCode} | ${premiumProduct.productName}`,
                  value: premiumProduct.productId,
                }))}
              />
            </Form.Item>
          </Col>
        )}

        {visibility.courseLevel && (
          <Col span={isBundled ? 24 : 6}>
            <Form.Item noStyle>
              <Form.Item
                name={productType === "SINGLE_LEVEL" ? ["course", "courseLevel"] : ["course", "courseId"]}
                label={productType === "MULTI_LEVEL" ? "Single level product" : "Course level"}
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  disabled={wasActive || !isBundled} // if it was active or not bundled product, can't edit here.
                  suffixIcon={<DropDown />}
                  mode={isBundled ? "multiple" : undefined}
                  placeholder={"Please choose course level"}
                  getPopupContainer={(target) => target.parentNode}
                  options={courseLevelOptions}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        )}

        {visibility.lessonRange && (
          <Col span={6}>
            <Form.Item label="Lesson range" required name={"lessonRange"} rules={rules.lessonRange}>
              <LessonRangeInput disabled={[true, true]} />
            </Form.Item>
          </Col>
        )}

        {visibility.version && (
          <Col span={6}>
            <Form.Item name={["course", "courseVersion"]} label="Version" required rules={[{ required: true }]}>
              <Input disabled={wasActive || !isBundled} />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Divider />

      <Row gutter={16} style={{ width: "1056px" }}>
        <Col span={12}>
          <Form.Item name="upsellName" label="Course name - Upsell card & Library" rules={[{ max: 500 }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="learnName" label="Course name - Learn page" rules={[{ max: 500 }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ width: "1056px" }}>
        <Col span={24}>
          <Form.Item
            className={styles.upsellDescriptionItem}
            name="upsellDescription"
            label="Course description - Upsell card"
            rules={[{ max: 2000 }]}
          >
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ width: "1056px" }}>
        <Col span={6}>
          <Form.Item name={["price", "amount"]} label="Price" rules={[{ required: true, type: "number", min: 0 }]}>
            <InputNumber disabled={isActive || productType === "FREE"} onKeyPress={preventNonNumericalInput} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={["price", "currency"]} label="Currency">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ width: "1056px" }}>
        <Col span={24}>
          <Form.Item name="notes" label="Note" rules={[{ max: 2000 }]}>
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

export default ProductBasicDetailForm;
