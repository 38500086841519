import React from "react";
import { useHistory, useRouteMatch } from "react-router";
import { Button } from "antd";

interface Props {
  text: string;
}
function AddButton({ text }: Props) {
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleClick = () => history.push(`${path}/add`);

  return (
    <Button type="primary" shape="round" onClick={handleClick}>
      {text}
    </Button>
  );
}

export default AddButton;
