import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { PrivateRoute } from "../../components";
import { Permission } from "../../types/permission";
import AddNewUpsellDowngrade from "./UpsellDowngrade/AddNewUpsellDowngrade";
import UpsellDowngradeList from "./UpsellDowngrade/UpsellDowngradeList";
import UpsellDowngradeDetails from "./UpsellDowngrade/UpsellDowngradeDetails";
import PromotionList from "./promotion/PromotionList";
import AddNewPromotion from "./promotion/AddNewPromotion";
import PromotionDetails from "./promotion/PromotionDetails";
import SaveOfferList from "./SaveOffer/SaveOfferList";
import ViewSaveOfferDetail from "./SaveOffer/ViewDetails";
import AddNewSaveOffer from "./SaveOffer/AddNewSaveOffer";

function Marketing() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <Redirect to={`${path}/upsell-downgrade`} />
      </Route>
      <PrivateRoute exact path={`${path}/upsell-downgrade`} permission={Permission.VIEW_UPSELL_DOWNGRADE_MENU}>
        <UpsellDowngradeList />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/upsell-downgrade/add`} permission={Permission.CREATE_UPSELL_DOWNGRADE}>
        <AddNewUpsellDowngrade />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/upsell-downgrade/:id`} permission={Permission.VIEW_UPSELL_DOWNGRADE_DETAIL}>
        <UpsellDowngradeDetails />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/promotions`} permission={Permission.VIEW_PROMOTION_MENU}>
        <PromotionList />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/promotions/add`} permission={Permission.CREATE_PROMOTION}>
        <AddNewPromotion />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/promotions/:id`} permission={Permission.VIEW_PROMOTION_DETAIL}>
        <PromotionDetails />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/save-offer`} permission={Permission.VIEW_SAVE_OFFER_MENU}>
        <SaveOfferList />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/save-offer/add`} permission={Permission.CREATE_SAVE_OFFER}>
        <AddNewSaveOffer />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/save-offer/:id`} permission={Permission.VIEW_SAVE_OFFER_DETAIL}>
        <ViewSaveOfferDetail />
      </PrivateRoute>
    </Switch>
  );
}

export default Marketing;
