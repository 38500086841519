import React, { useMemo } from "react";
import { capitalize } from "lodash";
import styles from "./index.module.scss";
import { isPresetStatusColor, presetStatusColors, PresetStatusColorType, STATUS_TYPE } from "../../utils/color";

interface DotProps {
  color?: PresetStatusColorType | string;
  size?: number;
  gap?: number;
  children?: React.ReactNode;
}

const CustomDot = ({ color = "#9c9c9c", size = 8, gap = 4, children }: DotProps) => {
  const isPresetStatus = useMemo(() => color && isPresetStatusColor(color), [color]);
  const backgroundColor = useMemo(() => {
    return color && isPresetStatus ? presetStatusColors[color.toLowerCase() as STATUS_TYPE] : color;
  }, [color, isPresetStatus]);

  const content = useMemo(() => {
    return children === undefined && isPresetStatus ? capitalize(color) : children;
  }, [children, isPresetStatus, color]);

  return (
    <div className={styles.dotWrapper} style={{ gap }}>
      <span className={styles.dot} style={{ backgroundColor, width: size, height: size }}></span>
      {content}
    </div>
  );
};

export default CustomDot;
