import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Card, Form, Space, Spin } from "antd";
import { message, Modal } from "components";
import { RootState, useAppDispatch } from "app/store";
import { LEAVE_CONFIRMATION } from "../../../constants/common";
import {
  fetchOrganization,
  organizationsSelectors,
  removeOrganization,
  updateOrganization,
} from "../organizationSlice";
import OrganizationInfoForm from "./OrganizationInfoForm";
import OrganizationForm, { OrganizationFormProps } from "../OrganizationForm";
import { renderDeletionMessage } from "../configs";

function OrganizationDetail() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { organizationId } = useParams<{ organizationId: string }>();
  const isLoading = useSelector((state: RootState) => state.loading);
  const organization = useSelector((state: RootState) => organizationsSelectors.selectById(state, organizationId));
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchOrganization(organizationId)).then(unwrapResult).catch(message.error);
  }, [dispatch, organizationId]);

  const handleSubmit: OrganizationFormProps["onFinish"] = (values) => {
    const payload = { ...values, groupAdmins: values?.groupAdmins ?? [] };
    dispatch(updateOrganization(payload))
      .then(unwrapResult)
      .then(() => dispatch(fetchOrganization(organizationId)))
      .then(unwrapResult)
      .then(() => setEditing(false))
      .catch(message.error);
  };

  function showConfirmModal() {
    Modal.confirm({
      title: "Confirm",
      content: LEAVE_CONFIRMATION,
      onOk: () => {
        setEditing(false);
      },
    });
  }

  function toggleEdit() {
    setEditing(!editing);
  }

  function onDelete() {
    return Modal.confirm({
      title: "Confirm",
      content: organization?.name && renderDeletionMessage(organization?.name),
      onOk: () =>
        dispatch(removeOrganization(parseInt(organizationId)))
          .then(unwrapResult)
          .then(() => history.push("/organization"))
          .catch(message.error),
    });
  }

  const buttonGroups = editing ? (
    <Space size={12}>
      <Button type="default" shape="round" onClick={showConfirmModal}>
        Cancel
      </Button>
      <Button type="primary" shape="round" onClick={() => form.submit()}>
        Save
      </Button>
    </Space>
  ) : (
    <Space size={12}>
      <Button type="default" shape="round" onClick={onDelete}>
        Delete
      </Button>
      <Button type="default" shape="round" onClick={toggleEdit}>
        Edit
      </Button>
    </Space>
  );

  return (
    <Card title={organization?.name} extra={buttonGroups} className="page-container">
      <Spin spinning={isLoading} size={"large"}>
        {editing ? (
          <OrganizationForm data={organization} form={form} onFinish={handleSubmit} />
        ) : (
          <OrganizationInfoForm data={organization} />
        )}
      </Spin>
    </Card>
  );
}

export default OrganizationDetail;
