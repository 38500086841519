import React, { useContext } from "react";
import { useHistory } from "react-router";
import * as queryString from "query-string";
import { unwrapResult } from "@reduxjs/toolkit";
import { CustomerModel } from "types/model/customer";
import { Button, Input, Space } from "antd";
import { message, notification, Modal } from "components";
import { useAppDispatch } from "app/store";
import useAppAuth0 from "hooks/useAppAuth0";
import { fetchCustomers, removeCustomer, resetPassword } from "../customerSlice";
import { boldAndItalic } from "../../../utils";
import { CustomerListContext } from "./CustomerListContext";

interface CustomerActionProps {
  record: CustomerModel;
}

function CustomerAction({ record }: CustomerActionProps) {
  const { user } = useAppAuth0();
  const dispatch = useAppDispatch();
  const { email } = record;
  const { dataSource, query } = useContext(CustomerListContext);
  const history = useHistory();

  const showResetPasswordModal = () => {
    Modal.confirm({
      title: "Send email",
      content: `Are you sure you want to send reset password email to ${email}?`,
      okText: "Send",
      onOk: () =>
        dispatch(resetPassword(email))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: "Email Send！",
              description: `Reset password Email has been successfully send to ${email}`,
            });
          })
          .catch(message.error),
    });
  };

  const refetch = async () => {
    if (query.page && query.page >= 1 && dataSource.length <= 1) {
      return history.push({
        search: queryString.stringify(
          { ...query, page: query.page - 1 },
          {
            arrayFormat: "comma",
          }
        ),
      });
    }
    return unwrapResult(await dispatch(fetchCustomers(query)));
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Space size={12}>
        <Button type="link" disabled={!record.supportResetPassword} onClick={showResetPasswordModal}>
          Reset Password
        </Button>
        {user.canDeleteCustomer && (
          <Button
            type="link"
            onClick={() => {
              const modal = Modal.confirm({
                title: "Confirm",
                content: (
                  <Space direction="vertical" size={12}>
                    {`Please be aware that we will delete all this customer's information, including Auth0 account, entitlement, learning progress, vocabulary. This can not be undone.`}
                    {boldAndItalic`To confirm deletion, type ${record.email}.`}
                    <Input
                      onChange={(e) =>
                        modal.update((prevConfig) => ({
                          ...prevConfig,
                          okButtonProps: {
                            ...prevConfig.okButtonProps,
                            disabled: e.target.value !== record.email,
                          },
                        }))
                      }
                    />
                  </Space>
                ),
                okText: "Delete",
                okButtonProps: {
                  disabled: true,
                  shape: "round",
                  style: { minWidth: "92px" },
                },
                onOk: () =>
                  dispatch(removeCustomer(record.customerId)).then(unwrapResult).then(refetch).catch(message.error),
              });
            }}
          >
            Delete
          </Button>
        )}
      </Space>
    </div>
  );
}

export default CustomerAction;
