import { DescriptionTemplateListResponse, DescriptionTemplateResponse } from "../dto/response/description";
import {
  DescriptionTemplateListModel,
  DescriptionTemplateDetailModel,
  DescriptionTemplateFormData,
} from "../model/description";
import { supportLocalTabs } from "../../utils/locale";
import { parseContent } from "../../utils/richTextEditor";
import { DescriptionTemplateCreateRequest, DescriptionTemplateUpdateRequest } from "../dto/request/description";

export function transformDescriptionTemplate(response: DescriptionTemplateResponse): DescriptionTemplateDetailModel {
  return {
    id: response.id,
    name: response.name,
    contents: supportLocalTabs.map((tab) => {
      return {
        label: tab.label,
        key: tab.key,
        locale: tab.locale,
        content: parseContent(response.multi_language_content?.[tab.locale] ?? ""),
        required: tab.required,
      };
    }),
    updatedAt: response.updated_at,
    isUsed: response.is_used ?? false,
  };
}

export function transformDescriptionTemplateList(
  response: DescriptionTemplateListResponse
): DescriptionTemplateListModel {
  return {
    list: response.data.map((item) => ({
      id: item.id,
      name: item.name,
      content: item.multi_language_content?.en,
      updatedAt: item.updated_at,
      isUsed: item.is_used ?? false,
    })),
    total: response.count,
  };
}

const transformDescriptionTemplateRequest = (
  description: DescriptionTemplateFormData
): DescriptionTemplateCreateRequest | DescriptionTemplateUpdateRequest => {
  return {
    name: description.name,
    multi_language_content: {
      en: JSON.stringify(description.enContent),
      es: JSON.stringify(description.esContent),
    },
  };
};

export const transformDescriptionTemplateCreateRequest = transformDescriptionTemplateRequest;
export const transformDescriptionTemplateUpdateRequest = transformDescriptionTemplateRequest;
