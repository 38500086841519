import { combineReducers, createReducer, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import customerReducer from "../features/customer/customerSlice";
import productsReducer from "../features/products/productSlice";
import addProductReducer from "../features/products/ContentProduct/AddNewProduct/addProductSlice";
import voucherReducer from "../features/voucher/voucherSlice";
import organizationReducer from "../features/organization/organizationSlice";
import entitlementReducer from "../features/entitlements/entitlementSlice";
import miniCoursesReducer from "../features/miniCourse/miniCourseSlice";
import { api } from "../services";

const loadingReducer = createReducer(false, (builder) => {
  builder.addMatcher(isPending, () => {
    return true;
  });
  builder.addMatcher(isFulfilled, () => {
    return false;
  });
  builder.addMatcher(isRejected, () => {
    return false;
  });
});

const rootReducer = combineReducers({
  customer: customerReducer,
  products: productsReducer,
  addProduct: addProductReducer,
  voucher: voucherReducer,
  organization: organizationReducer,
  entitlement: entitlementReducer,
  miniCourses: miniCoursesReducer,
  loading: loadingReducer,
  [api.reducerPath]: api.reducer,
});

export default rootReducer;
