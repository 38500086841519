import React, { useEffect, useMemo, useState } from "react";
import { ConfigProvider, Divider, Form, FormInstance, Input, Space, Spin } from "antd";
import {
  EComProductStatus,
  EComProductStatusType,
  NewPlanFormFieldValues,
  PRODUCT_TYPE,
} from "types/model/eComProduct";
import { validateMessages } from "../../../../../constants/common";
import styles from "./index.module.scss";
import CourseFormItems from "./components/CourseFormItems";
import PricingFormItems from "./components/PricingFormItems";
import DescriptionTemplate from "./components/DescriptionTemplate";
import PlanImage from "./components/PlanImage";

interface IAddNewPlanFormProps {
  form: FormInstance;
  initialValues?: NewPlanFormFieldValues;
  onFormChange: (values: any) => void;
  status?: EComProductStatusType;
}

const AddNewPlanForm = ({ form, initialValues, onFormChange, status }: IAddNewPlanFormProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPlanImageLoading, setIsPlanImageLoading] = useState(false);
  const [isDescriptionLoading, setDescriptionLoading] = useState(false);
  const disabledFields = status === EComProductStatus.Active || status === EComProductStatus.Inactive;
  const [selectedProductType, setSelectedProductType] = useState<PRODUCT_TYPE | null>(
    form.getFieldValue("contentProductType")
  );

  const [selectedBillingPlanId, setSelectedBillingPlanId] = useState<string | undefined>(
    form.getFieldValue("billingPlanId")
  );
  const isLifetime = useMemo(
    () => selectedProductType !== PRODUCT_TYPE.SUBSCRIPTION && selectedProductType !== PRODUCT_TYPE.ALL_ACCESS,
    [selectedProductType]
  );
  const disabledJoinUpsell = useMemo(() => selectedProductType === PRODUCT_TYPE.UPGRADE, [selectedProductType]);

  useEffect(() => {
    setSelectedProductType(form.getFieldValue("contentProductType"));
  }, [form.getFieldValue("contentProductType")]);

  const save = () => {
    onFormChange(form.getFieldsValue());
  };

  const handlePlanImageChange = (url: string) => {
    form.setFieldsValue({ productImage: url });
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Spin spinning={isLoading || isPlanImageLoading || isDescriptionLoading} size={"large"}>
      <ConfigProvider form={{ validateMessages }}>
        <Form
          className={styles.form}
          form={form}
          validateTrigger={"onChange"}
          onFinish={save}
          initialValues={
            initialValues ?? {
              pid: "-",
              contentProductType: "-",
              contentProductStatus: "-",
              checkoutDeeplink: "-",
              billingCycle: "-",
              version: "-",
              courseLevel: "-",
              lessonRange: {
                start: "-",
                end: "-",
              },
              joinUpsell: true,
            }
          }
        >
          <Space size={24} direction={"vertical"}>
            <Space size={32} className={styles.fixedSpace}>
              <Form.Item
                label="Plan Name"
                name="name"
                required
                rules={[
                  { required: true },
                  {
                    type: "string",
                    max: 100,
                    message: "'Plan Name' cannot be longer than 100 characters",
                  },
                ]}
                className={styles.formItem}
              >
                <Input />
              </Form.Item>
              <Form.Item label="PID" name="pid" required className={styles.formItem}>
                <Input disabled={true} />
              </Form.Item>
            </Space>
            <Divider />
            <CourseFormItems
              form={form}
              selectedProductType={selectedProductType}
              setSelectedProductType={setSelectedProductType}
              setSelectedBillingPlanId={setSelectedBillingPlanId}
              setIsLoading={setIsLoading}
              disabled={disabledFields}
            />
            <Divider />
            <PricingFormItems
              form={form}
              setPricingLoading={setIsLoading}
              isLifetime={isLifetime}
              disabled={disabledFields}
              disabledJoinUpsell={disabledJoinUpsell}
              selectedBillingPlanId={selectedBillingPlanId}
              setSelectedBillingPlanId={setSelectedBillingPlanId}
            />
            <Divider />
            <Space size={32} className={styles.descriptionRow}>
              <DescriptionTemplate setDescriptionLoading={setDescriptionLoading} form={form} />
              <Form.Item
                label="Checkout Deeplink"
                name="checkoutDeeplink"
                required
                rules={[]}
                className={styles.formItem}
              >
                <Input disabled={true} />
              </Form.Item>
            </Space>
            <PlanImage form={form} setIsPlanImageLoading={setIsPlanImageLoading} onChange={handlePlanImageChange} />
          </Space>
        </Form>
      </ConfigProvider>
    </Spin>
  );
};

export default AddNewPlanForm;
