import ReactQuill, { UnprivilegedEditor } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect, useRef } from "react";
import { DeltaStatic, Sources } from "quill";
import styles from "./index.module.scss";

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "bullet" }, { list: "ordered" }],
    ["link", "clean"],
  ],
};

const disabledModules = {
  toolbar: false,
};

interface RichTextEditorProps {
  bounds?: string | HTMLElement;
  readonly?: boolean;
  value: DeltaStatic | string;
  onChange: (content: string, delta: DeltaStatic, source: Sources, editor: UnprivilegedEditor) => void;
}

const RichTextEditor = ({ bounds, readonly, value, onChange }: RichTextEditorProps) => {
  const quillRef = useRef<ReactQuill>(null);
  useEffect(() => {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      quill.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
        if (delta.ops) {
          delta.ops.forEach(function (op) {
            if (op.attributes) {
              delete op.attributes;
            }
          });
        }
        return delta;
      });
    }
  }, []);

  if (readonly) {
    return (
      <ReactQuill theme={"snow"} value={value} modules={disabledModules} readOnly className={styles.rteDisabled} />
    );
  }

  return (
    <div data-text-editor={`editor-container-${bounds}`}>
      <ReactQuill
        ref={quillRef}
        theme={"snow"}
        value={value}
        onChange={onChange}
        modules={modules}
        className={styles.rte}
        bounds={`[data-text-editor="editor-container-${bounds}"]`}
      />
    </div>
  );
};

export default RichTextEditor;
