import React from "react";
import { ProductStatus } from "types/model/product";
import { PRODUCT_STATUS_VIEW_MAP } from "constants/common";
import styles from "./ProductStatus.module.scss";

interface ProductStatusProps {
  status: ProductStatus;
}

function ProductStatusView({ status }: ProductStatusProps) {
  let iconStyle;

  switch (status) {
    case "ACTIVE":
      iconStyle = styles.active;
      break;
    case "READY_FOR_REVIEW":
      iconStyle = styles.readyForReview;
      break;
    case "STAGING":
      iconStyle = styles.staging;
      break;
    case "ARCHIVED":
      iconStyle = styles.archived;
      break;
  }
  return <div className={iconStyle}>{PRODUCT_STATUS_VIEW_MAP.get(status)}</div>;
}

export default ProductStatusView;
