import { supportLocalTabs } from "../../../utils/locale";
import { ContentBase } from "../../../types/model/description";

export const transformDescriptionDisclosure = (values: any, contentKey: string) => {
  return Object.keys(values)
    .filter((key) => key.includes(contentKey))
    .map((key) => {
      const locale = key.split(contentKey)[0];
      return {
        ...supportLocalTabs.find((item) => item.key === locale),
        required: false,
        content: values[key],
      } as ContentBase;
    });
};
