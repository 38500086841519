import React, { ChangeEvent, useEffect, useState } from "react";
import { Card, Input, Row, Space, Table } from "antd";
import { useRouteMatch } from "react-router-dom";
import AddButton from "components/AddButton";
import { ArrayParam, NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { useHistory } from "react-router";
import omit from "lodash/omit";
import { SorterResult } from "antd/lib/table/interface";
import { ColumnType, TableProps } from "antd/lib/table";
import { SearchOutlined } from "@ant-design/icons";
import getTableColumns from "./getTableColumns";
import { DisclosureListModel, DisclosureModel, DisclosureStatus } from "../../../types/model/disclosure";
import ResizableTitle from "../../../components/ResizableTitle";
import { getTotalDisplay } from "../../../utils/getTotalDisplay";
import { getOffset } from "../../../utils/getPagination";
import styles from "./index.module.scss";
import { updateSortOrder } from "../../../types/transformers/updateSortOrder";
import * as eComDisclosureAPI from "../../../services/disclosure";
import DisclosureListRequest from "../../../types/dto/request/disclosure";
import useAppAuth0 from "../../../hooks/useAppAuth0";
import DropdownInput from "../../../components/DropdownInput";

const statusOptions = [
  { label: "Active", value: DisclosureStatus.ACTIVE },
  { label: "Inactive", value: DisclosureStatus.INACTIVE },
];

function DisclosureList() {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
    name: withDefault(StringParam, undefined),
    order: withDefault(StringParam, "-updated_at"),
    status: withDefault(ArrayParam, undefined),
  });
  const history = useHistory();
  const { user } = useAppAuth0();
  const [selectStatus, setSelectStatus] = useState<DisclosureStatus[]>([]);
  const [isDisclosureListPending, setIsDisclosureListPending] = useState(false);
  const [disclosureList, setDisclosureList] = useState<DisclosureListModel>({} as DisclosureListModel);
  const [name, setName] = useState(query.name);
  const columns = getTableColumns();
  const [tableColumns, setTableColumns] = useState<ColumnType<DisclosureModel>[]>(columns);
  const { path } = useRouteMatch();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleInputPressEnter = () => {
    setQuery({
      ...query,
      name: name?.trim(),
      page: name?.trim() ? 1 : query.page,
    });
  };

  const refreshDisclosureList = () => {
    setIsDisclosureListPending(true);
    eComDisclosureAPI
      .fetchDisclosureList({
        ...omit(query, ["page"]),
        offset: getOffset(query.page, query.limit),
      } as DisclosureListRequest)
      .then((res) => {
        setDisclosureList(res);
      })
      .finally(() => {
        setIsDisclosureListPending(false);
      });
  };

  useEffect(() => {
    refreshDisclosureList();
    setName(query.name);
  }, [query]);

  const handleTableChange: TableProps<DisclosureModel>["onChange"] = (pagination, filters, sorter, extra) => {
    const { order } = sorter as SorterResult<DisclosureModel>;
    if (extra.action === "sort") {
      setQuery({
        ...query,
        order: updateSortOrder[order as string],
        page: 1,
        limit: pagination.pageSize,
      });
    }
  };
  const handleResize = (index: number) => (e: any, { size }: any) => {
    const nextColumns = [...tableColumns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    setTableColumns(nextColumns);
  };

  const columnsToDisplay = tableColumns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: ColumnType<DisclosureModel>) =>
      ({
        width: column.width,
        onResize: handleResize(index),
      } as React.HTMLAttributes<HTMLElement>),
  }));

  const handleOnRowClick: TableProps<DisclosureModel>["onRow"] = (record) => ({
    onClick: () =>
      history.push({
        pathname: `${path}/${record.id}`,
      }),
  });

  const handleStatusChange = (statuses: DisclosureStatus[]) => {
    setSelectStatus(statuses);
    setQuery({ ...query, status: statuses, page: 1 });
  };

  return (
    <Card
      title="Disclosure list"
      extra={user.canCreateDisclosure && <AddButton text={"+ Add new disclosure"} />}
      className="page-container"
    >
      <Row justify="space-between" className={styles.filter}>
        <Space>
          <DropdownInput
            options={statusOptions}
            placeholder="All statuses"
            value={selectStatus}
            onChange={(statuses) => handleStatusChange(statuses as DisclosureStatus[])}
          />
          <Input
            allowClear
            value={name}
            onChange={handleInputChange}
            onBlur={() => setName((prevState) => prevState?.trim())}
            onPressEnter={handleInputPressEnter}
            className={styles.disclosureNameInput}
            prefix={<SearchOutlined />}
            placeholder="Search disclosure template name"
          />
        </Space>
      </Row>

      <Table<DisclosureModel>
        loading={{
          spinning: isDisclosureListPending,
        }}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        rowKey={(record) => record.id}
        columns={columnsToDisplay}
        dataSource={disclosureList.list}
        onChange={handleTableChange}
        pagination={{
          showTotal: getTotalDisplay,
          current: query.page,
          pageSize: query.limit,
          total: disclosureList.total,
          defaultCurrent: 1,
          onChange: (page, pageSize) => {
            setQuery({
              ...query,
              page,
              limit: pageSize,
              order: query.order,
            });
          },
        }}
        onRow={handleOnRowClick}
      />
    </Card>
  );
}

export default DisclosureList;
