import React from "react";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { DropDown } from "../../../../../assets/icons";
import { DisplayLanguage } from "./index";
import styles from "./index.module.scss";

interface EComLanguageSelectProps {
  languages: Omit<DisplayLanguage, "usedCourseTypes">[];
  onChange?: (value: number) => void;
  disabled?: boolean;
}

export default function EComLanguageSelect({ languages, onChange, disabled, ...restProps }: EComLanguageSelectProps) {
  const handleInputKeyDown: SelectProps<any>["onInputKeyDown"] = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Select
      {...restProps}
      allowClear
      showSearch={true}
      listHeight={160}
      onInputKeyDown={handleInputKeyDown}
      onChange={onChange}
      getPopupContainer={(trigger) => trigger.parentNode}
      suffixIcon={<DropDown />}
      placeholder={"Please choose language"}
      optionFilterProp={"label"}
      disabled={disabled}
      options={languages.map(({ name, languageId, disabled }, index) => ({
        label: name,
        value: languageId,
        disabled,
      }))}
      className={styles.selectDropdown}
    />
  );
}
