import { useContext, useState } from "react";
import { Form, Modal, Space } from "antd";
import { map } from "lodash";
import styles from "../AddNewPlan/index.module.scss";
import {
  EComProductStatus,
  EComProductStatusType,
  NewPlanFormFieldValues,
  ProductPlan,
} from "../../../../../types/model/eComProduct";
import AddNewPlanForm from "../AddNewPlanForm";
import { message } from "../../../../../components";
import { ProductDetailContext } from "../../ProductDetails";
import * as eComProductAPI from "../../../../../services/eComProduct";
import { convertToProductPlan } from "../../../../../types/transformers/eComProduct";
import { EComModal } from "../../../../../components/EComModal";
import modalStyles from "../../../basePrice/index.module.scss";

interface IEditPlanModalProps {
  fetchData?: (pid: string) => void;
  initialValues?: NewPlanFormFieldValues;
  visible: boolean;
  productPlanId?: string;
  editingIndex?: number;
  setNewPlanFieldsValue?: any;
  closeModal: () => void;
  status?: EComProductStatusType;
}

interface IEditPopUpProps {
  editPopUp: boolean;
  name: string;
}

function EditPlan({
  setNewPlanFieldsValue,
  fetchData,
  visible,
  initialValues,
  editingIndex,
  productPlanId,
  closeModal,
  status,
}: IEditPlanModalProps) {
  const editProductInfo = useContext(ProductDetailContext);
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [editConfirmLoading, setEditConfirmLoading] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [editForm, setEditForm] = useState<NewPlanFormFieldValues>();
  const [planId, setPlanId] = useState<string>();
  const [isEditPopUp, setIsEditPopUp] = useState<IEditPopUpProps>();

  const handleFormChange = (values: any) => {
    form.setFieldsValue(values);
  };

  const handleSave = async () => {
    form.validateFields().then(() => {
      const value = form.getFieldsValue();
      if (value && setNewPlanFieldsValue && editingIndex !== undefined) {
        setNewPlanFieldsValue((prevPlans: ProductPlan[]) =>
          map(prevPlans, (plan, index) => (index === editingIndex ? value : plan))
        );
        closeModal();
      }
    });
  };

  function updatePlan(value: any, productPlanId: string | undefined) {
    if (value && editProductInfo?.productId && productPlanId) {
      isEditPopUp?.editPopUp ? setEditConfirmLoading(true) : setConfirmLoading(true);
      const payload = convertToProductPlan(value);
      eComProductAPI
        .updateProductPlan(editProductInfo.productId, productPlanId, payload)
        .then((r) => {
          message.success("Plan updated successfully.");
          if (fetchData) {
            fetchData(r.pid);
          }
        })
        .finally(() => {
          if (isEditPopUp?.editPopUp) {
            setEditConfirmLoading(false);
            setEditVisible(false);
          } else {
            setConfirmLoading(false);
            closeModal();
          }
        });
      setConfirmLoading(false);
      closeModal();
    }
  }

  const handleSaveOnEditPage = async () => {
    form.validateFields().then(() => {
      updatePlan(form.getFieldsValue(), productPlanId);
    });
  };

  const handleSavePage = async () => {
    const editPopUp = status === EComProductStatus.Active || status === EComProductStatus.Inactive;
    setIsEditPopUp({ editPopUp: editPopUp, name: form.getFieldsValue().name });
    if (productPlanId) {
      if (editPopUp) {
        form.validateFields().then(() => {
          setEditForm(form.getFieldsValue());
          setPlanId(productPlanId);
          setEditVisible(true);
          closeModal();
        });
      } else await handleSaveOnEditPage();
    } else {
      await handleSave();
    }
  };
  return (
    <>
      <Modal
        bodyStyle={{ overflowY: "auto", maxHeight: "70vh" }}
        visible={visible}
        centered={true}
        title="Edit plan"
        onCancel={() => {
          form.resetFields();
          closeModal();
        }}
        onOk={handleSavePage}
        okText="Save"
        maskClosable={false}
        cancelButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
        okButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
        className={styles.modal}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
      >
        <Space direction="vertical" size={12}>
          <AddNewPlanForm form={form} status={status} initialValues={initialValues} onFormChange={handleFormChange} />
        </Space>
      </Modal>
      <EComModal
        visible={editVisible}
        title="Confirm Change"
        okText="Confirm"
        onCancel={() => {
          setEditVisible(false);
        }}
        onOk={() => updatePlan(editForm, planId)}
        confirmLoading={editConfirmLoading}
      >
        <>
          <p>
            You are about to update the <span className={modalStyles.fontBold}>{isEditPopUp?.name}</span>.{" "}
          </p>
          <p>This update will be applied to the production environment and will be visible to customers.</p>
          <p>Please confirm that you want to proceed with the update.</p>
        </>
      </EComModal>
    </>
  );
}

export default EditPlan;
