import React, { useContext, useEffect, useState } from "react";
import { Form, FormInstance, Input, InputNumber, Select, Space } from "antd";
import { DropDown } from "assets/icons";
import { join } from "lodash";
import ProductModel from "../../../../../../../types/model/product";
import styles from "../../index.module.scss";
import * as productAPI from "../../../../../../../services/product";
import { DEFAULT_MAX_PAGINATION, PRODUCT_STATUS_VIEW_MAP } from "../../../../../../../constants/common";
import { AddNewPlanContext } from "../../../../AddNewProduct";
import { ProductDetailContext } from "../../../../ProductDetails";
import { PRODUCT_TYPE } from "../../../../../../../types/model/eComProduct";
import { getCourseLevels } from "../../../../../../../utils/ecomProductUtils";

interface Props {
  form: FormInstance;
  selectedProductType: PRODUCT_TYPE | null;
  setSelectedProductType: (productType: PRODUCT_TYPE | null) => void;
  setSelectedBillingPlanId: (billingPlanId?: string) => void;
  disabled?: boolean;
  setIsLoading: (loading: boolean) => void;
}

function CourseFormItems({
  form,
  selectedProductType,
  setSelectedProductType,
  setSelectedBillingPlanId,
  setIsLoading,
  disabled,
}: Props) {
  const productInfo = useContext(AddNewPlanContext);
  const editProductInfo = useContext(ProductDetailContext);
  const [courseOptions, setCourseOptions] = useState<ProductModel[]>([]);

  useEffect(() => {
    let ignore = false;

    if (productInfo || editProductInfo) {
      const { isAllAccess, isPremium, languageId } = productInfo ?? editProductInfo ?? {};
      setIsLoading(true);
      productAPI
        .fetchAll({
          languageId: isAllAccess ? undefined : languageId,
          isPremium: isAllAccess ? undefined : isPremium,
          productTypes: isAllAccess ? ["ALL_ACCESS"] : ["SINGLE_LEVEL", "MULTI_LEVEL", "SUBSCRIPTION", "UPGRADE"],
          isPublished: true,
          ...DEFAULT_MAX_PAGINATION,
        })
        .then((data) => {
          if (!ignore) {
            setCourseOptions(data.content);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    return () => {
      ignore = true;
    };
  }, []);

  const getProductDetails = (productId: string) => {
    setIsLoading(true);
    productAPI
      .fetchProduct(productId)
      .then((res) => {
        form.setFieldsValue({
          version: res.course?.courseVersion,
          courseLevel: res.course?.courseLevel,
          bundledProductIds: res.bundledProducts
            ?.map((bundledProduct) =>
              join(
                [bundledProduct.isbn, bundledProduct?.course?.courseName, bundledProduct?.course?.courseVersion],
                " "
              )
            )
            .sort(),
          selectedCourse: {
            lessonStart: res.lessonFrom,
            lessonEnd: res.lessonTo,
          },
          courseLevels: getCourseLevels(res),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function handleProductChange(value: string) {
    const selectedProduct = courseOptions.find((option) => option.isbn === value);

    if (selectedProduct) {
      getProductDetails(selectedProduct.productId.toString());
      const contentProductType = PRODUCT_TYPE[selectedProduct.productType as keyof typeof PRODUCT_TYPE];
      form.setFieldsValue({
        joinUpsell: contentProductType !== PRODUCT_TYPE.UPGRADE,
      });
      setSelectedProductType(contentProductType);
      setSelectedBillingPlanId(undefined);
      form.setFieldsValue({
        contentProductType,
        contentProductStatus: PRODUCT_STATUS_VIEW_MAP.get(selectedProduct.status),
        contentProductId: selectedProduct.productId,
        pid: "-",
        billingPlanId: null,
        checkoutDeeplink: "-",
        freeTrialType: undefined,
        freeTrialUsageLimit: undefined,
      });
    } else {
      form.resetFields([
        "contentProductType",
        "contentProductStatus",
        "version",
        "courseLevel",
        "bundledProductIds",
        "contentProductId",
      ]);
      form.setFieldsValue({
        selectedCourse: {
          lessonStart: null,
          lessonEnd: null,
        },
      });
    }
  }

  const renderFieldsByProductType = () => {
    switch (selectedProductType) {
      case PRODUCT_TYPE.SUBSCRIPTION:
      case PRODUCT_TYPE.MULTI_LEVEL:
        return (
          <Form.Item label="Single level product" name="bundledProductIds" className={styles.largeFormItem} required>
            <Select
              allowClear
              listHeight={160}
              optionFilterProp={"label"}
              getPopupContainer={(trigger) => trigger.parentNode}
              showArrow
              suffixIcon={<DropDown />}
              mode="multiple"
              placeholder="Please select course level"
              options={[]}
              disabled
            />
          </Form.Item>
        );
      case PRODUCT_TYPE.SINGLE_LEVEL:
        return (
          <Space size={32} direction="horizontal" className={styles.fixedSpace}>
            <Form.Item label="Version" name="version" rules={[{ required: true }]} className={styles.smallFormItem}>
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Course level"
              name="courseLevel"
              rules={[{ required: true }]}
              className={styles.smallFormItem}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item label="Lesson range" rules={[{ required: true }]} required className={styles.smallFormItem}>
              <Space size={22} align="baseline">
                <Form.Item noStyle name={["selectedCourse", "lessonStart"]}>
                  <InputNumber disabled />
                </Form.Item>
                <span>to</span>
                <Form.Item noStyle name={["selectedCourse", "lessonEnd"]}>
                  <InputNumber disabled />
                </Form.Item>
              </Space>
            </Form.Item>
          </Space>
        );
      case PRODUCT_TYPE.UPGRADE:
        return (
          <Form.Item label="Premium Content ISBN" name="bundledProductIds" rules={[{ required: true }]}>
            <Select
              allowClear
              listHeight={160}
              optionFilterProp={"label"}
              getPopupContainer={(trigger) => trigger.parentNode}
              showArrow
              suffixIcon={<DropDown />}
              placeholder="Please select course level"
              options={[]}
              disabled
            />
          </Form.Item>
        );
      case PRODUCT_TYPE.ALL_ACCESS:
      default:
        return null;
    }
  };

  return (
    <>
      <Form.Item name="contentProductId" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="courseLevels" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Space size={24} direction={"vertical"}>
        <Space direction="horizontal" size={32} className={styles.fixedSpace}>
          <Form.Item label="Course" name="isbn" rules={[{ required: true }]} className={styles.formItem}>
            <Select
              optionFilterProp={"label"}
              allowClear
              showSearch
              listHeight={160}
              getPopupContainer={(trigger) => trigger.parentNode}
              suffixIcon={<DropDown />}
              placeholder="Please choose Content ISBN"
              onChange={handleProductChange}
              options={courseOptions.map((option) => ({
                label: `${option.isbn} ${option.productName}`,
                value: option.isbn,
              }))}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            label="Content Product Type"
            name="contentProductType"
            rules={[{ required: true }]}
            className={styles.smallFormItem}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Content Product Status"
            name="contentProductStatus"
            rules={[{ required: true }]}
            className={styles.smallFormItem}
          >
            <Input disabled />
          </Form.Item>
        </Space>
        {renderFieldsByProductType()}
      </Space>
    </>
  );
}

export default CourseFormItems;
