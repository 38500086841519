import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  CustomerModel,
  GiftCardBalanceCurrencyItem,
  GiftModal,
  InAppPurchaseRecordModel,
  LearningProgressModel,
} from "types/model/customer";
import * as customerAPI from "services/customer";
import Page from "types/page";
import { initialPageRecords } from "constants/common";
import { CustomerInfoResponse, CustomerResponse } from "types/dto/response/customer";
import { CustomerListParams } from "types/dto/request/customer";
import { EntitlementModel } from "types/model/entitlement";
import { RootState } from "app/store";
import { TransactionModel } from "types/model/transactions";
import * as basePriceAPI from "../../services/basePrice";

export interface CustomersState {
  content: CustomerModel[];
  totalElements: number;
  currentPage: number;
  currentSize: number;
  customersGiftCardBalance: GiftCardBalanceCurrencyItem[];
  customersEntitlements: EntitlementModel[];
  customerTransactions: TransactionModel[];
  learningProgresses: Page<LearningProgressModel>;
  inAppPurchaseRecords: Page<InAppPurchaseRecordModel>;
  customerGift: GiftModal;
}

const initialState: CustomersState = {
  content: [],
  totalElements: 0,
  currentPage: 1,
  currentSize: 10,
  customersGiftCardBalance: [],
  customersEntitlements: [],
  customerTransactions: [],
  learningProgresses: initialPageRecords,
  inAppPurchaseRecords: initialPageRecords,
  customerGift: {
    givenList: [],
    receivedList: [],
  },
};

export const fetchCustomers = createAsyncThunk<Page<CustomerResponse>, CustomerListParams>(
  "customers/fetchAll",
  (params) => {
    return customerAPI.fetchAll(params);
  }
);

export const resetPassword = createAsyncThunk<void, string>(
  "customers/resetPassword",
  async (email) => await customerAPI.resetPassword(email)
);

export const fetchCurrencyAssociation = createAsyncThunk("customers/fetchCurrencyAssociation", async () => {
  return await basePriceAPI.fetchCurrencyAssociation();
});

export const updateGiftCardBalance = createAsyncThunk(
  "customers/updateGiftCardBalance",
  customerAPI.updateGiftCardBalance
);

export const fetchGiftCardBalance = createAsyncThunk(
  "customers/fetchGiftCardBalance",
  customerAPI.fetchGiftCardBalance
);

export const fetchEntitlementList = createAsyncThunk("customers/fetchEntitlementList", async (customerId: number) => {
  const data = await customerAPI.fetchEntitlementList(customerId);
  return data.content;
});

export const restoreEntitlement = createAsyncThunk("customers/restoreEntitlement", customerAPI.restoreEntitlement);

export const fetchTransactions = createAsyncThunk("customers/fetchTransactions", customerAPI.fetchTransactions);

export const fetchGift = createAsyncThunk("customers/fetchGift", customerAPI.fetchGift);

export const fetchCustomerDetail = createAsyncThunk("customers/fetchCustomerDetail", customerAPI.fetchCustomerDetail);

export const fetchLearningProgresses = createAsyncThunk(
  "customers/fetchLearningProgresses",
  customerAPI.fetchLearningProgresses
);

export const fetchInAppPurchaseRecords = createAsyncThunk(
  "customers/fetchInAppPurchaseRecords",
  customerAPI.fetchInAppPurchaseRecords
);

export const removeCustomer = createAsyncThunk("customers/delete", customerAPI.removeCustomer);

type Entity = CustomerModel & CustomerInfoResponse;

const customersAdapter = createEntityAdapter<Entity>({
  selectId: (model) => model.customerId,
});

export const customerSlice = createSlice({
  name: "customer",
  initialState: customersAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCustomers.fulfilled, (state, { payload }) => {
      state.totalElements = payload.totalElements;
      state.currentPage = payload.page + 1;
      state.currentSize = payload.size;
      state.content = payload.content;
      customersAdapter.upsertMany(state, payload.content);
    });
    builder.addCase(fetchGiftCardBalance.fulfilled, (state, { payload }) => {
      state.customersGiftCardBalance = payload;
    });
    builder.addCase(fetchEntitlementList.fulfilled, (state, { payload }) => {
      state.customersEntitlements = payload;
    });
    builder.addCase(fetchTransactions.fulfilled, (state, { payload }) => {
      state.customerTransactions = payload;
    });
    builder.addCase(fetchGift.fulfilled, (state, { payload }) => {
      state.customerGift = payload;
    });
    builder.addCase(fetchCustomerDetail.fulfilled, (state, { payload }) => {
      customersAdapter.upsertOne(state, payload);
    });
    builder.addCase(fetchLearningProgresses.fulfilled, (state, { payload }) => {
      state.learningProgresses = payload;
    });
    builder.addCase(fetchInAppPurchaseRecords.fulfilled, (state, { payload }) => {
      state.inAppPurchaseRecords = payload;
    });
  },
});

export default customerSlice.reducer;

export const customerSelectors = customersAdapter.getSelectors<RootState>((state) => state.customer);
