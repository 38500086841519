import React from "react";
import moment from "moment";
import { ColumnType } from "antd/lib/table";
import { DATETIME_FORMAT } from "constants/common";
import { EComProductModel } from "../../../../types/model/eComProduct";
import CustomDot from "../../../../components/CustomDot";

function getTableColumns(): ColumnType<EComProductModel>[] {
  return [
    {
      title: "PID",
      key: "pid",
      dataIndex: "pid",
      width: 155,
      ellipsis: true,
      render: (value) => value,
    },
    {
      title: "Language",
      key: "language",
      dataIndex: "language",
      width: 140,
      ellipsis: true,
      render: (value) => value || "-",
    },
    {
      title: "Course type",
      key: "courseType",
      dataIndex: "courseType",
      width: 98,
      ellipsis: true,
      render: (value) => value || "-",
    },
    {
      title: "Plan name",
      key: "planName",
      dataIndex: "planName",
      width: 180,
      ellipsis: true,
      render: (value) => value || "-",
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 132,
      ellipsis: true,
      render: (value) => {
        return <CustomDot color={value} />;
      },
    },
    {
      title: "Base price name",
      key: "basePriceName",
      dataIndex: "basePriceName",
      width: 165,
      ellipsis: true,
      render: (value) => value || "-",
    },
    {
      title: "Modified at",
      key: "updatedAt",
      dataIndex: "updatedAt",
      width: 155,
      ellipsis: true,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (value) => moment(value).format(DATETIME_FORMAT),
    },
  ];
}

export default getTableColumns;
