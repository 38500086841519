import React from "react";
import { Layout } from "antd";
import moment from "moment";
import styles from "./Footer.module.scss";

function Footer() {
  const statement = `© & ℗ ${moment().year()} Simon & Schuster, LLC Pimsleur® is an imprint of Simon & Schuster Audio, a division of Simon & Schuster, LLC All rights reserved.`;
  const links = [
    {
      content: "Terms of Use",
      href: "https://www.pimsleur.com/c/terms-of-use",
    },
    {
      content: "Privacy Policy",
      href: "https://www.pimsleur.com/c/privacy-policy",
    },
    {
      content: "Contact Us",
      href: "https://www.pimsleur.com/enterprise-contact-us",
    },
    {
      content: "Pimsleur.com",
      href: "https://www.pimsleur.com/",
    },
  ];

  return (
    <Layout.Footer className={styles.footer}>
      <p className={styles.copyright}>{statement}</p>
      <ul className={styles.links}>
        {links.map((link, index) => (
          <li key={index}>
            <a href={link.href} key={index}>
              {link.content}
              {(link.content === "Terms of Use" || link.content === "Privacy Policy") && (
                <span className={styles.bold}> UPDATED</span>
              )}
            </a>
            {index < links.length - 1 && <span>|</span>}
          </li>
        ))}
      </ul>
    </Layout.Footer>
  );
}

export default Footer;
