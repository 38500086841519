import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Card, Input, Row, Table } from "antd";
import { SorterResult, SortOrder } from "antd/es/table/interface";
import { TableProps } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { message, Modal } from "components";
import { RootState, useAppDispatch } from "app/store";
import AddButton from "../../../components/AddButton";
import * as Icons from "../../../assets/icons";
import { OrganizationListResponse } from "../../../types/dto/response/organization";
import useColumn from "./useColumn";
import { fetchOrganizations, organizationsSelectors, removeOrganization } from "../organizationSlice";
import { renderDeletionMessage } from "../configs";
import { getTotalDisplay } from "../../../utils/getTotalDisplay";

function OrganizationList() {
  const [query, setQuery] = useQueryParams({
    name: withDefault(StringParam, ""),
    page: withDefault(NumberParam, 1),
    size: withDefault(NumberParam, 10),
    sortKey: withDefault(StringParam, "lastModifiedDate"),
    order: withDefault(StringParam, "descend"),
  });
  const [name, setName] = useState<string>(query.name);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const isLoading = useSelector((state: RootState) => state.loading);
  const dataSource = useSelector(organizationsSelectors.selectAll);
  const { totalElements, currentPage, currentSize } = useSelector((state: RootState) => state.organization);

  const fetchData = useCallback(
    (q: typeof query) =>
      dispatch(fetchOrganizations({ ...q, order: q.order as SortOrder }))
        .then(unwrapResult)
        .catch(message.error),
    [dispatch]
  );

  useEffect(() => {
    fetchData(query);
    setName(query.name);
  }, [dispatch, fetchData, query]);

  const refresh = () => {
    if (query.page === 1) {
      fetchData(query);
    } else {
      setQuery({ page: 1 });
    }
  };

  const handleTableChange: TableProps<OrganizationListResponse>["onChange"] = (pagination, filters, sorter, extra) => {
    const { order, field } = sorter as SorterResult<OrganizationListResponse>;
    if (extra.action === "sort" && order && field) {
      setQuery({ sortKey: field as string, order: order, page: 1 });
    }
  };

  const onDelete = (record: OrganizationListResponse) => {
    return Modal.confirm({
      title: "Confirm",
      content: record.name && renderDeletionMessage(record.name),
      onOk: () =>
        dispatch(removeOrganization(record.id))
          .then(unwrapResult)
          .then(() => {
            if (dataSource.length === 1) {
              setQuery({ page: query.page > 1 ? query.page - 1 : 1 });
            } else {
              fetchData(query);
            }
          })
          .catch((err) => message.error(err).then(Promise.reject)),
    });
  };

  const column = useColumn(query.order as SortOrder, onDelete);

  return (
    <Card title="Organization list" extra={<AddButton text={"+ Add organization"} />} className="page-container">
      <Row justify="space-between" style={{ marginBottom: "16px" }}>
        <Input
          allowClear
          placeholder={"Search organization name"}
          value={name}
          prefix={<SearchOutlined />}
          onChange={(e) => setName(e.target.value)}
          onBlur={() => setName((prevState) => prevState.trim())}
          onPressEnter={() => setQuery({ name: name.trim(), page: 1 })}
          style={{ width: "25%" }}
        />
        <Button onClick={refresh} type="text" shape="circle" icon={<Icons.Refresh />} />
      </Row>

      <Table<OrganizationListResponse>
        loading={{ spinning: isLoading }}
        columns={column}
        dataSource={dataSource}
        rowKey={(record) => record.id}
        onRow={(record) => ({
          onClick: () => history.push(`${pathname}/${record.id}`),
        })}
        onChange={handleTableChange}
        pagination={{
          showTotal: getTotalDisplay,
          total: totalElements,
          showSizeChanger: true,
          current: currentPage,
          pageSize: currentSize,
          onChange: (page, pageSize) => setQuery({ page, size: pageSize }),
        }}
      />
    </Card>
  );
}

export default OrganizationList;
