import React, { useEffect } from "react";
import { Col, Divider, Form, Row, Tabs } from "antd";
import { DisplayInput } from "components";
import styles from "./DisclosureDetail.module.scss";
import DescriptionContentInput from "../../DescriptionTemplate/DescriptionDetails/DescriptionContentInput";
import DescriptionContentDescription from "../../DescriptionTemplate/DescriptionDetails/DescriptionContentDescription";
import {
  DisclosureDetailModel,
  DisclosureDetailViewFields,
  DisclosureStatus,
} from "../../../../types/model/disclosure";
import CustomDot from "../../../../components/CustomDot";
import { ContentBase } from "../../../../types/model/description";
import { getContentByLocale, LocaleType } from "../../../../utils/locale";

const { TabPane } = Tabs;

interface DisclosureDetailViewProps {
  disclosure?: DisclosureDetailModel;
}

function DisclosureDetailView({ disclosure }: DisclosureDetailViewProps) {
  const [form] = Form.useForm<DisclosureDetailViewFields>();

  useEffect(() => {
    if (disclosure) {
      const { basePrices, countries, billingPlan, contents, ...fields } = disclosure;
      form.setFieldsValue({
        ...fields,
        basePrices: basePrices.map((price) => price.name).join(", "),
        countries: countries.map((country) => country.displayName).join(", "),
        billingPlan: billingPlan?.name ?? "",
        esContent: getContentByLocale(contents, LocaleType.Spanish),
        enContent: getContentByLocale(contents, LocaleType.English),
      });
    }
  }, [disclosure, form]);

  return (
    <Form className={styles.disclosureDetail} form={form} layout="horizontal">
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item name="name" label="Disclosure name">
            <DisplayInput />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="status" label="Status">
            <DisplayInput<DisclosureStatus> render={(x) => x && <CustomDot color={x} />} />
          </Form.Item>
        </Col>
      </Row>
      <Divider className={styles.divider} />
      <Col span={24}>
        <Form.Item name="basePrices" label="Applied base price">
          <DisplayInput />
        </Form.Item>
      </Col>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item name="billingPlan" label="Applied billing plan">
            <DisplayInput />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="countries" label="Applied Country/Region">
            <DisplayInput />
          </Form.Item>
        </Col>
      </Row>
      <Divider className={styles.divider} />
      <div className={styles.tabCardContainer}>
        <Tabs type="card">
          {disclosure?.contents.map((item: ContentBase) => (
            <TabPane tab={item.label} key={item.key}>
              <Form.Item name={`${item.locale}Content`} label="Disclosure Content">
                <DisplayInput render={(x) => <DescriptionContentInput editable={false} value={x} />} />
              </Form.Item>
              <DescriptionContentDescription />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </Form>
  );
}

export default DisclosureDetailView;
