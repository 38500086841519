import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { DATETIME_FORMAT, DAY_FORMAT, EMPTY } from "constants/common";
import { getCountryName, getPaymentMethodText } from "../../../types/model/paymentMethod";
import {
  BillingPlanType,
  BillingPlanTypeMap,
  getTransactionAmountText,
  PaymentInformation,
  TransactionModel,
  TransactionStatus,
  TransactionStatusMap,
  TransactionSubscription,
} from "../../../types/model/transactions";
import Actions from "./Actions";
import { Country } from "../../../types/model/price";
import { fetchAllCountries } from "../../../services/basePrice";

const BillingAddressText = ({ district, postalCode, country }: PaymentInformation) => {
  const [countries, setCountries] = useState([] as Country[]);
  useEffect(() => {
    fetchAllCountries().then((data) => setCountries(data));
  }, []);

  return (
    <>
      {`${district ? district : ""} ${postalCode ? postalCode + "," : ""} ${getCountryName(
        country || "",
        countries
      )}` ?? EMPTY}
    </>
  );
};

const getSubscriptionPeriod = (value?: TransactionSubscription) => {
  if (!value) {
    return EMPTY;
  }
  return `${moment(value.startTime).format(DAY_FORMAT)} - ${moment(value.endTime).format(DAY_FORMAT)}`;
};

const isLifeTime = (billingPlanType: BillingPlanType) => billingPlanType === BillingPlanType.LIFE_TIME;

const columnConfig: ColumnType<TransactionModel>[] = [
  {
    title: "Transaction ID",
    dataIndex: "id",
    width: 160,
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "PID",
    dataIndex: ["productPlan", "pid"],
    width: 160,
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Plan name",
    dataIndex: ["productPlan", "name"],
    width: 200,
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Source",
    dataIndex: "source",
    width: 100,
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 120,
    ellipsis: true,
    render: (value: TransactionStatus) => TransactionStatusMap[value] || EMPTY,
  },
  {
    title: "Type",
    ellipsis: true,
    width: 120,
    render: (item: TransactionModel) => BillingPlanTypeMap[item.billingPlan.type] || EMPTY,
  },
  {
    title: "Price",
    key: "price",
    ellipsis: true,
    width: 80,
    render: (value: TransactionModel) => value.currency.symbol + value.price.originalPrice,
  },
  {
    title: "Discount",
    key: "discount",
    ellipsis: true,
    width: 80,
    render: (value: TransactionModel) =>
      value.price.discount ? "-" + value.currency.symbol + value.price.discount : EMPTY,
  },
  {
    title: "Credit",
    key: "credit",
    ellipsis: true,
    width: 80,
    render: (value: TransactionModel) =>
      value.price.credit ? "-" + value.currency.symbol + value.price.credit : EMPTY,
  },
  {
    title: "Tax",
    key: "tax",
    ellipsis: true,
    width: 80,
    render: (value: TransactionModel) => {
      if (value.price.taxType === "Inclusive Sales") return EMPTY;
      return value.currency.symbol + value.price.tax;
    },
  },
  {
    title: "Total Payment",
    key: "total",
    ellipsis: true,
    width: 187,
    render: (value: TransactionModel) => getTransactionAmountText(value),
  },
  {
    title: "Payment method",
    key: "paymentMethod",
    dataIndex: "paymentInformation",
    ellipsis: true,
    width: 220,
    render: (value: PaymentInformation) => getPaymentMethodText(value) || EMPTY,
  },
  {
    title: "Billing address",
    key: "billingAddress",
    dataIndex: "paymentInformation",
    ellipsis: true,
    width: 180,
    render: (value: PaymentInformation) => <BillingAddressText {...value} />,
  },
  {
    title: "Subscription ID",
    key: "subscriptionId",
    ellipsis: true,
    width: 140,
    render: (value: TransactionModel) => (isLifeTime(value.billingPlan.type) ? EMPTY : value.subscription?.id || EMPTY),
  },
  {
    title: "Purchased cycles",
    key: "purchasedCycles",
    ellipsis: true,
    width: 200,
    render: (value: TransactionModel) =>
      isLifeTime(value.billingPlan.type) ? EMPTY : getSubscriptionPeriod(value.subscription),
  },
  {
    title: "Created at",
    dataIndex: "createdTime",
    ellipsis: true,
    width: 160,
    render: (value) => (value ? moment(value).format(DATETIME_FORMAT) : EMPTY),
  },
  {
    title: "Refund amount",
    key: "refundAmount",
    ellipsis: true,
    width: 195,
    render: (value: TransactionModel) => {
      if (!value.refundPrice) {
        return EMPTY;
      }
      const accountAmount = value.refundPrice.accountAmount;
      const amount = `${value.currency.symbol}${value.refundPrice.amount}`;

      if (Number(accountAmount) > 0) {
        return `${amount} + ${value.currency.symbol}${accountAmount}(Balance)`;
      }

      return amount;
    },
  },
  {
    title: "Refund date",
    key: "refundDate",
    ellipsis: true,
    width: 160,
    render: (value: TransactionModel) => {
      return value.refundPrice?.created ? moment(value.refundPrice?.created).format(DATETIME_FORMAT) : EMPTY;
    },
  },
  {
    title: "Action",
    width: 80,
    fixed: "right",
    render: (record) => <Actions transaction={record} />,
  },
];

export default columnConfig;
