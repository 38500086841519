import React from "react";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { SortOrder } from "antd/lib/table/interface";
import { DATETIME_FORMAT, EMPTY } from "../../../../constants/common";
import { ProductLogModel } from "../../../../types/model/eComProduct";

export function DisplayValueComponent({ value }: any) {
  return value ? (
    <div>
      {Object.entries(value).map(([key, value]) => (
        <div key={key} style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          <span style={{ fontWeight: "bold" }}>{key === "_content_product_id" ? "Course" : key}: </span>
          {typeof value === "boolean" ? (value ? "Yes" : "No") : value}
        </div>
      ))}
    </div>
  ) : (
    <span>{EMPTY}</span>
  );
}

export const tableColumnConfig = (sortOrder: SortOrder): ColumnType<ProductLogModel>[] => [
  {
    title: "PID",
    key: "pid",
    dataIndex: "pid",
    width: 180,
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    width: 150,
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Previous value",
    key: "prevValue",
    dataIndex: "prevValue",
    width: 196,
    ellipsis: true,
    render: (value) => <DisplayValueComponent value={value} />,
  },
  {
    title: "New value",
    key: "currentValue",
    dataIndex: "currentValue",
    width: 196,
    ellipsis: true,
    render: (value) => <DisplayValueComponent value={value} />,
  },
  {
    title: "Modified by",
    key: "updatedBy",
    dataIndex: "updatedBy",
    width: 180,
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Modified at",
    key: "updatedAt",
    dataIndex: "updatedAt",
    width: 180,
    defaultSortOrder: "descend",
    sortOrder: sortOrder,
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true,
    ellipsis: true,
    render: (value) => moment(value).format(DATETIME_FORMAT),
  },
];
