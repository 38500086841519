import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { security } from "../utils/security";

export const api = createApi({
  reducerPath: "api",
  keepUnusedDataFor: 5,
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: async (headers) => {
      // TODO: get token from local storage
      const jwtToken = await security.getAccessTokenSilently()();
      headers.set("Authorization", `Bearer ${jwtToken}`);
      return headers;
    },
  }),
  endpoints: () => ({}),
});
