import { useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  Popover,
  Space,
  Radio,
  Select,
  DatePicker as AntDatePicker,
  DatePickerProps,
} from "antd";
import Icon from "@ant-design/icons";
import moment from "moment";
import type { FormProps } from "antd/lib/form";

import { DropdownInput } from "components";
import { DropDown } from "assets/icons";
import type { EntitlementListParams } from "types/dto/request/entitlement";
import type ProductListRequest from "types/dto/request/ProductListRequest";

import { subscriptionOptions, statusOptions, sourceOptions } from "../utils";
import useLanguagesOptions from "../hooks/useLanguagesOptions";
import styles from "./AdvancedSearch.module.scss";

export interface EntitlementAdvancedSearchProps {
  initialValues: EntitlementListParams;
  onSubmit: (values: EntitlementListParams) => void;
}

function AdvancedSearch({ initialValues, onSubmit }: EntitlementAdvancedSearchProps) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const languagesOptions = useLanguagesOptions();

  const handleSearch: FormProps<ProductListRequest>["onFinish"] = (values) => {
    onSubmit(values);
    setVisible(!visible);
  };

  // useEffect(() => form.resetFields(), [form, initialValues]);

  /** toggle tooltip and reset form value to initial search condition. */
  function toggleAdvanceSearchButton() {
    setVisible((prevState) => {
      form.resetFields();
      return !prevState;
    });
  }

  const content = (
    <Form
      form={form}
      layout="inline"
      initialValues={initialValues}
      className={styles.searchForm}
      onFinish={handleSearch}
    >
      <Space direction="vertical" align="end" size={24}>
        <Form.Item label="Created at">
          <Form.Item noStyle name="createdDateFrom">
            <DatePicker
              placeholder="Start date"
              onChange={(date) => form.setFieldsValue({ createdDateFrom: date?.valueOf() })}
              disabledDate={(date) => {
                const createdDateTo = form.getFieldValue("createdDateTo");
                return date.isAfter(moment(), "day") || (createdDateTo != null && date.isAfter(createdDateTo, "day"));
              }}
            />
          </Form.Item>
          <span style={{ margin: "0 20px" }}>to</span>
          <Form.Item noStyle name="createdDateTo">
            <DatePicker
              placeholder="End date"
              onChange={(date) => form.setFieldsValue({ createdDateTo: date?.valueOf() })}
              disabledDate={(date) => {
                const createdDateFrom = form.getFieldValue("createdDateFrom");
                return (
                  date.isAfter(moment(), "day") || (createdDateFrom != null && date.isBefore(createdDateFrom, "day"))
                );
              }}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Language" name="languageId">
          <Select
            placeholder="Please choose language"
            options={languagesOptions}
            suffixIcon={<DropDown />}
            listHeight={160}
            allowClear
            showSearch
            optionFilterProp={"label"}
          />
        </Form.Item>
        <Form.Item label="ISBN" name="isbn">
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>
      </Space>

      <Space direction="vertical" align="end" size={24}>
        <Form.Item label="Status" name="statuses">
          <DropdownInput width={408} options={statusOptions} placeholder="All statuses" />
        </Form.Item>
        <Form.Item
          label="Purchase type"
          name="isSubscription"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Radio.Group options={subscriptionOptions} />
        </Form.Item>
        <Form.Item label="Source" name="sources">
          <DropdownInput width={408} options={sourceOptions} placeholder="All sources" />
        </Form.Item>
      </Space>

      <Divider />

      <Space className={styles.buttonGroup}>
        <Button type="default" shape="round" onClick={toggleAdvanceSearchButton}>
          Cancel
        </Button>
        <Button type="primary" shape="round" htmlType="submit">
          Search
        </Button>
      </Space>
    </Form>
  );

  return (
    <Popover content={content} trigger="click" visible={visible} onVisibleChange={toggleAdvanceSearchButton}>
      <Button type="default" shape="round">
        Advanced Search
        <Icon component={DropDown} />
      </Button>
    </Popover>
  );
}

function DatePicker(props: DatePickerProps & { value?: number }) {
  return <AntDatePicker {...props} value={props?.value != null ? moment(props.value) : undefined} />;
}

export default AdvancedSearch;
