import { useHistory, useParams } from "react-router";
import { Button, Card, Space, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import * as eComDisclosureAPI from "../../../../services/disclosure";
import { EMPTY } from "../../../../constants/common";
import DisclosureDetailView from "./DisclosureDetailView";
import { message, Modal } from "../../../../components";
import { bold } from "../../../../utils";
import { DisclosureForm, DisclosureFormMode } from "../AddNewDisclosure/DisclosureForm";
import { DisclosureDetailModel, DisclosureStatus } from "../../../../types/model/disclosure";
import useAppAuth0 from "../../../../hooks/useAppAuth0";
import { getContentByLocale, LocaleType } from "../../../../utils/locale";

function DisclosureDetails() {
  const history = useHistory();
  const { user } = useAppAuth0();
  const { disclosureId } = useParams<{
    disclosureId: string;
  }>();

  const [loading, setLoading] = useState(false);
  const [disclosure, setDisclosure] = useState<DisclosureDetailModel>();
  const [editing, setEditing] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const fetchData = useCallback((disclosureId?: string) => {
    let ignore = false;
    setLoading(true);
    disclosureId &&
      eComDisclosureAPI
        .fetchDisclosureById(disclosureId)
        .then((disclosure) => {
          if (!ignore) {
            setDisclosure(disclosure);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    fetchData(disclosureId);
  }, [disclosureId, fetchData, isSaved]);

  const handleDelete = () => {
    Modal.confirm({
      title: "Confirm",
      okText: "Confirm",
      content: disclosure && bold`Are you sure you want to delete the ${disclosure.name}?`,
      onOk: () =>
        eComDisclosureAPI.deleteDisclosure(disclosureId).then(() => {
          history.push("/products/disclosures");
          message.success("Disclosure deleted successfully.");
        }),
    });
  };

  const handleSwitchStatus = () => {
    const newStatus =
      disclosure?.status === DisclosureStatus.ACTIVE ? DisclosureStatus.INACTIVE : DisclosureStatus.ACTIVE;
    Modal.confirm({
      title: newStatus === DisclosureStatus.ACTIVE ? "Activate" : "Deactivate",
      okText: "Confirm",
      content:
        disclosure &&
        (newStatus === DisclosureStatus.ACTIVE
          ? bold`Are you sure you want to activate the ${disclosure.name}? Once activated, the disclosure will be marked as "Active" and will be displayed on the checkout page. This means that customers will be able to view the disclosure information during checkout.`
          : bold`Are you sure you want to deactivate the ${disclosure.name}? Once deactivated, the disclosure will be marked as "Inactive" and will no longer be displayed on the checkout page. This means that customers will not be able to view the disclosure information during checkout.`),
      onOk: () =>
        eComDisclosureAPI.switchDisclosureStatus(disclosureId, newStatus).then(({ status }) => {
          setDisclosure({
            ...disclosure,
            status,
          } as DisclosureDetailModel);
          message.success("Disclosure updated successfully.");
        }),
    });
  };

  const handleEditAndSaveStatus = ({ editing, saved }: { editing: boolean; saved: boolean }) => {
    setEditing(editing);
    setIsSaved(saved);
  };

  return (
    <Spin spinning={loading} size={"large"}>
      {editing ? (
        <DisclosureForm
          mode={DisclosureFormMode.EDIT}
          title={disclosure?.name ?? EMPTY}
          initialValues={
            disclosure
              ? {
                  id: disclosure.id,
                  name: disclosure.name,
                  basePriceIds: disclosure.basePrices.map((price) => price.id),
                  billingPlanId: disclosure.billingPlan?.id,
                  countryIds: disclosure.countries.map((country) => country.id),
                  enContent: getContentByLocale(disclosure.contents, LocaleType.English),
                  esContent: getContentByLocale(disclosure.contents, LocaleType.Spanish),
                }
              : {}
          }
          handleStatusChange={handleEditAndSaveStatus}
          updateDisclosure={setDisclosure}
        />
      ) : (
        <Card
          title={disclosure?.name ?? EMPTY}
          extra={
            <Space size={10}>
              {user.canDeleteDisclosure && (
                <Button type="default" shape="round" onClick={handleDelete}>
                  Delete
                </Button>
              )}
              {user.canUpdateDisclosure && (
                <Button
                  type="default"
                  shape="round"
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  Edit
                </Button>
              )}
              {user.canSwitchDisclosureStatus && disclosure?.status && (
                <Button type="default" shape="round" onClick={handleSwitchStatus}>
                  {disclosure.status === DisclosureStatus.ACTIVE ? "Deactivate" : "Activate"}
                </Button>
              )}
            </Space>
          }
          className="page-container"
        >
          <DisclosureDetailView disclosure={disclosure} />
        </Card>
      )}
    </Spin>
  );
}

export default DisclosureDetails;
