import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { BrowserRouter as Router, BrowserRouterProps, Route } from "react-router-dom";
import { WaterMark } from "@ant-design/pro-layout";
import { QueryParamProvider } from "use-query-params";
import { security } from "utils/security";
import { Header, Sider, Content, Modal } from "components";
import styles from "./App.module.scss";

const { REACT_APP_WATER_MARK } = process.env;

function App() {
  const { getAccessTokenSilently, error, logout } = useAuth0();
  security.setAccessTokenSilently(getAccessTokenSilently);

  const showConfirm: BrowserRouterProps["getUserConfirmation"] = (message, callback) => {
    Modal.confirm({
      title: "Confirm",
      content: message,
      onOk: () => {
        callback(true);
        Modal.destroyAll();
      },
    });
  };

  const AuthApp = withAuthenticationRequired(() => (
    <WaterMark content={REACT_APP_WATER_MARK} gapX={300} gapY={300} zIndex={9999} fontColor="rgba(0, 0, 0, 0.1)">
      <Router getUserConfirmation={showConfirm}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Header />
          <div className={styles.mainWrapper}>
            <Sider />
            <Content />
          </div>
        </QueryParamProvider>
      </Router>
    </WaterMark>
  ));

  if (error) {
    let title = "Permission Denied";
    let content = "Sorry, you don’t have permission to this website.";
    if (error.message === "Timeout") {
      title = "Timeout";
      content = "Session timeout, please login again";
    }
    Modal.error({
      title: title,
      content: content,
      onOk: () => {
        Modal.destroyAll();
        logout({ returnTo: window.location.origin });
      },
    });
    return <div />;
  }

  return <AuthApp />;
}

export default App;
