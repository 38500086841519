import React, { HTMLAttributes } from "react";
import { Resizable } from "react-resizable";

interface CellProps extends HTMLAttributes<HTMLElement> {
  width: number;
  onResize: () => void;
}

function ResizableTitle({ onResize, width, ...restProps }: CellProps) {
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
}

export default ResizableTitle;
