import React, { useEffect, useState } from "react";
import { useHistory, useParams, Prompt } from "react-router-dom";
import { Button, Card, Form, Space, Spin } from "antd";
import { LEAVE_CONFIRMATION } from "../../../../constants/common";
import { UpsellDowngradeForm } from "./UpsellDowngradeForm";
import { UpsellDowngradeDetailsView } from "./UpsellDowngradeDetailsView";
import * as upsellDowngradeAPI from "../../../../services/upsellDowngrade";
import { message, Modal } from "../../../../components";
import { bold } from "../../../../utils";
import { UpsellDowngradeDetailViewFields } from "../../../../types/model/upsellDowngrade";
import useAppAuth0 from "../../../../hooks/useAppAuth0";

function EditingButtons({
  onCancel,
  onSave,
  isSaveLoading,
}: {
  onCancel: () => void;
  onSave: () => void;
  isSaveLoading: boolean;
}) {
  return (
    <Space size={8}>
      <Button type="default" shape="round" onClick={onCancel}>
        Cancel
      </Button>
      <Button type="primary" shape="round" onClick={onSave} loading={isSaveLoading}>
        Save
      </Button>
    </Space>
  );
}

function ViewButtons({ onDelete, onEdit, user }: { onDelete: () => void; onEdit: () => void; user: any }) {
  return (
    <Space size={8}>
      {user.canDeleteUpsellDowngrade && (
        <Button type="default" shape="round" onClick={onDelete}>
          Delete
        </Button>
      )}
      {user.canUpdateUpsellDowngrade && (
        <Button type="primary" shape="round" onClick={onEdit}>
          Edit
        </Button>
      )}
    </Space>
  );
}

function UpsellDowngradeDetails() {
  const { id } = useParams<{
    id: string;
  }>();
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState("");
  const [form] = Form.useForm();
  const { user } = useAppAuth0();
  const [upsellDowngradeDetails, setUpsellDowngradeDetails] = useState<UpsellDowngradeDetailViewFields>();
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    upsellDowngradeAPI
      .fetchUpsellDowngradeDetails(id)
      .then((data) => {
        setName(data.originalBasePriceName);
        setUpsellDowngradeDetails(data);
        form.setFieldsValue(data);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleUpdate = () => {
    form.validateFields().then((values) => {
      setIsSaveLoading(true);
      upsellDowngradeAPI
        .updateUpsellDowngrade(id, values)
        .then((data) => {
          setName(data.originalBasePriceName);
          form.setFieldsValue(data);
          setUpsellDowngradeDetails(data);
          setEditing(false);
          message.success("Upsell & downgrade updated successfully.");
        })
        .catch(() => {
          form.setFieldsValue(upsellDowngradeDetails);
        })
        .finally(() => {
          setIsSaveLoading(false);
        });
    });
  };

  const handleDelete = () => {
    Modal.confirm({
      title: "Delete Upsell & Downgrade",
      okText: "Confirm",
      content:
        upsellDowngradeDetails &&
        bold`Are you sure you want to delete the ${upsellDowngradeDetails.originalBasePriceName}?`,
      onOk: () =>
        upsellDowngradeAPI.deleteUpsellDowngrade(id).then(() => {
          history.push("/marketing/upsell-downgrade");
          message.success("Upsell & downgrade deleted successfully.");
        }),
    });
  };

  const handleCancel = () => {
    Modal.confirm({
      title: "Confirm",
      okText: "OK",
      content: LEAVE_CONFIRMATION,
      onOk: () => {
        setEditing(false);
        form.setFieldsValue(upsellDowngradeDetails);
      },
    });
  };

  return (
    <Card
      className="page-container"
      title={name}
      extra={
        editing ? (
          <EditingButtons onSave={handleUpdate} onCancel={handleCancel} isSaveLoading={isSaveLoading} />
        ) : (
          <ViewButtons onDelete={handleDelete} onEdit={() => setEditing(true)} user={user} />
        )
      }
    >
      <Spin spinning={loading} size="large">
        {editing ? (
          <>
            <Prompt when={editing} message={LEAVE_CONFIRMATION} />
            <UpsellDowngradeForm form={form} />
          </>
        ) : (
          <UpsellDowngradeDetailsView form={form} />
        )}
      </Spin>
    </Card>
  );
}

export default UpsellDowngradeDetails;
