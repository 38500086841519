import { Button, Divider, Card, message, Tabs, Col } from "antd";
import { useHistory } from "react-router";
import React, { useState } from "react";
import { find, get } from "lodash";
import { SaveOfferContent, SaveOfferDetailModel, SaveOfferType } from "../../../../types/model/saveOffer";

import styles from "./index.module.scss";
import ItemField from "../components/ItemField";
import { EMPTY } from "../../../../constants/common";
import DescriptionContentInput from "../../../products/DescriptionTemplate/DescriptionDetails/DescriptionContentInput";
import { DiscountType } from "../../../../types/model/promotion";
import * as saveOfferAPI from "../../../../services/saveOffer";
import { EComModal } from "../../../../components/EComModal";
import { LocaleType } from "../../../../utils/locale";
import useAppAuth0 from "../../../../hooks/useAppAuth0";
const { TabPane } = Tabs;

export default function ViewSaveOfferDetailContent({
  saveOfferDetail,
  onEdit,
}: {
  saveOfferDetail: SaveOfferDetailModel;
  onEdit: () => void;
}) {
  const history = useHistory();
  const { user } = useAppAuth0();
  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const onDelete = () => {
    setIsDeleting(true);
    saveOfferAPI
      .deleteSaveOffer(saveOfferDetail.id)
      .then(() => {
        history.push("/marketing/save-offer");
        message.success("Save offer deleted successfully.").then();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <>
      <Card
        title={get(find(saveOfferDetail.contents, { locale: LocaleType.English }), "title") || EMPTY}
        extra={
          <div className={styles.detailActionContainer}>
            {user.canDeleteSaveOffer && (
              <Button type="default" shape="round" onClick={() => setIsDeleteModalShowing(true)}>
                Delete
              </Button>
            )}
            {user.canUpdateSaveOffer && (
              <Button type="default" shape="round" onClick={onEdit}>
                Edit
              </Button>
            )}
          </div>
        }
        className="page-container"
      >
        <BaseInfo />
        <Divider />
        <Details />
        <Divider />
        {saveOfferDetail.type === SaveOfferType.PROMOTION && <PromotionTypeContent />}
        {saveOfferDetail.type === SaveOfferType.SWITCH_PLAN && <SwitchPlanContent />}
        {saveOfferDetail.type === SaveOfferType.PAUSE && <PauseTypeContent />}
        {saveOfferDetail.type === SaveOfferType.EXTEND_BILLING_DATE && <ExtendBillingDateContent />}
      </Card>
      {isDeleteModalShowing && (
        <EComModal
          title="Delete save offer"
          okText="confirm"
          visible={isDeleteModalShowing}
          onCancel={() => {
            setIsDeleteModalShowing(false);
          }}
          onOk={onDelete}
          confirmLoading={isDeleting}
        >
          Are you sure you want to delete the save offer?
        </EComModal>
      )}
    </>
  );

  function BaseInfo() {
    return (
      <div className={styles.content}>
        <span className={styles.sectionTitle}>Application rules</span>
        <div className={styles.detailSection}>
          <div className={styles.sectionContent}>
            <ItemField label="Applied cancel reason" value={saveOfferDetail.cancelReason?.description || EMPTY} />
            <ItemField label="Applied stage" value={saveOfferDetail.appliedStages.join(", ")} />
            <ItemField
              label="Applied Base price"
              value={saveOfferDetail?.appliedBasePrices.map((x) => x.name).join(", ")}
            />
          </div>
        </div>
      </div>
    );
  }

  function Details() {
    return (
      <div className={styles.content}>
        <span className={styles.sectionTitle}>Save offer details</span>
        <Col className={styles.tabCardContainer} span={18}>
          <Tabs type="card">
            {saveOfferDetail.contents.map((item: SaveOfferContent) => (
              <TabPane tab={item.label} key={item.key}>
                <div className={`${styles.detailSection} ${styles.multiple}`}>
                  <div className={`${styles.sectionContent} ${styles.full}`}>
                    <ItemField label="Save offer title" value={item.title || EMPTY} />
                  </div>
                  <div className={`${styles.sectionContent} ${styles.full}`}>
                    <div className={styles.itemContainer}>
                      <span className={styles.itemLabel}>Save offer description:</span>
                      <DescriptionContentInput editable={false} value={item.description} />
                    </div>
                  </div>
                </div>
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </div>
    );
  }

  function PromotionTypeContent() {
    const { promotion } = saveOfferDetail || {};
    if (!promotion) {
      return null;
    }

    return (
      <>
        <div className={`${styles.detailSection}`}>
          <div className={`${styles.sectionContent} ${styles.full}`}>
            <ItemField label="Save offer type:" value="Promotion" />
          </div>
        </div>
        <Divider />
        <div className={`${styles.detailSection} ${styles.multiple}`}>
          <div className={`${styles.sectionContent} ${styles.small}`}>
            <ItemField label="Promotion code:" value={promotion.code} />
            <ItemField label="Applied Billing cycle:" value={`1 to ${promotion.billingCycle}`} />
            <ItemField
              label="Applied country/region:"
              value={promotion.countries?.length ? promotion.countries.map((x) => x.displayName).join(", ") : EMPTY}
            />
          </div>
          <div className={`${styles.sectionContent} ${styles.small}`}>
            <ItemField
              label="Promotion type:"
              value={promotion.discountType === DiscountType.PERCENTAGE ? "Percentage off" : "Amount off"}
            />
            {promotion.discountType === DiscountType.AMOUNT && (
              <ItemField label="Currency code" value={promotion.currencyCode || EMPTY} />
            )}
            <ItemField
              label="Discount:"
              value={
                promotion.discountType === DiscountType.PERCENTAGE
                  ? `${promotion.percentageDiscount}%`
                  : `${promotion.amountDiscount}`
              }
            />
          </div>
        </div>
      </>
    );
  }

  function SwitchPlanContent() {
    const { switchToPlan, promotion } = saveOfferDetail || {};
    if (!switchToPlan) {
      return null;
    }

    return (
      <>
        <div className={`${styles.detailSection}`}>
          <div className={`${styles.sectionContent} ${styles.full}`}>
            <ItemField label="Save offer type:" value="Switch plan" />
          </div>
        </div>
        <Divider />
        <div className={`${styles.detailSection} ${styles.multiple}`}>
          <div className={`${styles.sectionContent} ${styles.full}`}>
            <ItemField label="Effective Date:" value={saveOfferDetail?.effectiveDate || EMPTY} />
          </div>
          <div className={`${styles.sectionContent} ${styles.full}`}>
            <ItemField
              label="Switch to plan:"
              value={`${switchToPlan.pid} ${switchToPlan.title} / ${switchToPlan.billingPlanId}`}
            />
          </div>
          {promotion && (
            <>
              <div className={`${styles.sectionContent} ${styles.small}`}>
                <ItemField label="Promotion code:" value={promotion.code} />
                <ItemField label="Applied Billing cycle:" value={`1 to ${promotion.billingCycle}`} />
                <ItemField
                  label="Applied country/region:"
                  value={promotion.countries?.length ? promotion.countries.map((x) => x.displayName).join(", ") : EMPTY}
                />
              </div>
              <div className={`${styles.sectionContent} ${styles.small}`}>
                <ItemField
                  label="Promotion type:"
                  value={promotion.discountType === DiscountType.PERCENTAGE ? "Percentage off" : "Amount off"}
                />

                {promotion.discountType === DiscountType.AMOUNT && (
                  <ItemField label="Currency code" value={promotion.currencyCode || EMPTY} />
                )}

                <ItemField
                  label="Discount:"
                  value={
                    promotion.discountType === DiscountType.PERCENTAGE
                      ? `${promotion.percentageDiscount}%`
                      : `${promotion.amountDiscount}`
                  }
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  function PauseTypeContent() {
    return (
      <div className={styles.detailSection}>
        <div className={`${styles.sectionContent} ${styles.full}`}>
          <ItemField label="Save offer type:" value="Pause" />
        </div>
      </div>
    );
  }

  function ExtendBillingDateContent() {
    return (
      <div className={styles.detailSection}>
        <div className={`${styles.sectionContent} ${styles.full}`}>
          <ItemField label="Save offer type:" value="Extend billing date" />
        </div>
        <Divider />
        <div className={`${styles.sectionContent} ${styles.full}`}>
          <ItemField label="Extend days:" value={saveOfferDetail?.extendDays?.toString() || EMPTY} />
        </div>
      </div>
    );
  }
}
