import React from "react";
import Icon from "@ant-design/icons";
import { Permission } from "../types/permission";
import { MenuItem } from "../types/menu";
import * as Icons from "../assets/icons";

const menu: MenuItem[] = [
  {
    title: "Customer",
    path: "",
    icon: Icons.Customer,
    children: [
      {
        title: "B2C customer",
        path: "/customers/b2c",
        permission: Permission.VIEW_B2C_CUSTOMER_MENU,
        children: [],
      },
      {
        title: "B2B customer",
        path: "/customers/b2b",
        permission: Permission.VIEW_B2B_CUSTOMER_MENU,
        children: [],
      },
    ],
  },
  {
    title: "Entitlement",
    path: "/entitlements",
    permission: Permission.VIEW_ENTITLEMENT_MENU,
    icon: Icons.Entitlement,
    children: [],
  },
  {
    title: "Product",
    path: "",
    icon: Icons.Product,
    children: [
      {
        title: "Content product",
        path: "/products/content",
        permission: Permission.VIEW_PRODUCT_MENU,
        children: [],
      },
      {
        title: "eCom product",
        path: "/products/ecom",
        permission: Permission.VIEW_ECOM_PRODUCT_MENU,
        children: [],
      },
      {
        title: "Base price",
        path: "/products/base-price",
        permission: Permission.VIEW_BASE_PRICE_MENU,
        children: [],
      },
      {
        title: "Disclosure",
        path: "/products/disclosures",
        permission: Permission.VIEW_DISCLOSURE_MENU,
        children: [],
      },
      {
        title: "Description template",
        path: "/products/description",
        permission: Permission.VIEW_DESCRIPTION_TEMPLATE_MENU,
        children: [],
      },
    ],
  },
  {
    title: "Mini course",
    path: "/mini-courses",
    permission: Permission.VIEW_MINI_COURSE_MENU,
    icon: Icons.MiniCourse,
    children: [],
  },
  {
    title: "Marketing",
    path: "",
    icon: Icons.Marketing,
    children: [
      {
        title: "Upsell & Downgrade",
        path: "/marketing/upsell-downgrade",
        permission: Permission.VIEW_UPSELL_DOWNGRADE_MENU,
        children: [],
      },
      {
        title: "Promotion",
        path: "/marketing/promotions",
        permission: Permission.VIEW_PROMOTION_MENU,
        children: [],
      },
      {
        title: "Save offer",
        path: "/marketing/save-offer",
        permission: Permission.VIEW_SAVE_OFFER_MENU,
        children: [],
      },
    ],
  },
  {
    title: "Organization",
    path: "/organization",
    permission: Permission.VIEW_ORGANIZATION_MENU,
    icon: Icons.Organization,
    children: [],
  },
  {
    title: "Voucher",
    path: "/vouchers",
    permission: Permission.VIEW_VOUCHER_MENU,
    icon: Icons.Voucher,
    children: [],
  },
  {
    title: "Deeplink",
    path: "/deeplink",
    permission: Permission.VIEW_DEEPLINK_MENU,
    icon: Icons.Deeplink,
    children: [],
  },
].map((menuItem) => ({
  ...menuItem,
  icon: <Icon component={menuItem.icon} style={{ height: "20px" }} />,
}));

export default menu;
