import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { ConfigProvider, Form, Input, message } from "antd";
import { ISubscriptionModalProps } from "./types";
import { EComModal } from "../../../../components/EComModal";
import styles from "../Subscription.module.scss";
import { validateMessages } from "../../../../constants/common";
import modalStyles from "../../../../components/EComModal/index.module.scss";
import { submitSwitchPlan } from "../../../../services/subscription";
import * as eComProductApi from "../../../../services/eComProduct";
import DebounceSearchInput from "../../../../components/DebounceSearchInput";

interface ISwitchSubscriptionModalProps extends ISubscriptionModalProps {
  email: string;
}

interface Option {
  key: string;
  label: string;
  value: string;
}

export default function SwitchSubscriptionModal({
  auth0Id,
  email,
  subscription,
  visible,
  closeModal,
}: ISwitchSubscriptionModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [optionList, setOptionList] = useState<Option[]>([]);
  const [form] = Form.useForm();

  const handleVariantsSearch = async (search: string): Promise<Option[]> => {
    const res = await eComProductApi.fetchProductVariantsByFuzzySearch(search);
    const options = res
      ? res.map(({ id, title, pid, billingPlan }) => ({
          key: id,
          label: billingPlan ? `${pid} ${title} / ${billingPlan.name}` : `${pid} ${title}`,
          value: pid,
        }))
      : [];
    setOptionList(options);
    return options;
  };

  async function onSubmit() {
    setIsLoading(true);
    try {
      const values = await form.validateFields();
      await submitSwitchPlan(auth0Id, values.email, subscription.subscriptionId, subscription.pid, values.switchTo);
      message.success("An email containing the switch plan link has been send to user.");
      closeModal();
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <EComModal
      title="Switch plan"
      visible={visible}
      okText="Send"
      cancelText="Cancel"
      onCancel={closeModal}
      onOk={onSubmit}
      confirmLoading={isLoading}
    >
      <div className={styles.subscriptionModal}>
        <p>Please verify the information below with the user. A confirmation email will be sent to the user.</p>
        <ConfigProvider form={{ validateMessages }}>
          <Form className={modalStyles.form} form={form}>
            <Form.Item
              label="Email:"
              name="email"
              required
              initialValue={email}
              className={modalStyles.formItem}
              rules={[
                { required: true },
                {
                  type: "email",
                  message: "Please check the format of your email and try again.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Switch to:"
              name="switchTo"
              required
              className={`${modalStyles.formItem} ${styles.customSelect}`}
              rules={[{ required: true }]}
            >
              <DebounceSearchInput
                showSearch
                fetchOptions={handleVariantsSearch}
                onClear={() => {
                  setOptionList([]);
                }}
                allowClear
                placeholder="Search PID or plan name"
                suffixIcon={<SearchOutlined className={styles.searchIcon} />}
                existedOptions={optionList}
              />
            </Form.Item>
          </Form>
        </ConfigProvider>
      </div>
    </EComModal>
  );
}
