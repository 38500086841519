import React from "react";
import { Col, Divider, Form, Row } from "antd";
import { DisplayInput } from "../../../../../components";
import NamiSupportCheckbox from "../../../../../components/NamiSupportCheckbox";
import ProductModel from "../../../../../types/model/product";

const ProductDeeplinkView = (props: { product: ProductModel }) => {
  const { product } = props;
  const { deepLink: deeplinkUrl, isDeeplinkSupportedByNami } = product;

  return (
    <React.Fragment>
      <Row style={{ width: "1056px" }}>
        <Col span={24}>
          <Form.Item name={["deepLinkPromotion", "promotionTitle"]} label={"Promotion title"}>
            <DisplayInput />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name={["deepLinkPromotion", "promotionContent"]} label={"Promotion content"}>
            <DisplayInput />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Col span={24}>
        <Form.Item name={["deepLinkPromotion", "promotionComment"]} label={"Comment"}>
          <DisplayInput />
        </Form.Item>
      </Col>

      <Divider />

      <Col span={24}>
        <Form.Item name={"deepLink"} label={"Deeplink"}>
          {deeplinkUrl ? (
            <a target="_blank" rel="noopener noreferrer" href={deeplinkUrl}>
              <DisplayInput value={deeplinkUrl} />
            </a>
          ) : (
            <DisplayInput />
          )}
        </Form.Item>
      </Col>

      <Row>
        <Col span={24}>
          <Form.Item name={"isDeeplinkSupportedByNami"} noStyle>
            <NamiSupportCheckbox checked={isDeeplinkSupportedByNami} disabled={true} />
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ProductDeeplinkView;
