import React from "react";
import styles from "../ViewDetails/index.module.scss";

export default function ItemField({ label, value }: { label: string; value: string }) {
  return (
    <div className={styles.itemContainer}>
      <span className={styles.itemLabel}>{label}</span>
      <span className={styles.itemValue}>{value}</span>
    </div>
  );
}
