import { ArrayParam, NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { ColumnType, TableProps } from "antd/es/table";
import { useHistory, useRouteMatch } from "react-router";
import { Card, Input, Row, Space, Table } from "antd";
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import omit from "lodash/omit";
import { SearchOutlined } from "@ant-design/icons";
import { SorterResult } from "antd/lib/table/interface";
import AddButton from "../../../../components/AddButton";
import ResizableTitle from "../../../../components/ResizableTitle";
import { getTotalDisplay } from "../../../../utils/getTotalDisplay";
import getSaveOfferTableColumns from "./getSaveOfferTableColumns";
import { SaveOfferListModel, SaveOfferModel } from "../../../../types/model/saveOffer";
import SaveOfferAdvancedSearch from "./SaveOfferAdvancedSearch";
import { BasePriceModel } from "../../../../types/model/price";
import * as basePriceAPI from "../../../../services/basePrice";
import * as eComSaveOfferAPI from "../../../../services/saveOffer";
import { SaveOfferListRequest } from "../../../../types/dto/request/saveOffer";
import { DEFAULT_MAX_PAGINATION } from "../../../../constants/common";
import ConfigureModal from "../../../../components/ConfigureModal";
import { getOffset } from "../../../../utils/getPagination";
import { updateSortOrder } from "../../../../types/transformers/updateSortOrder";
import DisplayTags from "./DisplayTags";
import CancelReasonConfigureTable from "../CancelReasonConfigureTable";
import useAppAuth0 from "../../../../hooks/useAppAuth0";

function SaveOfferList() {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
    cancelReason: withDefault(StringParam, undefined),
    appliedStages: withDefault(ArrayParam, undefined),
    type: withDefault(ArrayParam, undefined),
    appliedBasePriceIds: withDefault(ArrayParam, undefined),
    order: withDefault(StringParam, "-updated_at"),
  });
  const history = useHistory();
  const { user } = useAppAuth0();
  const [basePriceList, setBasePriceList] = useState<BasePriceModel[]>([]);
  const [isSaveOfferListPending, setIsSaveOfferListPending] = useState(false);
  const [saveOfferList, setSaveOfferList] = useState<SaveOfferListModel>({} as SaveOfferListModel);
  const [cancelReason, setCancelReason] = useState(query.cancelReason);
  const columns = getSaveOfferTableColumns();
  const [tableColumns, setTableColumns] = useState<ColumnType<SaveOfferModel>[]>(columns);
  const { path } = useRouteMatch();
  const tableRef = useRef<{
    validate: () => Promise<boolean>;
    update: () => Promise<void>;
    refresh: () => {};
  }>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCancelReason(e.target.value);
  };

  const handleInputPressEnter = () => {
    setQuery({
      ...query,
      cancelReason: cancelReason?.trim(),
      page: cancelReason?.trim() ? 1 : query.page,
    });
  };

  useEffect(() => {
    basePriceAPI
      .fetchBasePriceList({
        page: 1,
        limit: DEFAULT_MAX_PAGINATION.size,
      })
      .then((basePriceList) => {
        setBasePriceList(basePriceList.list);
      });
  }, []);

  const refreshSaveOfferList = useCallback(() => {
    setIsSaveOfferListPending(true);
    eComSaveOfferAPI
      .fetchSaveOfferList({
        ...omit(query, ["page"]),
        offset: getOffset(query.page, query.limit),
      } as SaveOfferListRequest)
      .then((res) => {
        setSaveOfferList(res);
      })
      .finally(() => {
        setIsSaveOfferListPending(false);
      });
  }, [query]);

  useEffect(() => {
    refreshSaveOfferList();
    setCancelReason(query.cancelReason);
  }, [query, refreshSaveOfferList]);

  const handleResize = (index: number) => (e: any, { size }: any) => {
    const nextColumns = [...tableColumns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    setTableColumns(nextColumns);
  };

  const handleAdvancedSearch = (values: SaveOfferListRequest) => {
    setQuery({
      ...query,
      ...values,
      page: 1,
    });
  };

  const columnsToDisplay = tableColumns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: ColumnType<SaveOfferModel>) =>
      ({
        width: column.width,
        onResize: handleResize(index),
      } as React.HTMLAttributes<HTMLElement>),
  }));

  const handleTableChange: TableProps<SaveOfferModel>["onChange"] = ({ pageSize }, _, sorter, { action }) => {
    const { order } = sorter as SorterResult<SaveOfferModel>;
    if (action === "sort") {
      setQuery({
        ...query,
        order: updateSortOrder[order as string],
        page: 1,
        limit: pageSize,
      });
    }
  };

  const handleOnRowClick: TableProps<SaveOfferModel>["onRow"] = (record) => ({
    onClick: () =>
      history.push({
        pathname: `${path}/${record.id}`,
      }),
  });

  const handleTagsChange = (patchQuery: SaveOfferListRequest) => {
    setQuery({
      ...query,
      ...patchQuery,
      page: 1,
    });
  };

  const refreshCancelReasons = () => {
    tableRef.current?.refresh();
  };
  return (
    <Card
      title="Save Offer list"
      extra={user.canCreateSaveOffer && <AddButton text={"+ Add new save offer"} />}
      className="page-container"
    >
      <Row justify={"space-between"} style={{ marginBottom: 16 }}>
        <Space>
          <Input
            allowClear
            value={cancelReason}
            onChange={handleInputChange}
            onBlur={() => setCancelReason((prevState) => prevState?.trim())}
            onPressEnter={handleInputPressEnter}
            prefix={<SearchOutlined />}
            placeholder="Search applied cancel reason"
            style={{ width: 322 }}
          />
          <SaveOfferAdvancedSearch
            initialValues={query as SaveOfferListRequest}
            basePriceList={basePriceList}
            onSubmit={handleAdvancedSearch}
          />
        </Space>
        {user.canConfigureCurrency && (
          <ConfigureModal
            width={1062}
            bodyStyle={{ overflowY: "hidden" }}
            tooltipTitle={"Configure cancel reasons"}
            title={"Configure cancel reason"}
            tableRef={tableRef}
            onChange={refreshCancelReasons}
          >
            <CancelReasonConfigureTable ref={tableRef} />
          </ConfigureModal>
        )}
      </Row>

      <DisplayTags
        query={query as SaveOfferListRequest}
        appliedBasePriceList={basePriceList}
        onTagsChange={handleTagsChange}
      />

      <Table<SaveOfferModel>
        loading={{
          spinning: isSaveOfferListPending,
        }}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        rowKey={(record) => record.id}
        columns={columnsToDisplay}
        dataSource={saveOfferList.list}
        onChange={handleTableChange}
        pagination={{
          showTotal: getTotalDisplay,
          current: query.page,
          pageSize: query.limit,
          total: saveOfferList.total,
          defaultCurrent: 1,
          onChange: (page, pageSize) => {
            setQuery({
              ...query,
              page,
              limit: pageSize,
              order: query.order,
            });
          },
        }}
        onRow={handleOnRowClick}
      />
    </Card>
  );
}

export default SaveOfferList;
