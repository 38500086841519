import { Form, Input, Button, message } from "antd";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useState } from "react";
import modalStyles from "../../../../components/EComModal/index.module.scss";
import { EComModal } from "../../../../components/EComModal";
import ModalContent from "../../../../components/EComModal/ModalContent";
import ModalListItem from "../../../../components/EComModal/ModalListItem";
import { getTransactionAmountText, TransactionModel } from "../../../../types/model/transactions";
import { refundTransaction } from "../../../../services/customer";
import { RootState } from "../../../../app/store";
import { customerSelectors } from "../../customerSlice";

export default function RefundTransactionModal({
  transaction,
  visible,
  onFinish,
  closeModal,
}: {
  transaction: TransactionModel;
  visible: boolean;
  onFinish: () => void;
  closeModal: () => void;
}) {
  const { customerId } = useParams<{ customerId: string }>();
  const customer = useSelector((state: RootState) => customerSelectors.selectById(state, customerId));
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const values = await form.validateFields();
      const refundAmount = Number(values.refundAmount);
      await refundTransaction(customer?.auth0Id || "", transaction.id, refundAmount, transaction.source);
      message.success("Transaction Refunded Successfully.");
      onFinish();
      closeModal();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EComModal
      visible={visible}
      title="Refund"
      okText="Refund"
      onCancel={closeModal}
      confirmLoading={isLoading}
      onOk={onSubmit}
    >
      <p>When refunding, the actual payment made will be prioritized for refund</p>
      <ModalContent>
        <ModalListItem label="Plan name:" value={transaction.productPlan.name}></ModalListItem>
        <ModalListItem label="Total payment :" value={getTransactionAmountText(transaction)}></ModalListItem>
      </ModalContent>
      <Form form={form} className={modalStyles.form}>
        <Form.Item
          label="Refund amount:"
          name="refundAmount"
          required
          rules={[
            { required: true, message: "Please input refund amount" },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: "Please input a valid number",
            },
            () => ({
              validator(_, value) {
                if (Number(value) > Number(transaction.price.total)) {
                  return Promise.reject("Refund amount exceeds total payment");
                }
                return Promise.resolve();
              },
            }),
          ]}
          initialValue={transaction.price.total}
          className={modalStyles.formItem}
        >
          <Input
            type="number"
            addonBefore={transaction.currency.symbol}
            onBlur={async (e) => {
              await form.validateFields();
              form.setFieldsValue({
                refundAmount: Number(e.target.value).toFixed(2),
              });
            }}
            suffix={
              <Button
                type="link"
                style={{ padding: "0" }}
                size="small"
                onClick={() => {
                  form.setFieldsValue({
                    refundAmount: transaction.price.total,
                  });
                }}
              >
                Full Amount
              </Button>
            }
          />
        </Form.Item>
      </Form>
    </EComModal>
  );
}
