import { DeltaStatic } from "quill";
import { ContentBase } from "../types/model/description";

export enum LocaleType {
  English = "en",
  Spanish = "es",
}

export const supportLocalTabs = [
  {
    label: "English(en)",
    locale: LocaleType.English,
    key: LocaleType.English,
    required: true,
  },
  {
    label: "Spanish(es)",
    locale: LocaleType.Spanish,
    key: LocaleType.Spanish,
    required: false,
  },
];

export const getContentByLocale = (contents: ContentBase[], locale: LocaleType) =>
  contents.find((content) => content.locale === locale)?.content as DeltaStatic;
