import React, { useEffect, useState } from "react";
import moment from "moment";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { Button, Card, Form, Space, Spin, Tooltip } from "antd";
import styles from "./index.module.scss";
import { bold } from "../../../../utils";
import { DATETIME_FORMAT_WITH_00_SECOND, LEAVE_CONFIRMATION } from "../../../../constants/common";
import { PromotionForm } from "../components/PromotionForm";
import PromotionDetailsView from "./PromotionDetailsView";
import * as promotionAPI from "../../../../services/promotion";
import { DescriptionDisclosure, DiscountType, PromotionDetailModel } from "../../../../types/model/promotion";
import { message, Modal } from "../../../../components";
import useAppAuth0 from "../../../../hooks/useAppAuth0";
import { transformDescriptionDisclosure } from "../util";

function EditingButtons({ onCancel, onSave }: { onCancel: () => void; onSave: () => void }) {
  return (
    <Space size={8}>
      <Button type="default" shape="round" onClick={onCancel}>
        Cancel
      </Button>
      <Button type="primary" shape="round" onClick={onSave}>
        Save
      </Button>
    </Space>
  );
}

function ViewButtons({
  isDeleteDisabled,
  onDelete,
  onEdit,
  user,
}: {
  isDeleteDisabled: boolean;
  onDelete: () => void;
  onEdit: () => void;
  user: any;
}) {
  const renderDeleteButton = () => (
    <Button type="default" shape="round" onClick={onDelete} disabled={isDeleteDisabled}>
      Delete
    </Button>
  );
  return (
    <Space size={8} className={styles.buttons}>
      {user.canDeletePromotion &&
        (isDeleteDisabled ? (
          <Tooltip title="Once active, the promotion can’t be deleted.">{renderDeleteButton()}</Tooltip>
        ) : (
          renderDeleteButton()
        ))}
      {user.canUpdatePromotion && (
        <Button type="default" shape="round" onClick={onEdit}>
          Edit
        </Button>
      )}
    </Space>
  );
}

export default function PromotionDetails() {
  const { id } = useParams<{
    id: string;
  }>();
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState("");
  const [form] = Form.useForm();
  const [promotionDetails, setPromotionDetails] = useState<PromotionDetailModel>();
  const [descriptionDisclosures, setDescriptionDisclosures] = useState<DescriptionDisclosure[]>([]);
  const history = useHistory();
  const { user } = useAppAuth0();

  useEffect(() => {
    getAndSetPromotionDetails();
  }, []);

  const setFormValues = (promotionDetailData: PromotionDetailModel) => {
    form.setFieldsValue({
      ...promotionDetailData,
      timeRange: [moment(promotionDetailData.startAt).local(), moment(promotionDetailData.endAt).local()],
      basePriceIds: promotionDetailData.basePrices?.map((basePrice) => basePrice.id),
      basePlanId: promotionDetailData.basePlan?.id,
      basePlanPid: promotionDetailData.basePlan?.pid,
      giftPlanId: promotionDetailData.giftPlan?.id,
      countryIds: promotionDetailData.countries?.map((country) => country.id),
      codeTextEn: promotionDetailData.promotionCodeTexts?.en,
      codeTextEs: promotionDetailData.promotionCodeTexts?.es,
    });
  };

  const getAndSetPromotionDetails = () => {
    setLoading(true);
    promotionAPI
      .fetchPromotion(id)
      .then((res) => {
        setName(res.name);
        setPromotionDetails(res);
        setDescriptionDisclosures(res.descriptionDisclosures || []);
        setFormValues(res);
      })
      .finally(() => setLoading(false));
  };

  const handleUpdate = () => {
    form.validateFields().then((values) => {
      Modal.confirm({
        title: "Edit Promotion",
        okText: "Confirm",
        content: bold`Are you sure you want to edit the ${name}? The ongoing subscription that already applied the promotion will be affected. Please confirm that you want to proceed.`,
        onOk: () =>
          promotionAPI
            .updatePromotion(id, {
              ...values,
              descriptionDisclosures:
                values.discountType === DiscountType.BUY_ONE_GIFT_ONE
                  ? [
                      {
                        description: transformDescriptionDisclosure(values, "DescriptionContent"),
                        disclosure: transformDescriptionDisclosure(values, "DisclosureContent"),
                      },
                    ]
                  : descriptionDisclosures,
              timeRange: values.timeRange.map((date: moment.Moment) => date.format(DATETIME_FORMAT_WITH_00_SECOND)),
            })
            .then(() => {
              setEditing(false);
              form.resetFields();
              message.success("Promotion updated successfully.");
              getAndSetPromotionDetails();
            }),
      });
    });
  };

  const handleDelete = () => {
    Modal.confirm({
      title: "Delete Promotion",
      okText: "Confirm",
      content:
        promotionDetails &&
        bold`Are you sure you want to delete the ${name}? This action will permanently delete the promotion. Please confirm that you want to proceed.`,
      onOk: () =>
        promotionAPI.deletePromotion(id).then(() => {
          history.push("/marketing/promotions");
          message.success("Promotion deleted successfully.");
        }),
    });
  };

  const handleCancel = () => {
    Modal.confirm({
      title: "Confirm",
      okText: "OK",
      content: LEAVE_CONFIRMATION,
      onOk: () => {
        setDescriptionDisclosures(promotionDetails?.descriptionDisclosures || []);
        form.resetFields();
        setFormValues(promotionDetails!);
        setEditing(false);
      },
    });
  };

  return (
    <Card
      className="page-container"
      title={name}
      extra={
        editing ? (
          <EditingButtons onSave={handleUpdate} onCancel={handleCancel} />
        ) : (
          <ViewButtons
            isDeleteDisabled={!!promotionDetails?.isActiveBefore}
            onDelete={handleDelete}
            onEdit={() => setEditing(true)}
            user={user}
          />
        )
      }
    >
      <Spin spinning={loading} size="large">
        {editing ? (
          <>
            <Prompt when={editing} message={LEAVE_CONFIRMATION} />
            <PromotionForm
              form={form}
              descriptionDisclosures={descriptionDisclosures}
              updateDescriptionDisclosures={setDescriptionDisclosures}
            />
          </>
        ) : (
          <PromotionDetailsView promotionDetails={promotionDetails} />
        )}
      </Spin>
    </Card>
  );
}
