import { notification as antNotification } from "antd";
import { ArgsProps, NotificationInstance } from "antd/lib/notification";
import styles from "./Notification.module.scss";

antNotification.config({
  placement: "topRight",
  top: 88,
  duration: 3,
});

type Notification = Record<
  Exclude<keyof NotificationInstance, "open">,
  (arg: Pick<ArgsProps, "description" | "message">) => void
>;

const notification: Notification = {
  success: ({ description, message }) =>
    antNotification.success({
      description,
      message,
      className: styles.success,
    }),
  error: ({ description, message }) =>
    antNotification.error({
      description,
      message,
      className: styles.success,
    }),
  info: ({ description, message }) =>
    antNotification.info({
      description,
      message,
      className: styles.success,
    }),
  warning: ({ description, message }) =>
    antNotification.warning({
      description,
      message,
      className: styles.success,
    }),
};

export default notification;
