import { eComInstance } from "../utils/instance";
import { DescriptionTemplateListRequest } from "../types/dto/request/description";
import { DescriptionTemplateListResponse, DescriptionTemplateResponse } from "../types/dto/response/description";
import {
  transformDescriptionTemplate,
  transformDescriptionTemplateCreateRequest,
  transformDescriptionTemplateList,
  transformDescriptionTemplateUpdateRequest,
} from "../types/transformers/description";
import { DescriptionTemplateFormData } from "../types/model/description";

export async function fetchDescriptionTemplateList(params: DescriptionTemplateListRequest) {
  const url = "/descriptions";
  return eComInstance
    .get<DescriptionTemplateListResponse>(url, { params })
    .then((res) => transformDescriptionTemplateList(res));
}

export async function fetchDescriptionTemplateById(id: string) {
  const url = `/descriptions/${id}`;
  return eComInstance.get<DescriptionTemplateResponse>(url).then((res) => transformDescriptionTemplate(res));
}

export async function deleteDescriptionTemplateById(id: string) {
  const url = `/descriptions/${id}`;
  return eComInstance.delete(url);
}

export async function createDescriptionTemplate(description: DescriptionTemplateFormData) {
  const url = "/descriptions";
  return eComInstance.post(url, transformDescriptionTemplateCreateRequest(description)).then((res) => res);
}

export async function updateDescriptionTemplate(id: string, description: DescriptionTemplateFormData) {
  const url = `/descriptions/${id}`;
  return eComInstance
    .put<DescriptionTemplateResponse>(url, transformDescriptionTemplateUpdateRequest(description))
    .then((res) => transformDescriptionTemplate(res));
}
