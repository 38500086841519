export interface UpsellDowngradeModel {
  id: string;
  originalBasePriceName: string;
  primaryUpsellBasePriceName: string;
  secondaryUpsellBasePriceName: string;
  downgradeUpsellBasePriceName: string;
  updatedAt: string;
}

export interface UpsellDowngradeListModel {
  list: UpsellDowngradeModel[];
  total: number;
}

export enum PRODUCT_TYPE_UPSELL {
  SINGLE_LEVEL = "Single level",
  MULTI_LEVEL = "Multi-level",
  SUBSCRIPTION = "Subscription",
  ALL_ACCESS = "All Access",
}

export interface UpsellDowngradeFormValues {
  originalBasePriceId: string;
  originalProductType: PRODUCT_TYPE_UPSELL;
  originalCourseLevel?: string;
  originalBillingPlanId?: string;
  primaryUpsellBasePriceId?: string;
  primaryUpsellProductType?: string;
  primaryUpsellCourseLevel?: string;
  primaryUpsellBillingPlanId?: string;
  primaryUpsellTag?: string;
  primaryUpsellSlogan?: string;
  secondaryUpsellBasePriceId?: string;
  secondaryUpsellProductType?: string;
  secondaryUpsellCourseLevel?: string;
  secondaryUpsellBillingPlanId?: string;
  secondaryUpsellTag?: string;
  secondaryUpsellSlogan?: string;
  downgradeBasePriceId?: string;
  downgradeProductType?: string;
  downgradeCourseLevel?: string;
  downgradeBillingPlanId?: string;
}

export interface UpsellDowngradeDetailViewFields extends UpsellDowngradeFormValues {
  originalBasePriceName: string;
  originalCourseLevelName?: string;
  originalBillingPlanName?: string;
  primaryUpsellBasePriceName?: string;
  primaryUpsellCourseLevelName?: string;
  primaryUpsellBillingPlanName?: string;
  secondaryUpsellBasePriceName?: string;
  secondaryUpsellCourseLevelName?: string;
  secondaryUpsellBillingPlanName?: string;
  downgradeBasePriceName?: string;
  downgradeCourseLevelName?: string;
  downgradeBillingPlanName?: string;
}
