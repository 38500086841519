export interface CurrencyFormValue {
  fields: CurrencyFieldValue[];
}

interface CurrencyFieldValue {
  currency: string;
  country: {
    label: string;
    value: number;
  }[];
}

export interface BasePriceFieldValue {
  id: string;
  name: string;
  status: string;
  plans: Plan[];

  [key: string]: any;
}

export enum PriceStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum PriceAction {
  EDIT = "Edit",
  DELETE = "Delete",
  ACTIVATE = "Activate",
  DEACTIVATE = "Deactivate",
}

export interface BasePriceListModel {
  list: BasePriceModel[];
  total: number;
}

export interface ActiveBasePriceModel {
  id: string;
  name: string;
}

export interface BasePriceModel {
  id: string;
  name: string;
  currencies: {
    amount: number | string;
    code: string;
  }[];
  status: PriceStatus;
  plans: Plan[];
  validActions: PriceAction[];
}

export interface CurrencyModel {
  code: string;
  countries: Country[];
}

export interface PlanListModel {
  list: Plan[];
  total: number;
}

export interface Plan {
  productId: string;
  pid: string;
  title: string;
  updatedAt: string;
  status: string;
  product: {
    title: string;
    subtitle: string;
  };
}

export interface Country {
  id: number;
  iso2: string;
  iso3: string;
  name: string;
  displayName: string;
}

export interface Currency {
  code: string;
  symbol: string;
  symbolNative: string;
  name: string;
}
