import React, { useState } from "react";
import { DatePicker, Row } from "antd";
import moment, { Moment } from "moment";

type RangePickerValue = [Moment | undefined, Moment | undefined];

interface DateRangePickerProps {
  value?: RangePickerValue;
  onChange?: (value: RangePickerValue) => void;
}

function RangePicker({ value = [undefined, undefined], onChange }: DateRangePickerProps) {
  const [start, setStart] = useState<Moment>();
  const [end, setEnd] = useState<Moment>();

  return (
    <Row align={"middle"}>
      <DatePicker
        mode="date"
        placeholder="Start date"
        value={value?.[0] ?? start}
        onChange={(val) => {
          const v = val?.startOf("day") ?? undefined;
          setStart(v);
          if (onChange) {
            onChange([v, value?.[1] ?? end]);
          }
        }}
        disabledDate={(date) => date.isAfter(moment()) || (end != null && date.startOf("day").isSameOrAfter(end))}
        style={{ flexGrow: 1 }}
      />
      <span style={{ margin: "0 12px" }}>to</span>
      <DatePicker
        mode="date"
        placeholder="End date"
        value={value?.[1] ?? end}
        onChange={(val) => {
          const v = val?.endOf("day") ?? undefined;
          setEnd(v);
          if (onChange) {
            onChange([value?.[0] ?? start, v]);
          }
        }}
        disabledDate={(date) => date.isAfter(moment()) || (start != null && date.endOf("day").isSameOrBefore(start))}
        style={{ flexGrow: 1 }}
      />
    </Row>
  );
}

export default RangePicker;
