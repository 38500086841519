import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Button, Divider, Form, Popover, Select, Space } from "antd";
import Icon from "@ant-design/icons";
import { FormProps } from "antd/lib/form";
import { DropDown } from "assets/icons";
import { SaveOfferListRequest } from "../../../../types/dto/request/saveOffer";
import styles from "./SaveOfferAdvancedSearch.module.scss";
import { AppliedStage, SaveOfferType } from "../../../../types/model/saveOffer";

export interface SaveOfferAdvancedSearchProps {
  initialValues: SaveOfferListRequest;
  basePriceList: {
    id: string;
    name: string;
  }[];
  onSubmit: (values: SaveOfferListRequest) => void;
}

function SaveOfferAdvancedSearch({ initialValues, basePriceList, onSubmit }: SaveOfferAdvancedSearchProps) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const handleSearch: FormProps<SaveOfferListRequest>["onFinish"] = (values) => {
    onSubmit(values);
    setVisible(!visible);
  };

  function toggleAdvanceSearchButton() {
    setVisible((prevState) => {
      form.resetFields();
      return !prevState;
    });
  }

  const content = (
    <Form<SaveOfferListRequest>
      form={form}
      layout="inline"
      initialValues={initialValues}
      className={styles.searchForm}
      onFinish={handleSearch}
    >
      <Space direction="vertical" size={24}>
        <section className={styles.searchRow}>
          <Form.Item
            label="Applied stage"
            name="appliedStages"
            className={classnames(styles.searchItem, styles.fistColumnLabel)}
          >
            <Select
              allowClear
              mode="multiple"
              aria-label="applied stage"
              optionFilterProp="label"
              getPopupContainer={(trigger) => trigger.parentNode}
              suffixIcon={<DropDown />}
              style={{ width: 408 }}
              placeholder="Select applied stage"
              showArrow={true}
              options={Object.values(AppliedStage).map((stage) => ({
                label: stage,
                value: stage,
              }))}
            />
          </Form.Item>
          <Form.Item label="Base price name" name="appliedBasePriceIds">
            <Select
              allowClear
              mode="multiple"
              aria-label="base price name"
              optionFilterProp="label"
              getPopupContainer={(trigger) => trigger.parentNode}
              suffixIcon={<DropDown />}
              style={{ width: 408 }}
              placeholder="Select base price name"
              showArrow={true}
              options={basePriceList.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            />
          </Form.Item>
        </section>
        <section className={styles.searchRow}>
          <Form.Item
            label="Save offer type"
            name="type"
            className={classnames(styles.searchItem, styles.fistColumnLabel)}
          >
            <Select
              allowClear
              mode="multiple"
              aria-label="save offer type"
              optionFilterProp="label"
              getPopupContainer={(trigger) => trigger.parentNode}
              suffixIcon={<DropDown />}
              style={{ width: 408 }}
              placeholder="Select save offer list"
              showArrow={true}
              options={Object.values(SaveOfferType).map((stage) => ({
                label: stage,
                value: stage,
              }))}
            />
          </Form.Item>
        </section>
      </Space>
      <Divider />
      <Space className={styles.buttonGroup}>
        <Button type="default" shape="round" onClick={toggleAdvanceSearchButton}>
          Cancel
        </Button>
        <Button type="primary" shape="round" htmlType="submit">
          Search
        </Button>
      </Space>
    </Form>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      visible={visible}
      overlayClassName={styles.popover}
      onVisibleChange={toggleAdvanceSearchButton}
    >
      <Button type="default" shape="round">
        Advanced Search
        <Icon component={DropDown} />
      </Button>
    </Popover>
  );
}

export default SaveOfferAdvancedSearch;
