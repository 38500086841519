import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Popover, Radio, Select, Space } from "antd";
import Icon from "@ant-design/icons";
import { FormProps } from "antd/lib/form";
import { CheckboxOptionType } from "antd/lib/checkbox/Group";
import StartDateInput from "components/StartDateInput";
import EndDateInput from "components/EndDateInput";
import { DropDown } from "assets/icons";
import styles from "./ProductAdvancedSearch.module.scss";
import { EComProductListRequest } from "../../../../types/dto/request/eComProduct";
import EComLanguageSelect from "../AddNewProduct/ProductInfo/EComLanguageSelect";
import { DisplayLanguage } from "../AddNewProduct/ProductInfo";
import { BillingPlan, CourseType } from "../../../../types/model/eComProduct";
import EComBillingPlanSelect from "../AddNewProduct/ProductInfo/EComBillingPlanSelect";

const courseTypeOptions: CheckboxOptionType[] = [
  { label: CourseType.STANDARD, value: CourseType.STANDARD },
  { label: CourseType.PREMIUM, value: CourseType.PREMIUM },
];

export interface ProductAdvancedSearchProps {
  initialValues: EComProductListRequest;
  languages: Omit<DisplayLanguage, "usedCourseTypes">[];
  basePriceList: { id: string; name: string }[];
  billingPlanList: BillingPlan[];
  onSubmit: (values: EComProductListRequest) => void;
}

function EComProductAdvancedSearch({
  initialValues,
  languages,
  basePriceList,
  billingPlanList,
  onSubmit,
}: ProductAdvancedSearchProps) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const handleSearch: FormProps<EComProductListRequest>["onFinish"] = (values) => {
    onSubmit(values);
    setVisible(!visible);
  };

  function toggleAdvanceSearchButton() {
    setVisible((prevState) => {
      form.resetFields();
      return !prevState;
    });
  }

  const content = (
    <Form<EComProductListRequest>
      form={form}
      layout="inline"
      initialValues={initialValues}
      className={styles.searchForm}
      onFinish={handleSearch}
    >
      <Space direction="vertical" size={24}>
        <section className={styles.searchRow}>
          <Form.Item label="Modified at" className={classnames(styles.searchItem, styles.fistColumnLabel)}>
            <Form.Item noStyle name="lastModifiedDateFrom">
              <StartDateInput form={form} endFieldName="lastModifiedDateTo" />
            </Form.Item>
            <span style={{ margin: "0 20px" }}>to</span>
            <Form.Item noStyle name="lastModifiedDateTo">
              <EndDateInput form={form} startFieldName="lastModifiedDateFrom" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Base price name"
            name="basePriceIds"
            className={classnames(styles.searchItem, styles.secondColumnLabel)}
          >
            <Select
              allowClear
              mode="multiple"
              aria-label="base price name"
              optionFilterProp="label"
              getPopupContainer={(trigger) => trigger.parentNode}
              suffixIcon={<DropDown />}
              style={{ width: 408 }}
              placeholder="Select base price name"
              showArrow={true}
              options={basePriceList.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            />
          </Form.Item>
        </section>

        <section className={styles.searchRow}>
          <Form.Item
            label="Language"
            name="languageId"
            className={classnames(styles.searchItem, styles.fistColumnLabel)}
          >
            <EComLanguageSelect languages={languages} />
          </Form.Item>

          <Form.Item
            label="Course type"
            name="courseType"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            className={classnames(styles.searchItem, styles.secondColumnLabel)}
          >
            <Radio.Group options={courseTypeOptions} />
          </Form.Item>
        </section>

        <section className={styles.searchRow}>
          <Form.Item
            label="Billing plan"
            name="billingPlanId"
            className={classnames(styles.searchItem, styles.fistColumnLabel)}
          >
            <EComBillingPlanSelect billingPlanList={billingPlanList} />
          </Form.Item>
        </section>
      </Space>

      <Divider />

      <Space className={styles.buttonGroup}>
        <Button type="default" shape="round" onClick={toggleAdvanceSearchButton}>
          Cancel
        </Button>
        <Button type="primary" shape="round" htmlType="submit">
          Search
        </Button>
      </Space>
    </Form>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      visible={visible}
      overlayClassName={styles.popover}
      onVisibleChange={toggleAdvanceSearchButton}
    >
      <Button type="default" shape="round">
        Advanced Search
        <Icon component={DropDown} />
      </Button>
    </Popover>
  );
}

export default EComProductAdvancedSearch;
