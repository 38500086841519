import { Form, Input, InputNumber } from "antd";
import React from "react";
import { BasePriceModel } from "../../types/model/price";
import { limitMaxTwoDecimalsInput } from "../../utils/limitMaxTwoDecimalsInput";
import styles from "./index.module.scss";

interface EditableCellProps {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: BasePriceModel;
  index: number;
  children: React.ReactNode;
  maxInputNumber?: number;
}

function EditableCell({
  maxInputNumber,
  editing,
  dataIndex,
  title,
  inputType,
  record,
  children,
  ...restProps
}: EditableCellProps) {
  const isNumber = inputType === "number";

  return (
    <td {...restProps}>
      {editing ? (
        isNumber ? (
          <Form.Item name={dataIndex} style={{ margin: 0 }}>
            <InputNumber
              type={"number"}
              className={styles.editableCellInputNumber}
              onKeyPress={limitMaxTwoDecimalsInput}
              max={maxInputNumber}
            />
          </Form.Item>
        ) : (
          <Form.Item name={dataIndex} style={{ margin: 0 }} rules={[{ required: true, max: 100 }]}>
            <Input />
          </Form.Item>
        )
      ) : (
        <div>{children}</div>
      )}
    </td>
  );
}

export default EditableCell;
