import React, { useMemo, useState } from "react";
import { ColumnType } from "antd/lib/table";

export default function useResizableTableHeader<T>(columnConfig: ColumnType<T>[]) {
  const [tableColumns, setTableColumns] = useState<ColumnType<T>[]>(columnConfig);

  const handleResize = (index: number) => (e: any, { size }: any) => {
    const nextColumns = [...tableColumns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    setTableColumns(nextColumns);
  };

  return useMemo(
    () =>
      tableColumns.map((col, index) => ({
        ...col,
        onHeaderCell: (column: ColumnType<T>) =>
          ({
            width: column.width,
            onResize: handleResize(index),
          } as React.HTMLAttributes<HTMLElement>),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableColumns]
  );
}
