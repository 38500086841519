import React from "react";
import { Redirect, Route } from "react-router-dom";
import { RouteProps } from "react-router";
import useAppAuth0 from "hooks/useAppAuth0";
import { Permission } from "types/permission";

interface PrivateRouteProps extends RouteProps {
  permission: Permission;
}

function PrivateRoute({ children, permission, ...rest }: PrivateRouteProps) {
  let { user } = useAppAuth0();
  const isAuthorized = user.hasPermission(permission);

  return <Route {...rest}>{isAuthorized ? children : <Redirect to="/" />}</Route>;
}

export default PrivateRoute;
