import { useEffect, useState } from "react";
import { Form, Spin } from "antd";
import { ProductPlanDetails } from "../../../../types/model/eComProduct";
import * as productAPI from "../../../../services/product";
import styles from "./index.module.scss";
import { getText } from "../../../../utils/richTextEditor";
import { displayBillingQuantityAndCycle } from "../../../../utils/displayBillingQuantityAndCycle";
import DetailContainerComponent from "./DetailContainerComponent";

interface IPlanDetails {
  plan: ProductPlanDetails;
}

const PlanDetails = ({ plan }: IPlanDetails) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      checkoutDeeplink: `${process.env.REACT_APP_ECOM_BASE_URL}/checkout?pid=${plan.pid}`,
    });
    let ignore = false;

    setLoading(true);
    productAPI
      .fetchProduct(`${plan.contentProductId}`)
      .then((productResponse) => {
        if (ignore) return;

        if (productResponse.productType === "SINGLE_LEVEL") {
          const course = productResponse.course!;
          form.setFieldsValue({
            ...plan,
            courseName:
              productResponse.isbn && course.courseName ? `${productResponse.isbn} ${course.courseName}` : "-",
            courseVersion: course.courseVersion,
            courseLevel: course.courseLevel ? `Level ${course.courseLevel}` : "-",
            lessonRange:
              productResponse.lessonFrom && productResponse.lessonTo
                ? `Lesson ${productResponse.lessonFrom} to ${productResponse.lessonTo}`
                : "-",
            contentProductStatus: productResponse.status,
          });
        }

        if (
          productResponse.productType === "MULTI_LEVEL" ||
          productResponse.productType === "ALL_ACCESS" ||
          productResponse.productType === "UPGRADE" ||
          productResponse.productType === "SUBSCRIPTION"
        ) {
          form.setFieldsValue({
            ...plan,
            billingQuantityAndCycle: displayBillingQuantityAndCycle(plan.billingCycle, plan.billingQuantity),
            courseName:
              productResponse.isbn && productResponse.name ? `${productResponse.isbn} ${productResponse.name}` : "-",
            contentProductStatus: productResponse.status,
            singleLevelProduct: productResponse
              .bundledProducts!.map(({ name, isbn }) => (name && isbn ? `${isbn} ${name}` : "-"))
              .join("\n"),
          });
        }
      })
      .finally(() => setLoading(false));

    return () => {
      ignore = true;
    };
  }, [plan, form]);

  const pidProp = plan.pid !== "-" && plan.pid ? { pid: plan.pid } : {};
  return (
    <Spin spinning={loading} size={"large"}>
      <Form form={form} className={styles.planDetailContainer}>
        <DetailContainerComponent
          basePriceHelpText={plan.basePriceHelpText}
          planImageUrl={plan.productImage}
          status={plan.status}
          descriptionHelpText={getText(plan.descTemplateContent)}
          freeTrialType={plan.freeTrialType}
          freeTrialUsageLimit={plan.freeTrialUsageLimit}
          contentProductType={plan.contentProductType}
          billingPlanId={plan.billingPlanId}
          {...pidProp}
        />
      </Form>
    </Spin>
  );
};

export default PlanDetails;
