import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Row, Space } from "antd";
import { TableProps } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import omit from "lodash/omit";
import { ArrayParam, NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import * as eComProductAPI from "../../../../services/eComProduct";
import { getOffset } from "../../../../utils/getPagination";
import { EComProductListRequest } from "../../../../types/dto/request/eComProduct";
import { EComProductListModel, EComProductModel } from "../../../../types/model/eComProduct";
import EComProductTable from "./ProductTable";
import { updateSortOrder } from "../../../../types/transformers/updateSortOrder";
import styles from "./index.module.scss";
import { EComModal } from "../../../../components/EComModal";
import { message } from "../../../../components";

export default function EComPublishPlans() {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
    order: withDefault(StringParam, "-updated_at"),
    status: withDefault(ArrayParam, undefined),
  });
  const [isEComProductListPending, setIsEComProductListPending] = useState(false);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [productList, setProductList] = useState<EComProductListModel>({} as EComProductListModel);
  const history = useHistory();
  const [isPublishModalVisible, setIsPublishModalVisible] = useState(false);

  useEffect(() => {
    setIsEComProductListPending(true);
    eComProductAPI
      .fetchProductList({
        ...omit(query, ["page"]),
        offset: getOffset(query.page, query.limit),
        status: "Ready for publish",
      } as EComProductListRequest)
      .then((res) => {
        setProductList(res);
      })
      .finally(() => {
        setIsEComProductListPending(false);
      });
  }, [query]);

  const handleTableChange: TableProps<EComProductModel>["onChange"] = (pagination, filters, sorter, extra) => {
    const { order } = sorter as SorterResult<EComProductModel>;
    if (extra.action === "sort") {
      setQuery({
        ...query,
        order: updateSortOrder[order as string],
        page: 1,
        limit: pagination.pageSize,
      });
    }
  };

  const handleCancel = () => {
    history.push("/products/ecom");
  };

  const handlePublish = () => {
    setIsPublishLoading(true);
    eComProductAPI
      .batchPublishPlans()
      .then(() => {
        message.success("All Ready for publish plans have been activated successfully.");
        history.push("/products/ecom");
      })
      .finally(() => {
        setIsPublishLoading(false);
      });
  };

  const renderTitleButtons = () => {
    return (
      <Row justify="end">
        <Space>
          <Button type="default" shape="round" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              setIsPublishModalVisible(true);
            }}
          >
            Publish
          </Button>
        </Space>
      </Row>
    );
  };

  return (
    <Card title="Publish plans" extra={renderTitleButtons()} className={`page-container ${styles.publishPlans}`}>
      <Space direction="vertical" size={24}>
        <p style={{ margin: 0 }}>
          All plans with status <b>Ready for publish</b> will be Active
        </p>
        <EComProductTable
          isLoading={isEComProductListPending}
          productData={productList}
          page={query.page}
          size={query.limit}
          onPaginationChange={(page) => {
            setQuery({
              ...query,
              page,
              order: query.order,
            });
          }}
          onTableChange={handleTableChange}
        />
      </Space>

      <EComModal
        visible={isPublishModalVisible}
        title="Publish plans"
        okText="Confirm"
        onCancel={() => {
          setIsPublishModalVisible(false);
        }}
        onOk={handlePublish}
        confirmLoading={isPublishLoading}
      >
        <div>
          <p>
            You are about to activate all the plans in <b>Ready for publish</b> status. This action will change the plan
            status to <b>Active</b> and customers will be able to purchase it.
          </p>
          <p>Please confirm that you want to proceed with the activation.</p>
        </div>
      </EComModal>
    </Card>
  );
}
