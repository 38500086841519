import React from "react";
import styles from "./CourseCompleted.module.scss";

interface Props {
  completed: boolean;
}

function CourseCompleted({ completed }: Props) {
  let iconStyle;

  switch (completed) {
    case true:
      iconStyle = styles.completed;
      break;
    case false:
      iconStyle = styles.notCompleted;
      break;
  }
  return <div className={iconStyle}>{completed ? "Y" : "N"}</div>;
}

export default CourseCompleted;
