import instance from "utils/instance";
import { OrganizationModel, GenericProductModel, IndividualProductModel } from "types/model/voucher";
import { VoucherGroupDetailResponse, VoucherGroupListResponse } from "../types/dto/response/voucher";
import Page from "../types/page";
import { FinancialReportParams, VoucherGroupCreateRequest, VoucherListParams } from "../types/dto/request/voucher";
import ProductModel from "../types/model/product";
import { DEFAULT_MAX_PAGINATION } from "../constants/common";

export async function fetchAll(params: VoucherListParams) {
  const url = "/vouchers/groups";
  return instance.get<Page<VoucherGroupListResponse>>(url, { params });
}

export async function fetchOrganizations() {
  const url = "/organizations";
  const params = { ...DEFAULT_MAX_PAGINATION };
  return instance.get<Page<OrganizationModel>>(url, { params });
}

export async function fetchProducts() {
  const url = "/products";
  const params = {
    ...DEFAULT_MAX_PAGINATION,
    productTypes: "SINGLE_LEVEL, MULTI_LEVEL, ALL_ACCESS",
    isPublished: true,
  };
  return instance.get<Page<ProductModel>>(url, { params });
}

export async function fetchGenericProducts() {
  const url = "/generic-products";
  return instance.get<GenericProductModel[]>(url);
}
export async function fetchIndividualProducts() {
  const url = "/individual-products";
  return instance.get<IndividualProductModel[]>(url);
}

export async function fetchVoucherGroup(groupId: string) {
  const url = `/vouchers/groups/${groupId}`;
  return instance.get<VoucherGroupDetailResponse>(url);
}

export async function addOne(data: VoucherGroupCreateRequest) {
  const url = `/vouchers/groups`;
  return instance.post<void>(url, data);
}

export async function update(groupId: string, patch: VoucherGroupCreateRequest) {
  const url = `/vouchers/groups/${groupId}`;
  return instance.put<void>(url, patch);
}

export function remove(groupId: string) {
  const url = `/vouchers/groups/${groupId}`;
  return instance.delete<void>(url);
}

export async function exportFinancialReport(params: FinancialReportParams) {
  const url = "/financial-reports/redeemed-generic-isbns/export";
  return instance.get(url, { params, responseType: "arraybuffer" });
}
