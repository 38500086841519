import React from "react";
import { Modal, ModalProps } from "antd";
import styles from "./index.module.scss";

interface Props extends ModalProps {
  title: string;
  okText: string;
  visible: boolean;
  onCancel: () => void;
  confirmLoading: boolean;
  onOk: () => void;
  children: React.ReactNode;
  style?: any;
}

export const EComModal = (props: Props) => {
  const { title, okText, visible, onCancel, confirmLoading, onOk, children, style, ...otherProps } = props;
  return (
    <Modal
      bodyStyle={{ overflowY: "auto", maxHeight: "70vh" }}
      okButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
      cancelButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
      centered={true}
      visible={visible}
      title={title}
      okText={okText}
      onCancel={onCancel}
      onOk={onOk}
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      className={style ?? styles.modal}
      {...otherProps}
    >
      {children}
    </Modal>
  );
};
