import React from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import { FormInstance } from "rc-field-form";
import { PRODUCT_TYPE_VIEW_MAPPING } from "constants/common";
import moment from "moment";
import { Alarm } from "assets/icons";
import { useAppDispatch } from "app/store";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { ProductStatusView, DisplayInput, Modal, message } from "components";
import ProductModel, { ProductStatus, ProductType } from "types/model/product";
import { getVisibility } from "./ProductBasicDetailForm";
import { fetchProduct, removeSchedule } from "../../productSlice";

import styles from "./ProductBasicDetailView.module.scss";

interface ProductBasicDetailViewProps {
  form: FormInstance<ProductModel>;
  scheduledPublishTime?: number;
}

function ProductBasicDetailView({ form, scheduledPublishTime }: ProductBasicDetailViewProps) {
  const dispatch = useAppDispatch();
  const { productId } = useParams<{ productId: string }>();
  const { productType } = form.getFieldsValue();
  const visibility = getVisibility(productType);
  const isBundled = ["MULTI_LEVEL", "SUBSCRIPTION", "UPSELL"].includes(productType);

  const handleDeleteSchedule = () => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure you want to stop the schedule publish?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        dispatch(removeSchedule(productId))
          .then(unwrapResult)
          .then(() => {
            dispatch(fetchProduct(productId));
          })
          .catch(message.error);
      },
    });
  };

  return (
    <>
      <div className={styles.formContainer}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name="productType" label="Product type:">
              <DisplayInput<ProductType> render={(x) => x && PRODUCT_TYPE_VIEW_MAPPING[x]} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="isbn" label="ISBN:">
              <DisplayInput />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="languageName" label="Language:">
              <DisplayInput />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="status" label="Status:">
              <DisplayInput<ProductStatus> render={(x) => x && <ProductStatusView status={x} />} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />

        <Row gutter={16}>
          {visibility.isPremium && (
            <Col span={6}>
              <Form.Item name="isPremium" label="Course type:">
                <DisplayInput<ProductModel["isPremium"]>
                  render={(x) => (x === undefined ? undefined : x ? "Premium" : "Standard")}
                />
              </Form.Item>
            </Col>
          )}
          {visibility.premiumISBN && (
            <Col span={12}>
              <Form.Item name={["premiumISBN", "label"]} label="Premium ISBN:">
                <DisplayInput />
              </Form.Item>
            </Col>
          )}
          {visibility.courseLevel && (
            <Col span={isBundled ? 18 : 6}>
              <Form.Item
                name="course"
                label={
                  productType === "MULTI_LEVEL" || productType === "UPSELL" ? "Single level product:" : "Course level:"
                }
                style={{ marginBottom: "24px" }}
              >
                <DisplayInput<ProductModel["course"]>
                  render={(x) => {
                    if (!x) {
                      return undefined;
                    }
                    const { courseName, courseVersion } = x;
                    if (productType === "SINGLE_LEVEL") {
                      return `Level ${x.courseLevel}`;
                    }
                    if (productType === "FREE" || productType === "LESSON_PACK") {
                      return `Level ${x.courseLevel} ${x.courseVersion}`;
                    }
                    return Array.isArray(courseName) ? courseName.join("\n") : `${courseName} ${courseVersion}`;
                  }}
                />
              </Form.Item>
            </Col>
          )}
          {visibility.lessonRange && (
            <Col span={6}>
              <Form.Item name="lessonRange" label="Lesson range:">
                <DisplayInput<ProductModel["lessonRange"]>
                  render={(x) => x && x.from && x.to && `Lesson ${x.from} to ${x.to}`}
                />
              </Form.Item>
            </Col>
          )}
          {visibility.version && (
            <Col span={6}>
              <Form.Item name={["course", "courseVersion"]} label="Version:">
                <DisplayInput />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Divider />

        <Row gutter={16}>
          <Col span={11}>
            <Form.Item name="upsellName" label="Course name - Upsell card & Library:">
              <DisplayInput />
            </Form.Item>
          </Col>
          <Col span={11} push={1}>
            <Form.Item name="learnName" label="Course name - Learn page:">
              <DisplayInput />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="upsellDescription" label="Course description - Upsell card:">
              <DisplayInput />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name={["price", "amount"]} label="Price:">
              <DisplayInput />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={["price", "currency"]} label="Currency:">
              <DisplayInput />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="notes" label="Note:">
              <DisplayInput />
            </Form.Item>
          </Col>
        </Row>
      </div>
      {scheduledPublishTime && (
        <div className={styles.scheduleContainer}>
          <div className={styles.scheduleContent}>
            <Alarm className={styles.icon} />
            <div>{moment(scheduledPublishTime).format()}</div>
            <Button className={styles.button} onClick={handleDeleteSchedule} type={"link"}>
              Delete
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default ProductBasicDetailView;
