import { PaymentMethodDto } from "../../../dto/response/paymentMethod";
import { BillingAddress, CreditCardType, PaymentMethod, PaymentMethodType } from "../../../model/paymentMethod";
import { BillingAddressUpdateRequest } from "../../../dto/request/paymentMethod";

export function transformPaymentMethod(dto: PaymentMethodDto): PaymentMethod {
  const model: PaymentMethod = {
    id: dto.id,
    created: dto.created,
    type: dto.type as PaymentMethodType,
    default: dto.default,
  };

  if (dto.billing_address) {
    model.billingAddress = {
      postalCode: dto.billing_address.postal_code,
      district: dto.billing_address.district,
      country: dto.billing_address.country,
    };
  }

  if (dto.credit_card) {
    model.creditCard = {
      account: dto.credit_card.account,
      expirationDate: dto.credit_card.expiration_date,
      type: dto.credit_card.type as CreditCardType,
    };
  }

  if (dto.paypal) {
    model.paypal = {
      paypalEmail: dto.paypal.paypal_email,
    };
  }

  return model;
}

export function transformToBillingAddressUpdateRequest(model: BillingAddress): BillingAddressUpdateRequest {
  return {
    billing_address: {
      postal_code: model.postalCode,
      country: model.country,
      district: model.district,
    },
  };
}
