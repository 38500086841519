import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, message } from "antd";
import { EComModal } from "../../../components/EComModal";
import { editEntitlement } from "../../../services";
import EntitlementForm from "../AddEntitlement/EntitlementForm";
import { fetchEntitlementList } from "../customerSlice";
import { useAppDispatch } from "../../../app/store";

export default function EditEntitlement({
  isbn,
  productName,
  isModalVisible,
  entitlementId,
  expirationDate,
  setIsModalVisible,
}: any) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { customerId } = useParams<{ customerId: string }>();
  const [confirmLoading, setConfirmLoading] = useState(false);

  function closeModal() {
    setIsModalVisible(false);
    form.resetFields();
  }

  function edit() {
    form.validateFields().then(() => {
      setConfirmLoading(true);
      const { expirationDate } = form.getFieldsValue();
      editEntitlement({
        id: entitlementId,
        expirationTimestamp: expirationDate.valueOf(),
      })
        .then(() => {
          closeModal();
          dispatch(fetchEntitlementList(Number(customerId)))
            .then(unwrapResult)
            .catch(message.error);
        })
        .catch((error) => {
          message.error(error);
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    });
  }

  return (
    <EComModal
      visible={isModalVisible}
      title="Edit entitlement"
      okText="Save"
      onCancel={closeModal}
      onOk={edit}
      confirmLoading={confirmLoading}
    >
      <EntitlementForm
        form={form}
        defaultValue={{
          isbn,
          productName,
          expirationDate,
        }}
      />
    </EComModal>
  );
}
