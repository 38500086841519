import React from "react";

interface HighlightTextConfig {
  highlightStyle?: React.CSSProperties;
  baseStyle?: React.CSSProperties;
}

const highlightText = (config?: HighlightTextConfig) => (
  literals: TemplateStringsArray,
  ...substitutions: string[]
) => (
  <div style={config?.baseStyle}>
    {literals.map((literal, i) => (
      <span key={i}>
        {literal}
        {substitutions[i] && <span style={config?.highlightStyle}>{substitutions[i]}</span>}
      </span>
    ))}
  </div>
);

export const boldAndItalic = highlightText({
  highlightStyle: {
    fontWeight: "bold",
    fontStyle: "italic",
    wordBreak: "break-all",
  },
  baseStyle: { whiteSpace: "pre-wrap" },
});

export const bold = highlightText({
  highlightStyle: {
    fontWeight: "bold",
    wordBreak: "break-all",
  },
  baseStyle: { whiteSpace: "pre-wrap" },
});

export default highlightText;
