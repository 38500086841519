import React from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { NavLink } from "react-router-dom";
import { Breadcrumb } from "antd";
import routes from "app/routes";

function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs(routes, {
    excludePaths: ["/customers", "/products", "/marketing", "/"],
  });

  if (breadcrumbs.length <= 1) {
    return null;
  }

  return (
    <Breadcrumb>
      {breadcrumbs.map(({ breadcrumb, location, match }, index) => (
        <Breadcrumb.Item key={index}>
          {
            // @ts-ignore
            match.url === location.pathname ? breadcrumb : <NavLink to={match.url}>{breadcrumb}</NavLink>
          }
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
