import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Prompt, useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { Button, Form, Input, Row, Space, Spin, Tabs } from "antd";
import Icon from "@ant-design/icons";
import { FormProps } from "antd/lib/form";
import _, { isEmpty, join } from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { RootState, useAppDispatch } from "app/store";
import { Copy, Fail, Success } from "assets/icons";
import { message, Modal, ScheduledPublish } from "components";
import { EMPTY, LEAVE_CONFIRMATION, validateMessages } from "constants/common";
import { boldAndItalic } from "utils";
import moment from "moment";
import useAppAuth0 from "hooks/useAppAuth0";
import ProductModel from "types/model/product";
import ProductBasicDetailView from "./ProductBasicDetailView";
import ProductUpsellDetail from "./ProductUpsellDetail";
import {
  fetchLanguages,
  fetchProduct,
  productsSelectors,
  publishProduct,
  removeProduct,
  scheduledPublishProduct,
  updateProduct,
  verifyProduct,
} from "../../productSlice";
import ProductUpsellForm from "../ProductUpsellForm";
import ProductBasicDetailForm from "./ProductBasicDetailForm";
import {
  cleanup,
  fetchAllAccessInfoByLanguageId,
  fetchEComAllAccessSelectionItems,
  fetchEComSelectionItems,
  fetchProductsInfoByLanguageId,
  fetchSelectionItems,
} from "../AddNewProduct/addProductSlice";
import ProductDeeplinkForm from "./ProductDeeplink/ProductDeeplinkForm";
import ProductDeeplinkView from "./ProductDeeplink/ProductDeeplinkView";
import ProductLog from "./ProductChangeLogView";
import styles from "./ProductDetail.module.scss";
import SaveChangesPopup from "./SaveChangesPopup";
import { LanguageModel } from "../../../../types/model/voucher";

function ProductDetail() {
  const dispatch = useAppDispatch();
  const { productId } = useParams<{ productId: string }>();
  const product = useSelector((state: RootState) => productsSelectors.selectById(state, productId));
  const { allAccess } = useSelector((state: RootState) => state.products);
  const isLoading = useSelector((state: RootState) => state.loading);
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const [scheduleForm] = Form.useForm();
  const [verifyForm] = Form.useForm();
  const history = useHistory();
  const [isDisplaySaveChangesPopup, setIsDisplaySaveChangesPopup] = useState(false);
  const fetchAllAccessData = (languageId: number) => {
    dispatch(fetchEComAllAccessSelectionItems(languageId));
    dispatch(
      fetchAllAccessInfoByLanguageId({
        languageId,
      })
    );
  };

  const { user } = useAppAuth0();

  useEffect(() => {
    window.onbeforeunload = () => (editing ? true : undefined);
    return () => {
      window.onbeforeunload = null;
    };
  });

  useEffect(() => form.resetFields(), [form, product]);

  useEffect(() => {
    dispatch(fetchProduct(productId)).then((res) => {
      const product = res.payload as ProductModel;
      dispatch(fetchEComSelectionItems(product?.languageId)).then(unwrapResult).catch(message.error);
      dispatch(
        fetchProductsInfoByLanguageId({
          languageId: product?.languageId,
        })
      );
      _.isEmpty(allAccess)
        ? dispatch(fetchLanguages())
            .then((res) => res.payload as LanguageModel[])
            .then((languages) => languages?.filter((language) => language.name === "All Access")[0]?.id)
            .then((id) => fetchAllAccessData(id))
        : fetchAllAccessData(allAccess.id);
    });
    // reset addProductSlice's state when unmounting
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch, productId]);

  // TODO - Enhancement: should fetch the data only at the first time.
  useEffect(() => {
    product &&
      editing &&
      dispatch(
        fetchSelectionItems({
          isPremium: product.isPremium,
          languageId: product.languageId,
          productType: product.productType,
        })
      )
        .then(unwrapResult)
        .catch(message.error);
  }, [dispatch, product, editing]);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCopy = () => {
    message.success("Copied", 1);
  };

  const handleVerify = () => {
    Modal.confirm({
      title: "Confirm",
      content: (
        <>
          <div>Are you sure you want to verify this product?</div>
          <Form className={styles.verifyForm} form={verifyForm}>
            <Form.Item label="Comments" name="comments" rules={[{ max: 200 }]}>
              <Input />
            </Form.Item>
          </Form>
        </>
      ),
      okText: "Yes",
      cancelText: "No",
      onOk: () =>
        verifyForm.validateFields().then(() => {
          const { comments } = verifyForm.getFieldsValue();
          dispatch(verifyProduct({ productId, comments }))
            .then(unwrapResult)
            .then((data) => {
              if (data?.succeeded) {
                Modal.info({
                  className: styles.modal,
                  title: "Verify Successful",
                  content: (
                    <div className={styles.content}>
                      {data?.updatedFiles.map((messageInfo) => (
                        <div>{messageInfo}</div>
                      ))}
                    </div>
                  ),
                  icon: <Icon component={Success} />,
                  okButtonProps: { shape: "round" },
                  onOk: () => {
                    history.go(0);
                  },
                });
              } else {
                const mismatchedItemsIsEmpty = isEmpty(data?.mismatchedItems);
                const incorrectFormatItemsIsEmpty = isEmpty(data?.incorrectFormatItems);
                Modal.info({
                  title:
                    !mismatchedItemsIsEmpty && !incorrectFormatItemsIsEmpty
                      ? "Mismatched Items And Incorrect Formats"
                      : !mismatchedItemsIsEmpty && incorrectFormatItemsIsEmpty
                      ? "Mismatched Items"
                      : "Incorrect Formats",
                  icon: <Icon component={Fail} />,
                  className: styles.modal,
                  content: (
                    <>
                      <div className={styles.content}>
                        {!mismatchedItemsIsEmpty && !incorrectFormatItemsIsEmpty ? (
                          <>
                            {data?.mismatchedItems.map((messageInfo) => (
                              <div>{messageInfo}</div>
                            ))}
                            <br />
                            {data?.incorrectFormatItems.map((messageInfo) => (
                              <div>{messageInfo}</div>
                            ))}
                          </>
                        ) : !mismatchedItemsIsEmpty && incorrectFormatItemsIsEmpty ? (
                          data?.mismatchedItems.map((messageInfo) => <div>{messageInfo}</div>)
                        ) : (
                          data?.incorrectFormatItems.map((messageInfo) => <div>{messageInfo}</div>)
                        )}
                      </div>
                      <div className={styles.clipboard}>
                        <Copy />
                        <CopyToClipboard
                          text={
                            !mismatchedItemsIsEmpty && !incorrectFormatItemsIsEmpty
                              ? join(data?.mismatchedItems && data?.incorrectFormatItems, "\n")
                              : !mismatchedItemsIsEmpty && incorrectFormatItemsIsEmpty
                              ? join(data?.mismatchedItems, "\n")
                              : join(data?.incorrectFormatItems, "\n")
                          }
                        >
                          <Button type={"link"} onClick={handleCopy}>
                            Copy to clipboard
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </>
                  ),
                  okButtonProps: { shape: "round" },
                  onOk: () => {
                    Modal.destroyAll();
                  },
                });
              }
            })
            .catch(message.error);
        }),
    });
  };

  const handlePublish = () => {
    Modal.confirm({
      title: "Confirm",
      content: <ScheduledPublish form={scheduleForm} initialPublishTime={product?.scheduledPublishTime} />,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () =>
        scheduleForm.validateFields().then(() => {
          const { publishNow, scheduledPublishTime } = scheduleForm.getFieldsValue();
          if (publishNow) {
            dispatch(publishProduct(productId))
              .then(unwrapResult)
              .then(() => {
                Modal.info({
                  className: styles.modal,
                  title: "Publish Successful",
                  content: "The product is published successfully!",
                  icon: <Icon component={Success} />,
                  okButtonProps: { shape: "round" },
                  onOk: () => {
                    history.go(0);
                  },
                });
              })
              .catch(message.error);
          } else {
            dispatch(
              scheduledPublishProduct({
                productId,
                scheduledPublishTime: moment(scheduledPublishTime).valueOf(),
              })
            )
              .then(unwrapResult)
              .then(() => {
                dispatch(fetchProduct(productId));
              })
              .catch(message.error);
          }
        }),
    });
  };

  const showCancelModal = () => {
    Modal.confirm({
      title: "Confirm",
      content: LEAVE_CONFIRMATION,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        form.resetFields();
        setEditing(false);
      },
    });
  };

  const handleDelete = () => {
    Modal.confirm({
      title: "Confirm",
      content:
        product &&
        boldAndItalic`Are you sure you want to delete product ${product.isbn}?
This can not be undone.`,
      onOk: () =>
        product?.id &&
        dispatch(removeProduct(product.id))
          .then(unwrapResult)
          .then(() => history.push("/products"))
          .catch((err) => Promise.reject(message.error(err))),
    });
  };

  const handleSubmit: FormProps<ProductModel>["onFinish"] = (values) => {
    if (product?.hasHistoricalActiveStatus) {
      setIsDisplaySaveChangesPopup(true);
    } else {
      const { deepLinkPromotion, namiGroups } = values;
      if (deepLinkPromotion) {
        values.deepLinkPromotion = {
          promotionTitle: deepLinkPromotion.promotionTitle?.trim(),
          promotionContent: deepLinkPromotion.promotionContent?.trim(),
          promotionComment: deepLinkPromotion.promotionComment?.trim(),
        };
      }
      if (!namiGroups) {
        values.namiGroups = [];
      }
      dispatch(updateProduct(values))
        .then(unwrapResult)
        .then(() => setEditing(false))
        .catch(message.error);
    }
  };

  const editingButtons = (
    <>
      <Button type="default" shape="round" onClick={showCancelModal}>
        Cancel
      </Button>
      <Form.Item noStyle>
        <Button type="primary" shape="round" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </>
  );

  const viewButtons = (
    <>
      {user.canDeleteProduct && (
        <Button
          type="default"
          shape="round"
          disabled={product?.status === "ACTIVE" || product?.status === "ARCHIVED"}
          onClick={handleDelete}
        >
          Delete
        </Button>
      )}
      {user.canEditProduct && (
        <Button
          type="default"
          shape="round"
          onClick={handleEdit}
          disabled={["DVD", "UPSELL"].includes(product?.productType!) || product?.status === "ARCHIVED"}
        >
          Edit
        </Button>
      )}
      {product?.status === "STAGING" && user.canVerifyProduct && (
        <Button type="primary" shape="round" onClick={handleVerify}>
          Verify
        </Button>
      )}
      {product?.status === "READY_FOR_REVIEW" && user.canPublishProduct && (
        <Button type="primary" shape="round" onClick={handlePublish}>
          Publish
        </Button>
      )}
    </>
  );

  return (
    <Spin spinning={isLoading} size={"large"}>
      <SaveChangesPopup
        isVisible={isDisplaySaveChangesPopup}
        values={form.getFieldsValue()}
        onCancel={() => setIsDisplaySaveChangesPopup(false)}
        onConfirm={() => {
          setIsDisplaySaveChangesPopup(false);
          setEditing(false);
        }}
      />
      <Prompt when={editing} message={LEAVE_CONFIRMATION} />
      <Form
        form={form}
        layout={"horizontal"}
        initialValues={product}
        validateTrigger={"onBlur"}
        validateMessages={validateMessages}
        onFinish={handleSubmit}
        className={styles.detailContainer}
      >
        <Row justify={"space-between"}>
          <h2>{product?.name || EMPTY}</h2>
          <Space>{editing ? editingButtons : viewButtons}</Space>
        </Row>

        <Tabs>
          {editing ? (
            <>
              <Tabs.TabPane tab="Product info" key={1}>
                <ProductBasicDetailForm />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Upsell & Upgrade" key={2}>
                <ProductUpsellForm form={form} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Deeplink" key={3}>
                <ProductDeeplinkForm form={form} />
              </Tabs.TabPane>
            </>
          ) : (
            <>
              <Tabs.TabPane tab="Product info" key={1}>
                <Form.Item shouldUpdate={true} noStyle>
                  {(formCtx) => (
                    <ProductBasicDetailView form={formCtx} scheduledPublishTime={product?.scheduledPublishTime} />
                  )}
                </Form.Item>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Upsell & Upgrade" key={2}>
                <ProductUpsellDetail product={product!} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Deeplink" key={3}>
                <ProductDeeplinkView product={product!} />
              </Tabs.TabPane>
            </>
          )}
          <Tabs.TabPane tab="Product log" key={4}>
            <ProductLog />
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Spin>
  );
}

export default ProductDetail;
