import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { useSelector } from "react-redux";
import { DropDown } from "../../../../assets/icons";
import { RootState } from "../../../../app/store";
import { PRODUCT_TYPE_FOR_ADD } from "../../../../types/model/product";

interface IProps extends SelectProps<number | undefined> {
  productType?: PRODUCT_TYPE_FOR_ADD;
}

function LanguageSelect(props: IProps) {
  const { productType, onChange } = props;
  const { languages, allAccess } = useSelector((state: RootState) => state.products);

  const [value, setValue] = useState<number>();

  const options = (productType === PRODUCT_TYPE_FOR_ADD.All_ACCESS ? [...languages, allAccess] : languages).map(
    (language) => ({
      label: language.name,
      value: language.id,
    })
  );

  useEffect(() => {
    if (productType === PRODUCT_TYPE_FOR_ADD.All_ACCESS) {
      onChangeInternal(allAccess.id);
    }
    if (productType !== PRODUCT_TYPE_FOR_ADD.All_ACCESS && value === allAccess.id) {
      onChangeInternal(undefined);
    }
  }, [productType]);

  const onChangeInternal = (value?: number) => {
    setValue(value);
    onChange && onChange(value, options);
  };

  const handleInputKeyDown: SelectProps<any>["onInputKeyDown"] = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Select
      {...props}
      allowClear
      disabled={productType === PRODUCT_TYPE_FOR_ADD.All_ACCESS}
      showSearch={true}
      listHeight={160}
      onInputKeyDown={handleInputKeyDown}
      getPopupContainer={(trigger) => trigger.parentNode}
      suffixIcon={<DropDown />}
      placeholder={"Please choose language"}
      optionFilterProp={"label"}
      options={options}
      value={value}
      onChange={onChangeInternal}
    />
  );
}

export default LanguageSelect;
