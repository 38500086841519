import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button, Card, DatePicker, Form, Input, Row, Select, Space, Table, Tooltip } from "antd";
import { omit, sortBy } from "lodash";
import { TableProps } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { SorterResult, SortOrder } from "antd/es/table/interface";
import { DropDown, Info, Refresh } from "assets/icons";
import { message, Modal } from "components";
import { VoucherModel } from "types/model/voucher";
import { VoucherListParams } from "types/dto/request/voucher";
import DropdownInput from "components/DropdownInput";
import AddButton from "components/AddButton";
import { RootState, useAppDispatch } from "app/store";
import moment, { utc } from "moment";
import { VOUCHER_TYPE_MAP } from "constants/common";
import { getOrder } from "utils";
import { saveFile } from "utils/saveFile";
import { getTotalDisplay } from "utils/getTotalDisplay";
import useAppAuth0 from "hooks/useAppAuth0";
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import useColumns from "./useColumns";
import { exportFinancialReport, fetchOrganizations, fetchVouchers, voucherSelectors } from "../voucherSlice";
import styles from "./VoucherList.module.scss";

function VoucherList() {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    size: withDefault(NumberParam, 10),
    sortKey: withDefault(StringParam, "lastModifiedDate"),
    sortOrder: withDefault(StringParam, "descend"),
    voucherTypes: withDefault(ArrayParam, undefined),
    organizationIds: withDefault(NumberParam, undefined),
    invoice: withDefault(StringParam, undefined),
    isActive: withDefault(BooleanParam, undefined),
  });
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { user } = useAppAuth0();
  const datasource = useSelector(voucherSelectors.selectAll);
  const { totalElements, organizations } = useSelector((state: RootState) => state.voucher);
  const isLoading = useSelector((state: RootState) => state.loading);
  const [invoice, setInvoice] = useState(query.invoice);
  const columns = useColumns(query.sortKey, query.sortOrder as SortOrder, query.isActive);
  const [form] = Form.useForm();

  useEffect(() => {
    handleRefresh();
    setInvoice(query.invoice);
  }, [dispatch, query]);

  useEffect(() => {
    dispatch(fetchOrganizations()).then(unwrapResult).catch(message.error);
  }, [dispatch]);

  const handleRefresh = () => {
    dispatch(
      fetchVouchers({
        ...omit(query, "sortKey", "sortOrder"),
        page: query.page - 1,
        sort: `${query.sortKey},${getOrder(query.sortOrder as SortOrder)}`,
      })
    )
      .then(unwrapResult)
      .catch(message.error);
  };

  const handleSelectChange = (value: number) => {
    setQuery({
      ...query,
      page: 1,
      organizationIds: value,
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInvoice(e.target.value);
  };

  const handleInputPressEnter = () => {
    setQuery({ ...query, invoice: invoice?.trim(), page: 1 });
  };

  const handleOnRowClick: TableProps<VoucherModel>["onRow"] = (record) => ({
    onClick: () => history.push(`${path}/${record.voucherGroupId}`),
  });

  const handleTableChange: TableProps<VoucherModel>["onChange"] = (pagination, filters, sorter, extra) => {
    if (extra.action === "sort") {
      const { order, field } = sorter as SorterResult<VoucherModel>;
      setQuery({
        ...query,
        sortKey: field as string,
        sortOrder: order as string,
        page: 1,
      });
    } else if (extra.action === "filter") {
      let isActive: boolean | undefined = undefined;
      if (filters.expirationDate?.length === 1) {
        isActive = filters.expirationDate[0] as boolean;
      }
      setQuery({
        ...query,
        isActive,
        page: 1,
      });
    }
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setQuery({ ...query, page, size: pageSize });
  };

  const validDateRange = () => {
    const { startDate, endDate } = form.getFieldsValue();
    if (!startDate || !endDate) {
      return Promise.reject("'Duration to export' is required");
    } else {
      return Promise.resolve();
    }
  };

  const handleExport = () => {
    return Modal.confirm({
      title: "Export Redeemed Generic ISBNs",
      content: (
        <Form form={form} colon={false} labelAlign="left" validateTrigger="onBlur" className={styles.form}>
          <Form.Item
            required
            label={
              <>
                <span>Duration to export</span>
                <Tooltip placement="top" title="UTC Timezone">
                  <Info style={{ marginLeft: "4px" }} />
                </Tooltip>
              </>
            }
            name="period"
            rules={[
              {
                validator: validDateRange,
              },
            ]}
          >
            <Space size={0}>
              <Form.Item noStyle name="startDate" initialValue={moment().startOf("month").subtract(1, "month")}>
                <DatePicker
                  placeholder="Start date"
                  disabledDate={(date) =>
                    date < moment().startOf("month").subtract(3, "month") ||
                    date > moment() ||
                    (form.getFieldValue("endDate") &&
                      date.startOf("day") >= form.getFieldValue("endDate").startOf("day"))
                  }
                />
              </Form.Item>
              <span style={{ margin: "0 20px" }}>to</span>
              <Form.Item noStyle name="endDate" initialValue={moment().startOf("month")}>
                <DatePicker
                  placeholder="End date"
                  disabledDate={(date) =>
                    date > moment() ||
                    (form.getFieldValue("startDate") &&
                      date.startOf("day") <= form.getFieldValue("startDate").startOf("day"))
                  }
                />
              </Form.Item>
            </Space>
          </Form.Item>
        </Form>
      ),
      cancelText: "Cancel",
      okText: "Confirm",
      onOk: () =>
        form.validateFields().then(() => {
          const { startDate, endDate } = form.getFieldsValue();
          dispatch(
            exportFinancialReport({
              startDate: utc(startDate.format("yyyy-MM-DD")).valueOf(),
              endDate: utc(endDate.format("yyyy-MM-DD")).valueOf(),
            })
          )
            .then(unwrapResult)
            .then((content: any) =>
              saveFile(content, `monthly_report ${startDate.format("YYYY-MM-DD")}_${endDate.format("YYYY-MM-DD")}.xlsx`)
            )
            .catch(message.error);
        }),
      afterClose: form.resetFields,
    });
  };

  const cardTitleExtra = (
    <Space>
      <Button type="default" shape="round" onClick={handleExport}>
        Export
      </Button>
      {user.canCreateVoucherGroup && <AddButton text={"+ Add vouchers"} />}
    </Space>
  );

  return (
    <>
      <Card title="Voucher list" extra={cardTitleExtra} className="page-container">
        <Row justify={"space-between"} style={{ marginBottom: "24px" }}>
          <Space>
            <DropdownInput
              options={Array.from(VOUCHER_TYPE_MAP).map((voucherType) => ({
                label: voucherType[1],
                value: voucherType[0],
              }))}
              placeholder="All types"
              value={query.voucherTypes as string[]}
              onChange={(types) => {
                setQuery({
                  ...query,
                  page: 1,
                  voucherTypes: types as VoucherListParams["voucherTypes"],
                });
              }}
            />
            <Select
              allowClear
              showSearch={true}
              listHeight={160}
              getPopupContainer={(trigger) => trigger.parentNode}
              suffixIcon={<DropDown />}
              className={styles.organizationSelect}
              placeholder={"Please choose organization"}
              optionFilterProp={"label"}
              value={query.organizationIds}
              onChange={handleSelectChange}
              options={sortBy(
                organizations?.map((organization) => ({
                  label: organization.name,
                  value: organization.id,
                })),
                ["label"]
              )}
            />
            <Input
              allowClear
              value={invoice}
              prefix={<SearchOutlined />}
              className={styles.invoiceInput}
              onChange={handleInputChange}
              onBlur={() => setInvoice((prevState) => prevState?.trim())}
              onPressEnter={handleInputPressEnter}
              placeholder="Search invoice number"
            />
          </Space>
          <Space>
            <Button type="text" shape="circle" icon={<Refresh />} onClick={handleRefresh} />
          </Space>
        </Row>

        <Table
          loading={{ spinning: isLoading }}
          columns={columns}
          dataSource={datasource}
          className={styles.table}
          rowKey={(record) => record.voucherGroupId}
          onRow={handleOnRowClick}
          onChange={handleTableChange}
          pagination={{
            showTotal: getTotalDisplay,
            total: totalElements,
            showSizeChanger: true,
            current: query.page,
            pageSize: query.size,
            onChange: handlePageChange,
          }}
        />
      </Card>
    </>
  );
}

export default VoucherList;
