import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import moment from "moment";
import * as basePriceAPI from "services/basePrice";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { TableProps } from "antd/lib/table";
import { Card, Table } from "antd";
import { Plan, PlanListModel } from "../../../../types/model/price";
import { getTotalDisplay } from "../../../../utils/getTotalDisplay";
import { DATETIME_FORMAT } from "../../../../constants/common";
import CustomDot from "../../../../components/CustomDot";

export default function BasePriceDetailList() {
  const { basePriceId } = useParams<{ basePriceId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [basePriceDetailList, setBasePriceDetailList] = useState<PlanListModel>({} as PlanListModel);
  const history = useHistory();
  const [query, setQuery] = useQueryParams({
    name: withDefault(StringParam, ""),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
  });

  useEffect(() => {
    setIsLoading(true);
    basePriceAPI
      .fetchBasePriceDetail({
        id: basePriceId,
        limit: query.limit,
        offset: offset(),
      })
      .then((res) => {
        setBasePriceDetailList(res);
      })
      .finally(() => setIsLoading(false));
  }, [query]);

  const offset = () => {
    return (query.page - 1) * query.limit;
  };

  const tableColumns = [
    {
      title: "Language",
      key: "language",
      width: "15%",
      render: (record: Plan) => {
        return <span>{record.product.title}</span>;
      },
    },
    {
      title: "Type",
      key: "type",
      width: "10%",
      render: (record: Plan) => {
        return <span>{record.product.subtitle}</span>;
      },
    },
    {
      title: "Plan",
      key: "plan",
      dataIndex: "title",
    },
    {
      title: "Status",
      key: "status",
      width: "20%",
      dataIndex: "status",
      render: (value: string) => <CustomDot color={value} />,
    },
    {
      title: "Modified at",
      key: "updatedAt",
      dataIndex: "updatedAt",
      width: "20%",
      render: (val: string) => {
        return <span>{moment(val).format(DATETIME_FORMAT)}</span>;
      },
    },
  ];

  const handleOnRowClick: TableProps<Plan>["onRow"] = (record) => ({
    onClick: () => {
      history.push({
        pathname: `/products/ecom/${record.productId}`,
        state: { pid: record.pid },
      });
    },
  });

  return (
    <Card title={query.name} className="page-container">
      <h3>Applied Plan</h3>
      <Table
        loading={{ spinning: isLoading }}
        pagination={{
          showTotal: getTotalDisplay,
          pageSize: query.limit,
          defaultCurrent: 1,
          total: basePriceDetailList.total,
          current: query.page,
          onChange: (page, pageSize) => {
            setQuery({ ...query, page, limit: pageSize });
          },
        }}
        columns={tableColumns}
        dataSource={basePriceDetailList.list}
        onRow={handleOnRowClick}
      />
    </Card>
  );
}
