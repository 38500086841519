import React, { useState } from "react";
import { Dropdown, Menu } from "antd";
import Icon from "@ant-design/icons";
import * as Icons from "../../../../assets/icons";

interface props {
  onEdit: () => void;
  onDelete: () => void;
}

export default function ActionMenu({ onEdit, onDelete }: props) {
  const [menuVisible, setMenuVisible] = useState(false);

  function ActionsMenu() {
    return (
      <Menu
        onClick={() => setMenuVisible(false)}
        style={{
          fontSize: "15px",
          borderRadius: "4px",
          boxShadow: "3px 3px 12px 0px rgba(0, 35, 90, 0.16)",
        }}
      >
        <Menu.Item key="edit" onClick={onEdit}>
          Edit
        </Menu.Item>
        <Menu.Item key="delete" onClick={onDelete}>
          Delete
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Dropdown
      overlay={<ActionsMenu />}
      placement="bottomRight"
      trigger={["click"]}
      visible={menuVisible}
      onVisibleChange={(visible) => setMenuVisible(visible)}
    >
      <Icon component={Icons.More} style={{ fontSize: 24 }} />
    </Dropdown>
  );
}
