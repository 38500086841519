import React from "react";
import { oneDecimalPlaceValidator } from "../constants/common";

export const limitMaxTwoDecimalsInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const {
    key,
    currentTarget: { value },
  } = e;

  if (key === "-" || !value?.toString().match(oneDecimalPlaceValidator) || e.key.toLowerCase() === "e") {
    e.preventDefault();
  }
};
