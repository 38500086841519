import { message } from "components";
import axios, { AxiosRequestConfig } from "axios";
import { stringify } from "qs";
import { isEmpty, omitBy } from "lodash";
import { security } from "./security";

const paramSerializer = (params: any, arrayFormat: "indices" | "brackets" | "repeat" | "comma") => {
  const paramsTemp = omitBy(params, (value: any) => {
    if (typeof value === "string" || value instanceof Array) {
      return isEmpty(value);
    }
  });
  return stringify(paramsTemp, { arrayFormat });
};

const authorizationSetter = async (config: AxiosRequestConfig) => {
  const token = await security.getAccessTokenSilently()();
  config.headers = {
    Authorization: "Bearer " + token,
  };
  return config;
};

const getErrorMessage = (err: any) => {
  let _message;
  if (err.response != null) {
    const { status, data } = err.response;
    _message = data?.detail || data?.violations?.[0]?.message || data?.title;
    if (_message == null) {
      switch (status) {
        case 400:
          _message = "Bad Request";
          break;
        case 401:
          _message = "Sorry, you don't have authorization.";
          break;
        case 403:
          _message = "Sorry, you don't have access to this page.";
          break;
        case 404:
          _message = "Sorry, can’t find the specific resource.";
          break;
        case 405:
          _message = "Method is incorrect";
          break;
        case 500:
          _message = "Sorry, the server is reporting an error.";
          break;
        default:
          _message = "Sorry, there are some errors.";
      }
    }
  }
  return _message;
};

const errorHandler = (err: any) => Promise.reject(getErrorMessage(err));

const eComErrorHandler = (err: any) => {
  const defaultMessage = "There was an error occurred. Please try again later. If the problem persists, contact us.";
  const defaultStatus = 500;
  const { status = defaultStatus, data: { message: msg = defaultMessage } = {} } = err.response || {};
  const reason = { message: msg, status };
  return Promise.reject(reason).finally(() => {
    message.error(reason);
  });
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // query params issue: https://github.com/axios/axios/issues/1727
  paramsSerializer: (params) => paramSerializer(params, "comma"),
});

const eComInstance = axios.create({
  baseURL: process.env.REACT_APP_ECOM_API_BASE_URL,
  // baseURL: "/e_com_api",
  paramsSerializer: (params) => paramSerializer(params, "repeat"),
});

instance.interceptors.request.use(authorizationSetter);
eComInstance.interceptors.request.use(authorizationSetter);

instance.interceptors.response.use((response) => response.data, errorHandler);
eComInstance.interceptors.response.use((response) => response.data, eComErrorHandler);
export default instance;
export { eComInstance };
