import moment from "moment";
import { ColumnType } from "antd/lib/table";
import { ProductChangeLog } from "types/model/product";
import { DATETIME_FORMAT } from "constants/common";

function useColumns(isMiniCourse?: boolean): ColumnType<ProductChangeLog>[] {
  return [
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      ellipsis: true,
      render: (value) => value || "-",
    },
    {
      title: "Comments",
      dataIndex: "comments",
      width: isMiniCourse ? 150 : 195,
      ellipsis: true,
      render: (value) => value || "-",
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      width: isMiniCourse ? 140 : 100,
      ellipsis: true,
      render: (value) => value || "-",
    },
    {
      title: "Created at",
      dataIndex: "createdDate",
      width: isMiniCourse ? 120 : 80,
      ellipsis: true,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (value) => moment(value).format(DATETIME_FORMAT),
    },
  ];
}

export default useColumns;
