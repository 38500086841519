import { createAsyncThunk, createEntityAdapter, createSlice, unwrapResult } from "@reduxjs/toolkit";
import * as voucherAPI from "services/voucher";
import {
  GenericProductModel,
  IndividualProductModel,
  OrganizationModel,
  RedeemableProductModel,
  VoucherModel,
} from "types/model/voucher";
import { FinancialReportParams, VoucherGroupCreateRequest, VoucherListParams } from "types/dto/request/voucher";
import { RootState } from "../../app/store";
import ProductModel from "../../types/model/product";

export const fetchOrganizations = createAsyncThunk("voucher/organizations", async () => {
  const data = await voucherAPI.fetchOrganizations();
  return data.content;
});

export const fetchProducts = createAsyncThunk("voucher/products", async () => {
  const data = await voucherAPI.fetchProducts();
  return data.content;
});

export const fetchGenericProducts = createAsyncThunk("voucher/generic-products", async () => {
  return await voucherAPI.fetchGenericProducts();
});

export const fetchIndividualProducts = createAsyncThunk("voucher/individual-products", async () => {
  return await voucherAPI.fetchIndividualProducts();
});

export const fetchVoucherGroup = createAsyncThunk("voucher/vouchers", async (groupId: string) => {
  return await voucherAPI.fetchVoucherGroup(groupId);
});

export const fetchVouchers = createAsyncThunk("/voucher/fetchAll", async (params: VoucherListParams) => {
  return await voucherAPI.fetchAll(params);
});

export const createVoucher = createAsyncThunk("/voucher/addOne", voucherAPI.addOne);

export const updateVoucher = createAsyncThunk(
  "/voucher/update",
  async (payload: VoucherGroupCreateRequest & { groupId: string }, { dispatch }) => {
    const { groupId, ...data } = payload;
    await voucherAPI.update(groupId, data);
    unwrapResult(await dispatch(fetchVoucherGroup(groupId)));
  }
);

export const removeVoucher = createAsyncThunk("/voucher/delete", voucherAPI.remove);

export const exportFinancialReport = createAsyncThunk(
  "voucher/exportFinancialReport",
  (params: FinancialReportParams) => {
    return voucherAPI.exportFinancialReport(params);
  }
);

const voucherAdapter = createEntityAdapter<VoucherModel>({
  selectId: (record) => record.voucherGroupId,
});

const voucherSlice = createSlice({
  name: "voucher",
  initialState: voucherAdapter.getInitialState({
    totalElements: 0,
    organizations: [] as OrganizationModel[],
    products: [] as ProductModel[],
    genericProducts: [] as GenericProductModel[],
    redeemableProducts: [] as RedeemableProductModel[],
    individualProducts: [] as IndividualProductModel[],
  }),
  reducers: {
    voucherUpdate: voucherAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizations.fulfilled, (state, { payload }) => {
      state.organizations = payload;
    });
    builder.addCase(fetchProducts.fulfilled, (state, { payload }) => {
      state.products = payload;
    });
    builder.addCase(fetchGenericProducts.fulfilled, (state, { payload }) => {
      state.genericProducts = payload;
    });
    builder.addCase(fetchIndividualProducts.fulfilled, (state, { payload }) => {
      state.individualProducts = payload;
    });
    builder.addCase(fetchVouchers.fulfilled, (state, { payload }) => {
      const { content, totalElements } = payload;
      voucherAdapter.setAll(state, content);
      state.totalElements = totalElements;
    });
    builder.addCase(fetchVoucherGroup.fulfilled, (state, { payload }) => {
      if (payload.languageProductGroups?.length === 0) {
        payload.languageProductGroups = undefined;
      }
      voucherAdapter.upsertOne(state, payload);
    });
  },
});

export const { voucherUpdate } = voucherSlice.actions;

export default voucherSlice.reducer;

export const voucherSelectors = voucherAdapter.getSelectors<RootState>((state) => state.voucher);
