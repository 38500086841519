import React, { HTMLAttributes, SyntheticEvent, useEffect, useState } from "react";
import { Card, Table, TableProps } from "antd";
import { ColumnsType, ColumnType } from "antd/es/table";
import { SorterResult } from "antd/es/table/interface";
import AddButton from "components/AddButton";
import moment from "moment";
import { ResizeCallbackData } from "react-resizable";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import omit from "lodash/omit";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getTotalDisplay } from "../../../../utils/getTotalDisplay";
import ResizableTitle from "../../../../components/ResizableTitle";
import { DATETIME_FORMAT, EMPTY } from "../../../../constants/common";
import { DescriptionTemplateListModel, DescriptionTemplateModel } from "../../../../types/model/description";
import * as descriptionTemplateAPI from "../../../../services/description";
import { getOffset } from "../../../../utils/getPagination";
import { updateSortOrder } from "../../../../types/transformers/updateSortOrder";
import { getText } from "../../../../utils/richTextEditor";
import useAppAuth0 from "../../../../hooks/useAppAuth0";

const columns: ColumnsType<DescriptionTemplateModel> = [
  {
    title: "Description template name",
    key: "name",
    dataIndex: "name",
    width: 320,
    ellipsis: true,
    render: (value: string) => value || EMPTY,
  },
  {
    title: "Description template content",
    key: "content",
    dataIndex: "content",
    ellipsis: true,
    render: (value: string) => getText(value) || EMPTY,
  },
  {
    title: "Modified at",
    key: "updatedAt",
    dataIndex: "updatedAt",
    width: 240,
    ellipsis: true,
    defaultSortOrder: "descend",
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true,
    render: (value: string) => moment(value).format(DATETIME_FORMAT),
  },
];

function DescriptionList() {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
    order: withDefault(StringParam, "-updated_at"),
  });
  const { user } = useAppAuth0();
  const [tableColumns, setTableColumns] = useState<ColumnsType<DescriptionTemplateModel>>(columns);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<DescriptionTemplateListModel>();
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleResize = (index: number) => (_: SyntheticEvent, { size }: ResizeCallbackData) => {
    const nextColumns = [...tableColumns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    setTableColumns(nextColumns);
  };

  const columnsToDisplay = tableColumns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: ColumnType<DescriptionTemplateModel>) =>
      ({
        width: column.width,
        onResize: handleResize(index),
      } as HTMLAttributes<HTMLElement>),
  }));

  const handleOnRowClick: TableProps<DescriptionTemplateModel>["onRow"] = (record) => ({
    onClick: () =>
      history.push({
        pathname: `${path}/${record.id}`,
      }),
  });

  const handleTableChange: TableProps<DescriptionTemplateModel>["onChange"] = ({ pageSize }, _, sorter, { action }) => {
    const { order } = sorter as SorterResult<DescriptionTemplateModel>;
    if (action === "sort") {
      setQuery({
        ...query,
        order: updateSortOrder[order as string],
        page: 1,
        limit: pageSize,
      });
    }
  };

  useEffect(() => {
    let ignore = false;

    setLoading(true);
    descriptionTemplateAPI
      .fetchDescriptionTemplateList({
        ...omit(query, ["page"]),
        offset: getOffset(query.page, query.limit),
      })
      .then((data) => {
        if (!ignore) {
          setData(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      ignore = true;
    };
  }, [query]);

  return (
    <Card
      title="Description template List"
      extra={user.canCreateDescription && <AddButton text={"+ Add new template"} />}
      className="page-container"
    >
      <Table<DescriptionTemplateModel>
        loading={{
          spinning: isLoading,
        }}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        rowKey={(record) => record.id}
        columns={columnsToDisplay}
        dataSource={data?.list}
        onChange={handleTableChange}
        pagination={{
          showTotal: getTotalDisplay,
          current: query.page,
          pageSize: query.limit,
          total: data?.total,
          onChange: (page, pageSize) =>
            setQuery({
              ...query,
              page,
              limit: pageSize,
            }),
        }}
        onRow={handleOnRowClick}
      />
    </Card>
  );
}

export default DescriptionList;
