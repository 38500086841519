import React from "react";
import { Button } from "antd";
import { MiniCourseStatus } from "../../types/model/miniCourse";
import styles from "./MiniCourseVerification.module.scss";
import { useVerifyMiniCourse } from "./useVerifyMiniCourse";
import { usePublishMiniCourse } from "./usePublishMiniCourse";
import useMiniCourseViewLog from "./useMiniCourseViewLog";
import useAppAuth0 from "../../hooks/useAppAuth0";

interface MiniCourseActionsProps {
  status: MiniCourseStatus;
  id: number;
  scheduledPublishTime?: number;
  languageName: string | null;
}

function MiniCourseActionsView({ status, id, scheduledPublishTime, languageName }: MiniCourseActionsProps) {
  const { handleVerify } = useVerifyMiniCourse(id);
  const { handlePublish } = usePublishMiniCourse();
  const [handleViewLog] = useMiniCourseViewLog(id, languageName);
  const { user } = useAppAuth0();

  return (
    <>
      {user.canVerifyMiniCourse && (
        <Button type="link" disabled={status !== "STAGING"} className={styles.button} onClick={handleVerify}>
          Verify
        </Button>
      )}
      {user.canPublishMiniCourse && (
        <Button
          type="link"
          disabled={status !== "READY_FOR_REVIEW"}
          className={styles.button}
          onClick={() => {
            handlePublish(id, scheduledPublishTime);
          }}
        >
          Publish
        </Button>
      )}
      {user.canReadMiniCourse && (
        <Button type="link" className={styles.button} onClick={handleViewLog}>
          View Log
        </Button>
      )}
    </>
  );
}

export default MiniCourseActionsView;
