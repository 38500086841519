import { ProductStatus } from "./product";

export const EComProductStatus = {
  Draft: "Draft",
  Inactive: "Inactive",
  ReadyForPublish: "Ready for publish",
  Active: "Active",
} as const;

export const EComProductStatusUrl = {
  [EComProductStatus.Draft]: "draft",
  [EComProductStatus.ReadyForPublish]: "ready-for-publish",
  [EComProductStatus.Active]: "active",
  [EComProductStatus.Inactive]: "inactive",
};

export type EComProductStatusType = typeof EComProductStatus[keyof typeof EComProductStatus];

export interface EComProductModel {
  id: string;
  pid: string;
  language: string;
  courseType: string;
  planName: string;
  status: EComProductStatusType;
  basePriceName: string;
  updatedAt: string;
  productId: string;
  basePricesId?: string;
  contentProductType?: string;
}

export interface EComProductListModel {
  list: EComProductModel[];
  total: number;
}

export enum CourseType {
  STANDARD = "Standard",
  PREMIUM = "Premium",
}

export const ALL_ACCESS = "All Access";

export interface EComProductLanguage {
  id: string;
  languageId: number;
  name: string;
  courseType: CourseType;
}

export enum PRODUCT_TYPE {
  SINGLE_LEVEL = "Single level",
  SUBSCRIPTION = "Subscription",
  ALL_ACCESS = "All Access",
  MULTI_LEVEL = "Multi-level",
  UPGRADE = "Upgrade",
}

export interface EComProductInfo {
  languageId: number;
  languageName: string;
  isPremium: boolean;
  isAllAccess: boolean;
  productId?: string;
}

export interface ProductPlan {
  name: string;
  basePriceId: string;
  contentProductType: string;
  billingPlanId: string;
  freeTrialType?: FreeTrialType;
  freeTrialUsageLimit?: number;
  joinUpsell: boolean;
  descTemplateId: string;
  productImage: string;
  isbn: string;
  contentProductId: number;
  courseLevels: number[];
}

export interface NewPlanFormFieldValues {
  name: string;
  pid: string;
  contentProductId: number;
  isbn: string;
  contentProductType: string;
  contentProductStatus: ProductStatus;
  checkoutDeeplink: string;
  version: string;
  courseLevel: number;
  selectedCourse: SelectedCourse;
  basePriceName: string;
  basePriceHelpText: string;
  basePriceId: string;
  freeTrialType?: FreeTrialType;
  freeTrialUsageLimit?: number;
  joinUpsell: boolean;
  billingPlanId: string;
  billingPlanName: string;
  billingCycle: string;
  billingQuantity: number;
  descTemplateId: string;
  descTemplateName: string;
  descTemplateContent: string;
  productImage: string;
  bundledProductIds: string[];
  courseLevels: number[];
}

interface SelectedCourse {
  lessonStart: number;
  lessonEnd: number;
}

export enum FreeTrialType {
  INTRODUCTORY = "Introductory",
  PROMOTIONAL = "Promotional",
}

export interface ProductPlanDetails {
  pid: string;
  name: string;
  contentProductType: string;
  billingPlanId: string;
  billingPlanName: string;
  billingCycle: string;
  billingQuantity: number;
  freeTrialType?: FreeTrialType;
  freeTrialUsageLimit?: number;
  joinUpsell: boolean;
  descTemplateId: string;
  descTemplateName: string;
  descTemplateContent: string;
  productImage: string;
  isbn: string;
  contentProductId: number;
  basePriceId: string;
  basePriceName: string;
  basePriceHelpText: string;
  planId?: string;
  status?: EComProductStatusType;
  checkoutDeeplink?: string;
  promotionCode?: string;
}

export interface BillingPlan {
  id: string;
  has_trial?: boolean;
  cycle: string;
  quantity: number;
  name: string;
}

export interface CountryInfo {
  id: number;
  displayName: string;
  iso2: string;
}

export interface VariantsImage {
  name: string;
  url: string;
}

export enum ECOM_PRODUCT_TAB {
  PRODUCT_INFO = "Product info",
  PRODUCT_LOG = "Product log",
}

export interface ProductLogModel {
  pid: string;
  action: string;
  prevValue: any;
  currentValue: any;
  updatedBy: string;
  updatedAt: string;
}

export interface EComProductLogListModel {
  list: ProductLogModel[];
  total: number;
}
