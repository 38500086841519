import { TransactionModel, TransactionResponse } from "../model/transactions";

export function transformTransactions(transactionsResponse: TransactionResponse[]): TransactionModel[] {
  return transactionsResponse.map((response) => ({
    id: response.id,
    source: response.source,
    status: response.status,
    productPlan: {
      name: response.product_info.name,
      pid: response.product_info.pid,
    },
    billingPlan: {
      type: response.product_info.type,
    },
    createdTime: response.created_time,
    currency: response.currency,
    price: {
      accountAmount: response.price_info.account_amount.toFixed(2),
      amount: response.price_info.amount.toFixed(2),
      tax: response.price_info.tax.toFixed(2),
      originalPrice: response.price_info.original_price.toFixed(2),
      taxType: response.price_info.tax_type,
      total: response.price_info.total.toFixed(2),
      credit: response.price_info.credit.toFixed(2),
      discount: response.price_info.discount.toFixed(2),
    },
    refundPrice: response.refund_info
      ? {
          accountAmount: response.refund_info.account_amount.toFixed(2),
          amount: response.refund_info.amount.toFixed(2),
          total: response.refund_info.total.toFixed(2),
          created: response.refund_info.created,
        }
      : undefined,
    subscription: response.subscription_info
      ? {
          id: response.subscription_info.id,
          startTime: response.subscription_info.start_time,
          endTime: response.subscription_info.end_time,
        }
      : undefined,
    paymentInformation: {
      id: response.payment_method.id,
      country: response.payment_method.country || "",
      district: response.payment_method.district || "",
      postalCode: response.payment_method.postal_code || "",
      type: response.payment_method.type,
      account: response.payment_method.account,
      cardType: response.payment_method.card_type || "",
      givenName: response.payment_method.given_name || "",
      familyName: response.payment_method.family_name || "",
    },
  }));
}
