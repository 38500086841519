import React, { useEffect, useState } from "react";
import moment from "moment";
import { ConfigProvider, Form, Select, Spin } from "antd";
import { DEFAULT_MAX_PAGINATION, validateMessages } from "../../../constants/common";
import { DropDown } from "../../../assets/icons";
import * as productAPI from "../../../services/product";
import ProductModel from "../../../types/model/product";
import EndDateInput from "../../voucher/VoucherForm/EndDateInput";

export default function EntitlementForm({ form, defaultValue }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [courseOptions, setCourseOptions] = useState<ProductModel[]>([]);
  const { isbn, expirationDate, productName } = defaultValue ?? {};

  useEffect(() => {
    if (isbn) {
      return setCourseOptions([{ isbn, productName }] as ProductModel[]);
    }
    setIsLoading(true);
    productAPI
      .fetchAll({
        isPublished: true,
        ...DEFAULT_MAX_PAGINATION,
      })
      .then((res) => {
        setCourseOptions(res.content);
        setIsLoading(false);
      })
      .catch((e) => {
        console.warn(e);
      });
  }, [isbn]);

  return (
    <Spin spinning={isLoading} size={"large"}>
      <ConfigProvider form={{ validateMessages }}>
        <Form form={form} layout="vertical">
          <Form.Item initialValue={isbn} label="Course" name="isbn" rules={[{ required: true }]}>
            <Select
              optionFilterProp={"label"}
              allowClear
              showSearch
              suffixIcon={<DropDown />}
              placeholder="Please choose Content ISBN"
              options={courseOptions.map((option) => ({
                label: `${option.isbn} ${option.productName}`,
                value: option.isbn,
              }))}
              disabled={!!isbn}
            />
          </Form.Item>
          <Form.Item
            label="Expiration date"
            name="expirationDate"
            rules={[{ required: true }]}
            initialValue={expirationDate ? moment(expirationDate).endOf("days") : undefined}
          >
            <EndDateInput defaultPickerValue={moment(expirationDate).endOf("days")} />
          </Form.Item>
        </Form>
      </ConfigProvider>
    </Spin>
  );
}
