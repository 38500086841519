import React, { ChangeEvent } from "react";
import { Input } from "antd";
import { InputProps } from "antd/lib/input";

interface InputNumberProps extends Omit<InputProps, "value" | "onChange"> {
  value?: number;
  onChange?: (value: number | undefined) => void;
}

function InputNumber(props: InputNumberProps) {
  const { value, onChange } = props;

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value === "" ? undefined : parseFloat(e.target.value);
    onChange && onChange(currentValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "e") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Input {...props} value={value} onKeyDown={handleKeyDown} onChange={handleValueChange} type={"number"} />
    </>
  );
}

export default InputNumber;
