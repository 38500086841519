import { GiftResponse } from "../dto/response/customer";
import { GiftModal } from "../model/customer";

export function transformGift({ given_list, received_list }: GiftResponse): GiftModal {
  return {
    givenList: given_list.map((given) => ({
      id: given.id,
      title: given.title,
      deliveryDate: given.delivery_date,
      recipient: {
        firstName: given.recipient.first_name,
        lastName: given.recipient.last_name,
        email: given.recipient.email,
      },
      status: given.status,
      redeemedEmail: given.redeemed_email,
    })),
    receivedList: received_list.map((received) => ({
      title: received.title,
      redeemDate: received.redeem_date,
      subscriptionStartDate: received.subscription_start_date,
      subscriptionEndDate: received.subscription_end_date,
      giver: {
        firstName: received.giver.first_name,
        lastName: received.giver.last_name,
        email: received.giver.email,
      },
      status: received.status,
      redeemedEmail: received.redeemed_email,
    })),
  };
}
