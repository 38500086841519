import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

function StartDateInput({ value, endFieldName, form, ...props }: any) {
  return (
    <DatePicker
      {...props}
      placeholder="Start date"
      value={value ? moment(value) : undefined}
      disabledDate={(date) =>
        date.startOf("day") > moment().startOf("day") ||
        (form.getFieldValue(endFieldName) && date > form.getFieldValue(endFieldName))
      }
    />
  );
}

export default StartDateInput;
