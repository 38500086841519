import React, { useEffect, useState } from "react";
import { ConfigProvider, Form, FormInstance, Radio } from "antd";
import styles from "./index.module.scss";
import { ALL_ACCESS, CourseType, EComProductInfo, EComProductLanguage } from "../../../../../types/model/eComProduct";
import LanguageModel from "../../../../../types/model/language";
import { validateMessages } from "../../../../../constants/common";
import EComLanguageSelect from "./EComLanguageSelect";

export interface DisplayLanguage {
  languageId: number;
  name: string;
  usedCourseTypes: CourseType[];
  disabled?: boolean;
}

export interface ProductInfoProps {
  form: FormInstance;
  allLanguages: LanguageModel[];
  eComLanguages: EComProductLanguage[];
  onChange: (productInfo: EComProductInfo) => void;
  shouldDisabled: boolean;
}

function ProductInfo({ form, allLanguages, eComLanguages, onChange, shouldDisabled }: ProductInfoProps) {
  const [languages, setLanguages] = useState<DisplayLanguage[]>([]);
  const [productInfo, setProductInfo] = useState<EComProductInfo>({} as EComProductInfo);
  const [disabledCourseType, setDisabledCourseType] = useState(false);

  useEffect(() => {
    setLanguages(generateDisplayLanguages(allLanguages, eComLanguages));
  }, [allLanguages, eComLanguages]);

  const generateDisplayLanguages = (
    allLanguages: LanguageModel[],
    eComLanguages: EComProductLanguage[]
  ): DisplayLanguage[] => {
    const eComLanguagesMap: Record<number, DisplayLanguage> = eComLanguages.reduce(
      (acc, { languageId, name, courseType }) => ({
        ...acc,
        [languageId]: {
          languageId,
          name,
          usedCourseTypes: acc[languageId] ? [...acc[languageId].usedCourseTypes, courseType] : [courseType],
        } as DisplayLanguage,
      }),
      {} as Record<number, DisplayLanguage>
    );

    return allLanguages.map(({ id, name }) => ({
      languageId: id,
      name,
      usedCourseTypes: eComLanguagesMap[id]?.usedCourseTypes || [],
      disabled: eComLanguagesMap[id]?.name === ALL_ACCESS || eComLanguagesMap[id]?.usedCourseTypes.length === 2,
    }));
  };

  const handleLanguageChange = () => {
    setDisabledCourseType(false);
    form.setFieldsValue({ isPremium: false });

    const { languageId } = form.getFieldsValue();
    if (!languageId) {
      setProductInfo({} as EComProductInfo);
      onChange({} as EComProductInfo);
      return;
    }

    const currentLanguage = languages.find((lang) => lang.languageId === languageId)!;
    const { name, usedCourseTypes } = currentLanguage;

    if (usedCourseTypes.length === 1) {
      const disabledCourseType = usedCourseTypes[0];
      setDisabledCourseType(true);
      form.setFieldsValue({
        isPremium: disabledCourseType === CourseType.STANDARD,
      });
    }

    const currentProductInfo = {
      languageId,
      languageName: name,
      isPremium: form.getFieldValue("isPremium"),
      isAllAccess: name === ALL_ACCESS,
    };
    setProductInfo(currentProductInfo);
    onChange(currentProductInfo);
  };

  const handleCourseTypeChange = () => {
    const { isPremium } = form.getFieldsValue();
    const currentProductInfo = { ...productInfo, isPremium };
    setProductInfo(currentProductInfo);
    onChange(currentProductInfo);
  };

  return (
    <ConfigProvider form={{ validateMessages }}>
      <Form form={form} validateTrigger={"onBlur"} initialValues={{ isPremium: false }} layout="vertical">
        <p className={styles.secondTitle}>Product Info</p>
        <section className={styles.productInfoForm}>
          <Form.Item
            label="Language"
            name="languageId"
            rules={[{ required: true }]}
            className={styles.productInfoFormItem}
          >
            <EComLanguageSelect disabled={shouldDisabled} languages={languages} onChange={handleLanguageChange} />
          </Form.Item>
          {!productInfo.isAllAccess && (
            <Form.Item
              label="Course type"
              name="isPremium"
              rules={[{ required: true }]}
              className={styles.productInfoFormItem}
            >
              <Radio.Group onChange={handleCourseTypeChange} disabled={disabledCourseType || shouldDisabled}>
                <Radio value={false}>Standard</Radio>
                <Radio value={true}>Premium</Radio>
              </Radio.Group>
            </Form.Item>
          )}
        </section>
      </Form>
    </ConfigProvider>
  );
}

export default ProductInfo;
