import React, { useState } from "react";
import { Layout, Menu, Tooltip } from "antd";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { flatten } from "lodash";
import { useLocation } from "react-router-dom";
import { MenuItem } from "types/menu";
import useMenu from "hooks/useMenu";

import styles from "./Sider.module.scss";

function Sider() {
  const history = useHistory();
  const { pathname } = useLocation();
  const { visibleMenuItems, defaultSelectedKeys, defaultOpenKeys } = useMenu();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleSiderCollapse = (collapsed: boolean) => {
    setIsCollapsed(collapsed);
  };

  return visibleMenuItems && visibleMenuItems.length > 0 ? (
    <Layout.Sider
      collapsible
      trigger={
        isCollapsed ? (
          <Tooltip placement="top" title="Expand">
            <RightCircleOutlined />
          </Tooltip>
        ) : (
          <Tooltip placement="top" title="Collapse">
            <LeftCircleOutlined />
          </Tooltip>
        )
      }
      collapsed={isCollapsed}
      onCollapse={handleSiderCollapse}
      theme="light"
      className={styles.navbar}
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={defaultSelectedKeys}
        defaultOpenKeys={defaultOpenKeys}
        selectedKeys={flatten(
          visibleMenuItems.map((config) =>
            config.children.length > 0 ? config.children.map((child) => child.path) : config.path
          )
        ).filter((path) => pathname.includes(path))}
        onClick={(e) => history.push(e.key)}
      >
        {visibleMenuItems.map(renderNavItem)}
      </Menu>
    </Layout.Sider>
  ) : null;
}

const renderNavItem = (config: MenuItem) => {
  const { title, icon, children, path } = config;

  if (children.length === 0) {
    return (
      <Menu.Item key={path} icon={icon} style={{ whiteSpace: "normal" }}>
        {title}
      </Menu.Item>
    );
  }

  return (
    <Menu.SubMenu title={title} key={title} icon={icon}>
      {children.map(renderNavItem)}
    </Menu.SubMenu>
  );
};

export default Sider;
