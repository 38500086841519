import React from "react";
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";
import CustomerList from "./CustomerList";
import CustomerDetail from "./CustomerDetail";

function Customer() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <Redirect to={`${path}/b2c`} />
      </Route>
      <Route exact path={`${path}/b2c`}>
        <CustomerList key={`${path}/b2c`} tab="B2C" />
      </Route>
      <Route exact path={`${path}/b2c/:customerId`}>
        <CustomerDetail tab="B2C" />
      </Route>
      <Route exact path={`${path}/b2b`}>
        <CustomerList key={`${path}/b2b`} tab="B2B" />
      </Route>
      <Route exact path={`${path}/b2b/:customerId`}>
        <CustomerDetail tab="B2B" />
      </Route>
    </Switch>
  );
}

export default Customer;
