import React, { useEffect, useState } from "react";
import { Form, FormInstance, Image, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { DropDown } from "../../../../../../../assets/icons";
import * as eComProductAPI from "../../../../../../../services/eComProduct";
import { VariantsImage } from "../../../../../../../types/model/eComProduct";
import styles from "../../index.module.scss";

interface PlanImageProps {
  form: FormInstance;
  setIsPlanImageLoading: (loading: boolean) => void;
  onChange: (url: string) => void;
}

export default function PlanImage({ form, setIsPlanImageLoading, onChange }: PlanImageProps) {
  const [images, setImages] = useState<VariantsImage[]>([]);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);

  useEffect(() => {
    setIsPlanImageLoading(true);
    eComProductAPI
      .fetchVariantsImages()
      .then((res) => setImages(res))
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsPlanImageLoading(false));
  }, []);

  useEffect(() => {
    setSelectedImageUrl(form.getFieldValue("productImage"));
  }, [form.getFieldValue("productImage")]);

  const handleInputKeyDown: SelectProps<any>["onInputKeyDown"] = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleImageChange = (url: string) => {
    setSelectedImageUrl(url);
    onChange(url);
  };

  return (
    <section className={styles.planImage}>
      <Form.Item label="Plan image" name="productImage" rules={[]}>
        <Select
          allowClear
          showSearch={true}
          listHeight={160}
          onInputKeyDown={handleInputKeyDown}
          onChange={handleImageChange}
          getPopupContainer={(trigger) => trigger.parentNode}
          suffixIcon={<DropDown />}
          placeholder={"Please choose image"}
          optionFilterProp={"label"}
          options={images.map(({ name, url }) => ({
            label: name,
            value: url,
          }))}
          className={styles.formItem}
        />
      </Form.Item>
      {selectedImageUrl && <Image src={selectedImageUrl} alt="Plan image" />}
    </section>
  );
}
