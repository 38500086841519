import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { DATETIME_FORMAT, EMPTY, IAP_PURCHASE_STATUS_MAP } from "constants/common";
import { InAppPurchaseRecordModel } from "types/model/customer";

const columnConfig: ColumnType<InAppPurchaseRecordModel>[] = [
  {
    title: "Product ISBN",
    dataIndex: "isbn",
    width: 150,
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Product name",
    dataIndex: "productName",
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Purchase type",
    dataIndex: "isSubscription",
    width: 130,
    ellipsis: true,
    render: (value: boolean) => (value ? "Subscription" : "One-shot"),
  },
  {
    title: "Purchase status",
    dataIndex: "status",
    width: 140,
    ellipsis: true,
    render: (value) => IAP_PURCHASE_STATUS_MAP.get(value) || EMPTY,
  },
  {
    title: "Source",
    dataIndex: "source",
    width: 140,
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Expiration date",
    dataIndex: "expirationDate",
    ellipsis: true,
    width: 180,
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true,
    render: (value) => (value ? moment(value).format(DATETIME_FORMAT) : EMPTY),
  },
  {
    title: "Created at",
    dataIndex: "createdDate",
    ellipsis: true,
    width: 180,
    defaultSortOrder: "descend",
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true,
    render: (value) => (value ? moment(value).format(DATETIME_FORMAT) : EMPTY),
  },
];

export default columnConfig;
