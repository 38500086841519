import React from "react";
import moment from "moment";
import { capitalize } from "lodash";
import { EMPTY } from "constants/common";
import { ColumnType } from "antd/lib/table";
import { LearningProgressModel } from "types/model/customer";
import CourseCompleted from "components/CourseCompleted";

const columnConfig: ColumnType<LearningProgressModel>[] = [
  {
    title: "Subuser",
    dataIndex: "subUserName",
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Course",
    dataIndex: "courseName",
    ellipsis: true,
    defaultSortOrder: "ascend",
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Lesson No.",
    dataIndex: "lessonNumber",
    ellipsis: true,
    defaultSortOrder: "ascend",
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true,
    width: "12%",
    render: (value) => value ?? EMPTY,
  },
  {
    title: "First activity date",
    dataIndex: "firstActivityDate",
    ellipsis: true,
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true,
    width: "16%",
    render: (value) => (value ? moment(value).format("YYYY-MM-DD") : EMPTY),
  },
  {
    title: "Last activity date",
    dataIndex: "lastActivityDate",
    ellipsis: true,
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true,
    width: "16%",
    render: (value) => (value ? moment(value).format("YYYY-MM-DD") : EMPTY),
  },
  {
    title: "Completed",
    dataIndex: "isCompleted",
    ellipsis: true,
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true,
    width: "12%",
    render: (value) => <CourseCompleted completed={value} />,
  },
  {
    title: "Listen time(Mins)",
    dataIndex: "listenTimeMinutes",
    ellipsis: true,
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true,
    width: "16%",
    render: (value) => value ?? EMPTY,
  },
  {
    title: "Platform",
    dataIndex: "sourcePlatform",
    ellipsis: true,
    render: (value) => capitalize(value ?? EMPTY),
  },
];

export default columnConfig;
