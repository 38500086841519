import { eComInstance } from "../utils/instance";
import { PromotionDetailResponse, PromotionListResponse } from "../types/dto/response/promotion";
import {
  transformPromotionDetail,
  transformPromotionFormDataRequest,
  transformPromotionList,
} from "../types/transformers/promotion";
import { PromotionListRequest } from "../types/dto/request/promotion";
import { PromotionDetailModel, PromotionFormData } from "../types/model/promotion";

export async function fetchPromotionList(params: PromotionListRequest) {
  const url = "/promotions";
  return eComInstance
    .get<PromotionListResponse>(url, { params })
    .then((res) => transformPromotionList(res));
}

export async function fetchPromotion(id: string): Promise<PromotionDetailModel> {
  const url = `/promotions/${id}`;
  return eComInstance.get<PromotionDetailResponse>(url).then((res) => transformPromotionDetail(res));
}

export async function createPromotion(promotion: PromotionFormData) {
  const url = "/promotions";
  return eComInstance.post(url, transformPromotionFormDataRequest(promotion)).then((res) => res);
}

export async function updatePromotion(id: string, promotion: PromotionFormData) {
  const url = `/promotions/${id}`;
  return eComInstance.put(url, transformPromotionFormDataRequest(promotion)).then((res) => res);
}

export async function deletePromotion(id: string) {
  const url = `/promotions/${id}`;
  return eComInstance.delete(url).then((res) => res);
}

export async function fetchPromotionsByBasePriceIds(basePriceIds: string[]): Promise<PromotionDetailModel[]> {
  const url = "/promotions/base-prices";
  return eComInstance
    .get<any>(url, {
      params: { base_price_ids: basePriceIds },
    })
    .then((res) => res.promotions.map((promotion: PromotionDetailResponse) => transformPromotionDetail(promotion)));
}
