import React from "react";
import { omit } from "lodash";
import { message as antMessage } from "antd";
import Icon from "@ant-design/icons";
import { Fail, Success, Message, Warning } from "../../assets/icons";

const message = omit(antMessage, "error") as typeof antMessage;
message.config({ top: 50 });

message.error = (error: Error) =>
  antMessage.error({
    content: <>{error.message}</>,
    icon: <Icon component={Fail} />,
  });

message.success = (content: string) =>
  antMessage.success({
    content,
    icon: <Icon component={Success} />,
  });

message.warning = (content: string) =>
  antMessage.warning({
    content,
    icon: <Icon component={Warning} />,
  });

message.info = (content: string) =>
  antMessage.info({
    content,
    icon: <Icon component={Message} />,
  });

export default message;
