import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Input, Popover, Space, Radio } from "antd";
import Icon from "@ant-design/icons";
import { FormProps } from "antd/lib/form";
import { DropdownInput } from "components";
import StartDateInput from "components/StartDateInput";
import EndDateInput from "components/EndDateInput";
import ProductListRequest from "types/dto/request/ProductListRequest";
import { DropDown } from "assets/icons";
import { ENTITLEMENT_SOURCE_MAP, ENTITLEMENT_STATUS_MAP } from "constants/common";
import { EntitlementListParams } from "types/dto/request/entitlement";
import LanguageSelect from "../../products/ContentProduct/LanguageSelect";

import styles from "./EntitlementAdvancedSearch.module.scss";

const isSubscriptionOptions = [
  { label: "Subscription", value: true },
  { label: "One-shot", value: false },
];

export interface EntitlementAdvancedSearchProps {
  initialValues: EntitlementListParams;
  onSubmit: (values: EntitlementListParams) => void;
}

function EntitlementAdvancedSearch({ initialValues, onSubmit }: EntitlementAdvancedSearchProps) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const handleSearch: FormProps<ProductListRequest>["onFinish"] = (values) => {
    onSubmit(values);
    setVisible(!visible);
  };

  useEffect(() => form.resetFields(), [form, initialValues]);

  /** toggle tooltip and reset form value to initial search condition. */
  function toggleAdvanceSearchButton() {
    setVisible((prevState) => {
      form.resetFields();
      return !prevState;
    });
  }

  const handleBlur = () => {
    const isbn = form.getFieldValue("isbn");
    const email = form.getFieldValue("email");
    form.setFieldsValue({
      ...form?.getFieldsValue(),
      isbn: isbn && isbn.trim(),
      email: email && email.trim(),
    });
  };
  const content = (
    <Form
      form={form}
      layout="inline"
      initialValues={initialValues}
      className={styles.searchForm}
      onFinish={handleSearch}
    >
      <Space direction="vertical" align="end" size={24}>
        <Form.Item label="Created at">
          <Form.Item noStyle name="createdDateFrom">
            <StartDateInput form={form} endFieldName="createdDateTo" />
          </Form.Item>
          <span style={{ margin: "0 20px" }}>to</span>
          <Form.Item noStyle name="createdDateTo">
            <EndDateInput form={form} startFieldName="createdDateFrom" />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Language" name="languageId">
          <LanguageSelect />
        </Form.Item>
        <Form.Item label="ISBN" name="isbn">
          <Input onBlur={handleBlur} />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input onBlur={handleBlur} />
        </Form.Item>
      </Space>

      <Space direction="vertical" align="end" size={24}>
        <Form.Item label="Status" name="statuses">
          <DropdownInput
            width={408}
            options={Array.from(ENTITLEMENT_STATUS_MAP).map((entitlementStatus) => ({
              label: entitlementStatus[1],
              value: entitlementStatus[0],
            }))}
            placeholder="All statuses"
          />
        </Form.Item>
        <Form.Item
          label="Purchase type"
          name="isSubscription"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Radio.Group options={isSubscriptionOptions} />
        </Form.Item>
        <Form.Item label="Source" name="sources">
          <DropdownInput
            width={408}
            options={Array.from(ENTITLEMENT_SOURCE_MAP).map((entitlementSource) => ({
              label: entitlementSource[1],
              value: entitlementSource[0],
            }))}
            placeholder="All sources"
          />
        </Form.Item>
      </Space>

      <Divider />

      <Space className={styles.buttonGroup}>
        <Button type="default" shape="round" onClick={toggleAdvanceSearchButton}>
          Cancel
        </Button>
        <Button type="primary" shape="round" htmlType="submit">
          Search
        </Button>
      </Space>
    </Form>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      visible={visible}
      overlayClassName={styles.popover}
      onVisibleChange={toggleAdvanceSearchButton}
    >
      <Button type="default" shape="round">
        Advanced Search
        <Icon component={DropDown} />
      </Button>
    </Popover>
  );
}

export default EntitlementAdvancedSearch;
