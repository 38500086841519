import { useHistory } from "react-router";
import React, { useState } from "react";
import { Button, Card, Form, Space } from "antd";
import { Prompt } from "react-router-dom";
import { DeltaStatic } from "quill";
import { LEAVE_CONFIRMATION } from "../../../../constants/common";
import { SaveOfferForm } from "../SaveOfferDetails/SaveofferForm";
import * as eComSaveOfferAPI from "../../../../services/saveOffer";
import message from "../../../../components/message";
import { SaveOfferContent } from "../../../../types/model/saveOffer";
import { supportLocalTabs } from "../../../../utils/locale";

function AddNewSaveOffer() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [formIsHalfFilledOut, setFormIsHalfFilledOut] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [contents, setContents] = useState<SaveOfferContent[]>(
    supportLocalTabs.map((item) => ({ ...item, title: "", description: {} as DeltaStatic }))
  );

  const handleCreate = () =>
    form
      .validateFields()
      .then((value) => {
        setIsSaveLoading(true);
        eComSaveOfferAPI
          .createSaveOffer(value, contents)
          .then(() => {
            setFormIsHalfFilledOut(false);
            history.push("/marketing/save-offer");
            message.success("Save offer added successfully.");
          })
          .finally(() => setIsSaveLoading(false));
      })
      .catch(() => {
        message.error(new Error("Please fill in all required fields."));
      });

  return (
    <Card
      title={"Add new save offer"}
      extra={
        <Space size={10}>
          <Button type="default" shape="round" onClick={() => history.push("/marketing/save-offer")}>
            Cancel
          </Button>
          <Button type="primary" shape="round" onClick={handleCreate} loading={isSaveLoading}>
            Save
          </Button>
        </Space>
      }
      className="page-container"
    >
      <Prompt when={formIsHalfFilledOut} message={LEAVE_CONFIRMATION} />
      <SaveOfferForm form={form} contents={contents} setContents={setContents} />
    </Card>
  );
}

export default AddNewSaveOffer;
