import React, { useState } from "react";
import { Table } from "antd";
import { ColumnType, TableProps } from "antd/lib/table";
import { EComProductListModel, EComProductModel } from "../../../../types/model/eComProduct";
import ResizableTitle from "../../../../components/ResizableTitle";
import { getTotalDisplay } from "../../../../utils/getTotalDisplay";
import getTableColumns from "./getTableColumns";

interface EComProductTableProps {
  isLoading: boolean;
  productData: EComProductListModel;
  page: number;
  size: number;
  onPaginationChange: (page: number, pageSize?: number) => void;
  onTableChange: TableProps<EComProductModel>["onChange"];
  onRawChange?: TableProps<EComProductModel>["onRow"];
}

export default function EComProductTable({
  isLoading,
  productData,
  page,
  size,
  onPaginationChange,
  onTableChange,
  onRawChange,
}: EComProductTableProps) {
  const [tableColumns, setTableColumns] = useState<ColumnType<EComProductModel>[]>(getTableColumns());

  const handleResize = (index: number) => (e: any, { size }: any) => {
    const nextColumns = [...tableColumns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    setTableColumns(nextColumns);
  };

  const columnsToDisplay = tableColumns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: ColumnType<EComProductModel>) =>
      ({
        width: column.width,
        onResize: handleResize(index),
      } as React.HTMLAttributes<HTMLElement>),
  }));

  return (
    <Table<EComProductModel>
      loading={{
        spinning: isLoading,
      }}
      components={{
        header: {
          cell: ResizableTitle,
        },
      }}
      rowKey={(record) => record.pid}
      columns={columnsToDisplay}
      dataSource={productData.list}
      onChange={onTableChange}
      pagination={{
        showTotal: getTotalDisplay,
        current: page,
        pageSize: size,
        total: productData.total,
        defaultCurrent: 1,
        onChange: (page, pageSize) => onPaginationChange(page, pageSize),
      }}
      onRow={onRawChange}
    />
  );
}
