import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Input, Row, Space, Table } from "antd";
import { ColumnType, TableProps } from "antd/lib/table";
import { SorterResult, SortOrder } from "antd/lib/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import * as Icons from "assets/icons";
import { omit } from "lodash";
import { RootState, useAppDispatch } from "app/store";
import ResizableTitle from "components/ResizableTitle";
import { unwrapResult } from "@reduxjs/toolkit";
import { ArrayParam, NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { getTotalDisplay } from "utils/getTotalDisplay";
import useColumns from "./useColumns";
import styles from "./MiniCourseList.module.scss";
import MiniCourseModel, { MiniCourseStatus } from "../../../types/model/miniCourse";
import { getOrder } from "../../../utils";
import { DropdownInput, message } from "../../../components";
import { fetchMiniCourses, miniCoursesSelectors } from "../miniCourseSlice";
import { statusOptions } from "../../products/ContentProduct/ProductList/ProductAdvancedSearch";
import MiniCourseListRequest from "../../../types/dto/request/MiniCourseRequest";

function MiniCourseList() {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    size: withDefault(NumberParam, 10),
    language: withDefault(StringParam, undefined),
    sortKey: withDefault(StringParam, "lastModifiedDate"),
    sortOrder: withDefault(StringParam, "descend"),
    statuses: withDefault(ArrayParam, undefined),
  });
  const datasource = useSelector(miniCoursesSelectors.selectAll);
  const dispatch = useAppDispatch();
  const { totalElements } = useSelector((state: RootState) => state.miniCourses);
  const isLoading = useSelector((state: RootState) => state.loading);
  const [language, setLanguage] = useState(query.language);
  const columns = useColumns(query.sortOrder as SortOrder);
  const [tableColumns, setTableColumns] = useState<ColumnType<MiniCourseModel>[]>(columns);

  const handleResize = (index: number) => (e: any, { size }: any) => {
    const nextColumns = [...tableColumns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    setTableColumns(nextColumns);
  };

  const columnsToDisplay = tableColumns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: ColumnType<MiniCourseModel>) =>
      ({
        width: column.width,
        onResize: handleResize(index),
      } as React.HTMLAttributes<HTMLElement>),
  }));

  useEffect(() => {
    handleRefresh();
  }, [query]);

  const handleTableChange: TableProps<MiniCourseModel>["onChange"] = (pagination, filters, sorter, extra) => {
    const { order, field } = sorter as SorterResult<MiniCourseModel>;
    if (extra.action === "sort") {
      setQuery({
        ...query,
        sortKey: field as string,
        sortOrder: order as string,
        page: 1,
      });
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLanguage(e.target.value);
  };

  const handleInputPressEnter = () => {
    setQuery({ ...query, language: language?.trim(), page: 1 });
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setQuery({ ...query, page, size: pageSize });
  };

  const handleRefresh = () => {
    dispatch(
      fetchMiniCourses({
        ...omit(query, "sortKey", "sortOrder"),
        page: query.page - 1,
        sort: `${query.sortKey},${getOrder(query.sortOrder as SortOrder)}`,
      } as MiniCourseListRequest)
    )
      .then(unwrapResult)
      .catch(message.error);
    setLanguage(query.language);
    setTableColumns(columns);
  };

  return (
    <Card title="Mini course list" className="page-container">
      <Row justify={"space-between"} className={styles.filter}>
        <Space>
          <DropdownInput
            options={statusOptions}
            placeholder="All statuses"
            value={query.statuses as MiniCourseStatus[]}
            onChange={(statuses) => {
              setQuery({
                ...query,
                page: 1,
                statuses: statuses as MiniCourseListRequest["statuses"],
              });
            }}
          />
          <Input
            allowClear
            value={language}
            onChange={handleInputChange}
            onBlur={() => setLanguage((prevState) => prevState?.trim())}
            onPressEnter={handleInputPressEnter}
            className={styles.isbnInput}
            prefix={<SearchOutlined />}
            placeholder="Search Language"
          />
        </Space>
        <Button type="text" shape="circle" icon={<Icons.Refresh />} onClick={handleRefresh} />
      </Row>

      <Table<MiniCourseModel>
        loading={{ spinning: isLoading }}
        dataSource={datasource}
        columns={columnsToDisplay}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        pagination={{
          showTotal: getTotalDisplay,
          total: totalElements,
          showSizeChanger: true,
          current: query.page,
          pageSize: query.size,
          onChange: handlePageChange,
        }}
        rowClassName={styles.row}
      />
    </Card>
  );
}

export default MiniCourseList;
