import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import OrganizationList from "./OrganizationList";
import OrganizationCreation from "./OrganizationCreation";
import OrganizationDetail from "./OrganizationDetail";

function Organization() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <OrganizationList />
      </Route>
      <Route exact path={`${path}/add`}>
        <OrganizationCreation />
      </Route>
      <Route exact path={`${path}/:organizationId`}>
        <OrganizationDetail />
      </Route>
    </Switch>
  );
}

export default Organization;
