import React from "react";
import { Spin } from "antd";
import styles from "./Loading.module.scss";

Spin.setDefaultIndicator(<Loading />);

function Loading() {
  return <div className={styles.loading} />;
}

export default Loading;
