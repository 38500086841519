import React from "react";
import { Image, Space } from "antd";
import { DisplayInput } from "../../../../components";

export function DisplayProductImage({ imageUrl }: { imageUrl: string | undefined }) {
  if (!imageUrl) {
    return <DisplayInput />;
  }

  const parsedUrl = new URL(imageUrl);
  const imageName = decodeURIComponent(
    parsedUrl.pathname.startsWith("/") ? parsedUrl.pathname.substring(1) : parsedUrl.pathname
  );

  return (
    <Space size={8} direction="vertical">
      <DisplayInput value={imageName} />
      <Image src={imageUrl} height={104} alt="Plan Image" data-testid="display-product-image" />
    </Space>
  );
}
