import React from "react";
import { Dropdown, Menu, Space } from "antd";
import Icon from "@ant-design/icons";
import logo from "../../assets/images/logo.png";
import useAppAuth0 from "../../hooks/useAppAuth0";
// import { Book } from "../../assets/icons";
import * as Icons from "../../assets/icons";
// import { Role } from "../../types/role";
import styles from "./GlobalHeader.module.scss";

function Header() {
  const { logout, user } = useAppAuth0();

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  // let userManualFileName = "L1.pdf";
  //
  // if (user.hasRole(Role.PRODUCT_MANAGER)) {
  //   userManualFileName = "product_manager_manual.pdf";
  // } else if (
  //   user.hasRole(Role.PIMSLEUR_ADMIN) &&
  //   user.hasRole(Role.PIMSLEUR_B2B_ADMIN)
  // ) {
  //   userManualFileName = "admin_portal_manual.pdf";
  // } else if (user.hasRole(Role.PIMSLEUR_B2B_ADMIN)) {
  //   userManualFileName = "B2B_admin_manual.pdf";
  // }

  const menu = (
    <Menu>
      <Menu.Item onClick={handleLogout} icon={<Icons.Exit />} className={styles.logout}>
        Log out
      </Menu.Item>
    </Menu>
  );
  return (
    <header className={styles.header}>
      <img className={styles.headerLogoImage} src={logo} alt="" />
      <Space size={32}>
        {/*<a*/}
        {/*  href={`${process.env.PUBLIC_URL}/${userManualFileName}`}*/}
        {/*  download="Admin portal manual.pdf"*/}
        {/*  className={styles.docs}*/}
        {/*>*/}
        {/*  <Book />*/}
        {/*  Docs*/}
        {/*</a>*/}
        <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
          <div className={styles.profile}>
            <Icon component={Icons.User} style={{ fontSize: "20px" }} />
            <span>Hi, {user.nickname}</span>
          </div>
        </Dropdown>
      </Space>
    </header>
  );
}

export default Header;
