import { DeltaStatic } from "quill";
import { BillingPlan } from "./eComProduct";
import { Country } from "./price";
import { ContentBase } from "./description";

export interface DisclosureBasePrices {
  id: string;
  name: string;
}

export enum DisclosureStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export interface DisclosureModel {
  id: string;
  name: string;
  status: DisclosureStatus;
  appliedBasePrice: DisclosureBasePrices[];
  appliedBillingPlan?: BillingPlan;
  appliedCountryOrRegion: Country[];
  updatedAt: string;
}

export interface DisclosureListModel {
  list: DisclosureModel[];
  total: number;
}

export interface DisclosureFormData {
  name: string;
  basePriceIds: string[];
  billingPlanId: string;
  countryIds: number[];
  enContent: string;
  esContent: string;
}

export interface DisclosureDetailModel {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  status: DisclosureStatus;
  billingPlanId: string;
  contents: ContentBase[];
  basePrices: BasePrice[];
  countries: { id: number; displayName: string }[];
  billingPlan: BillingPlan;
}

export interface DisclosureDetailViewFields {
  id: string;
  name: string;
  status: DisclosureStatus;
  billingPlanId: string;
  enContent: DeltaStatic;
  esContent: DeltaStatic;
  basePrices: string;
  countries: string;
  billingPlan: string;
}

export interface BasePrice {
  id: string;
  name: string;
}
