import { omit, pickBy } from "lodash";
import {
  BasePriceListResponse,
  BasePriceResponse,
  CountryResponse,
  CurrencyAssociationResponse,
  CurrencyPrice,
  CurrencyResponse,
  PlanListResponse,
  PlanResponse,
} from "../../dto/response/basePrice";
import {
  BasePriceFieldValue,
  BasePriceListModel,
  Country,
  Currency,
  CurrencyFormValue,
  CurrencyModel,
  Plan,
  PlanListModel,
  PriceAction,
  PriceStatus,
} from "../../model/price";
import { PlanListParams } from "../../dto/request/basePrice";

export function transformCurrencyAssociation(currencyAssociationResponse: CurrencyAssociationResponse): CurrencyModel {
  return {
    code: currencyAssociationResponse.currency_code,
    countries: currencyAssociationResponse.countries.map((country) => ({
      id: country.id,
      iso2: country.iso_2,
      iso3: country.iso_3,
      name: country.name,
      displayName: country.display_name,
    })),
  };
}

export function transformCurrency(currencyResponse: CurrencyResponse[]): Currency[] {
  return currencyResponse.map((response) => ({
    code: response.code,
    symbol: response.symbol,
    symbolNative: response.symbol_native,
    name: response.name,
  }));
}

export function transformCountry(countryResponse: CountryResponse[]): Country[] {
  return countryResponse.map((response) => ({
    id: response.id,
    iso2: response.iso_2,
    iso3: response.iso_3,
    name: response.name,
    displayName: response.display_name,
  }));
}

export function transformBasePriceList(basePriceListResponse: BasePriceListResponse): BasePriceListModel {
  return {
    list: basePriceListResponse.data.map((basePrice) => ({
      id: basePrice.id,
      name: basePrice.name,
      currencies: basePrice.currency_prices.map((currencyPrice) => ({
        amount: currencyPrice.amount,
        code: currencyPrice.currency_code,
      })),
      status: basePrice.status as PriceStatus,
      plans: basePrice.plans.map((plan) => {
        return transformPlan(plan);
      }),
      validActions: [
        PriceAction.EDIT,
        basePrice.plans.length === 0 ? PriceAction.DELETE : undefined,
        basePrice.plans.length === 0 ? PriceAction.DEACTIVATE : undefined,
        PriceAction.ACTIVATE,
      ].filter((action) => action !== undefined) as PriceAction[],
    })),
    total: basePriceListResponse.total,
  };
}

export function getTransformedUpdatedAssociations(values: CurrencyFormValue, allCountries: Country[]) {
  return values.fields.flatMap(({ currency, country }) => {
    const currencyCode = currency.toLowerCase();
    const countries = country.map((countryOption) => {
      const countryData = allCountries.find((country) => country.id === countryOption.value);
      if (!countryData) {
        throw new Error(`Country information not found for ID: ${countryOption.value}`);
      }
      return {
        id: countryData.id,
        iso_2: countryData.iso2,
        iso_3: countryData.iso3,
        name: countryData.name.toUpperCase(),
        display_name: countryData.displayName,
      };
    });
    countries.sort((a, b) => a.name.localeCompare(b.name));

    return {
      currency_code: currencyCode,
      countries: countries,
    };
  });
}

export function getTransformedUpdatedBasePrice(values: BasePriceFieldValue): BasePriceResponse {
  const currency_prices: CurrencyPrice[] = Object.entries(
    pickBy(omit(values, ["name", "status", "id"]), (_, key) => typeof values[key] === "number")
  ).map(([currency_code, amount]) => ({ currency_code, amount }));

  return {
    name: values.name,
    status: values.status,
    currency_prices,
  };
}

export function transformPlanListParams(params: { id: string; limit: number; offset: number }): PlanListParams {
  return {
    base_price_id: params.id,
    limit: params.limit,
    offset: params.offset,
    order: "-updated_at",
  };
}

export function transformPlanList(planList: PlanListResponse): PlanListModel {
  return {
    list: planList.variants.map((plan) => transformPlan(plan)),
    total: planList.count,
  };
}

export function transformPlan(plan: PlanResponse): Plan {
  return {
    productId: plan.product_id,
    pid: plan.pid,
    title: plan.title,
    updatedAt: plan.updated_at,
    status: plan.status,
    product: plan.product,
  };
}
