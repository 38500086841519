import React from "react";
import moment from "moment";
import { ColumnType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import { Button, Popover } from "antd";
import { DATETIME_FORMAT, EMPTY } from "constants/common";
import ProductStatusView from "components/ProductStatus";
import MiniCourseModel from "../../../types/model/miniCourse";
import MiniCourseActionsView from "../../../components/MiniCourseActions";
import { Alarm } from "../../../assets/icons";
import styles from "./MiniCourseList.module.scss";
import { usePublishMiniCourse } from "../../../components/MiniCourseActions/usePublishMiniCourse";

function useColumns(sortOrder: SortOrder): ColumnType<MiniCourseModel>[] {
  const { handleDeleteSchedule } = usePublishMiniCourse();

  return [
    {
      title: "Language",
      dataIndex: "languageName",
      width: 210,
      ellipsis: true,
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <div>{record.languageName || EMPTY}</div>
          {record.scheduledPublishTime && (
            <Popover
              overlayClassName={styles.scheduledPopover}
              content={
                <div>
                  {moment(record.scheduledPublishTime).format()}
                  <Button className={styles.deleteBtn} onClick={() => handleDeleteSchedule(record.id)} type="link">
                    Delete
                  </Button>
                </div>
              }
            >
              <Alarm className={styles.alarmIcon} />
            </Popover>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 144,
      ellipsis: true,
      render: (value) => <ProductStatusView status={value} />,
    },
    {
      title: "Number of Lessons",
      dataIndex: "totalLessons",
      width: 144,
      ellipsis: true,
      render: (value: number) => value || EMPTY,
    },
    {
      title: "Modified at",
      key: "lastModifiedDate",
      dataIndex: "lastModifiedDate",
      width: 168,
      ellipsis: true,
      defaultSortOrder: "descend",
      sortOrder: sortOrder,
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (value) => moment(value).format(DATETIME_FORMAT),
    },
    {
      title: "Actions",
      dataIndex: "status",
      width: 210,
      ellipsis: true,
      render: (_, record) => (
        <MiniCourseActionsView
          status={record.status}
          id={record.id}
          scheduledPublishTime={record.scheduledPublishTime}
          languageName={record.languageName}
        />
      ),
    },
  ];
}

export default useColumns;
