import { message } from "antd";
import React, { useState } from "react";
import { ISubscriptionModalProps } from "./types";
import { EComModal } from "../../../../components/EComModal";
import { resumeSubscription } from "../../../../services/subscription";

export default function ResumeSubscriptionModal({
  subscription,
  visible,
  closeModal,
  onFinish,
}: ISubscriptionModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  async function onSubmit() {
    setIsLoading(true);
    try {
      await resumeSubscription(subscription.subscriptionId);
      message.success("Resumed successfully");
      if (onFinish) {
        onFinish();
      }
      closeModal();
    } catch (e) {
      message.error(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <EComModal
      title="Resume subscription "
      visible={visible}
      okText="Resume subscription "
      cancelText="Cancel"
      onCancel={closeModal}
      onOk={onSubmit}
      confirmLoading={isLoading}
    >
      <p>
        Are you sure to end the pause of user's subscription? The user can access the courses immediately, and user’s
        account will be billed today.
      </p>
    </EComModal>
  );
}
