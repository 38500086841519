import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Card, Input, Row, Space, Table, Tag } from "antd";
import { TableProps } from "antd/lib/table";
import { SorterResult, SortOrder } from "antd/lib/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import { omit, map } from "lodash";
import * as Icons from "assets/icons";
import { RootState, useAppDispatch } from "app/store";
import { DropdownInput, message } from "components";
import { EntitlementListParams } from "types/dto/request/entitlement";
import { EntitlementModel } from "types/model/entitlement";
import { getDateRange, getOrder } from "utils";
import moment from "moment";
import { NumberParam, StringParam, useQueryParams, ArrayParam, BooleanParam, withDefault } from "use-query-params";
import { DAY_BEGIN, DAY_END, ENTITLEMENT_SOURCE_MAP, ENTITLEMENT_STATUS_MAP } from "constants/common";
import { getTotalDisplay } from "utils/getTotalDisplay";
import { fetchLanguages } from "../../products/productSlice";
import { entitlementsSelectors, fetchEntitlements } from "../entitlementSlice";
import useColumns from "./useColumns";
import EntitlementAdvancedSearch, { EntitlementAdvancedSearchProps } from "./EntitlementAdvancedSearch";

import styles from "./EntitlementList.module.scss";

function EntitlementList() {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    size: withDefault(NumberParam, 10),
    sortKey: withDefault(StringParam, "createdDate"),
    sortOrder: withDefault(StringParam, "descend"),
    isbn: withDefault(StringParam, undefined),
    sources: withDefault(ArrayParam, []),
    createdDateFrom: withDefault(NumberParam, undefined),
    createdDateTo: withDefault(NumberParam, undefined),
    isSubscription: withDefault(BooleanParam, undefined),
    languageId: withDefault(NumberParam, undefined),
    statuses: withDefault(ArrayParam, []),
    email: withDefault(StringParam, undefined),
  });
  const dispatch = useAppDispatch();
  const datasource = useSelector(entitlementsSelectors.selectAll);
  const { languages } = useSelector((state: RootState) => state.products);
  const isLoading = useSelector((state: RootState) => state.loading);
  const { totalElements } = useSelector((state: RootState) => state.entitlement);
  const [email, setEmail] = useState(query.email);
  const columns = useColumns(query.sortOrder as SortOrder);

  useEffect(() => {
    dispatch(fetchLanguages()).then(unwrapResult).catch(message.error);
  }, [dispatch]);

  useEffect(() => {
    handleRefresh();
    setEmail(query.email);
  }, [dispatch, query]);

  const handleTableChange: TableProps<EntitlementModel>["onChange"] = (pagination, filters, sorter, extra) => {
    const { order, field } = sorter as SorterResult<EntitlementModel>;
    if (extra.action === "sort") {
      setQuery({
        ...query,
        sortKey: field as string,
        sortOrder: order as string,
        page: 1,
      });
    }
  };

  const handleAdvancedSearch: EntitlementAdvancedSearchProps["onSubmit"] = (values) => {
    setQuery({
      ...query,
      ...values,
      isbn: values.isbn?.trim(),
      email: values.email?.trim(),
      createdDateFrom: values.createdDateFrom ? moment(values.createdDateFrom).set(DAY_BEGIN).valueOf() : undefined,
      createdDateTo: values.createdDateTo ? moment(values.createdDateTo).set(DAY_END).valueOf() : undefined,
      page: 1,
    });
  };

  const handleRefresh = () => {
    dispatch(
      fetchEntitlements({
        ...omit(query, "sortKey", "sortOrder"),
        page: query.page - 1,
        sort: `${query.sortKey},${getOrder(query.sortOrder as SortOrder)}`,
      } as EntitlementListParams)
    )
      .then(unwrapResult)
      .catch(message.error);
  };

  const removeDateTag = () => {
    setQuery({
      ...query,
      createdDateFrom: undefined,
      createdDateTo: undefined,
      page: 1,
    });
  };

  const removeStatusesTag = (status: string) => {
    setQuery({
      ...query,
      statuses: query.statuses?.filter((item) => item !== status),
      page: 1,
    });
  };

  return (
    <Card title="Entitlement list" className="page-container">
      <Row justify={"space-between"} className={styles.filter}>
        <Space>
          <DropdownInput
            options={Array.from(ENTITLEMENT_SOURCE_MAP).map((entitlementSource) => ({
              label: entitlementSource[1],
              value: entitlementSource[0],
            }))}
            placeholder="All sources"
            value={query.sources as string[]}
            onChange={(sources) => {
              setQuery({
                ...query,
                page: undefined,
                sources: sources as string[],
              });
            }}
          />
          <Input
            allowClear
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() => setEmail((prevState) => prevState?.trim())}
            onPressEnter={() => setQuery(omit({ ...query, email: email?.trim() }, "page"))}
            className={styles.emailInput}
            prefix={<SearchOutlined />}
            placeholder="Search customer email"
          />
          <EntitlementAdvancedSearch
            initialValues={{ ...query, email: email } as EntitlementListParams}
            onSubmit={handleAdvancedSearch}
          />
        </Space>
        <Button type="text" shape="circle" icon={<Icons.Refresh />} onClick={handleRefresh} />
      </Row>

      <Row className={styles.tags}>
        {(query.createdDateFrom || query.createdDateTo) && (
          <Tag closable onClose={() => removeDateTag()}>
            {getDateRange(query.createdDateFrom, query.createdDateTo)}
          </Tag>
        )}
        {query.languageId && (
          <Tag closable onClose={() => setQuery({ ...query, languageId: undefined, page: 1 })}>
            {languages.find((language) => language.id === query.languageId)?.name}
          </Tag>
        )}
        {query.isSubscription !== undefined && (
          <Tag closable onClose={() => setQuery({ ...query, isSubscription: undefined, page: 1 })}>
            {query.isSubscription ? "Subscription" : "One-shot"}
          </Tag>
        )}
        {query.statuses &&
          map(query.statuses, (status: string) => (
            <Tag key={status} closable onClose={() => removeStatusesTag(status)}>
              {ENTITLEMENT_STATUS_MAP.get(status)}
            </Tag>
          ))}
        {query.isbn && (
          <Tag closable onClose={() => setQuery({ ...query, isbn: undefined, page: 1 })}>
            {query.isbn}
          </Tag>
        )}
      </Row>

      <Table
        className={styles.table}
        loading={{ spinning: isLoading }}
        dataSource={datasource}
        columns={columns}
        rowKey={(record) => record.entitlementId}
        onChange={handleTableChange}
        pagination={{
          showTotal: getTotalDisplay,
          total: totalElements,
          showSizeChanger: true,
          current: query.page,
          pageSize: query.size,
          onChange: (page, pageSize) => setQuery({ ...query, page, size: pageSize }),
        }}
      />
    </Card>
  );
}

export default EntitlementList;
