import React from "react";
import { DatePicker, Form, FormInstance, Radio } from "antd";
import moment from "moment";
import { validateMessages } from "constants/common";

interface Props {
  form: FormInstance;
  initialPublishTime?: number;
  isMiniCourse?: boolean;
}

function ScheduledPublish({ form, initialPublishTime, isMiniCourse }: Props) {
  const validTime = () => {
    const { scheduledPublishTime } = form.getFieldsValue();
    if (!scheduledPublishTime) {
      return Promise.reject("'Schedule time' is required");
    } else if (scheduledPublishTime < moment()) {
      return Promise.reject("'Schedule time' should not be earlier than now");
    } else {
      return Promise.resolve();
    }
  };

  return (
    <>
      <div>
        How would you like to publish this
        {isMiniCourse ? " mini course" : " product"}?
      </div>
      <Form form={form} validateMessages={validateMessages}>
        <Form.Item name="publishNow" initialValue={!initialPublishTime} rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value={true}>Publish now</Radio>
            <Radio value={false}>Schedule publish</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          {(formInstance) => {
            return formInstance.getFieldValue("publishNow") ? (
              <div>Once published, all the changes will be available to all the end users.</div>
            ) : (
              <>
                <div>Please select the time for publishing</div>
                <Form.Item
                  label="Schedule time"
                  name="scheduledPublishTime"
                  rules={[
                    {
                      required: true,
                      validator: validTime,
                    },
                  ]}
                  initialValue={initialPublishTime ? moment(initialPublishTime) : undefined}
                >
                  <DatePicker showTime showNow={false} disabledDate={(date) => date < moment().startOf("day")} />
                </Form.Item>
              </>
            );
          }}
        </Form.Item>
      </Form>
    </>
  );
}

export default ScheduledPublish;
