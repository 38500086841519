import React, { useEffect, useState } from "react";
import { Button, Modal, Space, Table } from "antd";
import CryptoJS from "crypto-js";
import { isEmpty } from "lodash";
import styles from "./ViewDeeplinkModal.module.scss";
import * as basePriceAPI from "../../../../services/basePrice";
import { CountryInfo } from "../../../../types/model/eComProduct";
import { CurrencyModel } from "../../../../types/model/price";
import DisplayCopyInput from "../../../../components/DisplayCopyInput";

interface DataType {
  key: React.Key;
  country: string;
  checkoutDeeplink: string;
}

const generateDeeplink = (iso2: string, pid: string) => {
  const cipherText = CryptoJS.RC4.encrypt(JSON.stringify({ country: iso2 }), "").toString();
  const encodedCipherText = encodeURIComponent(cipherText);
  return `${process.env.REACT_APP_ECOM_BASE_URL}/checkout?pid=${pid}&data=${encodedCipherText}`;
};

const ViewDeeplinkModal = ({ pid }: { pid: string }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataType[]>([]);

  useEffect(() => {
    if (visible) {
      setLoading(true);
      basePriceAPI
        .fetchCurrencyAssociation()
        .then((response: CurrencyModel[]) => {
          const Countries: CountryInfo[] = response.reduce((acc: CountryInfo[], curr: CurrencyModel) => {
            curr.countries.forEach((country) => {
              acc.push({
                id: country.id,
                displayName: country.displayName,
                iso2: country.iso2,
              });
            });
            return acc;
          }, []);
          !isEmpty(Countries) &&
            setData(
              Countries.map((country) => ({
                key: country.id,
                country: country.displayName,
                checkoutDeeplink: generateDeeplink(country.iso2, pid),
              }))
            );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [visible, pid]);

  const columns = [
    {
      title: "Country",
      dataIndex: "country",
      width: 200,
    },
    {
      title: "Checkout Deeplink",
      dataIndex: "checkoutDeeplink",
      render: (checkoutDeeplink: string) => <DisplayCopyInput value={checkoutDeeplink} textStyle={{ width: 580 }} />,
    },
  ];

  return (
    <Space className={styles.deeplink}>
      <Button
        key="viewMore"
        type="link"
        onClick={() => {
          setVisible(true);
        }}
      >
        View More
      </Button>
      <Modal
        bodyStyle={{ overflowY: "auto", maxHeight: "70vh" }}
        width={900}
        title="Checkout Deeplink"
        visible={visible}
        footer={null}
        className={styles.modal}
        onCancel={() => {
          setVisible(false);
        }}
        maskClosable={false}
        centered
        destroyOnClose
      >
        {visible && (
          <Table className={styles.table} columns={columns} dataSource={data} pagination={false} loading={loading} />
        )}
      </Modal>
    </Space>
  );
};

export default ViewDeeplinkModal;
