import React, { useState } from "react";
import { Input, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { join } from "lodash";
import styles from "./EditableTagGroup.module.scss";

interface EditableTagGroupProps {
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
}

function EditableTagGroup({ value, onChange, onBlur }: EditableTagGroupProps) {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState("");
  let tagsValue: string[] = value ? value.split(",") : [];

  function addTag() {
    if (inputValue) {
      tagsValue = tagsValue.concat(inputValue.trim());
      onChange && onChange(join(tagsValue, ","));
    }
    setInputValue("");
    setEditing(false);
  }

  function removeTag(index: number) {
    tagsValue = tagsValue.filter((_, idx) => idx !== index);
    onChange && onChange(join(tagsValue, ","));
  }

  return (
    <div className={styles.container}>
      {tagsValue?.map((text, index) => (
        <Tag onClose={() => removeTag(index)} closable visible key={index}>
          {text}
        </Tag>
      ))}
      {editing ? (
        <Input
          ref={(input) => input && input.focus()}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={(e) => {
            addTag();
            setEditing(false);
            onBlur && onBlur(e);
          }}
          onPressEnter={addTag}
          className={styles.input}
        />
      ) : (
        <Tag onClick={() => setEditing(true)} className={styles.plusTag}>
          <PlusOutlined /> Add allowed domain
        </Tag>
      )}
    </div>
  );
}

export default EditableTagGroup;
