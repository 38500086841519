import { Row, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { EComProductListRequest } from "../../../../types/dto/request/eComProduct";
import styles from "./index.module.scss";
import { getDateRange } from "../../../../utils";
import { DisplayLanguage } from "../AddNewProduct/ProductInfo";
import { BillingPlan } from "../../../../types/model/eComProduct";

export interface DisplayTagsProps {
  query: EComProductListRequest;
  languages: Omit<DisplayLanguage, "usedCourseTypes">[];
  basePriceList: { id: string; name: string }[];
  billingPlanList: BillingPlan[];
  onTagsChange: (patchQuery: EComProductListRequest) => void;
}

export default function DisplayTags({
  query,
  languages,
  basePriceList,
  billingPlanList,
  onTagsChange,
}: DisplayTagsProps) {
  const [patchQuery, setPatchQuery] = useState(query);

  useEffect(() => {
    onTagsChange(patchQuery);
  }, [patchQuery]);

  const removeDateTag = () => {
    setPatchQuery({
      lastModifiedDateFrom: undefined,
      lastModifiedDateTo: undefined,
    });
  };

  const removeBasePrice = (basePriceId: string) => {
    setPatchQuery({
      basePriceIds: query.basePriceIds?.filter((id) => id !== basePriceId),
    });
  };

  const removeLanguage = () => {
    setPatchQuery({ languageId: undefined });
  };

  const removeCourseType = () => {
    setPatchQuery({ courseType: undefined });
  };

  const removeBillingPlan = () => {
    setPatchQuery({ billingPlanId: undefined });
  };

  return (
    <Row className={styles.tags}>
      {(query.lastModifiedDateFrom || query.lastModifiedDateTo) && (
        <Tag closable onClose={removeDateTag}>
          {getDateRange(query.lastModifiedDateFrom, query.lastModifiedDateTo)}
        </Tag>
      )}
      {query.basePriceIds &&
        query.basePriceIds.map((id) => (
          <Tag key={id} closable onClose={() => removeBasePrice(id)}>
            {basePriceList.find((item) => item.id === id)?.name}
          </Tag>
        ))}
      {query.languageId && (
        <Tag closable onClose={removeLanguage}>
          {languages.find(({ languageId }) => languageId === query.languageId)?.name}
        </Tag>
      )}
      {query.courseType && (
        <Tag closable onClose={removeCourseType}>
          {query.courseType}
        </Tag>
      )}
      {query.billingPlanId && (
        <Tag closable onClose={removeBillingPlan}>
          {billingPlanList.find(({ id }) => id === query.billingPlanId)?.name}
        </Tag>
      )}
    </Row>
  );
}
