import React, { forwardRef, useImperativeHandle } from "react";
import { Table, Form, Button, Spin, FormInstance } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import message from "../message";
import styles from "./index.module.scss";
import { CancelReasonModal } from "../../types/model/saveOffer";
import { NO_REASON_SELECTED_CANCEL_REASON_CODE } from "../../constants/common";

interface Props {
  form: FormInstance;
  updateData: () => Promise<void>;
  successMessage: string;
  refreshData: () => Promise<void>;
  isLoading: boolean;
  dataSource: CancelReasonModal[];
  columns: any[];
  handleAdd: () => void;
  buttonTitle?: string;
}
const GenericConfigureTable = forwardRef((props: Props, ref) => {
  useImperativeHandle(ref, () => ({
    validate,
    update,
    refresh,
  }));

  const update = async (): Promise<void> => {
    try {
      await props.form.validateFields();
      await props.updateData();
      message.success(props.successMessage, 2);
    } catch (error) {}
  };

  const refresh = async () => {
    await props.refreshData();
  };

  const validate = async (): Promise<boolean> => {
    try {
      const values = await props.form.validateFields();
      return Object.values(values).length > 0;
    } catch (e) {
      return false;
    }
  };

  return (
    <Spin spinning={props.isLoading} size="small">
      <div className={styles.configureTable}>
        <Form form={props.form} onFinish={update}>
          <Table
            dataSource={props.dataSource.filter(({ code }) => code !== NO_REASON_SELECTED_CANCEL_REASON_CODE)}
            columns={props.columns}
            rowKey={(record) => record.key}
            pagination={false}
            scroll={{ y: "calc(70vh - 150px)" }}
            style={{ maxWidth: 1014 }}
            className={styles.table}
          />
        </Form>
        {props.buttonTitle && (
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={props.handleAdd}
            className={styles.addButton}
            data-testid="add-button"
            aria-label="add button"
          >
            {props.buttonTitle}
          </Button>
        )}
      </div>
    </Spin>
  );
});

export default GenericConfigureTable;
