import React, { useEffect, useRef, useState } from "react";
import { Collapse } from "antd";
import * as Icons from "assets/icons";
import Icon from "@ant-design/icons";
import styles from "./index.module.scss";
import EmptyContent from "../../../../components/EmptyContent";
import { ProductPlanDetails } from "../../../../types/model/eComProduct";
import PlanActions from "./PlanActions";
import PlanDetails from "../PlanDetails";
import CustomDot from "../../../../components/CustomDot";

interface IPlanListProps {
  plans: ProductPlanDetails[];
  setNewPlanFieldsValue?: any;
  activeKey: string;
  onEditPlan: (plan: ProductPlanDetails, index: number) => void;
  onDeletePlan: (plan: ProductPlanDetails, index: number) => void;
  onPublishPlan: (plan: ProductPlanDetails, index: number) => void;
  onRevertToDraftPlan: (plan: ProductPlanDetails, index: number) => void;
  onActivatePlan: (plan: ProductPlanDetails, index: number) => void;
  onDeactivatePlan: (plan: ProductPlanDetails, index: number) => void;
  onPreviewPlan: (plan: ProductPlanDetails, index: number) => void;
  keyExtractor?: (plan: ProductPlanDetails) => string;
}

const { Panel } = Collapse;

function PlanList({
  plans,
  activeKey,
  onEditPlan,
  onDeletePlan,
  onPublishPlan,
  onRevertToDraftPlan,
  onActivatePlan,
  onDeactivatePlan,
  onPreviewPlan,
  keyExtractor,
}: IPlanListProps) {
  const [currentActiveKey, setCurrentActiveKey] = useState<string | string[]>(activeKey);

  useEffect(() => {
    setCurrentActiveKey(activeKey);
  }, [activeKey]);

  const refMap = useRef<{ [key: string]: HTMLElement }>({});

  useEffect(() => {
    const activeElement = refMap.current[activeKey];
    if (activeElement && activeKey === currentActiveKey) {
      activeElement.scrollIntoView();
    }
  }, [Object.keys(refMap.current), activeKey]);

  if (plans.length === 0) {
    return (
      <section className={styles.emptyPlan}>
        <EmptyContent displayTexts={["No plan yet.", "Please add at least one plan"]} />
      </section>
    );
  }

  return (
    <div className={styles.container}>
      {plans.map((plan, index) => (
        <div
          key={keyExtractor?.(plan) ?? index}
          ref={(el) => {
            if (el) {
              refMap.current[keyExtractor?.(plan) ?? index.toString()] = el;
            }
          }}
        >
          <Collapse
            activeKey={currentActiveKey}
            collapsible={"header"}
            onChange={(keys) => setCurrentActiveKey(keys)}
            className={styles.collapseCustomCollapse}
            expandIcon={({ isActive }) => (
              <Icon
                component={Icons.CareRight}
                className={styles.icon}
                rotate={isActive ? 90 : 0}
                onClick={() => {
                  if (typeof currentActiveKey === "string") {
                    currentActiveKey === plan.pid
                      ? setCurrentActiveKey([])
                      : setCurrentActiveKey([currentActiveKey, plan.pid]);
                  } else if (currentActiveKey === undefined) {
                    setCurrentActiveKey(plan.pid);
                  } else {
                    currentActiveKey.includes(plan.pid)
                      ? setCurrentActiveKey(currentActiveKey.filter((key) => key !== plan.pid))
                      : setCurrentActiveKey([...currentActiveKey, plan.pid]);
                  }
                }}
              />
            )}
          >
            <Panel
              header={
                <div className={styles.collapsePanelHeader}>
                  <span className={styles.headerText}>{plan.name}</span>
                  <CustomDot color={plan.status} />
                </div>
              }
              className={styles.collapseCustomPanel}
              key={keyExtractor?.(plan) ?? index}
              extra={
                <PlanActions
                  onEdit={() => onEditPlan(plan, index)}
                  onDelete={() => onDeletePlan(plan, index)}
                  onReadyForPublish={() => onPublishPlan(plan, index)}
                  onRevertToDraft={() => onRevertToDraftPlan(plan, index)}
                  onActivate={() => onActivatePlan(plan, index)}
                  onDeactivate={() => onDeactivatePlan(plan, index)}
                  onPreviewCheckout={() => onPreviewPlan(plan, index)}
                  status={plan.status!}
                />
              }
            >
              <PlanDetails plan={plan} />
            </Panel>
          </Collapse>
        </div>
      ))}
    </div>
  );
}

export default PlanList;
