import instance from "utils/instance";
import Page from "types/page";
import { OrganizationListParams } from "types/dto/request/organization";
import { OrganizationModal } from "types/model/organization";
import { OrganizationListResponse } from "../types/dto/response/organization";

export function fetchAll(params: OrganizationListParams) {
  const url = "/organizations";
  return instance.get<Page<OrganizationListResponse>>(url, { params });
}

export async function createOrganization(data: OrganizationModal) {
  const url = `/organizations`;
  return instance.post<void>(url, data);
}

export async function fetchOrganization(id: string) {
  const url = `/organizations/${id}`;
  return instance.get<OrganizationModal>(url);
}

export function removeOrganization(id: number) {
  const url = `/organizations/${id}`;
  return instance.delete<void>(url);
}

export async function sendEmail(id: string, email: string) {
  const url = `/organizations/${id}/send-email`;
  return instance.post<void>(url, { email: email });
}

export async function updateOrganization(payload: OrganizationModal) {
  const url = `/organizations/${payload.id}`;
  return instance.put<void>(url, payload);
}
