import React from "react";
import { useHistory } from "react-router";
import moment from "moment";
import { ColumnType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import { DATETIME_FORMAT, EMPTY, ENTITLEMENT_SOURCE_MAP, ENTITLEMENT_STATUS_MAP } from "constants/common";
import { Button, Tooltip } from "antd";
import { EntitlementModel } from "types/model/entitlement";
import Icon from "@ant-design/icons/lib/components/Icon";
import { LongArrowup } from "assets/icons";
import useAppAuth0 from "hooks/useAppAuth0";
import { Typography } from "antd/es";
import { renderEntitlementType } from "../../customer/CustomerEntitlements/columnConfig";

const { Text } = Typography;

function renderLanguageName(languageName: string, entity: EntitlementModel) {
  if (entity.isUpgradedToAllAccess) {
    return (
      <Tooltip title="Upgraded from premium subscription to all access" overlayInnerStyle={{ width: "max-content" }}>
        <Icon component={LongArrowup} />
        <Text>{languageName}</Text>
      </Tooltip>
    );
  }
  return languageName;
}

function useColumns(sortOrder: SortOrder): ColumnType<EntitlementModel>[] {
  const history = useHistory();
  const { user } = useAppAuth0();
  return [
    {
      title: "Product ISBN",
      dataIndex: "isbn",
      width: 225,
      ellipsis: true,
      render: (value, record) =>
        user.canViewProductMenu ? (
          <Button
            type="link"
            style={{ padding: 0, height: "auto" }}
            onClick={() => history.push(`products/content/${record.productId}`)}
          >
            {value || EMPTY}
          </Button>
        ) : (
          value || EMPTY
        ),
    },
    {
      title: "Customer email",
      dataIndex: "customerEmail",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Language",
      dataIndex: "languageName",
      ellipsis: true,
      render: renderLanguageName,
    },
    {
      title: "Source",
      dataIndex: "source",
      width: "10%",
      ellipsis: true,
      render: (value) => ENTITLEMENT_SOURCE_MAP.get(value) || EMPTY,
    },
    {
      title: "Purchase type",
      width: "12%",
      ellipsis: true,
      render: (item: EntitlementModel) => renderEntitlementType(item),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "9%",
      ellipsis: true,
      render: (value) => ENTITLEMENT_STATUS_MAP.get(value) || EMPTY,
    },
    {
      title: "Created at",
      dataIndex: "createdDate",
      width: "17%",
      ellipsis: true,
      defaultSortOrder: "descend",
      sortOrder: sortOrder,
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (value) => (value ? moment(value).format(DATETIME_FORMAT) : EMPTY),
    },
  ];
}

export default useColumns;
