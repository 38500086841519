import { DeltaStatic } from "quill";
import RichTextEditor from "../../../../components/RichTextEditor";

interface IDescriptionContentInputProps {
  editable: boolean;
  value: DeltaStatic;
  onChange?: (value: DeltaStatic) => void;
  bounds?: string | HTMLElement;
}

function DescriptionContentInput({ bounds, editable, value, onChange = () => {} }: IDescriptionContentInputProps) {
  return (
    <RichTextEditor
      bounds={bounds}
      value={value}
      onChange={(content, delta, source, editor) => onChange(editor.getContents())}
      readonly={!editable}
    />
  );
}

export default DescriptionContentInput;
