import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import LanguageModel from "types/model/language";
import { RootState } from "app/store";
import * as entitlementAPI from "services/entitlement";
import { EntitlementListParams } from "types/dto/request/entitlement";
import { EntitlementModel } from "types/model/entitlement";

export const fetchEntitlements = createAsyncThunk("entitlements/fetchAll", async (params: EntitlementListParams) => {
  return await entitlementAPI.fetchAll(params);
});

const entitlementsAdapter = createEntityAdapter<EntitlementModel>({
  selectId: (record) => record.entitlementId,
});

const entitlementsSlice = createSlice({
  name: "entitlements",
  initialState: entitlementsAdapter.getInitialState({
    languages: [] as LanguageModel[],
    currentPage: 1,
    currentSize: 10,
    totalElements: 0,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEntitlements.fulfilled, (state, { payload }) => {
      const { content, totalElements, page, size } = payload;
      entitlementsAdapter.setAll(state, content);
      state.currentPage = page + 1;
      state.currentSize = size;
      state.totalElements = totalElements;
    });
  },
});

export default entitlementsSlice.reducer;

export const entitlementsSelectors = entitlementsAdapter.getSelectors<RootState>((state) => state.entitlement);
