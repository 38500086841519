import React, { useEffect, useState } from "react";
import { Form, FormInstance, Input, Select, Space } from "antd";
import { DropDown } from "../../../../../../../assets/icons";
import { DescriptionTemplateModel } from "../../../../../../../types/model/description";
import * as descriptionTemplateAPI from "../../../../../../../services/description";
import { DEFAULT_MAX_PAGINATION } from "../../../../../../../constants/common";
import styles from "../../index.module.scss";
import { getText } from "../../../../../../../utils/richTextEditor";

interface IDescriptionTemplateProps {
  form: FormInstance;
  setDescriptionLoading: (loading: boolean) => void;
}

export default function DescriptionTemplate({ form, setDescriptionLoading }: IDescriptionTemplateProps) {
  const [descriptionTemplates, setDescriptionTemplates] = useState<DescriptionTemplateModel[]>([]);
  const [content, setContent] = useState(form.getFieldValue("descTemplateContent"));

  useEffect(() => {
    setContent(form.getFieldValue("descTemplateContent"));
  }, [form.getFieldValue("descTemplateContent")]);

  useEffect(() => {
    let ignore = false;

    setDescriptionLoading(true);
    descriptionTemplateAPI
      .fetchDescriptionTemplateList({
        offset: 0,
        limit: DEFAULT_MAX_PAGINATION.size,
      })
      .then((data) => {
        if (!ignore) {
          setDescriptionTemplates(data.list);
        }
      })
      .finally(() => {
        setDescriptionLoading(false);
      });

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      <Form.Item name="descTemplateName" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="descTemplateContent" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Space direction={"vertical"} size={8}>
        <Form.Item
          label="Description template"
          name="descTemplateId"
          rules={[{ required: true }]}
          className={styles.formItem}
        >
          <Select
            allowClear
            showSearch={true}
            listHeight={160}
            getPopupContainer={(trigger) => trigger.parentNode}
            suffixIcon={<DropDown />}
            placeholder={"Description for single language"}
            optionFilterProp={"label"}
            options={descriptionTemplates.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
            onChange={(value) => {
              const description = descriptionTemplates.find(({ id }) => id === value);
              setContent(description?.content ?? "{}");
              form.setFieldsValue({
                descTemplateContent: description?.content ?? "{}",
                descTemplateName: description?.name,
              });
            }}
          />
        </Form.Item>
        {content && <span className={styles.formHelpText}>{getText(content)}</span>}
      </Space>
    </div>
  );
}
