import instance from "utils/instance";
import ProductListRequest from "types/dto/request/ProductListRequest";
import Page from "types/page";
import ProductModel, { ProductChangeLog, VerifyResult } from "types/model/product";
import LanguageModel from "types/model/language";
import { ProductChangeLogParams, ProductUpdateRequest } from "types/dto/request/product";
import { FetchOptionParams, ProductCreateRequest, ProductSelections } from "../types/model/itemsForAddProduct";
import { ProductDetailResponse } from "../types/dto/response/product";
import { DEFAULT_MAX_PAGINATION } from "../constants/common";

export async function fetchAll(params: ProductListRequest) {
  const url = "/products";
  return instance.get<Page<ProductModel>>(url, { params });
}

export async function fetchLanguages() {
  const url = "/languages";
  const params = { ...DEFAULT_MAX_PAGINATION, sort: "name,asc" };
  return instance.get<Page<LanguageModel>>(url, { params });
}

export async function fetchLanguagesIncludeAllAccess() {
  const url = "/languages";
  const params = {
    ...DEFAULT_MAX_PAGINATION,
    sort: "name,asc",
    includeAllAccess: true,
  };
  return instance.get<Page<LanguageModel>>(url, { params });
}

export async function fetchProduct(productId: string) {
  const url = `/products/${productId}`;
  return instance.get<ProductDetailResponse>(url);
}

export async function fetchProductChangeLogs(params: ProductChangeLogParams) {
  const url = `/product-change-logs`;
  return instance.get<Page<ProductChangeLog>>(url, { params });
}

export async function fetchSelectionItems(params: FetchOptionParams) {
  const url = "/product-items";
  return instance.get<ProductSelections>(url, { params });
}

export async function createProduct(product: ProductCreateRequest) {
  const url = "/products";
  return instance.post(url, product);
}

export function removeProduct(productId: number) {
  const url = `/products/${productId}`;
  return instance.delete<void>(url);
}

export async function updateProduct(productId: number, patch: ProductUpdateRequest) {
  const url = `/products/${productId}`;
  return instance.put<ProductDetailResponse>(url, patch);
}

export async function verifyProduct(productId: string, comments: string) {
  const url = `/products/${productId}/verification`;
  return instance.post<VerifyResult>(url, { comments });
}

export async function publishProduct(productId: string) {
  const url = `/products/${productId}/publishing`;
  return instance.post<void>(url);
}

export function scheduledPublishProduct(productId: string, scheduledPublishTime: number) {
  const url = `/products/${productId}/scheduled-publishing`;
  return instance.post<void>(url, { scheduledPublishTime });
}

export function removeSchedule(productId: string) {
  const url = `/products/${productId}/scheduled-publishing`;
  return instance.delete<void>(url);
}
