import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button, Card, Input, Row, Space, Table, Tag } from "antd";
import { TableProps, ColumnType } from "antd/lib/table";
import { SorterResult, SortOrder } from "antd/lib/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import { omit } from "lodash";
import * as Icons from "assets/icons";
import { RootState, useAppDispatch } from "app/store";
import { DropdownInput, message } from "components";
import ProductModel, { ProductStatus, ProductType } from "types/model/product";
import ProductListRequest from "types/dto/request/ProductListRequest";
import { getDateRange, getOrder } from "utils";
import useAppAuth0 from "hooks/useAppAuth0";
import AddButton from "components/AddButton";
import ResizableTitle from "components/ResizableTitle";
import { DAY_BEGIN, DAY_END } from "constants/common";
import moment from "moment";
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { getTotalDisplay } from "utils/getTotalDisplay";
import { fetchLanguages, fetchProducts, productsSelectors } from "../../productSlice";
import useColumns from "./useColumns";
import ProductAdvancedSearch, {
  ProductAdvancedSearchProps,
  productTypeOptions,
  statusOptions,
} from "./ProductAdvancedSearch";

import styles from "./ProductList.module.scss";

function ProductList() {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    size: withDefault(NumberParam, 10),
    sortKey: withDefault(StringParam, "lastModifiedDate"),
    sortOrder: withDefault(StringParam, "descend"),
    lastModifiedDateFrom: withDefault(NumberParam, undefined),
    lastModifiedDateTo: withDefault(NumberParam, undefined),
    productTypes: withDefault(ArrayParam, undefined),
    languageId: withDefault(NumberParam, undefined),
    isPremium: withDefault(BooleanParam, undefined),
    statuses: withDefault(ArrayParam, undefined),
    isbn: withDefault(StringParam, undefined),
  });
  const dispatch = useAppDispatch();
  const { user } = useAppAuth0();
  const history = useHistory();
  const { path } = useRouteMatch();
  const datasource = useSelector(productsSelectors.selectAll);
  const { totalElements, languages } = useSelector((state: RootState) => state.products);
  const isLoading = useSelector((state: RootState) => state.loading);
  const [isbn, setIsbn] = useState(query.isbn);
  const columns = useColumns(query.sortOrder as SortOrder);

  const [tableColumns, setTableColumns] = useState<ColumnType<ProductModel>[]>(columns);

  const handleResize = (index: number) => (e: any, { size }: any) => {
    const nextColumns = [...tableColumns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    setTableColumns(nextColumns);
  };

  const columnsToDisplay = tableColumns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: ColumnType<ProductModel>) =>
      ({
        width: column.width,
        onResize: handleResize(index),
      } as React.HTMLAttributes<HTMLElement>),
  }));

  useEffect(() => {
    dispatch(fetchLanguages()).then(unwrapResult).catch(message.error);
  }, [dispatch]);

  useEffect(() => {
    handleRefresh();
    setIsbn(query.isbn);
    setTableColumns(columns);
  }, [dispatch, query]);

  const handleTableChange: TableProps<ProductModel>["onChange"] = (pagination, filters, sorter, extra) => {
    const { order, field } = sorter as SorterResult<ProductModel>;
    if (extra.action === "sort") {
      setQuery({
        ...query,
        sortKey: field as string,
        sortOrder: order as string,
        page: 1,
      });
    }
  };

  const handleAdvancedSearch: ProductAdvancedSearchProps["onSubmit"] = (values) => {
    setQuery({
      ...query,
      ...values,
      isbn: values.isbn?.trim(),
      lastModifiedDateFrom: values.lastModifiedDateFrom
        ? moment(values.lastModifiedDateFrom).set(DAY_BEGIN).valueOf()
        : undefined,
      lastModifiedDateTo: values.lastModifiedDateTo
        ? moment(values.lastModifiedDateTo).set(DAY_END).valueOf()
        : undefined,
      page: 1,
    });
  };

  const handleRefresh = () => {
    dispatch(
      fetchProducts({
        ...omit(query, "sortKey", "sortOrder"),
        page: query.page - 1,
        sort: `${query.sortKey},${getOrder(query.sortOrder as SortOrder)}`,
      } as ProductListRequest)
    )
      .then(unwrapResult)
      .catch(message.error);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsbn(e.target.value);
  };

  const handleInputPressEnter = () => {
    setQuery({ ...query, isbn: isbn?.trim(), page: 1 });
  };

  const removeDateTag = () => {
    setQuery({
      ...query,
      lastModifiedDateFrom: undefined,
      lastModifiedDateTo: undefined,
      page: 1,
    });
  };

  const removeProductTypeTag = (productType: ProductType) => {
    setQuery({
      ...query,
      productTypes: query.productTypes?.filter((item) => item !== productType),
      page: 1,
    });
  };

  const handleOnRowClick: TableProps<ProductModel>["onRow"] = (record) => ({
    onClick: () => history.push(`${path}/${record.productId}`),
  });

  const handlePageChange = (page: number, pageSize?: number) => {
    setQuery({ ...query, page, size: pageSize });
  };

  return (
    <Card
      title="Product list"
      extra={user.canCreateProduct && <AddButton text={"+ Add new product"} />}
      className="page-container"
    >
      <Row justify={"space-between"} className={styles.filter}>
        <Space>
          <DropdownInput
            options={statusOptions}
            placeholder="All statuses"
            value={query.statuses as ProductStatus[]}
            onChange={(statuses) => {
              setQuery({
                ...query,
                page: 1,
                statuses: statuses as ProductListRequest["statuses"],
              });
            }}
          />
          <Input
            allowClear
            value={isbn}
            onChange={handleInputChange}
            onBlur={() => setIsbn((prevState) => prevState?.trim())}
            onPressEnter={handleInputPressEnter}
            className={styles.isbnInput}
            prefix={<SearchOutlined />}
            placeholder="Search ISBN"
          />
          <ProductAdvancedSearch
            initialValues={{ ...query, isbn: isbn } as ProductListRequest}
            onSubmit={handleAdvancedSearch}
          />
        </Space>
        <Button type="text" shape="circle" icon={<Icons.Refresh />} onClick={handleRefresh} />
      </Row>

      <Row className={styles.tags}>
        {(query.lastModifiedDateFrom || query.lastModifiedDateTo) && (
          <Tag closable onClose={() => removeDateTag()}>
            {getDateRange(query.lastModifiedDateFrom, query.lastModifiedDateTo)}
          </Tag>
        )}
        {query.productTypes &&
          productTypeOptions
            .filter((option) => query.productTypes?.includes(option.value))
            .map((option) => (
              <Tag key={option.label} closable onClose={() => removeProductTypeTag(option.value)}>
                {option.label}
              </Tag>
            ))}
        {query.isPremium !== undefined && (
          <Tag closable onClose={() => setQuery({ ...query, isPremium: undefined, page: 1 })}>
            {query.isPremium ? "Premium" : "Standard"}
          </Tag>
        )}
        {query.languageId && (
          <Tag closable onClose={() => setQuery({ ...query, languageId: undefined, page: 1 })}>
            {languages.find((language) => language.id === query.languageId)?.name}
          </Tag>
        )}
      </Row>

      <Table<ProductModel>
        loading={{ spinning: isLoading }}
        dataSource={datasource}
        columns={columnsToDisplay}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        rowKey={(record) => record.isbn}
        onRow={handleOnRowClick}
        onChange={handleTableChange}
        pagination={{
          showTotal: getTotalDisplay,
          total: totalElements,
          showSizeChanger: true,
          current: query.page,
          pageSize: query.size,
          onChange: handlePageChange,
        }}
      />
    </Card>
  );
}

export default ProductList;
