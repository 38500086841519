import React, { createContext, useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { Button, Card, Divider, Form, Row, Space, Spin } from "antd";
import * as contentProductAPI from "services/product";
import * as eComProductAPI from "services/eComProduct";
import { LEAVE_CONFIRMATION } from "../../../../constants/common";
import AddNewPlan from "../components/AddNewPlan";
import styles from "./index.module.scss";
import modalStyles from "../../basePrice/index.module.scss";
import LanguageModel from "../../../../types/model/language";
import { EComProductInfo, EComProductLanguage, NewPlanFormFieldValues } from "../../../../types/model/eComProduct";
import ProductInfo from "./ProductInfo";
import { message } from "../../../../components";
import { convertToProductPlan } from "../../../../types/transformers/eComProduct";
import PlanList from "../PlanList";
import EditPlan from "../components/EditPlan";
import { EComModal } from "../../../../components/EComModal";

export const AddNewPlanContext = createContext<EComProductInfo | null>(null);

function EComAddNewProduct() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [allLanguages, setAllLanguages] = useState<LanguageModel[]>([]);
  const [eComLanguages, setEComLanguages] = useState<EComProductLanguage[]>([]);
  const [productInfo, setProductInfo] = useState<EComProductInfo>({} as EComProductInfo);
  const [newPlanFieldsValue, setNewPlanFieldsValue] = useState<NewPlanFormFieldValues[]>([]);

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const [formIsHalfFilledOut, setFormIsHalfFilledOut] = useState(true);

  const [actionPlan, setActionPlan] = useState<NewPlanFormFieldValues>();
  const [actionPlanIndex, setActionPlanIndex] = useState(-1);
  const [editPlanModalVisible, setEditPlanModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const productPlans = newPlanFieldsValue.map(convertToProductPlan);
  const shouldProductInfoDisabled = productPlans.length > 0;

  useEffect(() => {
    setIsLoading(true);
    Promise.all([contentProductAPI.fetchLanguagesIncludeAllAccess(), eComProductAPI.fetchProductLanguages()])
      .then(([allLanguagesResponse, eComLanguages]) => {
        setAllLanguages(allLanguagesResponse.content);
        setEComLanguages(eComLanguages);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleCancel = () => {
    history.push("/products/ecom");
  };

  const handleDelete = () => {
    setNewPlanFieldsValue((plans: NewPlanFormFieldValues[]) => plans.filter((_, i) => i !== actionPlanIndex));
    setIsDeleteModalVisible(false);
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      if (productPlans.length === 0) {
        message.warning("For new product, you need to save at lease one plan to create the product.");
      } else {
        setIsSaveLoading(true);
        eComProductAPI
          .createProduct(productInfo, productPlans)
          .then(() => {
            setFormIsHalfFilledOut(false);
            message.success("Product added successfully.");
            history.push("/products/ecom");
          })
          .finally(() => {
            setIsSaveLoading(false);
          });
      }
    });
  };

  const renderSummitAndCancelButtons = () => {
    return (
      <Row justify="end">
        <Space>
          <Button type="default" shape="round" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" shape="round" onClick={handleSubmit} loading={isSaveLoading}>
            Save
          </Button>
        </Space>
      </Row>
    );
  };

  return (
    <Card title="Add new product" extra={renderSummitAndCancelButtons()} className="page-container">
      <Spin spinning={isLoading} size={"large"}>
        <Prompt when={formIsHalfFilledOut} message={LEAVE_CONFIRMATION} />

        <ProductInfo
          form={form}
          allLanguages={allLanguages}
          eComLanguages={eComLanguages}
          onChange={(productInfo) => setProductInfo(productInfo)}
          shouldDisabled={shouldProductInfoDisabled}
        />

        <Divider className={styles.divider} />

        <AddNewPlanContext.Provider value={productInfo}>
          <section>
            <header className={styles.planHeader}>
              <p className={styles.secondTitle}>Plan</p>
              <AddNewPlan
                setNewPlanFieldsValue={setNewPlanFieldsValue}
                resetEditingPlan={() => setActionPlanIndex(-1)}
              />
            </header>
            <PlanList
              plans={newPlanFieldsValue}
              setNewPlanFieldsValue={setNewPlanFieldsValue}
              activeKey={`${actionPlanIndex >= 0 ? actionPlanIndex : newPlanFieldsValue.length - 1}`}
              onEditPlan={(detail, index) => {
                setEditPlanModalVisible(true);
                setActionPlan(detail as NewPlanFormFieldValues);
                setActionPlanIndex(index);
              }}
              onDeletePlan={(detail, index) => {
                if (productPlans) {
                  if (productPlans.length <= 1) {
                    message.error({
                      message: "For a product, at least one plan is needed.",
                    });
                  } else {
                    setIsDeleteModalVisible(true);
                    setActionPlan(detail as NewPlanFormFieldValues);
                    setActionPlanIndex(index);
                  }
                }
              }}
              onPublishPlan={(detail, index) => {}}
              onRevertToDraftPlan={(detail, index) => {}}
              onActivatePlan={(detail, index) => {}}
              onDeactivatePlan={(detail, index) => {}}
              onPreviewPlan={(detail, index) => {}}
            />
            <EditPlan
              visible={editPlanModalVisible}
              initialValues={actionPlan}
              editingIndex={actionPlanIndex}
              closeModal={() => {
                setEditPlanModalVisible(false);
                setActionPlan(undefined);
              }}
              setNewPlanFieldsValue={setNewPlanFieldsValue}
            />

            <EComModal
              visible={isDeleteModalVisible}
              title="Delete Plan"
              okText="Confirm"
              onCancel={() => {
                setIsDeleteModalVisible(false);
                setActionPlan(undefined);
              }}
              onOk={handleDelete}
              confirmLoading={false}
            >
              <p>
                Are you sure you want to delete the <span className={modalStyles.fontBold}>{actionPlan?.name}</span>?
              </p>
            </EComModal>
          </section>
        </AddNewPlanContext.Provider>
      </Spin>
    </Card>
  );
}

export default EComAddNewProduct;
