interface BasePrice {
  id: string;
  name: string;
}

export interface UpsellDowngradeOption {
  id: string;
  option_type: UpsellDowngradeType;
  base_price: BasePrice;
}

export enum UpsellDowngradeType {
  PRIMARY_UPSELL = "primary_upsell",
  SECONDARY_UPSELL = "secondary_upsell",
  DOWNGRADE = "downgrade",
}

export interface UpsellDowngradeResponse {
  id: string;
  original_base_price: BasePrice;
  created_at: string;
  updated_at: string;
  upsell_downgrade_options: UpsellDowngradeOption[];
}

export interface UpsellDowngradeListResponse {
  data: UpsellDowngradeResponse[];
  count: number;
  offset: number;
  limit: number;
}

export interface UpsellDowngradeRequest {
  original_base_price_id: string;
  product_type: string;
  course_levels?: number[];
  billing_plan_id?: string;
  upsell_downgrade_options?: UpsellOption[];
}

export interface UpsellOption {
  option_type: UpsellDowngradeType;
  base_price_id: string;
  product_type: string;
  course_levels?: number[];
  billing_plan_id?: string;
  upsell_tag?: string;
  slogan?: string;
}

export interface UpsellDowngradeDetailOption {
  id: string;
  option_type: UpsellDowngradeType;
  base_price: BasePrice;
  product_type: string;
  course_levels?: number[];
  billing_plan?: {
    id: string;
    name: string;
  };
  slogan?: string;
  upsell_tag?: string;
}

export interface UpsellDowngradeDetailResponse {
  id: string;
  created_at: string;
  updated_at: string;
  original_base_price: BasePrice;
  product_type: string;
  billing_plan?: {
    id: string;
    name: string;
  };
  course_levels?: number[];
  upsell_downgrade_options: UpsellDowngradeDetailOption[];
}
