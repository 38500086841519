import { components, operations } from "types/api";
import { api } from "./baseApi";

export const languageApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLanguages: build.query<
      components["schemas"]["PageLanguageListResponse"],
      operations["getLanguages"]["parameters"]["query"] | undefined
    >({
      query: (params) => ({ url: "/languages", params }),
    }),
  }),
});

export const { useGetLanguagesQuery } = languageApi;
