import moment from "moment";

const DATE_FORMAT = "YYYY-MM-DD";
export function getDateRange(startDate?: number, endDate?: number) {
  if (startDate && endDate) {
    return moment(startDate).format(DATE_FORMAT) + " ~ " + moment(endDate).format(DATE_FORMAT);
  } else if (startDate) {
    return moment(startDate).format(DATE_FORMAT) + " ~ *";
  } else {
    return "* ~ " + moment(endDate).format(DATE_FORMAT);
  }
}
