import React from "react";
import { compose } from "redux";
import { EMPTY } from "constants/common";
import styles from "./DisplayInput.module.scss";

interface DisplayInputProps<T> {
  value: T;
  onChange: (value: T) => void;
  render: (value: T | undefined) => React.ReactNode;
}

function DisplayInput<T = any>({ value, render = (x: any) => x }: Partial<DisplayInputProps<T>>) {
  render = compose((x: React.ReactNode) => {
    if (x === undefined || x === null || x === "") {
      return EMPTY;
    } else if (typeof x === "boolean") {
      return x ? "Yes" : "No";
    } else if (typeof x === "string") {
      return (
        <>
          {x.split("\n").map((line, idx) => (
            <div key={idx} className={styles.paragraph}>
              {line}
            </div>
          ))}
        </>
      );
    } else {
      return x;
    }
  }, render);

  return <span>{render(value)}</span>;
}

export default DisplayInput;
