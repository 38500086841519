export enum Permission {
  READ_CUSTOMERS = "read:customers",
  VIEW_B2B_CUSTOMER_MENU = "view:b2b_customer_menu",
  VIEW_B2C_CUSTOMER_MENU = "view:b2c_customer_menu",
  DELETE_CUSTOMER = "delete:customer",
  VIEW_ENTITLEMENT_MENU = "view:entitlement_menu",
  RESTORE_ENTITLEMENTS = "restore:entitlements",
  CREATE_PRODUCT = "create:product",
  VIEW_PRODUCT_MENU = "view:product_menu",
  DELETE_PRODUCT = "delete:product",
  UPDATE_PRODUCT = "update:product",
  VERIFY_PRODUCT = "verify:product",
  PUBLISH_PRODUCT = "publish:product",
  VIEW_ORGANIZATION_MENU = "view:organization_menu",
  VIEW_VOUCHER_MENU = "view:voucher_menu",
  CREATE_VOUCHER_GROUP = "create:voucher_group",
  UPDATE_VOUCHER_GROUP = "update:voucher_group",
  DELETE_VOUCHER_GROUP = "delete:voucher_group",
  VIEW_DEEPLINK_MENU = "view:deeplink_menu",
  VIEW_MINI_COURSE_MENU = "view:mini_course_menu",
  READ_MINI_COURSE = "read:mini_courses",
  VERIFY_MINI_COURSE = "verify:mini_course",
  PUBLISH_MINI_COURSE = "publish:mini_course",
  UPDATE_B2C_CUSTOMER_INFO = "update:b2c_customer_info",
  CREATE_ENTITLEMENT = "create:entitlement",
  UPDATE_ENTITLEMENT = "update:entitlement",
  REVOKE_ENTITLEMENT = "revoke:entitlement",
  UPDATE_SUBSCRIPTION = "update:subscription",
  VIEW_ECOM_PRODUCT_MENU = "view:ecom_product_menu",
  VIEW_ECOM_PRODUCT_DETAIL = "view:ecom_product_detail",
  CREATE_ECOM_PRODUCT = "create:ecom_product",
  DELETE_ECOM_PRODUCT = "delete:ecom_product",
  CREATE_ECOM_PLAN = "create:ecom_plan",
  UPDATE_ECOM_PLAN = "update:ecom_plan",
  DELETE_ECOM_PLAN = "delete:ecom_plan",
  REVERT_ECOM_PLAN_TO_DRAFT = "revert:ecom_plan_to_draft",
  READY_FOR_PUBLISH_ECOM_PLAN = "ready_for_publish:ecom_plan",
  DEACTIVATE_ECOM_PLAN = "deactivate:ecom_plan",
  ACTIVATE_ECOM_PLAN = "activate:ecom_plan",
  PREVIEW_PRODUCT_PLAN = "preview:product-plan",
  PUBLISH_ECOM_PRODUCT = "publish:ecom_product",
  VIEW_BASE_PRICE_MENU = "view:base_price_menu",
  CREATE_BASE_PRICE = "create:base_price",
  CONFIGURE_CURRENCY = "configure:currency",
  UPDATE_BASE_PRICE = "update:base_price",
  ACTIVATE_BASE_PRICE = "activate:base_price",
  DEACTIVATE_BASE_PRICE = "deactivate:base_price",
  DELETE_BASE_PRICE = "delete:base_price",
  VIEW_DISCLOSURE_MENU = "view:disclosure_menu",
  VIEW_DISCLOSURE_DETAIL = "view:disclosure_detail",
  CREATE_DISCLOSURE = "create:disclosure",
  UPDATE_DISCLOSURE = "update:disclosure",
  DELETE_DISCLOSURE = "delete:disclosure",
  SWITCH_DISCLOSURE_STATUS = "switch:disclosure_status",
  VIEW_DESCRIPTION_TEMPLATE_MENU = "view:description_template_menu",
  VIEW_DESCRIPTION_TEMPLATE_DETAIL = "view:description_template_detail",
  CREATE_DESCRIPTION_TEMPLATE = "create:description_template",
  UPDATE_DESCRIPTION_TEMPLATE = "update:description_template",
  DELETE_DESCRIPTION_TEMPLATE = "delete:description_template",
  VIEW_UPSELL_DOWNGRADE_MENU = "view:upsell_downgrade_menu",
  VIEW_UPSELL_DOWNGRADE_DETAIL = "view:upsell_downgrade_detail",
  CREATE_UPSELL_DOWNGRADE = "create:upsell_downgrade",
  UPDATE_UPSELL_DOWNGRADE = "update:upsell_downgrade",
  DELETE_UPSELL_DOWNGRADE = "delete:upsell_downgrade",
  VIEW_PROMOTION_MENU = "view:promotion_menu",
  VIEW_PROMOTION_DETAIL = "view:promotion_detail",
  CREATE_PROMOTION = "create:promotion",
  UPDATE_PROMOTION = "update:promotion",
  DELETE_PROMOTION = "delete:promotion",
  REFUND_TRANSACTION = "refund:transaction",
  UPDATE_GIFT_CARD_BALANCE = "update:gift_card_balance",
  UPDATE_GIFT = "update:gift",
  VIEW_SAVE_OFFER_MENU = "view:save_offer_menu",
  CONFIGURE_CANCEL_REASON = "configure:cancel_reason",
  VIEW_SAVE_OFFER_DETAIL = "view:save_offer_detail",
  CREATE_SAVE_OFFER = "create:save_offer",
  UPDATE_SAVE_OFFER = "update:save_offer",
  DELETE_SAVE_OFFER = "delete:save_offer",
}
