import React, { useEffect, useState } from "react";
import { Prompt } from "react-router";
import { Button, Form, Input, Radio, FormProps, Divider, Tooltip } from "antd";
import Icon, { InfoCircleOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/es/form";
import { LEAVE_CONFIRMATION, validateMessages } from "constants/common";
import { Minus, Plus } from "assets/icons";
import { span } from "utils/style";
import { OrganizationModal } from "types/model/organization";
import styles from "./OrganizationForm.module.scss";
import EditableTagGroup from "../../../components/EditableTagGroup";

export interface OrganizationFormProps extends Pick<FormProps<OrganizationModal>, "onFinish" | "onFinishFailed"> {
  data?: Partial<OrganizationModal>;
  form: FormInstance<OrganizationModal>;
}

function OrganizationForm({ data, form, ...otherProps }: OrganizationFormProps) {
  const { onFinish, onFinishFailed } = otherProps;
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    window.onbeforeunload = () => true;
    return () => {
      window.onbeforeunload = null;
    };
  });

  useEffect(() => form.resetFields(), [form, data]);

  const handleBlur = () => {
    const name = form.getFieldValue("name");
    form.setFieldsValue({
      ...form.getFieldsValue(),
      name: name && name.trim(),
      groupAdmins: form.getFieldsValue().groupAdmins?.map((item: any) => ({
        ...item,
        email: item.email && item.email.trim(),
        name: item.name && item.name.trim(),
        telephone: item.telephone && item.telephone.trim(),
      })),
    });
  };

  return (
    <>
      <Prompt when={!submitted} message={LEAVE_CONFIRMATION} />
      <Form
        {...otherProps}
        form={form}
        layout={"inline"}
        labelAlign={"left"}
        initialValues={data}
        validateMessages={validateMessages}
        validateTrigger={["onBlur", "onSubmit"]}
        onFinish={(values) => {
          setSubmitted(true);
          onFinish && onFinish(values);
        }}
        onFinishFailed={(values) => {
          setSubmitted(false);
          onFinishFailed && onFinishFailed(values);
        }}
        className={styles.form}
      >
        <Form.Item
          label="Organization name"
          name="name"
          rules={[{ required: true, type: "string", max: 100 }]}
          style={span(12)}
        >
          <Input onBlur={handleBlur} />
        </Form.Item>
        <Form.Item style={span(12)} />
        <Form.Item
          label="Allowed to create subgroup"
          name="isCreatingSubgroupEnabled"
          rules={[{ required: true }]}
          style={span(8)}
        >
          <Radio.Group
            options={[
              { value: true, label: "Yes", style: { flexGrow: 1 } },
              { value: false, label: "No", style: { flexGrow: 1 } },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Progress reporting"
          name="isAnalyticsReportingEnabled"
          rules={[{ required: true }]}
          style={span(8)}
        >
          <Radio.Group
            options={[
              { value: true, label: "Yes", style: { flexGrow: 1 } },
              { value: false, label: "No", style: { flexGrow: 1 } },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Create seat user account"
          name="isCreatingAuth0AccountForSeatUserEnabled"
          rules={[{ required: true }]}
          style={span(8)}
        >
          <Radio.Group
            options={[
              { value: true, label: "Yes", style: { flexGrow: 1 } },
              { value: false, label: "No", style: { flexGrow: 1 } },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          style={span(24)}
          className={styles.descriptionTextArea}
          rules={[{ max: 2000 }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Divider style={{ margin: "18px 0 21px 0" }} />

        <Form.List name="groupAdmins">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <React.Fragment key={index}>
                  <Form.Item
                    label={index > 0 ? "" : "Admin email"}
                    name={[field.name, "email"]}
                    fieldKey={[field.fieldKey, "email"]}
                    rules={[
                      {
                        type: "email",
                        message: "'Admin email' is not a valid email",
                      },
                      {
                        required: true,
                        message: "'Admin email' is required",
                      },
                      {
                        type: "string",
                        max: 100,
                        message: "'Admin email' cannot be longer than 100 characters",
                      },
                    ]}
                    style={span(12)}
                  >
                    <Input placeholder="Please input email" onBlur={handleBlur} />
                  </Form.Item>
                  <Form.Item
                    label={index > 0 ? "" : "Admin name"}
                    name={[field.name, "name"]}
                    fieldKey={[field.fieldKey, "name"]}
                    style={span(6)}
                    rules={[
                      {
                        message: "Must be less than 200 characters",
                        type: "string",
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please input admin name" onBlur={handleBlur} />
                  </Form.Item>
                  <Form.Item
                    label={index > 0 ? "" : "Admin phone number"}
                    name={[field.name, "telephone"]}
                    fieldKey={[field.fieldKey, "telephone"]}
                    style={span(5)}
                    rules={[
                      {
                        message: "Must be less than 32 characters",
                        type: "string",
                        max: 32,
                      },
                    ]}
                  >
                    <Input placeholder="Please input phone number" onBlur={handleBlur} />
                  </Form.Item>
                  <Form.Item label={index === 0 && " "} colon={false} style={span(1)} className={styles.minusIcon}>
                    <Icon component={Minus} onClick={() => remove(field.name)} />
                  </Form.Item>
                </React.Fragment>
              ))}
              <Button onClick={() => add()} icon={<Icon component={Plus} />} className={styles.button} type="link">
                Add Admin
              </Button>
            </>
          )}
        </Form.List>
        <Form.Item
          label={
            <>
              Allowed domains:
              {allowedDomainTip}
            </>
          }
          colon={false}
          name="allowedLoginDomains"
          style={span(24)}
        >
          <EditableTagGroup />
        </Form.Item>

        <Form.Item name="id" noStyle hidden />
      </Form>
    </>
  );
}

const allowedDomainTip = (
  <Tooltip
    title={
      <>
        Example value: <br />
        testdomain.com
      </>
    }
  >
    <InfoCircleOutlined style={{ paddingLeft: "4px" }} />
  </Tooltip>
);

export default OrganizationForm;
