import React, { forwardRef, useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { ColumnType } from "antd/lib/table";
import * as saveOfferAPI from "../../../../services/saveOffer";
import { CancelReasonModal } from "../../../../types/model/saveOffer";
import GenericConfigureTable from "../../../../components/GenericConfigureTable";
import { LocaleType } from "../../../../utils/locale";

const CancelReasonConfigureTable = forwardRef<{
  validate: () => Promise<boolean>;
  update: () => Promise<void>;
  refresh: () => void;
}>((_, ref) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [dataSource, setDataSource] = useState<CancelReasonModal[]>([]);
  const [keyCounter, setKeyCounter] = useState(0);

  useEffect(() => {
    fetchAndSetCancelReason().finally(() => {
      setIsLoading(false);
    });
  }, [form]);

  useEffect(() => {
    dataSource.forEach((item) => {
      form.setFieldsValue({ [item.key]: { description: item.description } });
    });
  }, [form, dataSource]);

  const fetchAndSetCancelReason = async () => {
    await saveOfferAPI
      .fetchCancelReason()
      .then((res) => {
        setDataSource(res);
      })
      .catch(() => {
        setDataSource([]);
      });
  };

  const checkDuplicate = async (rule: any, value: string) => {
    const existCancelReason = dataSource.reduce((acc: string[], item: any) => {
      if (item.description.en) {
        acc.push(item.description.en);
      }
      if (item.description.es) {
        acc.push(item.description.es);
      }
      return acc;
    }, []);
    const hasDuplicateCancelReason = existCancelReason.filter((item: any) => item === value).length > 1;
    if (hasDuplicateCancelReason) {
      return Promise.reject("The cancel reason already exists.");
    }
    return Promise.resolve();
  };

  const handleAdd = () => {
    const newData = [
      ...dataSource,
      {
        description: {
          en: "",
        },
        isUsed: false,
        key: `key_${keyCounter}`,
      },
    ];

    setDataSource(newData);
    setKeyCounter(keyCounter + 1);
  };

  const handleRemove = (key: string) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const handleDescriptionChange = (value: string, key: string, locale: LocaleType) => {
    const updatedDataSources = dataSource.map((cancelReason) =>
      cancelReason.key === key
        ? {
            ...cancelReason,
            description: {
              ...cancelReason.description,
              [locale]: value,
            },
          }
        : cancelReason
    );
    setDataSource(updatedDataSources);
  };

  const columns: ColumnType<CancelReasonModal>[] = [
    {
      title: "Cancel Reason(English)",
      dataIndex: ["description", "en"],
      width: 457,
      render: (text: string, record: any) => (
        <Form.Item
          style={{ margin: 0 }}
          name={[record.key, "description", LocaleType.English]}
          initialValue={text}
          rules={[
            {
              required: true,
              message: "'Cancel reason' is required",
            },
            {
              max: 100,
              message: "'Cancel reason' cannot be longer than 100 characters",
            },
            {
              validator: checkDuplicate,
            },
          ]}
        >
          <Input
            placeholder="New cancel reason"
            aria-label="description_en"
            onChange={(e) => handleDescriptionChange(e.target.value, record.key, LocaleType.English)}
          />
        </Form.Item>
      ),
    },
    {
      title: "Cancel Reason(Spanish)",
      dataIndex: ["description", "es"],
      width: 457,
      render: (text: string, record: any) => (
        <Form.Item
          style={{ margin: 0 }}
          name={[record.key, "description", LocaleType.Spanish]}
          initialValue={text}
          rules={[
            {
              max: 100,
              message: "'Cancel reason' cannot be longer than 100 characters",
            },
            {
              validator: checkDuplicate,
            },
          ]}
        >
          <Input
            placeholder="New cancel reason"
            aria-label="description_es"
            onChange={(e) => handleDescriptionChange(e.target.value, record.key, LocaleType.Spanish)}
          />
        </Form.Item>
      ),
    },
    {
      title: "Action",
      width: 100,
      fixed: "right",
      render: (text: string, record: any) => (
        <Button
          type="link"
          size="small"
          aria-label="delete"
          disabled={record.isUsed}
          onClick={() => {
            handleRemove(record.key);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <GenericConfigureTable
      ref={ref}
      form={form}
      updateData={() => saveOfferAPI.updateCancelReason(dataSource)}
      successMessage="Cancel reason updated successfully."
      refreshData={fetchAndSetCancelReason}
      isLoading={isLoading}
      dataSource={dataSource}
      columns={columns}
      handleAdd={handleAdd}
      buttonTitle="Add new cancel reason"
    />
  );
});

export default CancelReasonConfigureTable;
