import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { join } from "lodash";
import { Auth0Provider } from "@auth0/auth0-react";
import "antd/dist/antd.less";

import App from "./components/App";
import { store } from "./app/store";
import "./styles/index.scss";
import { Permission } from "./types/permission";

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_AUDIENCE } = process.env;

const scopes = [
  "read:customers",
  "delete:customer",
  "read:entitlements",
  "restore:entitlements",
  "read:products",
  "create:product",
  "delete:product",
  "update:product",
  "verify:product",
  "publish:product",
  "read:organizations",
  "read:voucher_groups",
  "create:voucher_group",
  "update:voucher_group",
  "delete:voucher_group",
  "view:mini_course_menu",
  "read:mini_courses",
  "verify:mini_course",
  "publish:mini_course",
  ...Object.values(Permission),
];

render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={REACT_APP_AUTH0_DOMAIN}
        clientId={REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        audience={REACT_APP_AUTH0_AUDIENCE}
        scope={join(Array.from(new Set(scopes)), " ")}
        cacheLocation="localstorage"
      >
        <App />
      </Auth0Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
