import { EMPTY } from "constants/common";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./DisplayCopyInput.module.scss";
import { Copy } from "../../assets/icons";

interface DisplayCopyInputProps {
  value?: string;
  textStyle?: React.CSSProperties;
}

function DisplayCopyInput({ value, textStyle }: DisplayCopyInputProps) {
  const [copied, setCopied] = useState(false);

  const text = value ?? EMPTY;

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (copied) {
      timeoutId = setTimeout(() => {
        setCopied(false);
      }, 2000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [copied]);

  const handleCopy = () => {
    setCopied(true);
  };

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <div className={styles.container}>
        <span className={styles.text} style={{ ...textStyle, whiteSpace: "normal" }}>
          {text}
        </span>
        <Tooltip title={"Copied"} visible={copied}>
          <Copy />
        </Tooltip>
      </div>
    </CopyToClipboard>
  );
}

export default DisplayCopyInput;
