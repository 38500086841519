import React from "react";
import styles from "./index.module.scss";

export default function ModalListItem({ label, value }: { label: string; value: string }) {
  return (
    <div className={styles.modal__list__item}>
      <span>{label}</span>
      <span>{value}</span>
    </div>
  );
}
