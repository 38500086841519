import React from "react";
import { Form } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import Icon from "@ant-design/icons";
import { useHistory } from "react-router";
import moment from "moment/moment";
import { message, Modal, ScheduledPublish } from "../index";
import { Success } from "../../assets/icons";
import { useAppDispatch } from "../../app/store";
import {
  publishMiniCourse,
  removeSchedule,
  scheduledPublishMiniCourse,
} from "../../features/miniCourse/miniCourseSlice";
import styles from "./MiniCourseVerification.module.scss";

export const usePublishMiniCourse = () => {
  const [scheduleForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleDeleteSchedule = (id: number) => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure you want to stop the schedule publish?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        dispatch(removeSchedule(id))
          .then(unwrapResult)
          .then(() => history.go(0))
          .catch(message.error);
      },
    });
  };

  const handlePublish = (id: number, scheduledPublishTime?: number) => {
    Modal.confirm({
      title: "Confirm",
      content: <ScheduledPublish form={scheduleForm} initialPublishTime={scheduledPublishTime} isMiniCourse={true} />,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () =>
        scheduleForm.validateFields().then(() => {
          const { publishNow, scheduledPublishTime } = scheduleForm.getFieldsValue();
          if (publishNow) {
            dispatch(publishMiniCourse(id))
              .then(unwrapResult)
              .then(() => {
                Modal.info({
                  className: styles.modal,
                  title: "Publish Successful",
                  content: "The mini course is published successfully!",
                  icon: <Icon component={Success} />,
                  okButtonProps: { shape: "round" },
                  onOk: () => {
                    history.go(0);
                  },
                });
              })
              .catch((err) => {
                message.error(err).then(() => {
                  history.go(0);
                });
              });
          } else {
            dispatch(
              scheduledPublishMiniCourse({
                id,
                scheduledPublishTime: moment(scheduledPublishTime).valueOf(),
              })
            )
              .then(unwrapResult)
              .then(() => {
                history.go(0);
              })
              .catch((err) => {
                message.error(err).then(() => {
                  history.go(0);
                });
              });
          }
        }),
    });
  };

  return { handlePublish, handleDeleteSchedule };
};
