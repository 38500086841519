import React from "react";
import { Form, Tabs } from "antd";
import styles from "./DescriptionAndDisclosureContentView.module.scss";
import { supportLocalTabs } from "../../../../utils/locale";
import { DisplayInput } from "../../../../components";
import DescriptionContentInput from "../../../products/DescriptionTemplate/DescriptionDetails/DescriptionContentInput";
import DescriptionContentDescription from "../../../products/DescriptionTemplate/DescriptionDetails/DescriptionContentDescription";

export default function DescriptionAndDisclosureContentView() {
  return (
    <div className={styles.tabCardContainer}>
      <Tabs type="card">
        {supportLocalTabs?.map((item) => (
          <Tabs.TabPane tab={item.label} key={item.key}>
            <Form.Item label="Description Content" name={`${item.locale}DescriptionContent`}>
              <DisplayInput render={(x) => <DescriptionContentInput editable={false} value={x} />} />
            </Form.Item>
            <Form.Item label="Disclosure Content" name={`${item.locale}DisclosureContent`}>
              <DisplayInput render={(x) => <DescriptionContentInput editable={false} value={x} />} />
            </Form.Item>
            <DescriptionContentDescription isPromotionContentDescription={true} isTemplateDescription={true} />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}
