import { ProductDetailResponse } from "../types/dto/response/product";

export const getCourseLevels = ({ productType, course, bundledProducts }: ProductDetailResponse): number[] => {
  switch (productType) {
    case "SINGLE_LEVEL":
      return [course!.courseLevel];
    case "MULTI_LEVEL":
      return bundledProducts!.map((bundledProduct) => bundledProduct.course!.courseLevel).sort((a, b) => a - b);
    default:
      return [];
  }
};
