import { DeltaStatic } from "quill";

export function parseContent(content: string) {
  let contentObj: DeltaStatic;

  try {
    contentObj = JSON.parse(content);
  } catch (e) {
    contentObj = {} as DeltaStatic;
  }

  return contentObj;
}

// https://github.com/quilljs/quill/blob/0b383a4d536945fc071fc1e6023ae32e598e49a0/packages/quill/src/core/editor.ts#L211
// copy from official code
// parse delta object to get text
export function getText(content: string): string {
  const delta = parseContent(content);

  return (delta.ops ?? [])
    .filter((op) => typeof op.insert === "string")
    .map((op) => op.insert)
    .join("");
}

// https://stackoverflow.com/a/75117204/12814009
export function isDeltaEmpty(delta: DeltaStatic) {
  if (!delta || !delta.ops || delta.ops.length === 0) {
    return true;
  }

  for (let i = 0; i < delta.ops.length; i++) {
    if (delta.ops[i].insert.trim() !== "") {
      return false;
    }
  }
  return true;
}
