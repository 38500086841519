import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Breadcrumbs, Footer, NotFound, PrivateRoute } from "components";
import { Customer } from "features/customer";
import EntitlementPage from "features/entitlements";
import Organization from "features/organization";
import Voucher from "features/voucher";
import Deeplink from "features/deeplink";
import { Permission } from "types/permission";
import useMenu from "hooks/useMenu";
import MiniCoursePage from "../../features/miniCourse";
import Product from "../../features/products";
import Marketing from "../../features/marketing";

function Content() {
  const { defaultPath } = useMenu();

  return (
    <main>
      <section>
        <Breadcrumbs />
      </section>
      <Switch>
        <Route exact path="/">
          <Redirect to={defaultPath!} />
        </Route>

        <PrivateRoute path="/customers" permission={Permission.READ_CUSTOMERS}>
          <Customer />
        </PrivateRoute>

        <PrivateRoute path="/entitlements" permission={Permission.VIEW_ENTITLEMENT_MENU}>
          <EntitlementPage />
        </PrivateRoute>

        <PrivateRoute path="/products" permission={Permission.VIEW_PRODUCT_MENU}>
          <Product />
        </PrivateRoute>

        <PrivateRoute path="/marketing" permission={Permission.VIEW_UPSELL_DOWNGRADE_MENU}>
          <Marketing />
        </PrivateRoute>

        <PrivateRoute path="/organization" permission={Permission.VIEW_ORGANIZATION_MENU}>
          <Organization />
        </PrivateRoute>

        <PrivateRoute path="/vouchers" permission={Permission.VIEW_VOUCHER_MENU}>
          <Voucher />
        </PrivateRoute>

        <PrivateRoute path="/deeplink" permission={Permission.VIEW_DEEPLINK_MENU}>
          <Deeplink />
        </PrivateRoute>

        <PrivateRoute path="/mini-courses" permission={Permission.VIEW_MINI_COURSE_MENU}>
          <MiniCoursePage />
        </PrivateRoute>

        <Route component={NotFound} />
      </Switch>
      <Footer />
    </main>
  );
}

export default Content;
