const DESCEND = "descend";
const ASCEND = "ascend";

interface updateSortOrderType {
  [key: string]: string;
}

export const updateSortOrder: updateSortOrderType = {
  [DESCEND]: "-updated_at",
  [ASCEND]: "updated_at",
};
