import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Prompt, useHistory } from "react-router-dom";
import { Button, ConfigProvider, Divider, Form, Input, Radio, Row, Space, Spin, Tabs } from "antd";
import { invert, join, keyBy } from "lodash";
import { LEAVE_CONFIRMATION, PRODUCT_TYPE_VIEW_MAPPING, validateMessages } from "constants/common";
import { RootState, useAppDispatch } from "app/store";
import {
  COURSE_TYPE,
  COURSES_FOR_SINGLE_LEVEL,
  isProductTypeCapital,
  PRODUCT_TYPE_FOR_ADD,
  ProductType,
} from "types/model/product";
import { FetchOptionParams } from "types/model/itemsForAddProduct";
import { preventNonNumericalInput } from "utils/preventNonNumericalInput";
import InputNumber from "components/InputNumber";
import { message } from "components";
import { fetchLanguages } from "../../productSlice";
import ProductUpsellForm from "../ProductUpsellForm";
import LanguageSelect from "../LanguageSelect";
import CourseLevel from "./CourseLevel";
import { cleanup, createProduct, fetchSelectionItems } from "./addProductSlice";
import ProductDeeplinkForm from "../ProductDetail/ProductDeeplink/ProductDeeplinkForm";

import styles from "./AddNewProduct.module.scss";

function AddNewProduct() {
  const history = useHistory();
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const { resetFields } = form;
  const { languages } = useSelector((state: RootState) => state.products);
  const { singleLevelProducts } = useSelector((state: RootState) => state.addProduct);
  const isLoading = useSelector((state: RootState) => state.loading);

  const languagesMap = keyBy(languages, "id");
  const singleLevelCourseMap = keyBy(COURSES_FOR_SINGLE_LEVEL, "courseLevelValue");
  const dispatch = useAppDispatch();
  const [disabledCourseType, setDisabledCourseType] = useState(false);
  const [disabledPrice, setDisabledPrice] = useState(false);
  const [formIsHalfFilledOut, setFormIsHalfFilledOut] = useState(true);
  const [productType, setProductType] = useState(PRODUCT_TYPE_FOR_ADD.SINGLE_LEVEL);
  const isAllAccess = productType === PRODUCT_TYPE_FOR_ADD.All_ACCESS;

  const initialParams: FetchOptionParams = {
    isPremium: false,
    productType: "SINGLE_LEVEL",
  };
  const [params, setParams] = useState(initialParams);

  useEffect(() => {
    window.onbeforeunload = () => (formIsHalfFilledOut ? LEAVE_CONFIRMATION : undefined);
    return () => {
      window.onbeforeunload = null;
    };
  });

  useEffect(() => {
    dispatch(fetchLanguages());
    // reset addProductSlice's state when unmounting
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    const needResetFields = [
      "courseLevel",
      "lessonRange",
      "lessons",
      "learnName",
      "webUpsellPid",
      ["newCourse", "level"],
      ["newCourse", "totalLessons"],
      ["newCourse", "version"],
      "bundledProductIds",
      ["selectedCourse", "id"],
      ["selectedCourse", "lessonStart"],
      ["selectedCourse", "lessonEnd"],
      "premiumISBN",
      "productUpsells",
      "upgradeId",
    ];
    if (!isAllAccess) {
      needResetFields.push("upsellName");
      needResetFields.push("upsellDescription");
    }
    resetFields(needResetFields);
    if (params.languageId) {
      dispatch(fetchSelectionItems(params)).then(unwrapResult).catch(message.error);
    }
  }, [dispatch, params, resetFields]);

  useEffect(() => {
    if (isAllAccess) {
      form.setFieldsValue({
        upsellName: "All Access Subscription",
        upsellDescription:
          "• Full access to 51 available languages\n" +
          "• Share with 3 household members\n" +
          "• Access to companion web-based app for computers",
      });
    }
  }, [productType]);

  const handleCancel = () => {
    history.push("/products");
  };

  const handleSubmit = () => {
    form.validateFields().then((fieldsValue) => {
      const { type, premiumISBN, deepLinkPromotion } = fieldsValue;
      if (type === PRODUCT_TYPE_FOR_ADD.UPGRADE && premiumISBN) {
        fieldsValue.bundledProductIds = [premiumISBN];
      }
      if (deepLinkPromotion) {
        fieldsValue.deepLinkPromotion = {
          promotionTitle: deepLinkPromotion.promotionTitle?.trim(),
          promotionContent: deepLinkPromotion.promotionContent?.trim(),
          promotionComment: deepLinkPromotion.promotionComment?.trim(),
        };
      }
      if (isAllAccess) {
        fieldsValue.isPremium = true;
      }
      dispatch(createProduct(fieldsValue))
        .then(unwrapResult)
        .then(() => {
          setFormIsHalfFilledOut(false);
          history.push("/products");
        })
        .catch(message.error);
    });
  };

  const handleProductTypeChange = () => {
    const newProductType = form.getFieldValue("type");
    setProductType(newProductType);

    if (newProductType === PRODUCT_TYPE_FOR_ADD.FREE) {
      setDisabledPrice(true);
      form.setFieldsValue({ price: { amount: 0 } });
    } else {
      setDisabledPrice(false);
      form.resetFields([["price", "amount"]]);
    }

    if (newProductType === PRODUCT_TYPE_FOR_ADD.LESSON_PACK) {
      form.setFieldsValue({ isPremium: false });
      setDisabledCourseType(true);
    } else if (newProductType === PRODUCT_TYPE_FOR_ADD.UPGRADE || newProductType === PRODUCT_TYPE_FOR_ADD.All_ACCESS) {
      form.setFieldsValue({ isPremium: true });
      setDisabledCourseType(true);
    } else {
      setDisabledCourseType(false);
    }
    setParams((prevState) => ({
      ...prevState,
      productType: isProductTypeCapital(newProductType)
        ? (invert(PRODUCT_TYPE_VIEW_MAPPING)[newProductType] as ProductType)
        : newProductType,
      isPremium: form.getFieldValue("isPremium"),
    }));
  };

  const handleLanguageChange = () => {
    const { languageId } = form.getFieldsValue();
    if (isAllAccess) {
      form.validateFields(["languageId"]);
    }
    setParams((prevState) => ({
      ...prevState,
      languageId: languageId,
    }));
  };

  const handleCourseTypeChange = () => {
    const { isPremium } = form.getFieldsValue();
    setParams((prevState) => ({
      ...prevState,
      isPremium: isPremium,
    }));
  };

  const setFieldsDefaultValues = () => {
    let { languageId } = form.getFieldsValue();
    if (languageId) {
      const languageName = languagesMap[languageId].name;
      form.setFieldsValue({
        upsellName: getUpsellName(languageName),
        learnName: getLearnName(languageName),
        upsellDescription: getUpsellDescription(),
      });
    }
  };

  const getUpsellName = (languageName: string) => {
    const { type, isPremium } = form.getFieldsValue();
    let courseNameUpsellValue = "";

    switch (type) {
      case PRODUCT_TYPE_FOR_ADD.SINGLE_LEVEL:
        const { newCourse } = form.getFieldsValue();
        if (newCourse && newCourse.level) {
          courseNameUpsellValue = join([languageName, singleLevelCourseMap[newCourse.level].courseLevelName], " ");
          if (isPremium) {
            courseNameUpsellValue = join([courseNameUpsellValue, COURSE_TYPE.PREMIUM], " ");
          }
        }
        break;
      case PRODUCT_TYPE_FOR_ADD.FREE:
        courseNameUpsellValue = languageName;
        break;
      case PRODUCT_TYPE_FOR_ADD.SUBSCRIPTION:
        courseNameUpsellValue = languageName;
        if (isPremium) {
          courseNameUpsellValue = join([courseNameUpsellValue, COURSE_TYPE.PREMIUM], " ");
        }
        courseNameUpsellValue = join([courseNameUpsellValue, type], " ");
        break;
      case PRODUCT_TYPE_FOR_ADD.MULTI_LEVEl:
        const { bundledProductIds } = form.getFieldsValue();
        let selectedLevelLength = bundledProductIds.length;
        if (selectedLevelLength > 0) {
          courseNameUpsellValue = join([languageName, `Levels 1-${selectedLevelLength}`], " ");
          if (isPremium) {
            courseNameUpsellValue = join([courseNameUpsellValue, COURSE_TYPE.PREMIUM], " ");
          }
        }
        break;
      case PRODUCT_TYPE_FOR_ADD.LESSON_PACK:
        const { selectedCourse } = form.getFieldsValue();
        const { id, lessonStart, lessonEnd } = selectedCourse;
        const selectedProduct = singleLevelProducts.find((product) => product.course.courseId === id);
        if (selectedProduct) {
          courseNameUpsellValue = selectedProduct.course.courseName;
        }
        if (lessonStart && lessonEnd) {
          courseNameUpsellValue = join([courseNameUpsellValue, `Lessons ${lessonStart}-${lessonEnd}`], " ");
        }
        break;
      default:
        courseNameUpsellValue = "";
    }
    return courseNameUpsellValue;
  };

  const getLearnName = (languageName: string) => {
    const { type, newCourse, selectedCourse } = form.getFieldsValue();
    if (type === PRODUCT_TYPE_FOR_ADD.SINGLE_LEVEL && newCourse && newCourse.level) {
      return join([languageName, singleLevelCourseMap[newCourse.level].courseLevelName], " ");
    }
    if (type === PRODUCT_TYPE_FOR_ADD.LESSON_PACK && selectedCourse && selectedCourse.id) {
      const selectedProduct = singleLevelProducts.find((product) => product.course.courseId === selectedCourse.id);
      if (selectedProduct) {
        return selectedProduct.course.courseName;
      }
    } else if (type === PRODUCT_TYPE_FOR_ADD.FREE) {
      return join([languageName, "Level 1"], " ");
    }
    return null;
  };

  const getUpsellDescription = () => {
    const { type, isPremium, bundledProductIds } = form.getFieldsValue();
    let descriptionValue = "";
    let courseLevelLength = 0;
    let totalLesson = 0;
    if (bundledProductIds) {
      courseLevelLength = bundledProductIds.length;
      totalLesson = courseLevelLength * 30;
    }
    switch (type) {
      case PRODUCT_TYPE_FOR_ADD.SINGLE_LEVEL:
        const { newCourse } = form.getFieldsValue();
        let total = 30;
        if (newCourse && newCourse.totalLessons) {
          total = newCourse.totalLessons;
        }
        if (isPremium) {
          descriptionValue = `The complete level of ${total} lessons and access to all premium features`;
        } else {
          descriptionValue = `The complete level of ${total} lessons`;
        }
        break;
      case PRODUCT_TYPE_FOR_ADD.SUBSCRIPTION:
        if (totalLesson) {
          descriptionValue =
            `• Unlimited access to all ${totalLesson} lessons\n` +
            `• Small monthly fee is easy on wallet\n` +
            `• Full course access today!`;
        }
        break;
      case PRODUCT_TYPE_FOR_ADD.LESSON_PACK:
        descriptionValue = `5 thirty-minute language lessons`;
        break;
      case PRODUCT_TYPE_FOR_ADD.MULTI_LEVEl:
        if (courseLevelLength && isPremium) {
          descriptionValue = `${courseLevelLength} complete levels totaling ${totalLesson} lessons and access to all premium features`;
        }
        break;
      default:
        descriptionValue = "";
    }
    return descriptionValue;
  };

  const handleBlur = () => {
    const isbn = form.getFieldValue("isbn");
    const upsellName = form.getFieldValue("upsellName");
    const learnName = form.getFieldValue("learnName");
    form.setFieldsValue({
      ...form?.getFieldsValue(),
      isbn: isbn && isbn.trim(),
      upsellName: upsellName && upsellName.trim(),
      learnName: learnName && learnName.trim(),
    });
  };

  return (
    <Spin spinning={isLoading} size={"large"}>
      <Prompt when={formIsHalfFilledOut} message={LEAVE_CONFIRMATION} />
      <ConfigProvider form={{ validateMessages }}>
        <Form
          className={styles.form}
          form={form}
          validateTrigger={"onBlur"}
          initialValues={{ type: "Single level", isPremium: false }}
        >
          <Row justify={"space-between"} align={"bottom"}>
            <h2>Add new product</h2>
            <Row justify={"end"}>
              <Space>
                <Button type="default" shape="round" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="primary" shape="round" onClick={handleSubmit}>
                  Save
                </Button>
              </Space>
            </Row>
          </Row>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Product info" key={1} className={styles.tab}>
              <section>
                <Form.Item label="Product type" name="type" rules={[{ required: true }]} className={styles.bigFormItem}>
                  <Radio.Group onChange={handleProductTypeChange}>
                    <Space size={60}>
                      {Object.values(PRODUCT_TYPE_FOR_ADD).map((type) => (
                        <Radio value={type} key={type}>
                          {type}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </section>
              <Divider />
              <section>
                <Space direction={"vertical"} size={0}>
                  <Space size={32}>
                    <Form.Item
                      label="ISBN"
                      name="isbn"
                      required
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!value) {
                              return Promise.reject("'ISBN' is required");
                            } else if (value.length < 13 || value.length > 40) {
                              return Promise.reject("The character length needs to be between 13 and 40");
                            } else if (value.substring(0, 3) !== "978") {
                              return Promise.reject("'ISBN' must start with '978'");
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                      className={styles.smallFormItem}
                    >
                      <Input onBlur={handleBlur} />
                    </Form.Item>
                    <Form.Item
                      label="Language"
                      name="languageId"
                      rules={[{ required: true }]}
                      className={styles.smallFormItem}
                    >
                      <LanguageSelect
                        productType={productType}
                        onChange={handleLanguageChange}
                        onClear={() => dispatch(cleanup())}
                      />
                    </Form.Item>
                    {!isAllAccess && (
                      <Form.Item
                        label="Course type"
                        name="isPremium"
                        rules={[{ required: true }]}
                        className={styles.smallFormItem}
                      >
                        <Radio.Group disabled={disabledCourseType} onChange={handleCourseTypeChange}>
                          <Space size={50}>
                            <Radio value={false}>Standard</Radio>
                            <Radio value={true}>Premium</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    )}
                  </Space>
                  <Form.Item noStyle>
                    <CourseLevel form={form} setFieldsDefaultValues={setFieldsDefaultValues} />
                  </Form.Item>
                </Space>
              </section>
              <Divider />
              <section>
                <Space size={32}>
                  <Form.Item
                    label="Course name - Upsell card & Library"
                    name="upsellName"
                    className={styles.formItem}
                    rules={[{ type: "string", max: 500 }]}
                  >
                    <Input onBlur={handleBlur} />
                  </Form.Item>
                  <Form.Item
                    label="Course name - Learn page"
                    name="learnName"
                    className={styles.formItem}
                    rules={[{ type: "string", max: 500 }]}
                  >
                    <Input onBlur={handleBlur} />
                  </Form.Item>
                </Space>
                <Form.Item
                  label="Course description - Upsell card"
                  name="upsellDescription"
                  rules={[{ type: "string", max: 2000 }]}
                  className={styles.textArea}
                >
                  <Input.TextArea />
                </Form.Item>
                <Space size={32}>
                  <Form.Item
                    label="Price"
                    name={["price", "amount"]}
                    rules={[{ required: true, type: "number", min: 0 }]}
                    className={styles.smallFormItem}
                  >
                    <InputNumber disabled={disabledPrice} onKeyPress={preventNonNumericalInput} />
                  </Form.Item>
                  <Form.Item
                    label="Currency"
                    name={["price", "currency"]}
                    className={styles.smallFormItem}
                    initialValue={"USD"}
                    rules={[{ type: "enum", enum: ["USD"] }]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Space>
                <Form.Item
                  label="Note"
                  name="notes"
                  rules={[{ type: "string", max: 2000 }]}
                  className={styles.textArea}
                >
                  <Input.TextArea />
                </Form.Item>
              </section>
            </TabPane>
            <TabPane tab="Upsell & Upgrade" key={2} style={{ marginLeft: "16px" }} className={styles.tab}>
              <ProductUpsellForm form={form} />
            </TabPane>
            <TabPane tab="Deeplink" key={3} style={{ marginLeft: "16px" }} className={styles.tab}>
              <ProductDeeplinkForm isCreate={true} />
            </TabPane>
          </Tabs>
        </Form>
      </ConfigProvider>
    </Spin>
  );
}

export default AddNewProduct;
