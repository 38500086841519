import React from "react";
import { Provider } from "react-redux";
import { Modal } from "../index";
import { store, useAppDispatch } from "../../app/store";
import { cleanMiniLessonLogsData } from "../../features/miniCourse/miniCourseSlice";
import MiniCourseLogsTable from "../../features/miniCourse/MiniCourseLogsTable";

const useMiniCourseViewLog = (id: number, miniCourseName: string | null) => {
  const dispatch = useAppDispatch();

  const handleViewLog = () => {
    Modal.confirm({
      title: `Log of ${miniCourseName}`,
      content: (
        <Provider store={store}>
          <MiniCourseLogsTable id={id} />
        </Provider>
      ),
      okButtonProps: { style: { display: "none" } },
      cancelButtonProps: { style: { display: "none" } },
      width: 1024,
      bodyStyle: { height: "700px" },
      onCancel: () => {
        dispatch(cleanMiniLessonLogsData());
      },
    });
  };
  return [handleViewLog];
};

export default useMiniCourseViewLog;
