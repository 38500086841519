import React from "react";
import { Button } from "antd";
import moment from "moment";
import type { ColumnType } from "antd/es/table";
import type { SortOrder } from "antd/lib/table/interface";

import type { Entity } from "../types";
import { DATETIME_FORMAT, EMPTY, ENTITLEMENT_SOURCE_MAP, ENTITLEMENT_STATUS_MAP } from "../../../../constants/common";
import useEntitlements from "./useEntitlements";

function useColumns(query?: ReturnType<typeof useEntitlements>["query"]): ColumnType<Entity>[] {
  return [
    {
      title: "Product ISBN",
      dataIndex: "isbn",
      width: "13%",
      render: (value) => (
        <Button
          type="link"
          style={{ padding: 0, height: "auto" }}
          onClick={() => {
            //  TODO: navigation
          }}
        >
          {value || EMPTY}
        </Button>
      ),
    },
    {
      title: "Customer email",
      dataIndex: "customerEmail",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Language",
      dataIndex: "languageName",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Source",
      dataIndex: "source",
      width: "12%",
      ellipsis: true,
      render: (value) => ENTITLEMENT_SOURCE_MAP.get(value) || EMPTY,
    },
    {
      title: "Purchase type",
      dataIndex: "isSubscription",
      width: "12%",
      ellipsis: true,
      render: (value: boolean) => (value ? "Subscription" : "One-shot"),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "9%",
      ellipsis: true,
      render: (value) => ENTITLEMENT_STATUS_MAP.get(value) || EMPTY,
    },
    {
      title: "Created at",
      dataIndex: "createdDate",
      width: "17%",
      ellipsis: true,
      defaultSortOrder: "descend",
      sortOrder: query?.order as SortOrder,
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (value) => (value ? moment(value).format(DATETIME_FORMAT) : EMPTY),
    },
  ];
}

export default useColumns;
