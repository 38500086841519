import { merge } from "object-mapper";
import { join, omit } from "lodash";
import { ProductDetailResponse } from "types/dto/response/product";
import ProductModel from "types/model/product";
import { ProductUpdateRequest } from "types/dto/request/product";

class ProductMapper {
  private static detailResponseToModelMap = {
    upsellCourseName: "upsellName",
    learnCourseName: "learnName",
    upsellCourseDesc: "upsellDescription",
    promotionTitle: "deepLinkPromotion.promotionTitle",
    promotionContent: "deepLinkPromotion.promotionContent",
    promotionComment: "deepLinkPromotion.promotionComment",
    lessonRange: {
      key: "lessonRange",
      transform: (_: string, srcObj: ProductDetailResponse) => ({
        from: srcObj.lessonFrom,
        to: srcObj.lessonTo,
      }),
    },
  };

  /**
   * ProductModalAttr: RequestModalAttr
   */
  private static modelToProductUpdateRequestMap = {
    isbn: "isbn",
    upsellName: "upsellName",
    learnName: "learnName",
    upsellDescription: "upsellDescription",
    price: "price",
    notes: "notes",
    productUpsells: "productUpsells",
    upgradeId: "upgradeId",
    upgradeEcomPid: "upgradeEcomPid",
    upgradePromotionCode: "upgradePromotionCode",
    deepLinkPromotion: "deepLinkPromotion",
    isUpsellSupportedByNami: "isUpsellSupportedByNami",
    isDeeplinkSupportedByNami: "isDeeplinkSupportedByNami",
    isEffectiveImmediately: "isEffectiveImmediately",
    namiGroups: "namiGroups",
  };

  static toProductModel(src: ProductDetailResponse) {
    let dest = (omit(src, Object.keys(this.detailResponseToModelMap)) as unknown) as ProductModel;
    dest = merge<ProductModel>(src, dest, this.detailResponseToModelMap);

    switch (src.productType) {
      case "UPGRADE":
        const premiumProduct = src.bundledProducts![0];
        dest.premiumISBN = {
          label: `${premiumProduct.isbn} ${premiumProduct.name}`,
          value: premiumProduct.id!,
        };
        break;
      case "MULTI_LEVEL":
      case "SUBSCRIPTION":
      case "UPSELL":
        const courseName = dest
          .bundledProducts!.map((bundledProduct) =>
            join([bundledProduct.isbn, bundledProduct?.course?.courseName, bundledProduct?.course?.courseVersion], " ")
          )
          .sort();
        const courseId = dest.bundledProducts!.map((bundledProduct) => bundledProduct.id);
        dest.course = { courseId, courseName };
        break;
    }
    return dest;
  }

  static toProductUpdateRequest(src: ProductModel) {
    let dest = merge(src, {} as ProductUpdateRequest, this.modelToProductUpdateRequestMap);
    dest.isEffectiveImmediately = src.isEffectiveImmediately ?? false;

    switch (src.productType) {
      case "SINGLE_LEVEL":
        dest.newCourse = {
          level: src.course?.courseLevel!,
          totalLessons: src.lessonRange.to!,
          version: src.course?.courseVersion!,
        };
        break;
      case "SUBSCRIPTION":
      case "MULTI_LEVEL":
        dest.bundledProductIds = src.course?.courseId as number[];
        break;
      case "LESSON_PACK":
      case "FREE":
        dest.selectedCourse = {
          id: src.course?.courseId as number,
          lessonStart: src.lessonRange.from!,
          lessonEnd: src.lessonRange.to!,
        };
        break;
      case "UPGRADE":
        dest.bundledProductIds = [src.premiumISBN?.value!];
        break;
    }

    return dest;
  }
}

export default ProductMapper;
