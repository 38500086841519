import React, { useEffect } from "react";
import moment, { Moment } from "moment";
import { Button, Form, FormInstance, Input, Select } from "antd";
import { LabeledValue } from "antd/es/select";
import { FormProps } from "antd/es";
import { Connection } from "types/dto/response/customer";
import { DropdownInput, RangePicker } from "components";
import { DropDown } from "../../../assets/icons";
import styles from "./AdvancedFilters.module.scss";
import { connectionTypeOptions } from "../../../constants/common";

type FormValues = {
  name?: string;
  connections?: Connection[];
  email?: string;
  auth0Id?: string;
  organizationId?: number;
  createdDateFrom?: number;
  createdDateTo?: number;
  createdDate?: [Moment | undefined, Moment | undefined];
};

export type Values = Omit<FormValues, "createdDate">;

export interface AdvancedFiltersProps {
  form: FormInstance;
  initialValues: Values;
  tab: "B2B" | "B2C";
  visible: boolean;
  organizations?: LabeledValue[];
  onFinish: FormProps<Values>["onFinish"];
  onCancel: () => void;
}

function AdvancedFilters({
  form,
  tab,
  organizations,
  visible,
  initialValues,
  onFinish,
  onCancel,
}: AdvancedFiltersProps) {
  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  });

  const { createdDateFrom, createdDateTo } = initialValues;

  const handleBlur = () => {
    const name = form.getFieldValue("name");
    const email = form.getFieldValue("email");
    const auth0Id = form.getFieldValue("auth0Id");
    form.setFieldsValue({
      ...form?.getFieldsValue(),
      name: name && name.trim(),
      email: email && email.trim(),
      auth0Id: auth0Id && auth0Id.trim(),
    });
  };

  return (
    <Form<FormValues>
      form={form}
      initialValues={{
        ...initialValues,
        createdDate: [
          createdDateFrom ? moment(createdDateFrom) : undefined,
          createdDateTo ? moment(createdDateTo) : undefined,
        ],
      }}
      onFinish={({ email, name, auth0Id, createdDate, ...values }) => {
        onFinish &&
          onFinish({
            email: email?.toString(),
            name: name?.toString(),
            auth0Id: auth0Id?.toString(),
            ...values,
          });
      }}
    >
      <div className={styles.form}>
        <Form.Item name={"createdDateFrom"} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={"createdDateTo"} hidden>
          <Input />
        </Form.Item>

        <Form.Item label="Created at:" name={"createdDate"}>
          <RangePicker
            onChange={([start, end]) =>
              form.setFieldsValue({
                createdDateFrom: start?.valueOf(),
                createdDateTo: end?.valueOf(),
              })
            }
          />
        </Form.Item>
        {tab === "B2C" && (
          <Form.Item label="Connection:" name="connections">
            <DropdownInput width={392} options={connectionTypeOptions} placeholder="All Connections" />
          </Form.Item>
        )}
        {tab === "B2B" && (
          <Form.Item label="Organization:" name="organizationId">
            <Select
              allowClear
              showSearch={true}
              listHeight={160}
              suffixIcon={<DropDown />}
              placeholder={"Please choose organization"}
              optionFilterProp={"label"}
              options={organizations}
            />
          </Form.Item>
        )}
        <Form.Item label="Customer name:" name="name">
          <Input onBlur={handleBlur} type="string" placeholder={"Please enter customer name"} />
        </Form.Item>
        <Form.Item label="Customer email:" name="email">
          <Input onBlur={handleBlur} placeholder={"Please enter customer email"} />
        </Form.Item>
        <Form.Item label="Customer Auth0 Id" name="auth0Id">
          <Input onBlur={handleBlur} placeholder={"Please enter customer Auth0 Id"} />
        </Form.Item>
      </div>

      <div className={styles.buttonGroup}>
        <Button type="default" shape="round" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" shape="round" htmlType="submit">
          Search
        </Button>
      </div>
    </Form>
  );
}

export default AdvancedFilters;
