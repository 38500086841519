import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import DeeplinkList from "./DeeplinkList";

function DeeplinkPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <DeeplinkList />
      </Route>
    </Switch>
  );
}

export default DeeplinkPage;
