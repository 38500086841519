import { createContext } from "react";
import { CustomerResponse } from "types/dto/response/customer";
import { CustomerListParams } from "types/dto/request/customer";

interface Value {
  dataSource: CustomerResponse[];
  query: CustomerListParams & { tab: string };
}

export const CustomerListContext = createContext<Value>({
  dataSource: [],
  query: { tab: "B2B" },
});
