import React from "react";
import { Button, Row, Space, Table, TableProps, Tooltip } from "antd";
import { EntitlementModel } from "types/model/entitlement";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "app/store";
import { useParams } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";
import useAppAuth0 from "hooks/useAppAuth0";
import { message } from "components";
import { CustomerTab } from "../CustomerList/CustomerList";
import columnConfig from "./columnConfig";
import { fetchEntitlementList, restoreEntitlement } from "../customerSlice";
import styles from "./CustomerEntitlements.module.scss";
import AddEntitlement from "../AddEntitlement";

interface Props {
  tab: CustomerTab;
  auth0Id?: string;
}

function CustomerEntitlements({ auth0Id, tab }: Props) {
  const dispatch = useAppDispatch();
  const { user } = useAppAuth0();
  const { customerId } = useParams<{
    customerId: string;
  }>();
  const columns = columnConfig[tab];
  const { customersEntitlements } = useSelector((state: RootState) => state.customer);
  const { REACT_APP_B2B_WEB_URL } = process.env;

  const handleRestore = () => {
    customerId &&
      dispatch(restoreEntitlement(Number(customerId)))
        .then(unwrapResult)
        .then(() =>
          dispatch(fetchEntitlementList(Number(customerId)))
            .then(unwrapResult)
            .catch(message.error)
        )
        .catch(message.error);
  };

  const handleOnRowClick: TableProps<EntitlementModel>["onRow"] = (row) => ({
    onClick: () => {
      if (
        tab === "B2B" &&
        row.status !== "REVOKED" &&
        row.adminGroupId &&
        row.voucherGroupId &&
        row.voucherCode &&
        row.voucherType
      ) {
        if (row.source === "B2B_SEAT_ASSIGNMENT") {
          window.open(
            `${REACT_APP_B2B_WEB_URL}/${row.adminGroupId}/assignment/${row.voucherGroupId}?assigneeEmail=${row.customerEmail}`
          );
        } else if (row.source === "B2B_REDEMPTION") {
          if (row.voucherType === "SINGLE") {
            window.open(
              `${REACT_APP_B2B_WEB_URL}/${row.adminGroupId}/assignment/${row.voucherGroupId}?voucherCode=${row.voucherCode}`
            );
          } else if (row.voucherType === "MASTER") {
            window.open(
              `${REACT_APP_B2B_WEB_URL}/${row.adminGroupId}/assignment/${row.voucherGroupId}?redeemerEmail=${row.customerEmail}`
            );
          }
        }
      }
    },
  });

  return (
    <Space size={24} direction={"vertical"} className={styles.entitlementSection}>
      <Row justify={"space-between"}>
        <header className={styles.subtitle}>Entitlement</header>
        {tab === "B2C" && (
          <div>
            {user.canRestoreEntitlements && (
              <Tooltip placement="topLeft" title="Only for Naviga and eCom entitlement">
                <Button type="default" shape="round" style={{ marginRight: "8px" }} onClick={handleRestore}>
                  Restore
                </Button>
              </Tooltip>
            )}
            {user.canCreateB2CCustomerEntitlement && <AddEntitlement auth0Id={auth0Id} />}
          </div>
        )}
      </Row>
      <Table
        className={styles.table}
        columns={columns}
        dataSource={customersEntitlements}
        rowKey={(row) => row.entitlementId}
        rowClassName={(row) =>
          tab === "B2C"
            ? styles.arrow
            : row.status === "REVOKED" ||
              !row.adminGroupId ||
              !row.voucherGroupId ||
              !row.voucherCode ||
              !row.voucherType
            ? styles.disable
            : row.source === "B2B_REDEMPTION" || row.source === "B2B_SEAT_ASSIGNMENT"
            ? styles.hand
            : styles.arrow
        }
        onRow={handleOnRowClick}
        pagination={false}
      />
    </Space>
  );
}

export default CustomerEntitlements;
