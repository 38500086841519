import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { PrivateRoute } from "components";
import { Permission } from "types/permission";
import ProductList from "./ContentProduct/ProductList/ProductList";
import AddNewProduct from "./ContentProduct/AddNewProduct";
import ProductDetail from "./ContentProduct/ProductDetail";
import BasePricePage from "./basePrice";
import BasePriceDetailList from "./basePrice/DetailList";
import EComProductList from "./EComProduct/ProductList";
import EComAddNewProduct from "./EComProduct/AddNewProduct";
import ProductDetails from "./EComProduct/ProductDetails";
import DescriptionList from "./DescriptionTemplate/DescriptionList";
import DisclosureList from "./Disclosure";
import AddNewDisclosure from "./Disclosure/AddNewDisclosure";
import DisclosureDetail from "./Disclosure/DisclosureDetail";
import DescriptionDetails from "./DescriptionTemplate/DescriptionDetails";
import EComPublishPlans from "./EComProduct/ProductList/PublishPlans";
import AddNewDescriptionTemplate from "./DescriptionTemplate/AddNewDescriptionTemplate";

function Product() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <Redirect to={`${path}/content`} />
      </Route>
      <Route exact path={`${path}/content`}>
        <ProductList />
      </Route>
      <PrivateRoute exact path={`${path}/content/add`} permission={Permission.CREATE_PRODUCT}>
        <AddNewProduct />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/content/:productId`} permission={Permission.VIEW_PRODUCT_MENU}>
        <ProductDetail />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/ecom`} permission={Permission.VIEW_ECOM_PRODUCT_MENU}>
        <EComProductList />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/ecom/add`} permission={Permission.CREATE_ECOM_PRODUCT}>
        <EComAddNewProduct />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/ecom/publish`} permission={Permission.PUBLISH_ECOM_PRODUCT}>
        <EComPublishPlans />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/ecom/:productId`} permission={Permission.VIEW_ECOM_PRODUCT_DETAIL}>
        <ProductDetails />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/base-price`} permission={Permission.VIEW_BASE_PRICE_MENU}>
        <BasePricePage />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/base-price/:basePriceId`} permission={Permission.VIEW_BASE_PRICE_MENU}>
        <BasePriceDetailList />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/disclosures`} permission={Permission.VIEW_DISCLOSURE_MENU}>
        <DisclosureList />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/disclosures/add`} permission={Permission.CREATE_DISCLOSURE}>
        <AddNewDisclosure />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/disclosures/:disclosureId`} permission={Permission.VIEW_DISCLOSURE_DETAIL}>
        <DisclosureDetail />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/description`} permission={Permission.VIEW_DESCRIPTION_TEMPLATE_MENU}>
        <DescriptionList />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/description/add`} permission={Permission.CREATE_DESCRIPTION_TEMPLATE}>
        <AddNewDescriptionTemplate />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={`${path}/description/:descriptionId`}
        permission={Permission.VIEW_DESCRIPTION_TEMPLATE_DETAIL}
      >
        <DescriptionDetails />
      </PrivateRoute>
    </Switch>
  );
}

export default Product;
