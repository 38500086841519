import { DatePicker, DatePickerProps } from "antd";
import moment from "moment";
import React from "react";

type Props = { startDate?: number } & DatePickerProps;

export default function EndDateInput(props: Props) {
  const { value, startDate } = props;
  return (
    <DatePicker
      {...props}
      value={value ? moment(value) : undefined}
      disabledDate={(date) => {
        const _startDate = moment(startDate ?? "0001-01-01");
        return date.isBefore(moment(), "day") || date.isBefore(_startDate, "day");
      }}
    />
  );
}
