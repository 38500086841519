import { Modal as antModal, ModalFuncProps as antModalFuncProps } from "antd";
import { omit } from "lodash";
import styles from "./Modal.module.scss";

type ModalFuncProps = Omit<antModalFuncProps, "className" | "closable" | "centered" | "icon">;

const Modal = omit(antModal, "confirm", "error") as typeof antModal;
Modal.confirm = function (props: ModalFuncProps) {
  return antModal.confirm({
    okButtonProps: { shape: "round", style: { minWidth: "92px" } },
    cancelButtonProps: { shape: "round", style: { minWidth: "92px" } },
    bodyStyle: { borderBottom: "1px solid #F0F0F0" },
    ...props,
    icon: undefined,
    closable: true,
    centered: true,
    className: styles.confirm,
  });
};

Modal.error = function (props: ModalFuncProps) {
  return antModal.error({
    ...props,
    centered: true,
    className: styles.container,
    okButtonProps: {
      shape: "round",
      style: { minWidth: "92px" },
    },
    cancelButtonProps: { shape: "round", style: { minWidth: "92px" } },
  });
};

export default Modal;
