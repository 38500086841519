import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Card, Form, Space, Spin } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import OrganizationForm, { OrganizationFormProps } from "../OrganizationForm";
import { message } from "../../../components";
import { RootState, useAppDispatch } from "../../../app/store";
import { createOrganization } from "../organizationSlice";

function OrganizationCreation() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const isLoading = useSelector((state: RootState) => state.loading);

  function goBackToOrganizationList() {
    history.push("/organization");
  }

  const handleSubmit: OrganizationFormProps["onFinish"] = (values) => {
    dispatch(createOrganization(values)).then(unwrapResult).then(goBackToOrganizationList).catch(message.error);
  };

  const cardTitleExtra = (
    <Space>
      <Button type="default" shape="round" onClick={goBackToOrganizationList}>
        Cancel
      </Button>
      <Button type="primary" shape="round" onClick={form.submit}>
        Save
      </Button>
    </Space>
  );

  return (
    <Card title="Add organization" extra={cardTitleExtra} className="page-container">
      <Spin spinning={isLoading} size={"large"}>
        <OrganizationForm
          form={form}
          data={{
            isCreatingSubgroupEnabled: true,
            isAnalyticsReportingEnabled: true,
            isCreatingAuth0AccountForSeatUserEnabled: true,
            groupAdmins: [],
          }}
          onFinish={handleSubmit}
        />
      </Spin>
    </Card>
  );
}

export default OrganizationCreation;
