import React, { useContext, useEffect, useState } from "react";
import { Form, FormInstance, Input, InputNumber, Radio, Select, Space } from "antd";
import { DropDown } from "assets/icons";
import styles from "../../index.module.scss";
import { BillingPlan, FreeTrialType } from "../../../../../../../types/model/eComProduct";
import { BasePriceModel, PriceStatus } from "../../../../../../../types/model/price";
import { DEFAULT_MAX_PAGINATION } from "../../../../../../../constants/common";
import * as basePriceAPI from "../../../../../../../services/basePrice";
import { BasePriceListRequest } from "../../../../../../../types/dto/request/basePrice";
import * as eComProductAPI from "../../../../../../../services/eComProduct";
import { displayBillingQuantityAndCycle } from "../../../../../../../utils/displayBillingQuantityAndCycle";
import { AddNewPlanContext } from "../../../../AddNewProduct";
import { ProductDetailContext } from "../../../../ProductDetails";

interface Props {
  form: FormInstance;
  setPricingLoading: (loading: boolean) => void;
  isLifetime: boolean;
  disabled?: boolean;
  disabledJoinUpsell: boolean;
  selectedBillingPlanId?: string;
  setSelectedBillingPlanId: (val: string | undefined) => void;
}

function PricingFormItems({
  form,
  setPricingLoading,
  isLifetime,
  disabled,
  disabledJoinUpsell,
  selectedBillingPlanId,
  setSelectedBillingPlanId,
}: Props) {
  const productInfo = useContext(AddNewPlanContext);
  const editProductInfo = useContext(ProductDetailContext);
  const [basePriceOptions, setBasePriceOptions] = useState<BasePriceModel[]>([]);
  const [billingPlansOptions, setBillingPlansOptions] = useState<BillingPlan[] | null>(null);
  const [basePriceCurrencies, setBasePriceCurrencies] = useState<string | null>(
    form.getFieldValue("basePriceHelpText")
  );

  useEffect(() => {
    setBasePriceCurrencies(form.getFieldValue("basePriceHelpText"));
  }, [form.getFieldValue("basePriceHelpText")]);

  useEffect(() => {
    let ignore = false;

    if (productInfo || editProductInfo) {
      setPricingLoading(true);
      Promise.all([
        basePriceAPI.fetchBasePriceList({
          status: PriceStatus.ACTIVE,
          page: 1,
          limit: DEFAULT_MAX_PAGINATION.size,
        } as BasePriceListRequest),
        eComProductAPI.fetchBillingPlans(),
      ])
        .then(([basePriceRes, billingPlansRes]) => {
          if (!ignore) {
            setBasePriceOptions(basePriceRes.list);
            setBillingPlansOptions(billingPlansRes);
          }
        })
        .finally(() => {
          setPricingLoading(false);
        });
    }

    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    const currentBillingPlanId = form.getFieldValue("billingPlanId");
    if (billingPlansOptions && currentBillingPlanId) {
      const currentBillingPlan = billingPlansOptions.find(({ id }) => id === currentBillingPlanId);
      form.setFieldsValue({
        billingCycleAndQuantity: displayBillingQuantityAndCycle(
          currentBillingPlan?.cycle,
          currentBillingPlan?.quantity
        ),
      });
    }
  }, [billingPlansOptions, form]);

  useEffect(() => {
    if (!billingPlansOptions) {
      return;
    }
    const currentBillingPlanId = form.getFieldValue("billingPlanId");
    const selectedPlan = billingPlansOptions?.find((option) => option.id === currentBillingPlanId);
    const isFreeTrial = selectedPlan?.has_trial ?? false;
    form.setFieldsValue({
      billingCycle: selectedPlan?.cycle,
      billingPlanName: selectedPlan?.name,
      billingCycleAndQuantity: displayBillingQuantityAndCycle(selectedPlan?.cycle, selectedPlan?.quantity),
      freeTrialType: isFreeTrial ? FreeTrialType.INTRODUCTORY : undefined,
      freeTrialUsageLimit: isFreeTrial ? 1 : undefined,
    });
  }, [selectedBillingPlanId]);

  function getBasePriceCurrencies(id: string): string | null {
    const option = basePriceOptions.find((item) => item.id === id);
    if (!option) {
      return null;
    }

    const currencyPrices = option.currencies;
    if (!currencyPrices || currencyPrices.length === 0) {
      return "Currency prices not available";
    }

    return currencyPrices.map(({ amount, code }) => `${code.toUpperCase()} ${amount}`).join(" / ");
  }

  function handleBasePriceChange(val: string) {
    setSelectedBillingPlanId(undefined);
    const selectedBasePrice = basePriceOptions.find((item: BasePriceModel) => item.id === val);

    form.setFieldsValue({
      basePriceHelpText: getBasePriceCurrencies(val),
      basePriceName: selectedBasePrice?.name,
      billingPlanId: undefined,
    });

    setBasePriceCurrencies(getBasePriceCurrencies(val));
  }

  const handleBillingPlanChange = (val: string) => {
    setSelectedBillingPlanId(val);
  };

  return (
    <>
      <Form.Item name="basePriceName" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="basePriceHelpText" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Space
        direction={isLifetime ? "horizontal" : "vertical"}
        size={isLifetime ? 32 : 24}
        style={{ alignItems: "flex-start" }}
      >
        <div className={styles.basePriceRow}>
          <Space direction={"vertical"} size={8}>
            <Form.Item label="Base Price" name="basePriceId" rules={[{ required: true }]} className={styles.formItem}>
              <Select
                allowClear
                showSearch={true}
                listHeight={160}
                getPopupContainer={(trigger) => trigger.parentNode}
                suffixIcon={<DropDown />}
                placeholder={"Please choose base price"}
                onChange={handleBasePriceChange}
                optionFilterProp={"label"}
                options={basePriceOptions.map((option) => ({
                  value: option.id,
                  label: option.name,
                }))}
                disabled={disabled}
              />
            </Form.Item>
            {basePriceCurrencies && <span className={styles.formHelpText}>{basePriceCurrencies}</span>}
          </Space>
          {isLifetime ||
          basePriceOptions
            .find((item: BasePriceModel) => item.id === form.getFieldValue("basePriceId"))
            ?.name.toLowerCase()
            .includes("lifetime") ? null : (
            <>
              <Form.Item name="billingPlanName" noStyle>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item
                label="Billing Plan"
                name="billingPlanId"
                rules={[{ required: true }]}
                className={styles.smallFormItem}
              >
                <Select
                  allowClear
                  showSearch
                  listHeight={160}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  suffixIcon={<DropDown />}
                  placeholder={"Please choose billing plan"}
                  onChange={handleBillingPlanChange}
                  optionFilterProp={"label"}
                  options={billingPlansOptions?.map((option) => ({
                    value: option.id,
                    label: option.name,
                  }))}
                  disabled={disabled}
                />
              </Form.Item>
              <Form.Item label="Billing Cycle" name="billingCycleAndQuantity" required className={styles.smallFormItem}>
                <Input disabled />
              </Form.Item>
            </>
          )}
        </div>
        <div className={styles.basePriceRow}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const shouldShowFreeTrial = !!getFieldValue("freeTrialType");
              return (
                shouldShowFreeTrial && (
                  <Space size={32} style={{ alignItems: "flex-start" }}>
                    <Form.Item
                      label="Free trial type"
                      name="freeTrialType"
                      initialValue={FreeTrialType.INTRODUCTORY}
                      rules={[{ required: true }]}
                      className={styles.smallFormItem}
                    >
                      <Radio.Group>
                        <Space size={16}>
                          {Object.values(FreeTrialType).map((type) => (
                            <Radio value={type} key={type}>
                              {type}
                            </Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        const shouldShowUsageLimit = getFieldValue("freeTrialType") === FreeTrialType.PROMOTIONAL;
                        return (
                          <>
                            {shouldShowUsageLimit && (
                              <Form.Item
                                label="Free trial usage limit"
                                name="freeTrialUsageLimit"
                                initialValue={1}
                                rules={[
                                  {
                                    required: true,
                                    message: "Free trial usage limit is required",
                                  },
                                ]}
                                className={styles.smallFormItem}
                              >
                                <InputNumber min={1} />
                              </Form.Item>
                            )}
                          </>
                        );
                      }}
                    </Form.Item>
                  </Space>
                )
              );
            }}
          </Form.Item>
          <Form.Item
            label="Join upsell"
            name="joinUpsell"
            rules={[{ required: true }]}
            className={styles.smallFormItem}
          >
            <Radio.Group disabled={disabledJoinUpsell}>
              <Space size={16}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>
      </Space>
    </>
  );
}

export default PricingFormItems;
