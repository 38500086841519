import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import { TransactionModel } from "../../../types/model/transactions";
import columnConfig from "./columnConfig";
import { CustomerInfoResponse, CustomerResponse } from "../../../types/dto/response/customer";
import ResizableTitle from "../../../components/ResizableTitle";
import useResizableTableHeader from "../../../hooks/useResizableTableHeader";
import { getTotalDisplay } from "../../../utils/getTotalDisplay";
import { RootState, useAppDispatch } from "../../../app/store";
import { message } from "../../../components";
import { fetchTransactions } from "../customerSlice";

interface Props {
  customer?: CustomerResponse & CustomerInfoResponse;
}

function Transactions({ customer }: Props) {
  const dispatch = useAppDispatch();
  const columnsToDisplay = useResizableTableHeader<TransactionModel>(columnConfig);

  const { customerTransactions } = useSelector((state: RootState) => state.customer);

  useEffect(() => {
    dispatch(fetchTransactions({ auth0Id: customer?.auth0Id ?? "" }))
      .then(unwrapResult)
      .catch(message.error);
  }, [dispatch]);

  return (
    <Table
      scroll={{ x: "max-content" }}
      components={{
        header: {
          cell: ResizableTitle,
        },
      }}
      columns={columnsToDisplay}
      dataSource={customerTransactions}
      rowKey={(row) => row.id}
      pagination={{
        showTotal: getTotalDisplay,
        total: customerTransactions.length,
        showSizeChanger: true,
      }}
    ></Table>
  );
}

export default Transactions;
