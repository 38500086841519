import React, { ChangeEvent, useEffect, useState } from "react";
import { ArrayParam, useQueryParams, withDefault } from "use-query-params";
import { Input, Row, Table, TableProps } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "app/store";
import { LearningProgressesParams } from "types/dto/request/customer";
import { DEFAULT_PAGINATION, EMPTY } from "constants/common";
import { unwrapResult } from "@reduxjs/toolkit";
import { SorterResult } from "antd/es/table/interface";
import { DropdownInput, message } from "components";
import { LearningProgressModel } from "types/model/customer";
import columnConfig from "./columnConfig";
import { customerSelectors, fetchCustomerDetail, fetchLearningProgresses } from "../customerSlice";
import styles from "./LearningProgress.module.scss";
import { getTotalDisplay } from "../../../utils/getTotalDisplay";

function LearningProgress() {
  const dispatch = useAppDispatch();
  const { customerId } = useParams<{ customerId: string }>();
  const customer = useSelector((state: RootState) => customerSelectors.selectById(state, customerId));
  const { subUsers, rootUserName } = customer ?? {};
  const [filter, setFilter] = useState<LearningProgressesParams>({
    ...DEFAULT_PAGINATION,
    sort: "courseName,lessonNumber,ASC",
    customerId: Number(customerId),
  });
  const [query, setQuery] = useQueryParams({
    subUserNames: withDefault(ArrayParam, []),
  });

  const [courseInput, setCourseInput] = useState("");

  const {
    learningProgresses: { page, size, content, totalElements },
  } = useSelector((state: RootState) => state.customer);

  useEffect(() => {
    customerId &&
      dispatch(
        fetchLearningProgresses({
          ...query,
          ...filter,
          customerId: Number(customerId),
        })
      )
        .then(unwrapResult)
        .catch(message.error);
  }, [dispatch, query, filter, customerId]);

  useEffect(() => {
    customerId &&
      dispatch(fetchCustomerDetail(Number(customerId)))
        .then(unwrapResult)
        .catch(message.error);
  }, [dispatch, customerId]);

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setFilter((prevState) => ({
      ...prevState,
      page: page - 1,
      size: pageSize,
    }));
  };

  const handleTableChange: TableProps<LearningProgressModel>["onChange"] = (pagination, filters, sorter, extra) => {
    const sortColumn = sorter as SorterResult<LearningProgressModel>;
    if ("sort" === extra.action) {
      setFilter((prevState) => ({
        ...prevState,
        page: undefined,
        sort: `${sortColumn.field},${sortColumn.order === "ascend" ? "ASC" : "DESC"}`,
      }));
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCourseInput(e.target.value);
  };

  const handlePressEnter = () => {
    setCourseInput((prevState) => prevState.trim());
    setFilter((prevState) => ({
      ...prevState,
      page: undefined,
      courseName: courseInput.trim(),
    }));
  };

  return (
    <>
      <Row className={styles.filter}>
        <Input
          allowClear
          value={courseInput}
          prefix={<SearchOutlined />}
          className={styles.courseInput}
          onChange={handleInputChange}
          onBlur={() => setCourseInput((prevState) => prevState.trim())}
          onPressEnter={handlePressEnter}
          placeholder="Search course"
        />
        <DropdownInput
          options={Array.from(subUsers ?? EMPTY)
            .sort((a, b) => {
              if (a === rootUserName) return -1;
              if (b === rootUserName) return 1;
              return a.localeCompare(b);
            })
            .map((subUserName) => ({
              label: (
                <>
                  {subUserName}
                  {rootUserName === subUserName && <div className={styles.accountHolder}>Account holder</div>}
                </>
              ),
              value: subUserName,
            }))}
          placeholder="All Subusers"
          value={query.subUserNames as string[]}
          onChange={(subUserNames) => {
            setQuery({
              ...query,
              subUserNames: subUserNames as string[],
            });
            setFilter((prevState) => ({
              ...prevState,
              page: undefined,
            }));
          }}
        />
      </Row>
      <Table
        className={styles.table}
        columns={columnConfig}
        dataSource={content}
        rowKey={(row) => row.lessonProgressId}
        onChange={handleTableChange}
        pagination={{
          showTotal: getTotalDisplay,
          total: totalElements,
          current: page + 1,
          pageSize: size,
          showSizeChanger: true,
          onChange: handlePaginationChange,
        }}
      />
    </>
  );
}

export default LearningProgress;
