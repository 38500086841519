import { BreadcrumbsRoute } from "use-react-router-breadcrumbs";

// TODO: add component & code split
const routes: BreadcrumbsRoute[] = [
  {
    path: "/customers/b2c",
    // component: () => <CustomerList tab="B2C" />,
    breadcrumb: "B2C customer list",
  },
  {
    path: "/customers/b2c/:path",
    breadcrumb: "Customer details",
  },
  {
    path: "/customers/b2b",
    breadcrumb: "B2B customer list",
  },
  {
    path: "/customers/b2b/:path",
    breadcrumb: "Customer details",
  },
  {
    path: "/products/content",
    breadcrumb: "Product list",
  },
  {
    path: "/products/content/add",
    breadcrumb: "Add new product",
  },
  {
    path: "/products/content/:path",
    breadcrumb: "View details",
  },
  {
    path: "/products/ecom",
    breadcrumb: "eCom Product",
  },
  {
    path: "/products/ecom/add",
    breadcrumb: "Add new product",
  },
  {
    path: "/products/ecom/publish",
    breadcrumb: "Publish Plans",
  },
  {
    path: "/products/ecom/:path",
    breadcrumb: "View details",
  },
  {
    path: "/products/base-price",
    breadcrumb: "Base Price",
  },
  {
    path: "/products/base-price/:path",
    breadcrumb: "View details",
  },
  {
    path: "/products/disclosures",
    breadcrumb: "Disclosure",
  },
  {
    path: "/products/disclosures/add",
    breadcrumb: "Add new disclosure",
  },
  {
    path: "/products/disclosures/:path",
    breadcrumb: "View details",
  },
  {
    path: "/products/description",
    breadcrumb: "Description template",
  },
  {
    path: "/products/description/add",
    breadcrumb: "Add new template",
  },
  {
    path: "/products/description/:path",
    breadcrumb: "View details",
  },
  {
    path: "/marketing/save-offer",
    breadcrumb: "Save offer",
  },
  {
    path: "/marketing/save-offer/add",
    breadcrumb: "Add new save offer",
  },
  {
    path: "/marketing/save-offer/:path",
    breadcrumb: "View details",
  },
  {
    path: "/marketing/upsell-downgrade",
    breadcrumb: "Upsell & Downgrade",
  },
  {
    path: "/marketing/upsell-downgrade/add",
    breadcrumb: "Add new upsell & downgrade",
  },
  {
    path: "/marketing/upsell-downgrade/:path",
    breadcrumb: "View details",
  },
  {
    path: "/marketing/promotions",
    breadcrumb: "Promotions",
  },
  {
    path: "/marketing/promotions/add",
    breadcrumb: "Add new promotion",
  },
  {
    path: "/marketing/promotions/:path",
    breadcrumb: "View details",
  },
  {
    path: "/vouchers",
    breadcrumb: "Vouchers",
  },
  {
    path: "/vouchers/add",
    breadcrumb: "Add vouchers",
  },
  {
    path: "/vouchers/:path",
    breadcrumb: "View details",
  },
  {
    path: "/organization",
    breadcrumb: "Organization",
  },
  {
    path: "/organization/add",
    breadcrumb: "Add organization",
  },
  {
    path: "/organization/:path",
    breadcrumb: "View details",
  },
];

export default routes;
