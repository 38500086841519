import { EMPTY } from "../../constants/common";
import { Country } from "./price";

export enum PaymentMethodType {
  CreditCard = "CreditCard",
  PayPal = "PayPal",
  GiftCertificate = "GiftCertificate",
}

export enum CreditCardType {
  "VI" = "VI",
  "MC" = "MC",
  "AX" = "AX",
  "DC" = "DC",
  "DD" = "DD",
  "JC" = "JC",
  "MD" = "MD",
  "SW" = "SW",
  "CU" = "CU",
  "DI" = "DI",
}

export const CreditCardNameMap: { [key: string]: string } = {
  [CreditCardType.VI]: "Visa",
  [CreditCardType.MC]: "MasterCard",
  [CreditCardType.AX]: "American Express",
  [CreditCardType.DC]: "Diners Club",
  [CreditCardType.DD]: "Discover",
  [CreditCardType.JC]: "JCB",
  [CreditCardType.MD]: "Maestro",
  [CreditCardType.SW]: "Switch",
  [CreditCardType.CU]: "UnionPay",
  [CreditCardType.DI]: "DinaCard",
};

export interface CreditCard {
  account: string;
  expirationDate: string;
  type?: CreditCardType;
}

export interface BillingAddress {
  postalCode: string;
  country: string;
  district?: string;
}

export interface PaymentMethod {
  id: string;
  created: string;
  type: PaymentMethodType;
  default: boolean;
  creditCard?: CreditCard;
  billingAddress?: BillingAddress;
  paypal?: {
    paypalEmail: string;
  };
}

export const formatAccount = (account: string): string => "**** **** **** " + account.substring(account.length - 4);

export const getPaymentMethodText = ({
  type,
  cardType,
  account,
}: {
  type?: PaymentMethodType;
  cardType?: CreditCardType | string;
  account: string;
}) => {
  const cardTypeText = cardType
    ? `Credit Card ${CreditCardNameMap[cardType] || cardType} ${formatAccount(account)}`
    : "";
  const paypalTypeText = account ? `Paypal ${account}` : "Paypal";

  if (!type) {
    return cardType ? cardTypeText : "";
  }

  if (type === PaymentMethodType.CreditCard) {
    return cardTypeText;
  }
  if (type === PaymentMethodType.PayPal) {
    return paypalTypeText;
  }
  if (type === PaymentMethodType.GiftCertificate) {
    return "Gift Certificate";
  }

  return EMPTY;
};

export function getPaymentMethodTextByPaymentMethod(paymentMethod: PaymentMethod) {
  if (!paymentMethod.id) return EMPTY;
  const { type: paymentMethodType } = paymentMethod || {};
  let paymentMethodText = "";
  switch (paymentMethodType) {
    case PaymentMethodType.CreditCard:
      if (!paymentMethod?.creditCard || !paymentMethod.creditCard.type) {
        return "";
      }

      const { account, type } = paymentMethod.creditCard;
      paymentMethodText = `Credit Card ${CreditCardNameMap[type]} ${formatAccount(account)}`;
      break;
    case PaymentMethodType.PayPal:
      const paypalEmail = paymentMethod?.paypal?.paypalEmail;
      paymentMethodText = `PayPal ${paypalEmail}`;
      break;
  }
  return paymentMethodText;
}

export const getCountryName = (billingAddressCountry: string, countries?: Country[]): string =>
  countries?.find((country) => country.iso2.toUpperCase() === billingAddressCountry?.toUpperCase())?.displayName ??
  billingAddressCountry;
