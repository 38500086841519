import React from "react";
import styles from "./index.module.scss";

export default function ModalContent({ children, style }: { children: React.ReactNode; style?: React.CSSProperties }) {
  return (
    <div className={styles.modal__content} style={style}>
      {children}
    </div>
  );
}
