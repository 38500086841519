import React from "react";
import { Button } from "antd";
import moment from "moment";
import { ColumnType } from "antd/lib/table/interface";
import { DATETIME_FORMAT } from "constants/common";
import { SortOrder } from "antd/es/table/interface";
import { OrganizationListResponse } from "../../../types/dto/response/organization";

type Record = OrganizationListResponse;

const useColumn = (sortOrder: SortOrder, onDelete: (record: Record) => void): ColumnType<Record>[] => {
  return [
    {
      title: "Organization name",
      dataIndex: "name",
      ellipsis: true,
    },
    {
      title: "Allowed to create subgroup",
      dataIndex: "isCreatingSubgroupEnabled",
      width: "20%",
      ellipsis: true,
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "Progress reporting",
      dataIndex: "isAnalyticsReportingEnabled",
      width: "15%",
      ellipsis: true,
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "Create seat user account",
      dataIndex: "isCreatingAuth0AccountForSeatUserEnabled",
      width: "20%",
      ellipsis: true,
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "Modified at",
      dataIndex: "lastModifiedDate",
      width: "18%",
      sorter: true,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder,
      ellipsis: true,
      render: (value) => moment(value).format(DATETIME_FORMAT),
    },
    {
      title: "Actions",
      dataIndex: "id",
      width: "9%",
      onCell: () => ({
        onClick: (e) => e.stopPropagation(),
      }),
      className: "cursor-default",
      render: (_, record) => (
        <Button type="link" onClick={() => onDelete(record)} style={{ padding: 0, height: "auto" }}>
          Delete
        </Button>
      ),
    },
  ];
};

export default useColumn;
