import React, { useEffect, useState } from "react";
import { Table, TableProps } from "antd";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { SorterResult } from "antd/es/table/interface";
import { getTotalDisplay } from "../../../utils/getTotalDisplay";
import useColumn from "../../products/ContentProduct/ProductDetail/useColumns";
import { RootState, useAppDispatch } from "../../../app/store";
import { DEFAULT_PAGINATION } from "../../../constants/common";
import { fetchMiniCourseChangeLogs } from "../miniCourseSlice";
import { ProductChangeLog } from "../../../types/model/product";
import styles from "./index.module.scss";
import { IMIniCourseChangeLogParams } from "../../../types/dto/request/MiniCourseRequest";

interface IProps {
  id: number;
}

const MiniCourseLogsTable = (props: IProps) => {
  const { id } = props;
  const columns = useColumn(true);
  const dispatch = useAppDispatch();
  const {
    changeLog: { page, size, content, totalElements },
  } = useSelector((state: RootState) => state.miniCourses);
  const [filter, setFilter] = useState<IMIniCourseChangeLogParams>({
    ...DEFAULT_PAGINATION,
    sort: "createdDate,DESC",
    miniCourseId: id,
  });

  useEffect(() => {
    dispatch(fetchMiniCourseChangeLogs(filter));
  }, [dispatch, filter]);

  const handleTableChange: TableProps<ProductChangeLog>["onChange"] = (pagination, filters, sorter, extra) => {
    const { order } = sorter as SorterResult<ProductChangeLog>;
    if (extra.action === "sort") {
      order &&
        setFilter((prevState) => ({
          ...prevState,
          page: undefined,
          sort: `createdDate,${order === "ascend" ? "ASC" : "DESC"}`,
        }));
    }
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setFilter((prevState) => ({
      ...prevState,
      page: page - 1,
      size: pageSize,
    }));
  };

  return (
    <Table
      columns={columns}
      dataSource={content}
      onChange={handleTableChange}
      pagination={{
        showTotal: getTotalDisplay,
        total: totalElements,
        showSizeChanger: true,
        current: page + 1,
        pageSize: size,
        onChange: handlePageChange,
      }}
      rowKey={(record) => record.id}
      className={classnames(content.length && styles.table)}
    />
  );
};

export default MiniCourseLogsTable;
