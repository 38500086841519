import { Row, Tag } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./SaveOfferAdvancedSearch.module.scss";
import { SaveOfferListRequest } from "../../../../types/dto/request/saveOffer";
import { AppliedStage, SaveOfferType } from "../../../../types/model/saveOffer";

export interface DisplayTagsProps {
  query: SaveOfferListRequest;
  appliedBasePriceList: { id: string; name: string }[];
  onTagsChange: (patchQuery: SaveOfferListRequest) => void;
}

export default function DisplayTags({ query, appliedBasePriceList, onTagsChange }: DisplayTagsProps) {
  const [patchQuery, setPatchQuery] = useState(query);

  useEffect(() => {
    onTagsChange(patchQuery);
  }, [patchQuery]);

  const removeBasePrice = (basePriceId: string) => {
    setPatchQuery({
      appliedBasePriceIds: query.appliedBasePriceIds?.filter((id) => id !== basePriceId),
    });
  };

  const removeAppliedStage = (stage: AppliedStage) => {
    setPatchQuery({
      appliedStages: query.appliedStages?.filter((appliedStage) => appliedStage !== stage),
    });
  };

  const removeType = (type: SaveOfferType) => {
    setPatchQuery({
      type: query.type?.filter((t) => t !== type),
    });
  };

  return (
    <Row className={styles.tags}>
      {query.appliedStages &&
        query.appliedStages.map((stage) => (
          <Tag key={stage} closable onClose={() => removeAppliedStage(stage)}>
            {stage}
          </Tag>
        ))}
      {query.appliedBasePriceIds &&
        appliedBasePriceList &&
        query.appliedBasePriceIds.map((id) => (
          <Tag key={id} closable onClose={() => removeBasePrice(id)}>
            {appliedBasePriceList.find((item) => item.id === id)?.name}
          </Tag>
        ))}
      {query.type &&
        query.type.map((type) => (
          <Tag key={type} closable onClose={() => removeType(type)}>
            {type}
          </Tag>
        ))}
    </Row>
  );
}
