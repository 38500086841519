import React, { useEffect } from "react";
import { Divider, Form } from "antd";
import moment from "moment";
import DisplayInput from "components/DisplayInput";
import { isEmpty, join } from "lodash";
import { span } from "utils/style";
import { DEAL_MAP, EMPTY, ENTITLEMENT_DEAL_MAP, ISBN_SELECTION_MAP, VOUCHER_TYPE_MAP } from "../../../constants/common";
import { VoucherModel } from "../../../types/model/voucher";
import styles from "../VoucherForm/VoucherForm.module.scss";

interface VoucherInfoProps {
  data: VoucherModel | undefined;
}

function VoucherInfoForm({ data }: VoucherInfoProps) {
  const [form] = Form.useForm();
  useEffect(() => form.setFieldsValue(data), [form, data]);

  let productMap = data?.languageProductGroups?.map((languageProductGroup: any) => ({
    language: languageProductGroup.languageName,
    products: languageProductGroup?.productItems?.map((product: any) => product.productName),
  }));
  if (isEmpty(productMap)) {
    productMap = [
      {
        language: EMPTY,
        products: [],
      },
    ];
  }

  return (
    <Form form={form} initialValues={data} layout="inline" labelAlign={"left"} className={styles.form}>
      <Form.Item label="Vouchers description" name="description" style={span(12)}>
        <DisplayInput />
      </Form.Item>
      <Form.Item label="Organization" name="organizationName" style={span(12)}>
        <DisplayInput />
      </Form.Item>
      <Form.Item label="Voucher type" style={span(12)}>
        <DisplayInput value={VOUCHER_TYPE_MAP.get(data?.voucherInfo?.voucherType!)} />
      </Form.Item>
      <Form.Item label="Voucher deal" style={span(6)}>
        <DisplayInput value={DEAL_MAP.get(data?.voucherDeal!)} />
      </Form.Item>
      {data?.voucherDeal === "FIXED_TERM" && (
        <Form.Item label="Valid duration" style={span(6)}>
          <DisplayInput
            value={
              data?.validPeriod &&
              `${moment(data.validPeriod?.startDate).format("YYYY-MM-DD")} to ${moment(
                data.validPeriod?.expirationDate
              ).format("YYYY-MM-DD")}`
            }
          />
        </Form.Item>
      )}

      <Divider style={{ margin: "8px 0 32px 0" }} />

      <Form.Item label="ISBN selection" style={span(9)}>
        <DisplayInput value={ISBN_SELECTION_MAP.get(data?.isbnSelectionType!)} />
      </Form.Item>

      {data?.isbnSelectionType === "INDIVIDUAL" && (
        <>
          <Form.Item style={span(15)} />
          {productMap?.map((languageProduct: any, index: number) => {
            const { language, products } = languageProduct;
            return (
              <React.Fragment key={index}>
                <Form.Item label={index === 0 ? "Language" : ""} style={span(6)}>
                  <DisplayInput value={language} />
                </Form.Item>
                <Form.Item label={index === 0 ? "Course" : ""} style={span(18)}>
                  <DisplayInput value={join(products, ", ")} />
                </Form.Item>
              </React.Fragment>
            );
          })}
        </>
      )}
      {data?.isbnSelectionType === "GENERIC" && (
        <>
          <Form.Item style={span(3)} />
          <Form.Item label="Price per voucher" name={["price", "amount"]} style={span(6)}>
            <DisplayInput />
          </Form.Item>
          <Form.Item label="Currency" name={["price", "currency"]} style={span(6)}>
            <DisplayInput />
          </Form.Item>
          <Form.Item label="Course" style={span(24)}>
            <DisplayInput
              value={data?.genericProduct && join([data.genericProduct.name, data.genericProduct.isbn], " - ")}
            />
          </Form.Item>
        </>
      )}

      <Form.Item label="Total licenses" name={["voucherInfo", "voucherAmount"]} style={span(6)}>
        <DisplayInput />
      </Form.Item>
      {data?.voucherInfo?.voucherType === "MASTER" && (
        <>
          <Form.Item label="Total licenses per user" name={["voucherInfo", "usesPerUser"]} style={span(6)}>
            <DisplayInput />
          </Form.Item>
        </>
      )}
      <Form.Item label="Entitlement deal" style={span(6)}>
        <DisplayInput value={ENTITLEMENT_DEAL_MAP.get(data?.entitlementDeal!)} />
      </Form.Item>
      {data?.entitlementDeal === "FIXED_TERM" && (
        <Form.Item label="Entitlement days" name="entitlementDays" style={span(6)}>
          <DisplayInput />
        </Form.Item>
      )}
      {data?.entitlementDeal === "FIXED_ENDED" && data?.entitlementExpirationDate && (
        <Form.Item label="Fixed expiration date" style={span(6)}>
          <DisplayInput value={`${moment(data.entitlementExpirationDate).format("YYYY-MM-DD")}`} />
        </Form.Item>
      )}

      <Divider style={{ margin: "8px 0 32px 0" }} />

      <Form.Item label="Payment type" style={span(6)}>
        <span>{data?.isPrepaidVoucher ? "Prepaid" : data?.isPrepaidVoucher === false ? "Billing later" : EMPTY}</span>
      </Form.Item>
      <Form.Item label="Invoice number" name="invoice" style={span(6)}>
        <DisplayInput />
      </Form.Item>
      <Form.Item label="Doc number" name="docNumber" style={span(12)}>
        <DisplayInput />
      </Form.Item>
    </Form>
  );
}

export default VoucherInfoForm;
