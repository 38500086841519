import { DeltaStatic } from "quill";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, ConfigProvider, Divider, Form, Input, Row, Select, Space, Spin, Tabs } from "antd";
import { Prompt, useHistory } from "react-router-dom";
import { DEFAULT_MAX_PAGINATION, LEAVE_CONFIRMATION, validateMessages } from "../../../../constants/common";
import styles from "./DisclosureForm.module.scss";
import { DropDown } from "../../../../assets/icons";
import * as basePriceAPI from "../../../../services/basePrice";
import * as eComProductAPI from "../../../../services/eComProduct";
import * as disclosureAPI from "../../../../services/disclosure";
import { BasePriceModel, Country, PriceStatus } from "../../../../types/model/price";
import { BillingPlan } from "../../../../types/model/eComProduct";
import { DisclosureDetailModel, DisclosureFormData } from "../../../../types/model/disclosure";
import { message, Modal } from "../../../../components";
import DescriptionContentInput from "../../DescriptionTemplate/DescriptionDetails/DescriptionContentInput";
import DescriptionContentDescription from "../../DescriptionTemplate/DescriptionDetails/DescriptionContentDescription";
import { DESCRIPTION_MAX_LENGTH } from "../common";
import { isDeltaEmpty } from "../../../../utils/richTextEditor";
import { ContentBase } from "../../../../types/model/description";
import { getContentByLocale, LocaleType, supportLocalTabs } from "../../../../utils/locale";

const { TabPane } = Tabs;

export enum DisclosureFormMode {
  ADD = "add",
  EDIT = "edit",
}

interface DisclosureFormProps {
  title: string;
  mode: DisclosureFormMode;
  initialValues?: any;
  handleStatusChange?: any;
  updateDisclosure?: (disclosure: DisclosureDetailModel) => void;
}

export function DisclosureForm({
  title,
  mode,
  initialValues,
  handleStatusChange,
  updateDisclosure,
}: DisclosureFormProps) {
  const history = useHistory();
  const [form] = Form.useForm<DisclosureFormData>();
  const [formIsHalfFilledOut, setFormIsHalfFilledOut] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [basePriceList, setBasePriceList] = useState<BasePriceModel[]>([]);
  const [billingPlans, setBillingPlans] = useState<BillingPlan[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [contents, setContents] = useState<ContentBase[]>(
    supportLocalTabs.map((item) => ({ ...item, content: {} as DeltaStatic }))
  );

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      basePriceAPI.fetchBasePriceList({
        status: PriceStatus.ACTIVE,
        page: 1,
        limit: DEFAULT_MAX_PAGINATION.size,
      }),
      eComProductAPI.fetchBillingPlans(),
      basePriceAPI.fetchAllCountries(),
    ])
      .then(([basePriceList, billingPlans, countries]) => {
        setBasePriceList(basePriceList.list);
        setBillingPlans(billingPlans);
        setCountries(countries);
        form.setFieldsValue(initialValues);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleCancel = () => {
    if (mode === DisclosureFormMode.ADD) {
      setContents([]);
      history.push("/products/disclosures");
    }
    if (mode === DisclosureFormMode.EDIT && handleStatusChange) {
      Modal.confirm({
        title: "Confirm",
        okText: "OK",
        content: LEAVE_CONFIRMATION,
        onOk: () => {
          handleStatusChange({ editing: false, saved: false });
        },
      });
    }
  };

  const createDisclosureRequest = (
    values: Omit<DisclosureFormData, "enContent" | "esContent">,
    contents: ContentBase[]
  ): DisclosureFormData => {
    return {
      ...values,
      countryIds: values.countryIds || [],
      enContent: JSON.stringify(getContentByLocale(contents, LocaleType.English)),
      esContent: JSON.stringify(getContentByLocale(contents, LocaleType.Spanish)),
      billingPlanId: values.billingPlanId ?? null,
    };
  };

  const handleAddNewDisclosure = () => {
    form
      .validateFields()
      .then((values: Omit<DisclosureFormData, "enContent" | "esContent">) => {
        setIsSaveLoading(true);
        const request = createDisclosureRequest(values, contents);
        disclosureAPI
          .createDisclosure(request)
          .then(() => {
            setFormIsHalfFilledOut(false);
            message.success("Disclosure added successfully.");
            history.push("/products/disclosures");
          })
          .finally(() => {
            setIsSaveLoading(false);
          });
      })
      .catch(() => {
        message.error(new Error("Please fill in all required fields."));
      });
  };

  const handleUpdateDisclosure = () => {
    form
      .validateFields()
      .then((values: Omit<DisclosureFormData, "content">) => {
        setIsSaveLoading(true);
        const request = createDisclosureRequest(values, contents);
        disclosureAPI
          .updateDisclosure(request, initialValues?.id)
          .then((disclosure) => {
            updateDisclosure?.(disclosure);
            setFormIsHalfFilledOut(false);
            message.success("Disclosure updated successfully.");
            setIsSaveLoading(false);
            handleStatusChange?.({ editing: false, saved: true });
          })
          .catch(() => {
            setIsSaveLoading(false);
          });
      })
      .catch(() => {
        message.error(new Error("Please fill in all required fields."));
      });
  };

  const renderSummitAndCancelButtons = () => {
    return (
      <Row justify="end">
        <Space>
          <Button type="default" shape="round" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            shape="round"
            onClick={mode === DisclosureFormMode.ADD ? handleAddNewDisclosure : handleUpdateDisclosure}
            loading={isSaveLoading}
          >
            Save
          </Button>
        </Space>
      </Row>
    );
  };

  const updateContent = (value: DeltaStatic, locale: LocaleType) => {
    const updatedContents = contents.map((content) =>
      content.locale === locale ? { ...content, content: value } : content
    );

    setContents(updatedContents);
  };

  return (
    <Card title={title} extra={renderSummitAndCancelButtons()} className="page-container">
      <Spin spinning={isLoading}>
        <Prompt when={formIsHalfFilledOut} message={LEAVE_CONFIRMATION} />
        <ConfigProvider form={{ validateMessages }}>
          <Form form={form} validateTrigger={"onBlur"} layout="vertical" className={styles.disclosureForm}>
            <Row gutter={32}>
              <Col span={12}>
                <Form.Item label="Disclosure name" name="name" rules={[{ required: true, type: "string", max: 100 }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Divider className={styles.divider} />
            <Row>
              <Col span={24}>
                <Form.Item label="Applied base price" name="basePriceIds" rules={[{ required: true, type: "array" }]}>
                  <Select
                    allowClear
                    optionFilterProp="label"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    showArrow
                    suffixIcon={<DropDown />}
                    mode="multiple"
                    placeholder="Please select applied base price"
                    options={basePriceList.map(({ id, name }) => ({
                      label: name,
                      value: id,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={12}>
                <Form.Item label="Applied billing plan" name="billingPlanId">
                  <Select
                    allowClear
                    optionFilterProp="label"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    showArrow
                    suffixIcon={<DropDown />}
                    placeholder="Please select applied billing plan"
                    options={billingPlans.map(({ id, name }) => ({
                      label: name,
                      value: id,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Applied Country/Region" name="countryIds">
                  <Select
                    allowClear
                    optionFilterProp="label"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    showArrow
                    suffixIcon={<DropDown />}
                    mode="multiple"
                    placeholder="Please select country/Region"
                    options={countries.map(({ id, displayName }) => ({
                      label: displayName,
                      value: id,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider className={styles.divider} />
            <div className={styles.tabCardContainer}>
              <Tabs type="card">
                {contents.map((item: ContentBase) => (
                  <TabPane tab={item.label} key={item.key}>
                    <Form.Item
                      label="Disclosure Content"
                      name={`${item.locale}Content`}
                      rules={[
                        {
                          required: item.required,
                          transform: (value) => (isDeltaEmpty(value) ? "" : JSON.stringify(value)),
                          type: "string",
                          max: DESCRIPTION_MAX_LENGTH,
                        },
                      ]}
                    >
                      <DescriptionContentInput
                        bounds={item.key}
                        editable={true}
                        value={item.content}
                        onChange={(value) => updateContent(value, item.locale)}
                      />
                    </Form.Item>
                    <DescriptionContentDescription />
                  </TabPane>
                ))}
              </Tabs>
            </div>
          </Form>
        </ConfigProvider>
      </Spin>
    </Card>
  );
}
