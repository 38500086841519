import React from "react";
import { InputNumber } from "antd";
import { InputNumberProps } from "antd/lib/input-number";
import styles from "./LessonRangeInput.module.scss";

interface LessonRangeValue {
  from?: number;
  to?: number;
}

interface LessonRangeInputProps extends Omit<InputNumberProps, "value" | "onChange" | "disabled"> {
  value?: LessonRangeValue;
  disabled?: [boolean, boolean];
  onChange?: (value: LessonRangeValue) => void;
}

/**
 * A form control component used in product related pages.
 * This component will only be used for SINGLE_LEVEL, LESSON_PACK and FREE products.
 * - For SINGLE_LEVEL product, display `1 (fixed) to x`
 * - For LESSON_PACK product , display `x to y`
 * - FOR FREE product, display `1 (fixed) to 1 (fixed)`
 *
 * @param props
 * @constructor
 */
function LessonRangeInput(props: LessonRangeInputProps) {
  const { value = {}, disabled = [false, false], onChange } = props;

  const triggerChange = (changedValue: LessonRangeValue) => {
    onChange && onChange({ ...value, ...changedValue });
  };

  return (
    <div className={styles.container}>
      <InputNumber
        {...props}
        disabled={disabled[0]}
        value={value.from}
        precision={0}
        onChange={(val) => triggerChange({ from: val as number })}
      />
      <span>to</span>
      <InputNumber
        {...props}
        disabled={disabled[1]}
        value={value.to}
        precision={0}
        onChange={(val) => triggerChange({ to: val as number })}
      />
    </div>
  );
}

export default LessonRangeInput;
