import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Card, Form, Input, Popover, Row, Table, Tag } from "antd";
import { LabeledValue } from "antd/es/select";
import Icon, { SearchOutlined } from "@ant-design/icons";
import { SorterResult, SortOrder } from "antd/lib/table/interface";
import { TableProps } from "antd/lib/table";
import { omit } from "lodash";
import moment from "moment";
import { ArrayParam, NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { message } from "components";
import { connectionTypeOptions, DAY_BEGIN, DAY_END } from "constants/common";
import { RootState, useAppDispatch } from "app/store";
import { CustomerModel } from "types/model/customer";
import { getDateRange, getOrder } from "utils";
import { DropDown, Refresh } from "assets/icons";
import { getTotalDisplay } from "utils/getTotalDisplay";
import { CustomerListParams } from "types/dto/request/customer";
import { Connection } from "types/dto/response/customer";
import { fetchCustomers } from "../customerSlice";
import useColumns from "./useColumns";
import AdvancedFilters, { AdvancedFiltersProps, Values } from "../AdvancedFilters";
import { fetchOrganizations } from "../../voucher/voucherSlice";
import { CustomerListContext } from "./CustomerListContext";

import styles from "./CustomerList.module.scss";
import SentCheckoutLinkModal from "./components/SentCheckoutLinkModal";

export type CustomerTab = "B2C" | "B2B";

interface CustomerListProps {
  tab: CustomerTab;
}

function CustomerList({ tab }: CustomerListProps) {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    size: withDefault(NumberParam, 10),
    sortKey: withDefault(StringParam, "createdDate"),
    sortOrder: withDefault(StringParam, "descend"),
    createdDateFrom: withDefault(NumberParam, undefined),
    createdDateTo: withDefault(NumberParam, undefined),
    email: withDefault(StringParam, undefined),
    name: withDefault(StringParam, undefined),
    auth0Id: withDefault(StringParam, undefined),
    connections: withDefault(ArrayParam, undefined),
    organizationId: withDefault(NumberParam, undefined),
  });
  const history = useHistory();
  const { path } = useRouteMatch();
  const { content, totalElements } = useSelector((state: RootState) => state.customer);
  const isLoading = useSelector((state: RootState) => state.loading);

  const [email, setEmail] = useState(query.email);
  const columns = useColumns(tab, query.sortOrder as SortOrder);

  const [isAdvancedSearchVisible, setIsAdvancedSearchVisible] = useState(false);
  const [isSendCheckoutLinkModalVisible, setIsSendCheckoutLinkModalVisible] = useState(false);
  const [form] = Form.useForm<Values>();

  const dispatch = useAppDispatch();

  const [organizations, setOrganizations] = useState<LabeledValue[]>([]);

  useEffect(() => {
    if (tab === "B2B") {
      dispatch(fetchOrganizations())
        .then(unwrapResult)
        .then((orgs) => {
          const data = orgs?.map(({ id, name }) => ({
            label: name,
            value: id,
          }));
          setOrganizations(data);
        })
        .catch(message.error);
    }
  }, [dispatch, tab]);

  useEffect(() => {
    handleRefresh();
    setEmail(query.email);
  }, [dispatch, query]);

  const handleTableChange: TableProps<CustomerModel>["onChange"] = (pagination, filters, sorter, extra) => {
    const { order, field } = sorter as SorterResult<CustomerModel>;
    if (extra.action === "sort") {
      setQuery({
        ...query,
        sortKey: field as string,
        sortOrder: order as string,
        page: 1,
      });
    }
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setQuery({ ...query, page, size: pageSize });
  };

  const handleRefresh = () => {
    dispatch(
      fetchCustomers({
        ...omit(query, "sortKey", "sortOrder"),
        page: query.page - 1,
        sort: `${query.sortKey},${getOrder(query.sortOrder as SortOrder)}`,
        isB2b: tab === "B2B" || undefined,
        isB2c: tab === "B2C" || undefined,
      } as CustomerListParams)
    )
      .then(unwrapResult)
      .catch(message.error);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleInputPressEnter = () => {
    setQuery({ ...query, email: email?.trim(), page: 1 });
  };

  const handleAdvancedSearch: AdvancedFiltersProps["onFinish"] = ({
    email,
    name,
    organizationId,
    connections,
    createdDateFrom,
    createdDateTo,
    auth0Id,
  }) => {
    setQuery({
      ...query,
      createdDateFrom: createdDateFrom ? moment(createdDateFrom).set(DAY_BEGIN).valueOf() : undefined,
      createdDateTo: createdDateTo ? moment(createdDateTo).set(DAY_END).valueOf() : undefined,
      email: email?.trim(),
      name: name?.trim(),
      auth0Id: auth0Id?.trim(),
      organizationId,
      connections,
      page: 1,
    });
    setIsAdvancedSearchVisible(false);
  };

  const handleAdvancedSearchVisibleChange = () => {
    setIsAdvancedSearchVisible(!isAdvancedSearchVisible);
  };

  const handleOnRowClick: TableProps<CustomerModel>["onRow"] = (record) => ({
    onClick: () => history.push(`${path}/${record.customerId}`),
  });

  const removeDateTag = () => {
    setQuery({
      ...query,
      createdDateFrom: undefined,
      createdDateTo: undefined,
      page: 1,
    });
  };

  const removeConnectionTypeTag = (connection: Connection) => {
    setQuery({
      ...query,
      connections: query.connections?.filter((item) => item !== connection),
      page: 1,
    });
  };

  return (
    <Card
      title="Customer list"
      className="page-container"
      extra={
        tab === "B2C" && (
          <Button
            type="default"
            shape="round"
            onClick={() => {
              setIsSendCheckoutLinkModalVisible(true);
            }}
          >
            Send checkout link
          </Button>
        )
      }
    >
      <Row className={styles.filters}>
        <Input
          allowClear
          value={email}
          type="string"
          placeholder="Search Customer Email"
          onChange={handleInputChange}
          onBlur={() => setEmail((prevState) => prevState?.trim())}
          onPressEnter={handleInputPressEnter}
          prefix={<SearchOutlined />}
        />
        <Popover
          content={
            <AdvancedFilters
              form={form}
              tab={tab}
              initialValues={{ ...query, email: email } as Values}
              visible={isAdvancedSearchVisible}
              organizations={organizations}
              onFinish={handleAdvancedSearch}
              onCancel={handleAdvancedSearchVisibleChange}
            />
          }
          placement="bottom"
          trigger={["click"]}
          visible={isAdvancedSearchVisible}
          onVisibleChange={handleAdvancedSearchVisibleChange}
          overlayInnerStyle={{ padding: "12px 8px 0 8px" }}
        >
          <Button type="default" shape="round">
            Advanced Search
            <Icon component={DropDown} />
          </Button>
        </Popover>
        <Button type="text" shape="circle" onClick={handleRefresh}>
          <Refresh />
        </Button>
      </Row>

      <div style={{ marginBottom: "16px" }}>
        {(query.createdDateFrom || query.createdDateTo) && (
          <Tag closable onClose={removeDateTag}>
            {getDateRange(query.createdDateFrom, query.createdDateTo)}
          </Tag>
        )}
        {query.name && (
          <Tag closable onClose={() => setQuery({ ...query, name: undefined, page: 1 })}>
            {query.name}
          </Tag>
        )}
        {query.connections &&
          connectionTypeOptions
            .filter((option) => query.connections?.includes(option.value))
            .map((option) => (
              <Tag key={option.label} closable onClose={() => removeConnectionTypeTag(option.value)}>
                {option.label}
              </Tag>
            ))}
        {query.organizationId && (
          <Tag closable onClose={() => setQuery({ ...query, organizationId: undefined, page: 1 })}>
            {organizations.find((org) => org.value === query.organizationId)?.label}
          </Tag>
        )}
      </div>

      <CustomerListContext.Provider
        value={{
          dataSource: content,
          query: { ...(query as CustomerListParams), tab },
        }}
      >
        <Table
          loading={{ spinning: isLoading }}
          className={styles.table}
          dataSource={content}
          columns={columns}
          rowClassName={"cursor-pointer"}
          onRow={handleOnRowClick}
          rowKey={(row) => row.customerId}
          showSorterTooltip={false}
          onChange={handleTableChange}
          pagination={{
            showTotal: getTotalDisplay,
            total: totalElements,
            current: query.page,
            pageSize: query.size,
            showSizeChanger: true,
            onChange: handlePageChange,
          }}
        />
      </CustomerListContext.Provider>
      {isSendCheckoutLinkModalVisible && (
        <SentCheckoutLinkModal
          visible={isSendCheckoutLinkModalVisible}
          onClose={() => {
            setIsSendCheckoutLinkModalVisible(false);
          }}
        />
      )}
    </Card>
  );
}

export default CustomerList;
