import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

function EndDateInput({ value, startFieldName, form, ...props }: any) {
  return (
    <DatePicker
      {...props}
      placeholder="End date"
      value={value ? moment(value) : undefined}
      disabledDate={(date) =>
        date.valueOf() > moment().valueOf() ||
        (form.getFieldValue(startFieldName) && date < form.getFieldValue(startFieldName))
      }
    />
  );
}

export default EndDateInput;
