import type { components } from "../types/api";
import { api } from "./baseApi";
import instance from "../utils/instance";
import Page from "../types/page";
import { EntitlementListParams } from "../types/dto/request/entitlement";
import { EntitlementModel } from "../types/model/entitlement";

export async function fetchAll(params: EntitlementListParams) {
  const url = "/entitlements";
  return instance.get<Page<EntitlementModel>>(url, { params });
}

export async function createEntitlement(data: any) {
  return instance.post<Page<EntitlementModel>>("/entitlements", data);
}

export async function editEntitlement({ id, ...data }: any) {
  return instance.put<Page<EntitlementModel>>(`/entitlements/${id}`, data);
}

export async function revokeEntitlement(id: string) {
  return instance.put<Page<EntitlementModel>>(`/entitlements/${id}/revoke`);
}

export const entitlementApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEntitlements: build.query<components["schemas"]["PageEntitlementResponse"], EntitlementListParams | undefined>({
      query: (params) => ({
        url: "/entitlements",
        method: "get",
        params: params,
      }),
      transformResponse: (response: components["schemas"]["PageEntitlementResponse"]) => ({
        ...response,
        page: response.page ? response.page + 1 : undefined,
      }),
    }),
  }),
});

export const { useGetEntitlementsQuery, usePrefetch } = entitlementApi;
