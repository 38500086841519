import { RootState, useAppDispatch } from "app/store";
import { useParams } from "react-router";
import React, { ChangeEvent, useEffect, useState } from "react";
import { InAppPurchaseRecordsParams } from "types/dto/request/customer";
import { DEFAULT_PAGINATION } from "constants/common";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { message } from "components";
import { SorterResult } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Table, TableProps } from "antd";
import { InAppPurchaseRecordModel } from "types/model/customer";
import { fetchInAppPurchaseRecords } from "../customerSlice";
import columnConfig from "./columnConfig";

import styles from "./InAppPurchaseRecord.module.scss";
import { getTotalDisplay } from "../../../utils/getTotalDisplay";

function InAppPurchaseRecord() {
  const dispatch = useAppDispatch();
  let { customerId } = useParams<{ customerId: string }>();
  const [filter, setFilter] = useState<InAppPurchaseRecordsParams>({
    ...DEFAULT_PAGINATION,
    sort: "createdDate,DESC",
    customerId: Number(customerId),
  });

  const [productName, setProductName] = useState("");

  const {
    inAppPurchaseRecords: { page, size, content, totalElements },
  } = useSelector((state: RootState) => state.customer);

  useEffect(() => {
    customerId && dispatch(fetchInAppPurchaseRecords(filter)).then(unwrapResult).catch(message.error);
  }, [dispatch, filter]);

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setFilter((prevState) => ({
      ...prevState,
      page: page - 1,
      size: pageSize,
    }));
  };

  const handleTableChange: TableProps<InAppPurchaseRecordModel>["onChange"] = (pagination, filters, sorter, extra) => {
    const sortColumn = sorter as SorterResult<InAppPurchaseRecordModel>;
    if ("sort" === extra.action) {
      setFilter((prevState) => ({
        ...prevState,
        page: undefined,
        sort: `${sortColumn.field},${sortColumn.order === "ascend" ? "ASC" : "DESC"}`,
      }));
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductName(e.target.value);
  };

  const handlePressEnter = () => {
    setProductName((prevState) => prevState.trim());
    setFilter((prevState) => ({
      ...prevState,
      page: undefined,
      productName: productName.trim(),
    }));
  };

  return (
    <>
      <Input
        allowClear
        value={productName}
        prefix={<SearchOutlined />}
        onChange={handleInputChange}
        onBlur={() => setProductName((prevState) => prevState.trim())}
        onPressEnter={handlePressEnter}
        className={styles.productInput}
        placeholder="Search product name"
      />
      <Table
        className={styles.table}
        columns={columnConfig}
        dataSource={content}
        rowKey={(row) => row.id}
        onChange={handleTableChange}
        pagination={{
          showTotal: getTotalDisplay,
          total: totalElements,
          current: page + 1,
          pageSize: size,
          showSizeChanger: true,
          onChange: handlePaginationChange,
        }}
      />
    </>
  );
}

export default InAppPurchaseRecord;
