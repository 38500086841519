import { useAuth0 } from "@auth0/auth0-react";
import { Permission } from "../types/permission";

const useAppAuth0 = () => {
  const { REACT_APP_AUTH0_AUDIENCE } = process.env;
  const { user } = useAuth0();

  user.hasPermission = (permission: Permission): boolean => {
    return user[REACT_APP_AUTH0_AUDIENCE]?.permissions.includes(permission);
  };

  user.canDeleteCustomer = user.hasPermission(Permission.DELETE_CUSTOMER);

  user.canRestoreEntitlements = user.hasPermission(Permission.RESTORE_ENTITLEMENTS);

  user.canCreateProduct = user.hasPermission(Permission.CREATE_PRODUCT);
  user.canViewProductMenu = user.hasPermission(Permission.VIEW_PRODUCT_MENU);
  user.canEditProduct = user.hasPermission(Permission.UPDATE_PRODUCT);
  user.canVerifyProduct = user.hasPermission(Permission.VERIFY_PRODUCT);
  user.canPublishProduct = user.hasPermission(Permission.PUBLISH_PRODUCT);
  user.canDeleteProduct = user.hasPermission(Permission.DELETE_PRODUCT);

  user.canViewMiniCourseMenu = user.hasPermission(Permission.VIEW_MINI_COURSE_MENU);
  user.canReadMiniCourse = user.hasPermission(Permission.READ_MINI_COURSE);
  user.canVerifyMiniCourse = user.hasPermission(Permission.VERIFY_MINI_COURSE);
  user.canPublishMiniCourse = user.hasPermission(Permission.PUBLISH_MINI_COURSE);

  user.canCreateVoucherGroup = user.hasPermission(Permission.CREATE_VOUCHER_GROUP);
  user.canEditVoucherGroup = user.hasPermission(Permission.UPDATE_VOUCHER_GROUP);
  user.canDeleteVoucherGroup = user.hasPermission(Permission.DELETE_VOUCHER_GROUP);

  user.canCreateEcomProduct = user.hasPermission(Permission.CREATE_ECOM_PRODUCT);
  user.canDeleteEcomProduct = user.hasPermission(Permission.DELETE_ECOM_PRODUCT);
  user.canCreateEcomPlan = user.hasPermission(Permission.CREATE_ECOM_PLAN);
  user.canUpdateEcomPlan = user.hasPermission(Permission.UPDATE_ECOM_PLAN);
  user.canDeleteEcomPlan = user.hasPermission(Permission.DELETE_ECOM_PLAN);
  user.canRevertEcomPlanToDraft = user.hasPermission(Permission.REVERT_ECOM_PLAN_TO_DRAFT);
  user.canReadyForPublishEcomPlan = user.hasPermission(Permission.READY_FOR_PUBLISH_ECOM_PLAN);
  user.canDeactivateEcomPlan = user.hasPermission(Permission.DEACTIVATE_ECOM_PLAN);
  user.canActivateEcomPlan = user.hasPermission(Permission.ACTIVATE_ECOM_PLAN);
  user.canPreviewEcomPlanCheckout = user.hasPermission(Permission.PREVIEW_PRODUCT_PLAN);
  user.canPublishEcomProduct = user.hasPermission(Permission.PUBLISH_ECOM_PRODUCT);

  user.canRefundTransaction = user.hasPermission(Permission.REFUND_TRANSACTION);
  user.canUpdateGiftCardBalance = user.hasPermission(Permission.UPDATE_GIFT_CARD_BALANCE);
  user.canUpdateGift = user.hasPermission(Permission.UPDATE_GIFT);

  user.canCreateBasePrice = user.hasPermission(Permission.CREATE_BASE_PRICE);
  user.canConfigureCurrency = user.hasPermission(Permission.CONFIGURE_CURRENCY);
  user.canUpdateBasePrice = user.hasPermission(Permission.UPDATE_BASE_PRICE);
  user.canActivateBasePrice = user.hasPermission(Permission.ACTIVATE_BASE_PRICE);
  user.canDeactivateBasePrice = user.hasPermission(Permission.DEACTIVATE_BASE_PRICE);
  user.canDeleteBasePrice = user.hasPermission(Permission.DELETE_BASE_PRICE);

  user.canCreateDisclosure = user.hasPermission(Permission.CREATE_DISCLOSURE);
  user.canUpdateDisclosure = user.hasPermission(Permission.UPDATE_DISCLOSURE);
  user.canDeleteDisclosure = user.hasPermission(Permission.DELETE_DISCLOSURE);
  user.canSwitchDisclosureStatus = user.hasPermission(Permission.SWITCH_DISCLOSURE_STATUS);

  user.canCreateDescription = user.hasPermission(Permission.CREATE_DESCRIPTION_TEMPLATE);
  user.canUpdateDescription = user.hasPermission(Permission.UPDATE_DESCRIPTION_TEMPLATE);
  user.canDeleteDescription = user.hasPermission(Permission.DELETE_DESCRIPTION_TEMPLATE);

  user.canCreateUpsellDowngrade = user.hasPermission(Permission.CREATE_UPSELL_DOWNGRADE);
  user.canUpdateUpsellDowngrade = user.hasPermission(Permission.UPDATE_UPSELL_DOWNGRADE);
  user.canDeleteUpsellDowngrade = user.hasPermission(Permission.DELETE_UPSELL_DOWNGRADE);

  user.canCreatePromotion = user.hasPermission(Permission.CREATE_PROMOTION);
  user.canUpdatePromotion = user.hasPermission(Permission.UPDATE_PROMOTION);
  user.canDeletePromotion = user.hasPermission(Permission.DELETE_PROMOTION);

  user.canUpdateB2CCustomerInfo = user.hasPermission(Permission.UPDATE_B2C_CUSTOMER_INFO);
  user.canCreateB2CCustomerEntitlement = user.hasPermission(Permission.CREATE_ENTITLEMENT);
  user.canUpdateB2CCustomerEntitlement = user.hasPermission(Permission.UPDATE_ENTITLEMENT);
  user.canRevokeB2CCustomerEntitlement = user.hasPermission(Permission.REVOKE_ENTITLEMENT);
  user.canUpdateB2CCustomerSubscription = user.hasPermission(Permission.UPDATE_SUBSCRIPTION);

  user.canConfigureCancelReason = user.hasPermission(Permission.CONFIGURE_CANCEL_REASON);
  user.canCreateSaveOffer = user.hasPermission(Permission.CREATE_SAVE_OFFER);
  user.canUpdateSaveOffer = user.hasPermission(Permission.UPDATE_SAVE_OFFER);
  user.canDeleteSaveOffer = user.hasPermission(Permission.DELETE_SAVE_OFFER);

  return { ...useAuth0() };
};

export default useAppAuth0;
