import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _, { isEmpty, join } from "lodash";
import { Button, Checkbox, Col, Divider, Form, Input, Radio, Row, Select } from "antd";
import { FormInstance } from "antd/es/form";
import { DropDown, Minus, Plus } from "assets/icons";
import { RootState, useAppDispatch } from "app/store";
import { SEPARATOR } from "constants/common";
import Icon from "@ant-design/icons";
import { upsellOptions } from "../../../entitlements/RList/utils";
import styles from "./ProductUpsellForm.module.scss";
import NamiSupportCheckbox from "../../../../components/NamiSupportCheckbox";
import { fetchPromotionData, fetchUpgradePromotionData } from "../AddNewProduct/addProductSlice";
import { message } from "../../../../components";

const DISABLE_CHECKBOX_TYPES = ["DVD", "MULTI_LEVEL", "SUBSCRIPTION", "UPGRADE", "UPSELL", "ALL_ACCESS"];

function ProductUpsellForm({ form }: { form: FormInstance }) {
  const {
    inAppUpsellProducts,
    upgradeProducts,
    inAppSubscriptionUpsellProducts,
    eComStandardProducts,
    eComPremiumProducts,
    eComAllAccessProducts,
    productInfo,
    allAccessInfo,
    promotionData,
    upgradePromotionData,
  } = useSelector((state: RootState) => state.addProduct);
  const dispatch = useAppDispatch();
  const [hasUpgradePromotion, setHasUpgradePromotion] = useState(
    !_.isEmpty(form.getFieldValue("upgradePromotionCode"))
  );
  const productUpsells = form.getFieldValue("productUpsells");
  const productType = form.getFieldValue("productType");
  const namiGroups = form.getFieldValue("namiGroups");
  const isPremium = form.getFieldValue("isPremium");
  const eComStandardSubscriptionProducts = eComStandardProducts.filter(
    (product) => product.contentProductType === "Subscription"
  );
  const eComPremiumSubscriptionProducts = eComPremiumProducts.filter(
    (product) => product.contentProductType === "Subscription"
  );
  const eComStandardUpsellProducts = eComStandardProducts.filter(
    (product) => product.contentProductType === "Single level"
  );
  const eComPremiumUpsellProducts = eComPremiumProducts.filter(
    (product) => product.contentProductType === "Single level"
  );
  const upgradeEComProducts =
    productType === "FREE" ? [] : eComPremiumProducts.filter((product) => product.contentProductType === "Upgrade");
  const subscriptionEComProducts = isPremium
    ? [...eComAllAccessProducts, ...eComPremiumSubscriptionProducts]
    : [...eComAllAccessProducts, ...eComStandardSubscriptionProducts];
  const upsellEComProducts = isPremium ? [...eComPremiumUpsellProducts] : [...eComStandardUpsellProducts];

  const combinedProductInfo = [...allAccessInfo, ...productInfo];
  const disableCheckboxByType = DISABLE_CHECKBOX_TYPES.includes(productType);
  const [showNamiSection, setShowNamiSection] = useState(productUpsells?.length > 0);
  const [isSupportedByNamiChecked, setIsSupportedByNamiChecked] = useState(
    form.getFieldValue("isUpsellSupportedByNami") || false
  );
  const currentProductId = form.getFieldValue("id");
  const currentUpsells = useSelector((state: RootState) => state.products.entities)[currentProductId]?.productUpsells;
  const defaultProductGroups = currentUpsells?.map((upsell) => `${upsell.upsellInAppIsbn}`) || [];

  useEffect(() => {
    productUpsells?.forEach((upsell: { upsellPromotionCode?: string; upsellEcomPid?: string }, index: number) => {
      !_.isEmpty(upsell.upsellPromotionCode) &&
        dispatch(
          fetchPromotionData({
            index,
            id: getBasePricesIdByEComPID(upsell.upsellEcomPid ?? ""),
          })
        ).catch(message.error);
    });
  }, []);

  const handleUpsellTypeChange = (index: number) => {
    const { productUpsells } = form.getFieldsValue();
    productUpsells[index] = {
      ...productUpsells[index],
      upsellInAppId: null,
      upsellEcomPid: null,
      hasEComPromotionCode: false,
    };
    form.setFieldsValue({
      productUpsells,
    });
  };

  const handleClickPromotion = (index: number, hasEComPromotionCode: boolean) => {
    const { productUpsells } = form.getFieldsValue();
    productUpsells[index] = {
      ...productUpsells[index],
      hasEComPromotionCode,
      upsellPromotionCode: null,
    };
    form.setFieldsValue({
      productUpsells,
    });
  };

  const getBasePricesIdByEComPID = (pid: string) => {
    return (
      [...eComAllAccessProducts, ...eComPremiumProducts, ...eComStandardProducts].find((product) => product.pid === pid)
        ?.basePricesId ?? ""
    );
  };

  const handleChangeEComUpsell = (index: number, value?: string) => {
    const { productUpsells } = form.getFieldsValue();
    productUpsells[index] = {
      ...productUpsells[index],
      upsellPromotionCode: null,
    };
    form.setFieldsValue({
      productUpsells,
    });
    value && dispatch(fetchPromotionData({ index, id: getBasePricesIdByEComPID(value) })).catch(message.error);
  };

  return (
    <div className={styles.container}>
      <Col span={24}>
        <h3>Upsell info: (upsell from the end of a level)</h3>
      </Col>
      <Form.List name="productUpsells">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <React.Fragment key={index}>
                <Row gutter={24} style={{ width: "1056px", alignItems: "baseline" }}>
                  <Col span={3}>
                    <h4>Upsell Card {index + 1}</h4>
                  </Col>
                  <Col span={1}>
                    <Button
                      className={styles.minusBtn}
                      onClick={() => {
                        remove(field.name);
                        fields.length <= 1 && setShowNamiSection(false);
                      }}
                      type="link"
                      icon={<Icon component={Minus} />}
                    />
                  </Col>
                </Row>
                <Row gutter={24} style={{ width: "1056px" }}>
                  <Col span={12}>
                    <Form.Item
                      {...field}
                      label={"Upsell Type"}
                      rules={[
                        {
                          required: true,
                          message: "'Upsell Type' is required",
                        },
                      ]}
                      name={[field.name, "upsellType"]}
                      initialValue={productUpsells[index]?.upsellType || "SUBSCRIPTION"}
                      fieldKey={[field.fieldKey, "upsellType"]}
                      className={styles.smallFormItem}
                    >
                      <Radio.Group options={upsellOptions} onChange={() => handleUpsellTypeChange(index)} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} style={{ width: "1056px" }}>
                  <Form.Item noStyle shouldUpdate={true}>
                    {(formInstance) => {
                      const currentProductUpsells = formInstance.getFieldValue("productUpsells") || productUpsells;
                      const { upsellType } = currentProductUpsells[index];
                      let options = inAppSubscriptionUpsellProducts;
                      if (upsellType === "UPSELL") {
                        options = inAppUpsellProducts;
                      } else if (upsellType === "UPGRADE") {
                        options = upgradeProducts;
                      }

                      return (
                        <Col span={11}>
                          <Form.Item
                            {...field}
                            label="In App Upsell ISBN"
                            rules={[
                              {
                                required: true,
                                message: "'In App Upsell ISBN' is required",
                              },
                            ]}
                            name={[field.name, "upsellInAppId"]}
                          >
                            <Select
                              allowClear
                              showSearch
                              listHeight={160}
                              optionFilterProp={"label"}
                              getPopupContainer={(trigger) => trigger.parentNode}
                              suffixIcon={<DropDown />}
                              placeholder={"Please choose ISBN"}
                              options={options.map((productOption) => ({
                                label: join([productOption.productCode, productOption.productName], SEPARATOR),
                                value: productOption.productId,
                              }))}
                            />
                          </Form.Item>
                        </Col>
                      );
                    }}
                  </Form.Item>
                </Row>
                <Row gutter={24} style={{ width: "1056px" }}>
                  <Form.Item noStyle shouldUpdate={true}>
                    {(formInstance) => {
                      const currentProductUpsells = formInstance.getFieldValue("productUpsells") || productUpsells;
                      const { upsellType } = currentProductUpsells[index];
                      let eComOptions = subscriptionEComProducts;
                      if (upsellType === "UPSELL") {
                        eComOptions = upsellEComProducts;
                      } else if (upsellType === "UPGRADE") {
                        eComOptions = upgradeEComProducts;
                      }
                      let promotionOptions = promotionData[index];
                      if (upsellType === "SUBSCRIPTION") {
                        promotionOptions = promotionOptions?.filter((item) => item.isSubscription);
                      } else {
                        promotionOptions = promotionOptions?.filter((item) => !item.isSubscription);
                      }

                      return (
                        <>
                          <Col span={11}>
                            <Form.Item
                              {...field}
                              label="eCom Upsell PID"
                              name={[field.name, "upsellEcomPid"]}
                              rules={[
                                {
                                  validator: (_, value) => {
                                    const hasEComPromotionCode = form.getFieldValue("productUpsells")[index]
                                      ?.hasEComPromotionCode;
                                    if (!value && hasEComPromotionCode) {
                                      return Promise.reject(new Error("Please select a PID first."));
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                },
                              ]}
                            >
                              <Select
                                allowClear
                                showSearch
                                listHeight={160}
                                optionFilterProp={"label"}
                                getPopupContainer={(trigger) => trigger.parentNode}
                                suffixIcon={<DropDown />}
                                placeholder={"Please choose PID"}
                                options={eComOptions.map((eComProduct) => ({
                                  label: join(
                                    [
                                      eComProduct.pid,
                                      combinedProductInfo.find(
                                        (product) => product.isbn === eComProduct.pid.split("_")[0]
                                      )?.courseName,
                                      eComProduct.planName,
                                    ],
                                    SEPARATOR
                                  ),
                                  value: eComProduct.pid,
                                }))}
                                className={styles.dropdown}
                                onChange={(v) => {
                                  handleChangeEComUpsell(index, v as string);
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              {...field}
                              name={[field.name, "hasEComPromotionCode"]}
                              initialValue={!_.isEmpty(productUpsells[index]?.upsellPromotionCode)}
                            >
                              <Checkbox
                                checked={form.getFieldValue("productUpsells")[index]?.hasEComPromotionCode}
                                onChange={() => {
                                  const currentProductUpsells = form.getFieldValue("productUpsells")[index];
                                  handleClickPromotion(index, !currentProductUpsells?.hasEComPromotionCode);
                                  form.validateFields([["productUpsells", index, "upsellEcomPid"]]).then(() => {
                                    !currentProductUpsells?.hasEComPromotionCode &&
                                      _.isEmpty(currentProductUpsells.upsellPromotionCode) &&
                                      dispatch(
                                        fetchPromotionData({
                                          index,
                                          id: getBasePricesIdByEComPID(currentProductUpsells.upsellEcomPid),
                                        })
                                      ).catch(message.error);
                                  });
                                }}
                                className={styles.checkbox}
                              >
                                eCom Promotion Code
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          {form.getFieldValue("productUpsells")[index]?.hasEComPromotionCode && (
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                label="eCom Promotion Code"
                                name={[field.name, "upsellPromotionCode"]}
                                rules={[
                                  {
                                    validator: (_, value) => {
                                      const hasEComPromotionCode = form.getFieldValue("productUpsells")[index]
                                        ?.hasEComPromotionCode;
                                      if (!value && hasEComPromotionCode) {
                                        return Promise.reject(new Error("'eCom Promotion Code' is required."));
                                      } else {
                                        return Promise.resolve();
                                      }
                                    },
                                  },
                                ]}
                              >
                                <Select
                                  allowClear
                                  showSearch
                                  listHeight={160}
                                  optionFilterProp={"label"}
                                  getPopupContainer={(trigger) => trigger.parentNode}
                                  suffixIcon={<DropDown />}
                                  placeholder={"Please choose promotion code"}
                                  options={promotionOptions?.map((promotion) => ({
                                    label: join([promotion.code, promotion.status], SEPARATOR),
                                    value: promotion.code,
                                  }))}
                                />
                              </Form.Item>
                            </Col>
                          )}
                        </>
                      );
                    }}
                  </Form.Item>
                </Row>
                {fields.length - 1 > index && <Divider />}
              </React.Fragment>
            ))}
            <Button
              className={styles.addNewUpsellBtn}
              onClick={() => {
                add();
                setShowNamiSection(true);
              }}
              type="link"
              icon={<Icon component={Plus} />}
            >
              Add new upsell
            </Button>
            <Divider />
          </>
        )}
      </Form.List>

      {showNamiSection && (
        <>
          <Form.Item name={"isUpsellSupportedByNami"} noStyle>
            <NamiSupportCheckbox
              checked={isSupportedByNamiChecked}
              onCheckboxChange={(isChecked) => {
                form.setFieldsValue({ isUpsellSupportedByNami: isChecked });
                setIsSupportedByNamiChecked(isChecked);
                isEmpty(namiGroups) &&
                  form.setFieldsValue({
                    namiGroups: !isEmpty(defaultProductGroups) ? defaultProductGroups : [""],
                  });
              }}
              disabled={disableCheckboxByType}
            />
          </Form.Item>
          {isSupportedByNamiChecked && (
            <Form.Item label="Nami Paywall Product Group:">
              {
                <Form.List name={"namiGroups"}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item key={index}>
                          <Row gutter={24} style={{ width: "1056px" }}>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                noStyle
                                rules={[
                                  {
                                    required: true,
                                    pattern: new RegExp("^[0-9a-zA-Z_-]{1,100}$", "g"),
                                    message: "Please enter an alphanumeric value with no special characters.",
                                  },
                                ]}
                              >
                                <Input placeholder={`Please enter product group id`} />
                              </Form.Item>
                            </Col>
                            {fields.length > 1 && (
                              <Button
                                className={styles.minusBtn}
                                onClick={() => remove(field.name)}
                                type="link"
                                icon={<Icon component={Minus} />}
                              />
                            )}
                          </Row>
                        </Form.Item>
                      ))}
                      <Button
                        className={styles.addNewUpsellBtn}
                        onClick={() => add()}
                        type="link"
                        icon={<Icon component={Plus} />}
                      >
                        Add new product group
                      </Button>
                    </>
                  )}
                </Form.List>
              }
            </Form.Item>
          )}
          <Divider />
        </>
      )}

      <Row gutter={24} style={{ width: "1056px" }}>
        <Col span={24}>
          <h3>One-shot Upgrade info: (upgrade from locked premium features)</h3>
        </Col>
        <Col span={11}>
          <Form.Item label="Upgrade ISBN" name={"upgradeId"}>
            <Select
              allowClear
              showSearch
              listHeight={160}
              optionFilterProp={"label"}
              getPopupContainer={(trigger) => trigger.parentNode}
              suffixIcon={<DropDown />}
              placeholder={"Please choose ISBN"}
              options={upgradeProducts.map((upgradeProduct) => ({
                label: join([upgradeProduct.productCode, upgradeProduct.productName], SEPARATOR),
                value: upgradeProduct.productId,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} style={{ width: "1056px" }}>
        <Col span={11}>
          <Form.Item
            label="eCom Upgrade PID"
            name={"upgradeEcomPid"}
            rules={[
              {
                validator: (_, value) => {
                  if (!value && hasUpgradePromotion) {
                    return Promise.reject(new Error("Please select a PID first."));
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              listHeight={160}
              optionFilterProp={"label"}
              getPopupContainer={(trigger) => trigger.parentNode}
              suffixIcon={<DropDown />}
              placeholder={"Please choose PID"}
              options={upgradeEComProducts.map((eComProduct) => ({
                label: join(
                  [
                    eComProduct.pid,
                    combinedProductInfo.find((product) => product.isbn === eComProduct.pid.split("_")[0])?.courseName,
                    eComProduct.planName,
                  ],
                  SEPARATOR
                ),
                value: eComProduct.pid,
              }))}
              className={styles.dropdown}
              onChange={(value) => {
                form.setFieldsValue({
                  upgradePromotionCode: null,
                });
                value &&
                  dispatch(fetchUpgradePromotionData(getBasePricesIdByEComPID(value as string))).catch(message.error);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Checkbox
            checked={hasUpgradePromotion}
            onChange={() => {
              setHasUpgradePromotion(!hasUpgradePromotion);
              form.validateFields([["upgradeEcomPid"]]).then(() => {
                !hasUpgradePromotion &&
                  _.isEmpty(form.getFieldValue("upgradePromotionCode")) &&
                  dispatch(
                    fetchUpgradePromotionData(getBasePricesIdByEComPID(form.getFieldValue("upgradeEcomPid")))
                  ).catch(message.error);
              });
            }}
            className={styles.checkbox}
          >
            eCom Promotion Code
          </Checkbox>
        </Col>
        {hasUpgradePromotion && (
          <Col span={8}>
            <Form.Item
              label="eCom Promotion Code"
              name={"upgradePromotionCode"}
              rules={[
                {
                  validator: (_, value) => {
                    if (!value && hasUpgradePromotion) {
                      return Promise.reject(new Error("'eCom Promotion Code' is required."));
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                listHeight={160}
                optionFilterProp={"label"}
                getPopupContainer={(trigger) => trigger.parentNode}
                suffixIcon={<DropDown />}
                placeholder={"Please choose promotion code"}
                options={upgradePromotionData
                  ?.filter((promotion) => !promotion.isSubscription)
                  .map((promotion) => ({
                    label: join([promotion.code, promotion.status], SEPARATOR),
                    value: promotion.code,
                  }))}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </div>
  );
}

export default ProductUpsellForm;
