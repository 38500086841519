import React from "react";
import { Col, Divider, Form, FormInstance, Row, Space } from "antd";
import { DisplayInput } from "../../../../components";
import styles from "./index.module.scss";

interface IDescriptionDetailsViewProps {
  form: FormInstance;
}

export function UpsellDowngradeDetailsView({ form }: IDescriptionDetailsViewProps) {
  const renderBasicRow = (labelPrefix: string, namePrefix: string) => (
    <Row gutter={32}>
      <Col span={12}>
        <Form.Item name={`${namePrefix}BasePriceName`} label={`${labelPrefix} base price`}>
          <DisplayInput />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name={`${namePrefix}ProductType`} label={`${labelPrefix} product type`}>
          <DisplayInput />
        </Form.Item>
      </Col>
      {form.getFieldValue(`${namePrefix}CourseLevel`) && (
        <Col span={6}>
          <Form.Item name={`${namePrefix}CourseLevelName`} label={`${labelPrefix} course level`}>
            <DisplayInput />
          </Form.Item>
        </Col>
      )}
      {form.getFieldValue(`${namePrefix}BillingPlanId`) && (
        <Col span={6}>
          <Form.Item name={`${namePrefix}BillingPlanName`} label={`${labelPrefix} billing plan`}>
            <DisplayInput />
          </Form.Item>
        </Col>
      )}
    </Row>
  );

  const renderTagAndSlogan = (labelPrefix: string, namePrefix: string) => (
    <Row gutter={32}>
      <Col span={12}>
        <Form.Item name={`${namePrefix}Tag`} label={`${labelPrefix} tag`}>
          <DisplayInput />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name={`${namePrefix}Slogan`} label={`${labelPrefix} promotional slogan`}>
          <DisplayInput />
        </Form.Item>
      </Col>
    </Row>
  );

  return (
    <Form className={styles.upsellDowngradeDetailContainer} form={form} layout="vertical">
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <Space direction="vertical" size={24} className={styles.formSections}>
          <header className={styles.subtitle}>Original base price</header>
          {renderBasicRow("Original", "original")}
        </Space>

        <Divider />

        <Space direction="vertical" size={24} className={styles.formSections}>
          <header className={styles.subtitle}>Upsell</header>
          {renderBasicRow("Primary upsell", "primaryUpsell")}
          {renderTagAndSlogan("Primary upsell", "primaryUpsell")}
          <Divider />
          {renderBasicRow("Secondary upsell", "secondaryUpsell")}
          {renderTagAndSlogan("Secondary upsell", "secondaryUpsell")}
        </Space>

        <Divider />

        <Space direction="vertical" size={24} className={styles.formSections}>
          <header className={styles.subtitle}>Downgrade base price</header>
          {renderBasicRow("Downgrade", "downgrade")}
        </Space>
      </Space>
    </Form>
  );
}
