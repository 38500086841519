import { Form, ConfigProvider, Input, message } from "antd";
import { useState } from "react";
import modalStyles from "../../../../../components/EComModal/index.module.scss";
import { EComModal } from "../../../../../components/EComModal";
import { validateMessages } from "../../../../../constants/common";
import * as CustomerAPI from "../../../../../services/customer";

interface IEditUserNameModalProps {
  customerId: number;
  visible: boolean;
  name: string;
  onFinish: () => void;
  closeModal: () => void;
}

export default function EditUserNameModal({
  customerId,
  visible,
  name,
  onFinish,
  closeModal,
}: IEditUserNameModalProps) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [givenName, familyName] = getName();

  function getName() {
    const result = name.split(" ", 2);
    console.log(result);
    result[1] = name.substring(result[0].length + 1);
    return result;
  }

  async function onSubmit() {
    setIsLoading(true);
    try {
      const values = await form.validateFields();
      await CustomerAPI.updateUsername(customerId, {
        givenName: values.givenName.trim(),
        familyName: values.familyName.trim(),
      });
      message.success("Update successful");
      onFinish();
      closeModal();
    } catch (e) {
      message.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <EComModal
      title="Edit customer name"
      visible={visible}
      okText="Confirm"
      onCancel={closeModal}
      onOk={onSubmit}
      style={modalStyles.modal}
      confirmLoading={isLoading}
      destroyOnClose
    >
      <ConfigProvider form={{ validateMessages }}>
        <Form
          className={modalStyles.form}
          form={form}
          initialValues={{
            givenName,
            familyName,
          }}
        >
          <Form.Item
            label="First Name:"
            name="givenName"
            required
            className={modalStyles.formItem}
            rules={[{ required: true }]}
          >
            <Input maxLength={50} />
          </Form.Item>
          <Form.Item
            label="Last Name:"
            name="familyName"
            required
            className={modalStyles.formItem}
            rules={[{ required: true }]}
          >
            <Input maxLength={50} />
          </Form.Item>
        </Form>
      </ConfigProvider>
    </EComModal>
  );
}
