import React from "react";
import moment from "moment";
import { ColumnType } from "antd/lib/table";
import { DATETIME_FORMAT } from "constants/common";
import { DisclosureModel, DisclosureStatus } from "../../../types/model/disclosure";
import CustomDot from "../../../components/CustomDot";

function getTableColumns(): ColumnType<DisclosureModel>[] {
  return [
    {
      title: "Disclosure name",
      key: "name",
      dataIndex: "name",
      width: 220,
      ellipsis: true,
      render: (value) => value || "-",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 100,
      ellipsis: true,
      render: (value: DisclosureStatus) => <CustomDot color={value} />,
    },
    {
      title: "Applied base price",
      key: "appliedBasePrice",
      dataIndex: "appliedBasePrice",
      width: 188,
      ellipsis: true,
      render: (value) => {
        return value.map((item: { name: string }) => item.name).join(", ") || "-";
      },
    },
    {
      title: "Applied billing plan",
      key: "appliedBillingPlan",
      dataIndex: "appliedBillingPlan",
      width: 188,
      ellipsis: true,
      render: (value) => value?.name || "-",
    },
    {
      title: "Applied Country/Region",
      key: "appliedCountryOrRegion",
      dataIndex: "appliedCountryOrRegion",
      width: 188,
      ellipsis: true,
      render: (value) => {
        return value?.map((item: { displayName: string }) => item.displayName).join(", ") || "-";
      },
    },
    {
      title: "Modified at",
      key: "updatedAt",
      dataIndex: "updatedAt",
      width: 176,
      ellipsis: true,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (value) => moment(value).format(DATETIME_FORMAT),
    },
  ];
}

export default getTableColumns;
