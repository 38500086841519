import React, { useEffect, useState } from "react";
import { Table, TableProps } from "antd";
import { RootState, useAppDispatch } from "app/store";
import { toNumber } from "lodash";
import { useSelector } from "react-redux";
import { SorterResult } from "antd/lib/table/interface";
import { getTotalDisplay } from "utils/getTotalDisplay";
import { useParams } from "react-router-dom";
import { DEFAULT_PAGINATION } from "constants/common";
import { ProductChangeLogParams } from "types/dto/request/product";
import { ProductChangeLog } from "types/model/product";
import { fetchProductChangeLogs } from "../../productSlice";
import useColumns from "./useColumns";

function ProductChangeLogView() {
  const dispatch = useAppDispatch();
  const { productId } = useParams<{ productId: string }>();
  const columns = useColumns();
  const {
    productChangeLogs: { page, size, content, totalElements },
  } = useSelector((state: RootState) => state.products);
  const [filter, setFilter] = useState<ProductChangeLogParams>({
    ...DEFAULT_PAGINATION,
    sort: "createdDate,DESC",
    productId: toNumber(productId),
  });

  useEffect(() => {
    dispatch(fetchProductChangeLogs(filter));
  }, [dispatch, filter]);

  const handleTableChange: TableProps<ProductChangeLog>["onChange"] = (pagination, filters, sorter, extra) => {
    const { order } = sorter as SorterResult<ProductChangeLog>;
    if (extra.action === "sort") {
      order &&
        setFilter((prevState) => ({
          ...prevState,
          page: undefined,
          sort: `createdDate,${order === "ascend" ? "ASC" : "DESC"}`,
        }));
    }
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setFilter((prevState) => ({
      ...prevState,
      page: page - 1,
      size: pageSize,
    }));
  };

  return (
    <Table
      dataSource={content}
      columns={columns}
      rowKey={(record) => record.id}
      onChange={handleTableChange}
      pagination={{
        showTotal: getTotalDisplay,
        total: totalElements,
        showSizeChanger: true,
        current: page + 1,
        pageSize: size,
        onChange: handlePageChange,
      }}
    />
  );
}

export default ProductChangeLogView;
