import React, { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";
import { Button, Form, message } from "antd";
import EntitlementForm from "./EntitlementForm";
import { EComModal } from "../../../components/EComModal";
import { createEntitlement } from "../../../services";
import { fetchEntitlementList } from "../customerSlice";
import { useAppDispatch } from "../../../app/store";

export default function AddEntitlement({ auth0Id }: any) {
  const [form] = Form.useForm();
  const { customerId } = useParams<{ customerId: string }>();
  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const saveEntitlement = () => {
    form.validateFields().then(() => {
      setConfirmLoading(true);
      const { isbn, expirationDate } = form.getFieldsValue();
      createEntitlement({
        isbn,
        auth0Id,
        expirationTimestamp: expirationDate.valueOf(),
      })
        .then(() => {
          closeModal();
          dispatch(fetchEntitlementList(Number(customerId)))
            .then(unwrapResult)
            .catch(message.error);
        })
        .catch((error) => {
          message.error(error);
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    });
  };

  function closeModal() {
    setIsModalVisible(false);
    form.resetFields();
  }

  return (
    <>
      <Button type="default" shape="round" style={{ marginRight: "16px" }} onClick={() => setIsModalVisible(true)}>
        Add new entitlement
      </Button>
      <EComModal
        visible={isModalVisible}
        title="Add new entitlement"
        okText="Save"
        onCancel={closeModal}
        onOk={saveEntitlement}
        confirmLoading={confirmLoading}
      >
        <EntitlementForm form={form} />
      </EComModal>
    </>
  );
}
