import { useRouteMatch } from "react-router-dom";
import MiniCourseList from "./MiniCourseList";
import { PrivateRoute } from "../../components";
import { Permission } from "../../types/permission";

function MiniCoursePage() {
  const { path } = useRouteMatch();

  return (
    <PrivateRoute exact path={path} permission={Permission.VIEW_MINI_COURSE_MENU}>
      <MiniCourseList />
    </PrivateRoute>
  );
}

export default MiniCoursePage;
