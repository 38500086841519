import { useParams } from "react-router";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { message, Spin } from "antd";
import { ISubscriptionModalProps } from "./types";
import { EComModal } from "../../../../components/EComModal";
import { RootState } from "../../../../app/store";
import { customerSelectors } from "../../customerSlice";
import { getCountryName, getPaymentMethodTextByPaymentMethod } from "../../../../types/model/paymentMethod";
import * as CheckoutAPI from "../../../../services/checkout";
import { Checkout, ProductVariantResponse } from "../../../../types/model/checkout";
import { EMPTY } from "../../../../constants/common";
import ModalContent from "../../../../components/EComModal/ModalContent";
import ModalListItem from "../../../../components/EComModal/ModalListItem";
import { Country } from "../../../../types/model/price";
import { fetchAllCountries } from "../../../../services/basePrice";

export default function RestartSubscriptionModal({
  subscription,
  visible,
  closeModal,
  onFinish,
}: ISubscriptionModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [checkoutInfo, setCheckoutInfo] = useState<Checkout>({} as Checkout);
  const [restartProductVariantInfo, setRestartProductVariantInfo] = useState<ProductVariantResponse>(
    {} as ProductVariantResponse
  );
  const { customerId } = useParams<{ customerId: string }>();
  const customer = useSelector((state: RootState) => customerSelectors.selectById(state, customerId));

  const [countries, setCountries] = useState([] as Country[]);
  useEffect(() => {
    fetchAllCountries().then((data) => setCountries(data));
  }, []);

  useEffect(() => {
    if (!visible) {
      return;
    }
    fetchProductAndCheckoutInfo();
  }, [visible]);

  async function fetchProductAndCheckoutInfo() {
    setIsFetching(true);
    try {
      const restartProductVariantRes = await CheckoutAPI.getRestartProductVariantByOldPid(subscription.pid);
      setRestartProductVariantInfo(restartProductVariantRes);
      const checkoutRes = await CheckoutAPI.getCheckoutInfo(restartProductVariantRes.pid, subscription.subscriptionId);
      setCheckoutInfo(checkoutRes);
    } catch (e) {
      message.error(`Product not found. Please make a new order.`);
    } finally {
      setIsFetching(false);
    }
  }

  async function onSubmit() {
    setIsLoading(true);
    try {
      const [givenName, familyName] = customer?.name.split(" ", 2) || [];
      await CheckoutAPI.checkout(restartProductVariantInfo.pid, subscription.subscriptionId, {
        givenName,
        familyName,
      });
      message.success("Restart successful");
      if (onFinish) {
        onFinish();
      }
      closeModal();
    } finally {
      setIsLoading(false);
    }
  }

  const { paymentMethod, orderSummary } = checkoutInfo;
  const { billingAddress } = paymentMethod || {};
  const { district, postalCode, country } = billingAddress || {};
  const { amount = 0, total = 0, tax = 0, currency } = orderSummary || {};
  const { symbol = "$" } = currency || {};

  return (
    <EComModal
      title="Restart subscription"
      visible={visible}
      okText="Restart"
      cancelText="Cancel"
      onCancel={closeModal}
      onOk={onSubmit}
      confirmLoading={isLoading}
    >
      <Spin size="large" spinning={isFetching}>
        <div>
          <p>Please verify the information below with the user. A new subscription will be created</p>
          <ModalContent>
            <ModalListItem label="Plan name:" value={restartProductVariantInfo?.title || ""} />
            <ModalListItem label="Billing plan:" value={restartProductVariantInfo?.billing_plan_name || ""} />
            <ModalListItem label="Customer name:" value={customer?.name || ""} />
            <ModalListItem
              label="Payment information:"
              value={paymentMethod ? getPaymentMethodTextByPaymentMethod(paymentMethod) : EMPTY}
            />
            <ModalListItem label="Billing address:" value={billingAddress ? getBillingAddressText() : EMPTY} />
            <ModalListItem label="Price:" value={`${symbol}${amount.toFixed(2)}`} />
            <ModalListItem label="Estimated Tax:" value={`${symbol}${tax?.toFixed(2)}`} />
            <ModalListItem label="Today’s Total:" value={`${symbol}${total.toFixed(2)}`} />
          </ModalContent>
        </div>
      </Spin>
    </EComModal>
  );

  function getBillingAddressText() {
    return (district ? `${district}, ` : "") + `${postalCode}, ${getCountryName(country ?? "", countries)}`;
  }
}
