import { Route, Switch, useRouteMatch } from "react-router-dom";
import EntitlementList from "./EntitlementList";
import RList from "./RList";

function EntitlementPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={EntitlementList} />
      <Route exact path={path + "/preview"} component={RList} />
    </Switch>
  );
}

export default EntitlementPage;
