import { Button, Card, Form, Space } from "antd";
import { Prompt } from "react-router-dom";
import React, { useState } from "react";
import { DeltaStatic } from "quill";
import { useHistory } from "react-router";
import { LEAVE_CONFIRMATION } from "../../../../constants/common";
import { message } from "../../../../components";
import * as descriptionTemplateAPI from "../../../../services/description";
import { getContentByLocale, LocaleType, supportLocalTabs } from "../../../../utils/locale";
import DescriptionTemplateForm from "../DescriptionDetails/DescriptionTemplateForm";
import { ContentBase, DescriptionTemplateFormData } from "../../../../types/model/description";

function AddNewDescriptionTemplate() {
  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState<ContentBase[]>(
    supportLocalTabs.map((item) => ({ ...item, content: {} as DeltaStatic }))
  );
  const [form] = Form.useForm<DescriptionTemplateFormData>();
  const history = useHistory();

  const handleCreate = () =>
    form
      .validateFields()
      .then(({ name }) => {
        setLoading(true);
        descriptionTemplateAPI
          .createDescriptionTemplate({
            name,
            enContent: getContentByLocale(contents, LocaleType.English),
            esContent: getContentByLocale(contents, LocaleType.Spanish),
          })
          .then(() => {
            history.push("/products/description");
            message.success("Description template added successfully.");
          })
          .finally(() => setLoading(false));
      })
      .catch(() => {
        message.error(new Error("Please fill in all required fields."));
      });

  return (
    <Card
      title={"Add new template"}
      extra={
        <Space size={10}>
          <Button type="default" shape="round" onClick={() => history.push("/products/description")}>
            Cancel
          </Button>
          <Button type="primary" shape="round" onClick={handleCreate} loading={loading}>
            Save
          </Button>
        </Space>
      }
      className="page-container"
    >
      <Prompt message={LEAVE_CONFIRMATION} when={!loading} />
      <DescriptionTemplateForm form={form} contents={contents} updateContents={setContents} />
    </Card>
  );
}

export default AddNewDescriptionTemplate;
