import moment from "moment";
import type { SortOrder } from "antd/lib/table/interface";

export function getSortOrder(sort?: string, order?: SortOrder) {
  if (sort && order) {
    return `${sort},${order === "ascend" ? "ASC" : "DESC"}`;
  }
  return undefined;
}

export function showTableFooter(total: number, range: [number, number]) {
  const [start, end] = range;
  return `${start}~${end} of ${total} items`;
}

const DATE_FORMAT = "YYYY-MM-DD";

export function getDateRange(startDate: number | undefined, endDate: number | undefined) {
  if (startDate && endDate) {
    return moment(startDate).format(DATE_FORMAT) + " ~ " + moment(endDate).format(DATE_FORMAT);
  } else if (startDate) {
    return moment(startDate).format(DATE_FORMAT) + " ~ *";
  } else {
    return "* ~ " + moment(endDate).format(DATE_FORMAT);
  }
}

export const sources = {
  ADMIN: "Admin",
  IOS_APPSTORE: "App store",
  ECOM: "eCom",
  GOOGLEPLAY: "Google play",
  DEMANDWARE: "SFCC",
  MG2: "Naviga",
  ECOM_MG2: "eCom Naviga",
  ECOM_GIFT: "eCom Gift",
  B2B_REDEMPTION: "Redemption",
  B2B_SEAT_ASSIGNMENT: "Seat",
  UNLIMITED_ACTIVATION: "Unlimited",
  EDT_SSDS: "SSDS",
  OKTA: "Okta",
} as const;

export const sourceOptions = Object.entries(sources).map(([key, val]) => ({
  value: key,
  label: val,
}));

export const subscriptionOptions = [
  { label: "Subscription", value: true },
  { label: "One-shot", value: false },
];

export const upsellOptions = [
  { label: "Subscription", value: "SUBSCRIPTION" },
  { label: "One-shot upsell", value: "UPSELL" },
  { label: "One-shot upgrade", value: "UPGRADE" },
];

export const statuses = {
  ACTIVE: "Active",
  EXPIRED: "Expired",
  CANCELED: "Canceled",
  PAUSED: "Paused",
  REVOKED: "Revoked",
} as const;

export const statusOptions = Object.entries(statuses).map(([key, val]) => ({
  value: key,
  label: val,
}));
