import { eComInstance } from "../utils/instance";
import { CheckoutResponse, Checkout, ProductVariantResponse } from "../types/model/checkout";
import { transformCheckout } from "../types/transformers/checkout";

export async function getCheckoutInfo(pid: string, subscriptionId: string): Promise<Checkout> {
  const url = "/checkout";
  const res = await eComInstance.get<CheckoutResponse>(url, {
    params: { pid, subscriptionId },
  });
  return transformCheckout(res);
}

export async function checkout(pid: string, subscriptionId: string, name: { givenName: string; familyName: string }) {
  const url = "/checkout";
  return eComInstance.post<CheckoutResponse>(
    url,
    {
      family_name: name.familyName,
      given_name: name.givenName,
    },
    {
      params: { pid, subscriptionId },
    }
  );
}

export async function getRestartProductVariantByOldPid(pid: string) {
  const url = `/variants/${pid}/no-free-trial`;
  return eComInstance.get<ProductVariantResponse>(url);
}

export async function sendCheckoutLink(email: string, checkoutLink: string, planName: string) {
  const url = "/checkout/send-link";
  return eComInstance.post(url, {
    email,
    checkout_link: checkoutLink,
    plan_name: planName,
  });
}
