import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Form, Row, Space } from "antd";
import moment from "moment";
import { CONNECTION_MAP, DATETIME_FORMAT, EMPTY } from "constants/common";
import { CustomerInfoResponse, CustomerResponse } from "types/dto/response/customer";
import { join } from "lodash";
import { DisplayInput } from "components";
import { CustomerTab } from "../CustomerList/CustomerList";

import styles from "./CustomerBasicInfo.module.scss";
import { Edit } from "../../../assets/icons";
import EditUserNameModal from "./components/modal/EditUserNameModal";
import EditUserEmailModal from "./components/modal/EditUserEmailModal";
import useAppAuth0 from "../../../hooks/useAppAuth0";
import { CustomerSubscriberInfo } from "../../../types/model/customer";
import EditMarketingOptInModal from "./components/modal/EditMarketingOptInModal";

interface Props {
  tab: CustomerTab;
  customer?: CustomerResponse & CustomerInfoResponse;
  customerSubscriberInfo?: CustomerSubscriberInfo;
  onFinishEditing: () => void;
}

function CustomerBasicInfo({ tab, customer, customerSubscriberInfo, onFinishEditing }: Props) {
  const [form] = Form.useForm();
  useEffect(() => form.setFieldsValue(customer), [form, customer]);
  const [editUsernameModalVisible, setEditUsernameModalVisible] = useState(false);
  const [editUserEmailModalVisible, setEditUserEmailModalVisible] = useState(false);
  const [editMarketingOptInModalVisible, setEditMarketingOptInModalVisible] = useState(false);
  const { user } = useAppAuth0();

  const connection = CONNECTION_MAP.get(customer?.connection as string) || EMPTY;

  return (
    <div className={styles.customerBasicInfoSection}>
      <Space size={24} direction={"vertical"} style={{ width: "100%" }}>
        <header className={styles.subtitle}>Basic information</header>
        <Form form={form} layout="vertical">
          <Row gutter={32}>
            <Col span={6}>
              <Form.Item label="Customer name" name="name">
                <DisplayInput
                  render={(text) => {
                    return (
                      <div className={styles.paragraph}>
                        <span>{text}</span>
                        {user.canUpdateB2CCustomerInfo && (
                          <Button
                            size="small"
                            type="text"
                            shape="circle"
                            onClick={() => {
                              setEditUsernameModalVisible(true);
                            }}
                          >
                            <Edit />
                          </Button>
                        )}
                      </div>
                    );
                  }}
                />
              </Form.Item>
            </Col>
            {tab === "B2B" && (
              <Col span={6}>
                <Form.Item label="Organization" name="organization">
                  <DisplayInput />
                </Form.Item>
              </Col>
            )}
            {tab === "B2B" && (
              <Col span={6}>
                <Form.Item label="Group" name="customerGroup">
                  <DisplayInput />
                </Form.Item>
              </Col>
            )}
            <Col span={6}>
              <Form.Item label="Subusers">
                <DisplayInput value={customer?.subUsers ? join(customer?.subUsers, ", ") : EMPTY} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Connection">
                <DisplayInput value={connection} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Email" name="email">
                <DisplayInput
                  render={(value) => {
                    return (
                      <div className={styles.paragraph}>
                        <span>{value}</span>
                        {user.canUpdateB2CCustomerInfo && connection === "Username" && (
                          <Button
                            size="small"
                            type="text"
                            shape="circle"
                            onClick={() => {
                              setEditUserEmailModalVisible(true);
                            }}
                          >
                            <Edit />
                          </Button>
                        )}
                      </div>
                    );
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Created at">
                <DisplayInput
                  value={customer?.createdDate ? moment(customer?.createdDate).format(DATETIME_FORMAT) : EMPTY}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Auth0 Id" name="auth0Id">
                <DisplayInput />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="SFMC Marketing Opt-in">
                <DisplayInput
                  render={() => {
                    return (
                      <div className={styles.paragraph}>
                        <span>{customerSubscriberInfo?.subscriberStatus}</span>
                        {customerSubscriberInfo?.subscriberStatus &&
                          customerSubscriberInfo?.subscriberStatus !== "No Data" && (
                            <Button
                              size="small"
                              type="text"
                              shape="circle"
                              onClick={() => {
                                setEditMarketingOptInModalVisible(true);
                              }}
                            >
                              <Edit />
                            </Button>
                          )}
                      </div>
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Space>
      {editUsernameModalVisible && (
        <EditUserNameModal
          customerId={customer?.customerId || 0}
          visible={editUsernameModalVisible}
          name={customer?.name || EMPTY}
          onFinish={onFinishEditing}
          closeModal={() => setEditUsernameModalVisible(false)}
        />
      )}
      {editUserEmailModalVisible && (
        <EditUserEmailModal
          customerId={customer?.customerId || 0}
          customerAuth0Id={customer?.auth0Id || EMPTY}
          visible={editUserEmailModalVisible}
          originEmail={customer?.email || EMPTY}
          onFinish={onFinishEditing}
          closeModal={() => {
            setEditUserEmailModalVisible(false);
          }}
        />
      )}
      {editMarketingOptInModalVisible && (
        <EditMarketingOptInModal
          optInInput={customerSubscriberInfo?.subscriberStatus || EMPTY}
          email={customer?.email || EMPTY}
          visible={editMarketingOptInModalVisible}
          onFinish={onFinishEditing}
          closeModal={() => {
            setEditMarketingOptInModalVisible(false);
          }}
        />
      )}
      <Divider />
    </div>
  );
}

export default CustomerBasicInfo;
