import useAppAuth0 from "hooks/useAppAuth0";
import { useLocation } from "react-router-dom";
import { compact, isEmpty } from "lodash";
import { MenuItem } from "../types/menu";
import menu from "../app/menu";

interface Menu {
  visibleMenuItems?: MenuItem[];
  defaultPath?: string;
  defaultSelectedKeys?: string[];
  defaultOpenKeys?: string[];
}

const useMenu = () => {
  const { user } = useAppAuth0();
  const { pathname } = useLocation();
  const getMenuByPermissions = (menuItems: MenuItem[]): MenuItem[] => {
    return compact(
      menuItems.map((menuItem) => {
        if (isEmpty(menuItem?.children)) {
          if (user.hasPermission(menuItem.permission)) {
            return menuItem;
          }
          return undefined;
        }
        menuItem.children = getMenuByPermissions(menuItem.children);
        if (isEmpty(menuItem.path) && isEmpty(menuItem.children)) {
          return undefined;
        }
        return menuItem;
      })
    );
  };

  const hasMatchedMenuItem = (): boolean => {
    return visibleMenuItems.some((menuItem) => {
      const childPathSegment = menuItem.children?.[0]?.path?.split("/")[1];
      const currentPathSegment = pathname.split("/")[1] ?? "";
      if (childPathSegment === currentPathSegment) {
        defaultPath = menuItem.children?.[0]?.path ?? menuItem.path;
        defaultSelectedKeys = [menuItem.children?.[0]?.title ?? menuItem.title];
        defaultOpenKeys = [menuItem.title];
        return true;
      }
      return menuItem.path === pathname;
    });
  };

  const visibleMenuItems: MenuItem[] = getMenuByPermissions(menu);
  let defaultPath = visibleMenuItems[0]?.path;
  let defaultSelectedKeys = [visibleMenuItems[0]?.title];
  let defaultOpenKeys = [""];

  if (!hasMatchedMenuItem() && visibleMenuItems[0]?.children?.length > 0) {
    defaultPath = visibleMenuItems[0]?.children[0].path;
    defaultSelectedKeys = [visibleMenuItems[0]?.children[0]?.title];
    defaultOpenKeys = [visibleMenuItems[0]?.title];
  }

  return {
    visibleMenuItems,
    defaultPath,
    defaultSelectedKeys,
    defaultOpenKeys,
  } as Menu;
};

export default useMenu;
