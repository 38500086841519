import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import * as productAPI from "services/product";
import ProductModel, { ProductChangeLog } from "types/model/product";
import ProductListRequest from "types/dto/request/ProductListRequest";
import Page from "types/page";
import LanguageModel from "types/model/language";
import { RootState } from "app/store";
import ProductMapper from "utils/productMapper";

export const fetchProducts = createAsyncThunk<Page<ProductModel>, ProductListRequest>(
  "products/fetchAll",
  async (params) => {
    return await productAPI.fetchAll(params);
  }
);

export const fetchProduct = createAsyncThunk("products/fetchOne", async (productId: string) => {
  const productResponse = await productAPI.fetchProduct(productId);
  return ProductMapper.toProductModel(productResponse);
});

export const fetchProductChangeLogs = createAsyncThunk(
  "products/fetchProductChangeLogs",
  productAPI.fetchProductChangeLogs
);

export const fetchLanguages = createAsyncThunk("products/languages/fetchAll", async () => {
  const data = await productAPI.fetchLanguagesIncludeAllAccess();
  return data.content;
});

export const removeProduct = createAsyncThunk("products/delete", productAPI.removeProduct);

export const updateProduct = createAsyncThunk("products/update", async (data: ProductModel) => {
  const request = ProductMapper.toProductUpdateRequest(data);
  const response = await productAPI.updateProduct(data.id!, request);
  return ProductMapper.toProductModel(response);
});

export const verifyProduct = createAsyncThunk(
  "product/verify",
  async ({ productId, comments }: { productId: string; comments: string }) => {
    return await productAPI.verifyProduct(productId, comments);
  }
);

export const publishProduct = createAsyncThunk("product/publish", async (productId: string) => {
  return await productAPI.publishProduct(productId);
});

export const scheduledPublishProduct = createAsyncThunk(
  "product/scheduledPublishProduct",
  async ({ productId, scheduledPublishTime }: { productId: string; scheduledPublishTime: number }) => {
    return await productAPI.scheduledPublishProduct(productId, scheduledPublishTime);
  }
);

export const removeSchedule = createAsyncThunk("products/removeSchedule", productAPI.removeSchedule);

const productsAdapter = createEntityAdapter<ProductModel>({
  selectId: (product) => product.productId,
});

const productsSlice = createSlice({
  name: "products",
  initialState: productsAdapter.getInitialState({
    languages: [] as LanguageModel[],
    totalElements: 0,
    productChangeLogs: {} as Page<ProductChangeLog>,
    allAccess: {} as LanguageModel,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.fulfilled, (state, { payload }) => {
      const { content, totalElements } = payload;
      productsAdapter.setAll(state, content);
      state.totalElements = totalElements;
    });
    builder.addCase(fetchLanguages.fulfilled, (state, { payload }) => {
      state.languages = payload.filter((language) => language.name !== "All Access");
      state.allAccess = payload.filter((language) => language.name === "All Access")[0];
    });
    builder.addCase(fetchProduct.fulfilled, (state, { payload }) => {
      productsAdapter.upsertOne(state, {
        ...payload,
        productId: payload.id,
      } as ProductModel);
    });
    builder.addCase(updateProduct.fulfilled, (state, { payload }) => {
      state.entities[payload.id!] = payload;
    });
    builder.addCase(fetchProductChangeLogs.fulfilled, (state, { payload }) => {
      state.productChangeLogs = payload;
    });
  },
});

export default productsSlice.reducer;

export const productsSelectors = productsAdapter.getSelectors<RootState>((state) => state.products);
