import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { useParams } from "react-router-dom";

import * as eComSaveOfferAPI from "../../../../services/saveOffer";
import { SaveOfferDetailModel } from "../../../../types/model/saveOffer";

import ViewSaveOfferDetailContent from "./ViewSaveOfferDetailContent";
import EditSaveOffer from "../EditSaveOffer";

export default function ViewSaveOfferDetail() {
  const { id } = useParams<{ id: string }>();
  const [isSaveOfferDetailLoading, setIsSaveOfferDetailLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [saveOfferDetail, setSaveOfferDetail] = useState<SaveOfferDetailModel>();

  useEffect(() => {
    fetchSaveOfferDetail();
  }, []);

  function fetchSaveOfferDetail() {
    setIsSaveOfferDetailLoading(true);
    eComSaveOfferAPI
      .fetchSaveOfferDetail(id)
      .then((detail) => {
        setSaveOfferDetail(detail);
      })
      .finally(() => {
        setIsSaveOfferDetailLoading(false);
      });
  }

  return (
    <Spin spinning={isSaveOfferDetailLoading}>
      {saveOfferDetail && !isEditing && (
        <ViewSaveOfferDetailContent
          saveOfferDetail={saveOfferDetail}
          onEdit={() => {
            setIsEditing(true);
          }}
        />
      )}
      {saveOfferDetail && isEditing && (
        <EditSaveOffer
          saveOfferDetail={saveOfferDetail}
          onCancel={() => setIsEditing(false)}
          onFinish={() => {
            setIsEditing(false);
            fetchSaveOfferDetail();
          }}
        />
      )}
    </Spin>
  );
}
