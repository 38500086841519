import React, { useState } from "react";
import { Button, Modal, Space, Tooltip } from "antd";
import * as Icons from "assets/icons";
import styles from "./index.module.scss";

interface Props {
  tooltipTitle?: string;
  title?: string;
  onChange?: () => void;
  tableRef: React.RefObject<{
    validate: () => Promise<boolean>;
    update: () => Promise<void>;
    refresh: () => {};
  }>;
  children?: React.ReactNode;
  width?: number;
  bodyStyle?: React.CSSProperties;
}

function ConfigureModal({ tooltipTitle, title, onChange, tableRef, children, width, bodyStyle }: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleSave = async () => {
    const isValid = (await tableRef?.current?.validate()) ?? false;
    if (isValid) {
      setIsModalVisible(false);
      setIsConfirmModalVisible(true);
    }
  };

  const handleConfirm = async () => {
    setConfirmLoading(true);
    tableRef?.current?.update().finally(() => {
      setConfirmLoading(false);
      setIsConfirmModalVisible(false);
      if (onChange) onChange();
      Modal.destroyAll();
    });
  };

  return (
    <div>
      <Tooltip title={tooltipTitle} placement="topLeft">
        <Button
          type="text"
          shape="circle"
          icon={<Icons.Gear />}
          onClick={() => {
            setIsModalVisible(true);
          }}
          aria-label="open modal"
          data-testid="open-modal"
        />
      </Tooltip>
      <Modal
        width={width}
        bodyStyle={{ overflowY: "auto", maxHeight: "70vh", ...bodyStyle }}
        centered={true}
        title={title}
        visible={isModalVisible}
        onCancel={() => {
          tableRef?.current?.refresh();
          setIsModalVisible(false);
        }}
        onOk={handleSave}
        okText="Save"
        maskClosable={false}
        cancelButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
        okButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
        className={styles.modal}
      >
        <Space direction="vertical" size={12}>
          {children}
        </Space>
      </Modal>

      <Modal
        visible={isConfirmModalVisible}
        centered
        title="Confirm Change"
        onCancel={() => {
          setIsConfirmModalVisible(false);
          setIsModalVisible(true);
        }}
        onOk={handleConfirm}
        okText="Confirm"
        maskClosable={false}
        cancelButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
        okButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
        className={styles.modal}
        confirmLoading={confirmLoading}
      >
        <Space direction="vertical" size={12}>
          Confirm to make it happen? The change will be live instantly.
        </Space>
      </Modal>
    </div>
  );
}

export default ConfigureModal;
