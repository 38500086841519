import { UpsellDowngradeListRequest } from "../types/dto/request/upsellDowngrade";
import { eComInstance } from "../utils/instance";
import {
  UpsellDowngradeDetailResponse,
  UpsellDowngradeListResponse,
} from "../types/dto/response/upsellDowngradeResponse";
import {
  createUpsellDowngradeRequest,
  transformUpsellDowngradeDetail,
  transformUpsellDowngradeList,
} from "../types/transformers/upsellDowngrade";
import { UpsellDowngradeFormValues } from "../types/model/upsellDowngrade";

export async function fetchUpsellDowngradeList(params: UpsellDowngradeListRequest) {
  const url = "/upsell-downgrades";
  return eComInstance
    .get<UpsellDowngradeListResponse>(url, { params })
    .then((res) => transformUpsellDowngradeList(res));
}

export async function fetchUpsellDowngradeDetails(id: string) {
  const url = `/upsell-downgrades/${id}`;
  return eComInstance.get<UpsellDowngradeDetailResponse>(url).then((res) => transformUpsellDowngradeDetail(res));
}

export async function createUpsellDowngrade(upsellDowngrade: UpsellDowngradeFormValues) {
  const url = "/upsell-downgrades";
  return eComInstance.post(url, createUpsellDowngradeRequest(upsellDowngrade)).then((res) => res);
}

export async function updateUpsellDowngrade(id: string, upsellDowngrade: UpsellDowngradeFormValues) {
  const url = `/upsell-downgrades/${id}`;
  return eComInstance
    .put<UpsellDowngradeDetailResponse>(url, createUpsellDowngradeRequest(upsellDowngrade))
    .then((res) => transformUpsellDowngradeDetail(res));
}

export async function deleteUpsellDowngrade(id: string) {
  const url = `/upsell-downgrades/${id}`;
  return eComInstance.delete(url);
}
