import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row, Table, Tag } from "antd";

import { DropdownInput } from "components";
import * as Icons from "assets/icons";
import type { EntitlementListParams } from "types/dto/request/entitlement";

import AdvancedSearch from "./components/AdvancedSearch";
import useColumns from "./hooks/useColumns";
import useEntitlements from "./hooks/useEntitlements";
import type { Entity } from "./types";
import { getDateRange, showTableFooter, sourceOptions, statuses } from "./utils";
import useLanguagesOptions from "./hooks/useLanguagesOptions";

function RList() {
  const { data, isFetching, refetch, query, setQuery } = useEntitlements();
  const columns = useColumns(query);
  const languages = useLanguagesOptions();

  return (
    <Card title="Entitlement list" className="page-container">
      <Row wrap={false} justify="start" gutter={8}>
        <Col>
          <DropdownInput
            placeholder="All sources"
            options={sourceOptions}
            value={(query.sources as string[]) ?? undefined}
            onChange={(value) => setQuery({ sources: value as string[], page: undefined })}
          />
        </Col>

        <Col>
          <Input
            placeholder="Search customer email"
            value={query.email}
            onChange={(e) => setQuery({ email: e.target.value.trim() })}
            prefix={<SearchOutlined />}
            allowClear
          />
        </Col>

        <Col>
          <AdvancedSearch
            key={JSON.stringify(query)}
            initialValues={query as EntitlementListParams}
            onSubmit={setQuery}
          />
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          <Button type="text" shape="circle" icon={<Icons.Refresh />} onClick={refetch} />
        </Col>
      </Row>

      <Row gutter={8} style={{ marginBottom: "12px" }}>
        <Tag
          closable
          hidden={!query.createdDateFrom && !query.createdDateTo}
          onClose={() =>
            setQuery({
              createdDateFrom: undefined,
              createdDateTo: undefined,
              page: undefined,
            })
          }
        >
          {getDateRange(query.createdDateFrom, query.createdDateTo)}
        </Tag>
        <Tag
          closable
          hidden={query.languageId == null}
          onClose={() => setQuery({ languageId: undefined, page: undefined })}
        >
          {languages?.find(({ value }) => value === query.languageId)?.label}
        </Tag>
        <Tag
          closable
          hidden={query.isSubscription == null}
          onClose={() => setQuery({ isSubscription: undefined, page: undefined })}
        >
          {query.isSubscription ? "Subscription" : "One-shot"}
        </Tag>
        {query.statuses?.map(
          (status) =>
            status != null && (
              <Tag
                key={status}
                closable
                onClose={() =>
                  setQuery({
                    statuses: query.statuses?.filter((s) => s !== status),
                    page: undefined,
                  })
                }
              >
                {statuses[status as keyof typeof statuses]}
              </Tag>
            )
        )}
        <Tag closable hidden={query.isbn == null} onClose={() => setQuery({ isbn: undefined, page: undefined })}>
          {query.isbn}
        </Tag>
      </Row>

      <Table<Entity>
        rowKey={(record) => record.entitlementId!}
        loading={isFetching}
        dataSource={data?.content}
        columns={columns}
        pagination={{
          total: data?.totalElements,
          current: data?.page ?? 1,
          pageSize: data?.size,
          showSizeChanger: true,
          showTotal: showTableFooter,
        }}
        onChange={({ current, pageSize }, _, sorter) => {
          if (!Array.isArray(sorter)) {
            setQuery({
              page: current,
              size: pageSize,
              sort: sorter?.field as string,
              order: sorter?.order ? sorter.order : undefined,
            });
          }
        }}
      />
    </Card>
  );
}

export default RList;
