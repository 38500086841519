import React from "react";
import emptyContent from "../../assets/images/emptyContent.png";
import styles from "./index.module.scss";

interface Props {
  displayTexts: string[];
}
export default function EmptyContent({ displayTexts }: Props) {
  return (
    <div className={styles.emptyContent}>
      <img src={emptyContent} width={190} alt="empty content" />
      <section className={styles.displayText}>
        {displayTexts.map((text) => (
          <p key={text}>{text}</p>
        ))}
      </section>
    </div>
  );
}
