import React, { isValidElement, useState } from "react";
import { Button, Checkbox, Popover } from "antd";
import Icon from "@ant-design/icons";
import { isEmpty } from "lodash";
import { CheckboxGroupProps, CheckboxOptionType } from "antd/es/checkbox";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import * as Icons from "assets/icons";
import styles from "./DropdownInput.module.scss";

interface DropdownInputProps {
  options: Array<CheckboxOptionType | string>;
  placeholder?: string;
  width?: number;
  value?: Array<CheckboxValueType>;
  onChange?: (checkedValue: Array<CheckboxValueType>) => void;
}

function DropdownInput({ options, placeholder, width, value, onChange }: DropdownInputProps) {
  const [visible, setVisible] = useState(false);

  const handleCheckboxChange: CheckboxGroupProps["onChange"] = (checkedValue) => {
    onChange && onChange(checkedValue);
  };

  const checkbox = (
    <Checkbox.Group
      value={value}
      options={options}
      onChange={handleCheckboxChange}
      style={width ? { width: `${width}px` } : { width: "auto" }}
      className={styles.dropdownCheckbox}
    />
  );

  let displayText: string;
  if (isEmpty(value) || value!.length === options.length) {
    displayText = placeholder ?? "";
  } else if (typeof options[0] === "string") {
    displayText = value!.join(",");
  } else {
    displayText = (options as CheckboxOptionType[])
      .filter((option) => value!.includes(option.value))
      .map((option) => {
        if (isValidElement(option.label)) {
          return option.label.props?.children?.[0] ?? "";
        }
        return option.label;
      })
      .join(",");
  }

  return (
    <Popover
      content={checkbox}
      overlayClassName={styles.overlay}
      placement="bottomLeft"
      trigger={["click"]}
      visible={visible}
      onVisibleChange={() => setVisible(!visible)}
    >
      <Button className={styles.dropdownInput}>
        <span>{displayText}</span>
        <Icon component={Icons.DropDown} className={styles.icon} />
      </Button>
    </Popover>
  );
}

export default DropdownInput;
