import React, { useState } from "react";
import { message } from "antd";
import { useParams } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";
import { EComModal } from "../../../../components/EComModal";
import { ISubscriptionModalProps } from "./types";
import { revokeSubscription } from "../../../../services/subscription";
import { useAppDispatch } from "../../../../app/store";
import { restoreEntitlement } from "../../customerSlice";

export default function RevokeEntitlementModal({
  auth0Id,
  subscription,
  visible,
  closeModal,
  onFinish,
}: ISubscriptionModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { customerId } = useParams<{ customerId: string }>();
  const dispatch = useAppDispatch();

  async function onSubmit() {
    setIsLoading(true);
    try {
      await revokeSubscription(subscription.subscriptionId, auth0Id, subscription.source);
      if (customerId) {
        await dispatch(restoreEntitlement(Number(customerId))).then(unwrapResult);
      }
      message.success("Revoke entitlement successfully");
      if (onFinish) onFinish();
      closeModal();
    } catch (e) {
      message.error(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <EComModal
      title="Revoke Subscription Confirmation"
      visible={visible}
      okText="Confirm"
      cancelText="Cancel"
      onCancel={closeModal}
      onOk={onSubmit}
      confirmLoading={isLoading}
    >
      <p>Proceed to immediately stop the subscription and revoke the entitlement access.</p>
    </EComModal>
  );
}
