import React, { useEffect, useState } from "react";
import { Prompt, useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { Button, Card, Form, Space, Spin, Tooltip } from "antd";
import * as descriptionTemplateAPI from "../../../../services/description";
import DescriptionDetailsView from "./DescriptionDetailsView";
import { EMPTY, LEAVE_CONFIRMATION } from "../../../../constants/common";
import { message, Modal } from "../../../../components";
import { bold } from "../../../../utils";
import useAppAuth0 from "../../../../hooks/useAppAuth0";
import { getContentByLocale, LocaleType } from "../../../../utils/locale";
import { ContentBase, DescriptionTemplateFormData } from "../../../../types/model/description";
import DescriptionTemplateForm from "./DescriptionTemplateForm";

function EditingButtons({ onCancel, onSave }: { onCancel: () => void; onSave: () => void }) {
  return (
    <Space size={10}>
      <Button type="default" shape="round" onClick={onCancel}>
        Cancel
      </Button>
      <Button type="primary" shape="round" onClick={onSave}>
        Save
      </Button>
    </Space>
  );
}

function ViewButtons({
  onDelete,
  onEdit,
  disabledDelete,
  user,
}: {
  onDelete: () => void;
  onEdit: () => void;
  disabledDelete: boolean;
  user: any;
}) {
  return (
    <Space size={10}>
      {user.canDeleteDescription &&
        (disabledDelete ? (
          <Tooltip title="A template linked to at least one product cannot be deleted.">
            <Button type="default" shape="round" onClick={onDelete} disabled={disabledDelete}>
              Delete
            </Button>
          </Tooltip>
        ) : (
          <Button type="default" shape="round" onClick={onDelete} disabled={disabledDelete}>
            Delete
          </Button>
        ))}
      {user.canUpdateDescription && (
        <Button type="default" shape="round" onClick={onEdit}>
          Edit
        </Button>
      )}
    </Space>
  );
}

function DescriptionDetails() {
  const { descriptionId } = useParams<{
    descriptionId: string;
  }>();
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState("");
  const [contents, setContents] = useState<ContentBase[]>([]);
  const [used, setUsed] = useState(false);
  const [form] = Form.useForm<DescriptionTemplateFormData>();
  const history = useHistory();
  const { user } = useAppAuth0();

  useEffect(() => {
    getAndSetDescriptionDetails();
  }, [descriptionId, form]);

  const handleUpdate = async () => {
    form
      .validateFields()
      .then(({ name }) => {
        setLoading(true);
        descriptionTemplateAPI
          .updateDescriptionTemplate(descriptionId, {
            name,
            enContent: getContentByLocale(contents, LocaleType.English),
            esContent: getContentByLocale(contents, LocaleType.Spanish),
          })
          .then(() => {
            setEditing(false);
            setName(name);
            message.success("Description template updated successfully.");
            getAndSetDescriptionDetails();
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        message.error(new Error("Please fill in all required fields."));
      });
  };

  const handleDelete = () =>
    Modal.confirm({
      title: "Confirm",
      okText: "Confirm",
      content: form.getFieldValue("name") && bold`Are you sure you want to delete the ${form.getFieldValue("name")}?`,
      onOk: () =>
        descriptionTemplateAPI.deleteDescriptionTemplateById(descriptionId).then(() => {
          history.push("/products/description");
          message.success("Description template deleted successfully.");
        }),
    });

  const getAndSetDescriptionDetails = () => {
    let ignore = false;
    setLoading(true);
    descriptionTemplateAPI
      .fetchDescriptionTemplateById(descriptionId)
      .then((description) => {
        if (!ignore) {
          setName(description.name);
          setContents(description.contents);
          setUsed(description.isUsed);
          form.setFieldsValue({
            name: description.name,
            enContent: getContentByLocale(description.contents, LocaleType.English),
            esContent: getContentByLocale(description.contents, LocaleType.Spanish),
          });
        }
      })
      .finally(() => setLoading(false));

    return () => {
      ignore = true;
    };
  };

  const handleCancel = () => {
    Modal.confirm({
      title: "Confirm",
      okText: "OK",
      content: LEAVE_CONFIRMATION,
      onOk: () => {
        setEditing(false);
      },
    });
  };

  return (
    <Card
      title={name ?? EMPTY}
      extra={
        editing ? (
          <EditingButtons onSave={handleUpdate} onCancel={handleCancel} />
        ) : (
          <ViewButtons onDelete={handleDelete} onEdit={() => setEditing(true)} disabledDelete={used} user={user} />
        )
      }
      className="page-container"
    >
      <Spin spinning={loading} size={"large"}>
        {editing ? (
          <>
            <Prompt when={editing} message={LEAVE_CONFIRMATION} />
            <DescriptionTemplateForm form={form} contents={contents} updateContents={setContents} />
          </>
        ) : (
          <DescriptionDetailsView form={form} contents={contents} />
        )}
      </Spin>
    </Card>
  );
}

export default DescriptionDetails;
