import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import * as miniCourseAPI from "services/miniCourse";
import { RootState } from "app/store";
import MiniCourseModel, { IMiniCourseLog } from "../../types/model/miniCourse";
import Page from "../../types/page";
import MiniCourseRequest, { IMIniCourseChangeLogParams } from "../../types/dto/request/MiniCourseRequest";
import { DEFAULT_PAGINATION } from "../../constants/common";

export const fetchMiniCourses = createAsyncThunk<Page<MiniCourseModel>, MiniCourseRequest>(
  "mini-courses/fetchAll",
  async (params) => {
    return await miniCourseAPI.fetchAll(params);
  }
);

export const verifyMiniCourse = createAsyncThunk(
  "mini-courses/verify",
  async ({ id, comments }: { id: number; comments: string }) => {
    return await miniCourseAPI.verifyMiniCourse(id, comments);
  }
);

export const publishMiniCourse = createAsyncThunk("mini-courses/publish", async (id: number) => {
  return await miniCourseAPI.publishMiniCourse(id);
});

export const scheduledPublishMiniCourse = createAsyncThunk(
  "mini-courses/scheduledPublishMiniCourse",
  async ({ id, scheduledPublishTime }: { id: number; scheduledPublishTime: number }) => {
    return await miniCourseAPI.scheduledPublishMiniCourse(id, scheduledPublishTime);
  }
);

export const removeSchedule = createAsyncThunk("mini-courses/removeSchedule", miniCourseAPI.removeSchedule);

export const fetchMiniCourseChangeLogs = createAsyncThunk<Page<IMiniCourseLog>, IMIniCourseChangeLogParams>(
  "mini-courses/fetchMiniCourseChangeLogs",
  async (params: IMIniCourseChangeLogParams) => {
    return await miniCourseAPI.fetchMiniCourseChangeLogs(params);
  }
);

const miniCoursesAdapter = createEntityAdapter<MiniCourseModel>({
  selectId: (miniCourse) => miniCourse.id,
});

const INITIAL_CHANGE_LOGS = {
  totalElements: 0,
  ...DEFAULT_PAGINATION,
  content: [] as IMiniCourseLog[],
};

const miniCoursesSlice = createSlice({
  name: "miniCourses",
  initialState: miniCoursesAdapter.getInitialState({
    totalElements: 0,
    changeLog: INITIAL_CHANGE_LOGS,
  }),
  reducers: {
    cleanMiniLessonLogsData: (state) => {
      state.changeLog = INITIAL_CHANGE_LOGS;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMiniCourses.fulfilled, (state, { payload }) => {
      const { content, totalElements } = payload;
      miniCoursesAdapter.setAll(state, content);
      state.totalElements = totalElements;
    });
    builder.addCase(fetchMiniCourseChangeLogs.fulfilled, (state, { payload }) => {
      const { content, totalElements, size, page } = payload;
      state.changeLog.totalElements = totalElements;
      state.changeLog.content = content;
      state.changeLog.page = page;
      state.changeLog.size = size;
    });
  },
});

export default miniCoursesSlice.reducer;

export const miniCoursesSelectors = miniCoursesAdapter.getSelectors<RootState>((state) => state.miniCourses);

export const { cleanMiniLessonLogsData } = miniCoursesSlice.actions;
