import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { useCallback, useEffect, useMemo } from "react";
import { omit } from "lodash";
import type { SortOrder } from "antd/lib/table/interface";

import useDebounce from "hooks/useDebounce";
import { useGetEntitlementsQuery, usePrefetch } from "../../../../services";
import { getSortOrder } from "../utils";

function useEntitlements() {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    size: withDefault(NumberParam, 10),
    sort: withDefault(StringParam, "createdDate"),
    order: withDefault(StringParam, "descend"),
    sources: withDefault(ArrayParam, undefined),
    email: withDefault(StringParam, undefined),
    createdDateFrom: withDefault(NumberParam, undefined),
    createdDateTo: withDefault(NumberParam, undefined),
    isSubscription: withDefault(BooleanParam, undefined),
    statuses: withDefault(ArrayParam, undefined),
    languageId: withDefault(NumberParam, undefined),
    isbn: withDefault(StringParam, undefined),
  });

  const params = useMemo(
    () => ({
      ...omit(query, "order"),
      page: query.page - 1,
      sort: getSortOrder(query.sort, query.order as SortOrder),
      sources: query.sources as string[] | undefined,
      statuses: query.statuses as string[] | undefined,
    }),
    [query]
  );

  const debouncedParams = useDebounce(params, 500);

  const entitlementsQuery = useGetEntitlementsQuery(debouncedParams);

  const prefetchEntitlements = usePrefetch("getEntitlements");

  const prefetchNext = useCallback(
    () =>
      prefetchEntitlements({
        ...debouncedParams,
        page: debouncedParams.page + 1,
      }),
    [debouncedParams, prefetchEntitlements]
  );

  useEffect(() => {
    const { data } = entitlementsQuery;
    if (debouncedParams.page !== data?.totalPages) {
      prefetchNext();
    }
  }, [debouncedParams.page, entitlementsQuery, prefetchNext]);

  return { ...entitlementsQuery, query, setQuery };
}

export default useEntitlements;
