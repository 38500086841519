import React, { useEffect } from "react";
import { capitalize, map } from "lodash";
import { Col, Form, FormInstance, Input, Radio, Row, Select, Space } from "antd";
import { DropDown } from "../../../../assets/icons";
import { DiscountType, PromotionDetailModel } from "../../../../types/model/promotion";
import styles from "./SaveOfferForm.module.scss";
import { EMPTY } from "../../../../constants/common";

interface Props {
  namePrefix: string;
  promotionDetail: PromotionDetailModel;
  form: FormInstance;
  promotionCodeInput: React.ReactNode;
}

const resetPromotionDetailFields = (form: FormInstance, namePrefix: string) => {
  form.resetFields([
    `${namePrefix}BillingCycle`,
    `${namePrefix}CountryIds`,
    `${namePrefix}PercentageDiscount`,
    `${namePrefix}AmountDiscount`,
    `${namePrefix}CurrencyCode`,
    `${namePrefix}DiscountType`,
  ]);
};

const SaveOfferPromoDetails = ({ namePrefix, promotionDetail, form, promotionCodeInput }: Props) => {
  useEffect(() => {
    if (promotionDetail) {
      const {
        discountType,
        billingCycle = undefined,
        countries = [],
        currencyCode = undefined,
        amountDiscount = undefined,
        percentageDiscount = undefined,
      } = promotionDetail;

      const isAmountDiscount = discountType === DiscountType.AMOUNT;
      const countryNames = hasPromotionCountries() ? map(countries, "displayName") : EMPTY;

      form.setFieldsValue({
        [`${namePrefix}BillingCycle`]: billingCycle,
        [`${namePrefix}CountryIds`]: countryNames,
        [`${namePrefix}DiscountType`]: discountType,
        [`${namePrefix}CurrencyCode`]: currencyCode,
        [`${namePrefix}AmountDiscount`]: isAmountDiscount ? amountDiscount : undefined,
        [`${namePrefix}PercentageDiscount`]: isAmountDiscount ? undefined : percentageDiscount,
      });
    } else {
      resetPromotionDetailFields(form, namePrefix);
    }
  }, [namePrefix, promotionDetail]);

  const hasPromotionCountries = () => {
    return promotionDetail?.countries && promotionDetail?.countries?.length > 0;
  };

  return (
    <>
      <Row gutter={32}>
        <Col span={6}>{promotionCodeInput}</Col>
        {promotionDetail.isSubscription && (
          <Col span={6}>
            <Form.Item label="Applied billing cycle">
              <Space size={22} align="baseline" className={styles.littleInput}>
                <Form.Item noStyle>
                  <Input disabled value={1} />
                </Form.Item>
                <span>to</span>
                <Form.Item noStyle name={`${namePrefix}BillingCycle`}>
                  <Input disabled={true} />
                </Form.Item>
              </Space>
            </Form.Item>
          </Col>
        )}
        <Col span={12}>
          <Form.Item
            label="Applied country/region"
            name={`${namePrefix}CountryIds`}
            className={hasPromotionCountries() ? styles.largeFormItem : undefined}
          >
            <Select
              mode={hasPromotionCountries() ? "multiple" : undefined}
              disabled
              showArrow
              suffixIcon={<DropDown />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={6}>
          <Form.Item label="Promotion type" name={`${namePrefix}DiscountType`}>
            <Radio.Group disabled>
              {Object.values(DiscountType)
                .filter((item) => item !== DiscountType.BUY_ONE_GIFT_ONE)
                .map((type) => (
                  <Radio value={type} key={type}>
                    {capitalize(type.toLowerCase())}
                  </Radio>
                ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Form.Item noStyle shouldUpdate>
          {() =>
            promotionDetail.discountType === DiscountType.AMOUNT ? (
              <>
                <Col span={6}>
                  <Form.Item label="Currency" name={`${namePrefix}CurrencyCode`}>
                    <Select showArrow suffixIcon={<DropDown />} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Amount" name={`${namePrefix}AmountDiscount`}>
                    <Input disabled />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <Col span={6}>
                <Form.Item label="Discount" name={`${namePrefix}PercentageDiscount`}>
                  <Input disabled addonAfter="%" />
                </Form.Item>
              </Col>
            )
          }
        </Form.Item>
      </Row>
    </>
  );
};

export default SaveOfferPromoDetails;
