import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { omit } from "lodash";
import { Button, Card, Form, Space, Spin } from "antd";
import { RootState, useAppDispatch } from "app/store";
import { message } from "components";
import { VoucherGroupDetailResponse } from "types/dto/response/voucher";
import VoucherForm, { toVoucherGroupCreateRequest, VoucherFormProps, VoucherFormValues } from "./index";
import { createVoucher } from "../voucherSlice";

function VoucherCreationContainer() {
  const history = useHistory();
  const isLoading = useSelector((state: RootState) => state.loading);
  const { state } = useLocation();
  const [form] = Form.useForm<VoucherFormValues>();
  const dispatch = useAppDispatch();

  function goBackToVoucherGroup() {
    history.push("/vouchers");
  }

  const handleSubmit: VoucherFormProps["onFinish"] = (values) => {
    const data = toVoucherGroupCreateRequest(values);
    dispatch(createVoucher(data)).then(unwrapResult).then(goBackToVoucherGroup).catch(message.error);
  };

  const cardTitleExtra = (
    <Space>
      <Button type="default" shape="round" onClick={goBackToVoucherGroup}>
        Cancel
      </Button>
      <Button type="primary" shape="round" onClick={form.submit}>
        Save
      </Button>
    </Space>
  );

  return (
    <Card title="Add vouchers" extra={cardTitleExtra} className="page-container">
      <Spin spinning={isLoading} size={"large"}>
        <VoucherForm
          form={form}
          data={omit(state as VoucherGroupDetailResponse, ["validPeriod", "entitlementExpirationDate"])}
          onFinish={handleSubmit}
        />
      </Spin>
    </Card>
  );
}

export default VoucherCreationContainer;
