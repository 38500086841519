import { DeltaStatic } from "quill";
import { find, isEmpty } from "lodash";
import debounce from "lodash/debounce";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Col,
  ConfigProvider,
  Divider,
  Empty,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
} from "antd";
import { SelectValue } from "antd/es/select";
import React, { useEffect, useState } from "react";
import { DEFAULT_MAX_PAGINATION, validateMessages } from "../../../../constants/common";
import styles from "./SaveOfferForm.module.scss";
import { DropDown } from "../../../../assets/icons";
import * as basePriceAPI from "../../../../services/basePrice";
import * as saveOfferAPI from "../../../../services/saveOffer";
import * as eComProductApi from "../../../../services/eComProduct";
import * as promotionApi from "../../../../services/promotion";
import { BasePriceModel } from "../../../../types/model/price";
import {
  AppliedStage,
  EffectiveDate,
  PromotionNamePrefix,
  SaveOfferContent,
  SaveOfferDetailModel,
  SaveOfferType,
} from "../../../../types/model/saveOffer";
import { isDeltaEmpty } from "../../../../utils/richTextEditor";
import { DESCRIPTION_MAX_LENGTH } from "../../../products/Disclosure/common";
import DescriptionContentInput from "../../../products/DescriptionTemplate/DescriptionDetails/DescriptionContentInput";
import { preventNonNumericalInput } from "../../../../utils/preventNonNumericalInput";
import SaveOfferPromoDetails from "./SaveOfferPromoDetails";
import { PromotionDetailModel } from "../../../../types/model/promotion";
import DebounceSearchInput from "../../../../components/DebounceSearchInput";
import { LocaleType } from "../../../../utils/locale";

const { TabPane } = Tabs;

interface Props {
  form: FormInstance;
  contents: SaveOfferContent[];
  setContents: (contents: SaveOfferContent[]) => void;
  saveOfferDetail?: SaveOfferDetailModel;
}

interface Option {
  label: string;
  value: string;
}

export function SaveOfferForm({ form, saveOfferDetail, contents, setContents }: Props) {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isLoadingPromoListByBasePrice, setIsLoadingPromoListByBasePrice] = useState<boolean>(false);
  const [lists, setLists] = useState<{
    cancelReasonList: Option[];
    basePriceList: BasePriceModel[];
  }>({
    cancelReasonList: [],
    basePriceList: [],
  });
  const [plans, setPlans] = useState<any>([]);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [planList, setPlanList] = useState<Option[]>([]);
  const [promotionListByBasePrice, setPromotionListByBasePrice] = useState<PromotionDetailModel[]>([]);
  const [promotionListByPlan, setPromotionListByPlan] = useState<PromotionDetailModel[]>([]);
  const [hasFilledForm, setHasFilledForm] = useState(false);
  const [selectedPromotionByBasePrice, setSelectedPromotionByBasePrice] = useState<PromotionDetailModel>(
    {} as PromotionDetailModel
  );
  const [selectedPromotionByPlan, setSelectedPromotionByPlan] = useState<PromotionDetailModel>(
    {} as PromotionDetailModel
  );

  useEffect(() => {
    setIsLoadingData(true);
    Promise.all([
      basePriceAPI.fetchBasePriceList({
        page: 1,
        limit: DEFAULT_MAX_PAGINATION.size,
      }),
      saveOfferAPI.fetchCancelReason(),
    ])
      .then(([basePriceList, cancelReasonData]) => {
        setLists({
          basePriceList: basePriceList.list,
          cancelReasonList: cancelReasonData.map(({ id, description }) => ({
            label: description.en,
            value: id ?? "",
          })),
        });
        setIsLoadingData(false);
      })
      .catch(() => {
        setIsLoadingData(false);
      });
  }, []);

  useEffect(() => {
    if (!saveOfferDetail || hasFilledForm) {
      return;
    }

    form.setFieldsValue({
      appliedStages: saveOfferDetail.appliedStages,
      cancelReasonId: saveOfferDetail.cancelReason?.id,
      appliedBasePriceIds: saveOfferDetail.appliedBasePrices.map((x) => x.id),
      saveOfferType: saveOfferDetail.type,
      extendDays: saveOfferDetail.extendDays,
    });

    saveOfferDetail.contents.forEach((item) => {
      form.setFieldsValue({
        [`${item.locale}Title`]: item.title,
        [`${item.locale}Description`]: item.description,
      });
    });

    handleFetchPromotionsByBasePrice();
  }, [saveOfferDetail, hasFilledForm]);

  useEffect(() => {
    if (!saveOfferDetail || !saveOfferDetail.switchToPlan || hasFilledForm) {
      return;
    }

    handleVariantsSearch(saveOfferDetail.switchToPlan.pid).then();
  }, [lists.basePriceList, hasFilledForm]);

  useEffect(() => {
    if (!saveOfferDetail || !saveOfferDetail.switchToPlan || plans?.length <= 0 || hasFilledForm) {
      return;
    }

    form.setFieldsValue({
      switchToPlanId: saveOfferDetail.switchToPlan.id,
      effectiveDate: saveOfferDetail.effectiveDate,
    });
    handleSwitchPlanInputChange(saveOfferDetail.switchToPlan.id);
  }, [plans, hasFilledForm]);

  useEffect(() => {
    if (!saveOfferDetail || !saveOfferDetail.promotion || hasFilledForm) {
      return;
    }

    const namePrefix = isSaveOfferTypePromotion() ? PromotionNamePrefix.BasePrice : PromotionNamePrefix.Plan;

    form.setFieldsValue({
      [`${namePrefix}PromotionId`]: saveOfferDetail.promotion?.id,
    });
    handlePromotionCodeChange(saveOfferDetail.promotion?.id);

    const promotionListLoaded =
      (isSaveOfferTypePromotion() && promotionListByBasePrice.length) ||
      (isSaveOfferTypeSwitchPlan() && promotionListByPlan.length);

    if (promotionListLoaded) {
      setHasFilledForm(true);
    }
  }, [promotionListByBasePrice, promotionListByPlan, hasFilledForm, form]);

  const handleVariantsSearch = async (search: string): Promise<Option[]> => {
    try {
      const res = await eComProductApi.fetchProductVariantsByFuzzySearch(search);
      if (res) {
        const options = res.map(({ id, title, pid, billingPlan }) => ({
          key: id,
          label: billingPlan ? `${pid} ${title} / ${billingPlan.name}` : `${pid} ${title}`,
          value: id,
        }));
        setPlanList(options);
        setPlans(res);
        return options;
      } else {
        return [];
      }
    } finally {
      setSelectedPlan(null);
    }
  };

  const handleFetchPromotionsByBasePrice = debounce(() => {
    const basePriceIds = form.getFieldValue("appliedBasePriceIds");
    if (!isEmpty(basePriceIds)) {
      setIsLoadingPromoListByBasePrice(true);
      promotionApi
        .fetchPromotionsByBasePriceIds(basePriceIds)
        .then((promotions) => {
          setPromotionListByBasePrice(promotions);
        })
        .finally(() => {
          setIsLoadingPromoListByBasePrice(false);
        });
    }
  }, 800);

  const isSaveOfferTypePromotion = () => {
    return form.getFieldValue("saveOfferType") === SaveOfferType.PROMOTION;
  };

  const isSaveOfferTypeSwitchPlan = () => {
    return form.getFieldValue("saveOfferType") === SaveOfferType.SWITCH_PLAN;
  };

  const handleSwitchPlanInputChange = (value: SelectValue) => {
    form.resetFields(["planPromotionId"]);
    setSelectedPromotionByPlan({} as PromotionDetailModel);
    const selectedVariant = plans?.find((plan: any) => plan.id === value) ?? null;
    if (selectedVariant) {
      setSelectedPlan(selectedVariant);
      setPromotionListByPlan(selectedVariant.promotions);
    } else {
      setSelectedPlan({});
      setPromotionListByPlan([]);
    }
  };

  const handlePromotionCodeChange = (value: SelectValue) => {
    const promotions = isSaveOfferTypePromotion() ? promotionListByBasePrice : selectedPlan?.promotions;
    const setPromotion = isSaveOfferTypePromotion() ? setSelectedPromotionByBasePrice : setSelectedPromotionByPlan;

    if (promotions) {
      const selectedPromotion = find(promotions, {
        id: value,
      });
      if (selectedPromotion) setPromotion(selectedPromotion);
    }
  };

  function validExtendDays() {
    const { extendDays } = form.getFieldsValue();
    const value = parseInt(extendDays);
    if (isNaN(value) || !value) {
      return Promise.reject("'Extend days' is required");
    } else if (Number(value) > 28) {
      return Promise.reject("'Extend days' cannot be longer than 28 days");
    } else {
      return Promise.resolve();
    }
  }

  const updateTitle = (value: string, locale: LocaleType) => {
    const updatedContents = contents.map((content) =>
      content.locale === locale ? { ...content, title: value } : content
    );
    setContents(updatedContents);
  };

  const updateDescription = (value: DeltaStatic, locale: LocaleType) => {
    const updatedContents = contents.map((content) =>
      content.locale === locale ? { ...content, description: value } : content
    );
    setContents(updatedContents);
  };

  const PromotionCodeTooltip = {
    title: (
      <div>
        You can only set up promotions for subscription plans (including all access). Remember, promotions need to be
        active and available to both new and existing subscribers.
      </div>
    ),
    icon: <InfoCircleOutlined className={styles.infoIcon} />,
  };

  const renderSwitchPlanItems = () => (
    <>
      <Divider className={styles.divider} />
      <Col span={24}>
        <Form.Item
          label="Effective date"
          name="effectiveDate"
          initialValue={EffectiveDate.IMMEDIATELY}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            {Object.values(EffectiveDate).map((type) => (
              <Radio key={type} value={type}>
                {type}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Switch to plan" name="switchToPlanId" rules={[{ required: true }]}>
          <DebounceSearchInput
            showSearch
            onChange={handleSwitchPlanInputChange}
            fetchOptions={handleVariantsSearch}
            existedOptions={planList}
            onClear={() => {
              setPlanList([]);
            }}
            allowClear
            placeholder="Search PID or plan name"
            suffixIcon={<SearchOutlined className={styles.searchIcon} />}
          />
        </Form.Item>
      </Col>
    </>
  );

  const renderSaveOfferInfo = () => {
    const createPromotionCodeInput = (name: string, options: PromotionDetailModel[], onChange: any) => (
      <Form.Item
        label="Promotion code"
        name={name}
        rules={[{ required: isSaveOfferTypePromotion() }]}
        tooltip={PromotionCodeTooltip}
      >
        <Select
          showArrow
          allowClear
          notFoundContent={
            isLoadingPromoListByBasePrice && isSaveOfferTypePromotion() ? (
              <div style={{ height: "120px" }}>
                <Spin size="small" />
              </div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          suffixIcon={<DropDown />}
          options={options.map(({ id, code }) => ({
            label: code,
            value: id,
          }))}
          onChange={onChange}
        />
      </Form.Item>
    );

    const promotionCodeBasePrice = createPromotionCodeInput(
      "basePricePromotionId",
      promotionListByBasePrice,
      handlePromotionCodeChange
    );

    const promotionCodePlan = createPromotionCodeInput(
      "planPromotionId",
      promotionListByPlan,
      handlePromotionCodeChange
    );

    return (
      <Form.Item shouldUpdate>
        {isSaveOfferTypePromotion() ? (
          form.getFieldValue("basePricePromotionId") ? (
            <SaveOfferPromoDetails
              promotionDetail={selectedPromotionByBasePrice}
              namePrefix={PromotionNamePrefix.BasePrice}
              form={form}
              promotionCodeInput={promotionCodeBasePrice}
            />
          ) : (
            <Row gutter={32}>
              <Col span={6}>{promotionCodeBasePrice}</Col>
            </Row>
          )
        ) : form.getFieldValue("planPromotionId") ? (
          <SaveOfferPromoDetails
            promotionDetail={selectedPromotionByPlan}
            namePrefix={PromotionNamePrefix.Plan}
            form={form}
            promotionCodeInput={promotionCodePlan}
          />
        ) : (
          <Row gutter={32}>
            <Col span={6}>{promotionCodePlan}</Col>
          </Row>
        )}
      </Form.Item>
    );
  };

  const renderExtendDays = () => (
    <>
      <Divider className={styles.divider} />
      <Col span={6}>
        <Form.Item
          label="Extend days"
          name="extendDays"
          className={styles.extendDays}
          rules={[
            {
              required: true,
              validator: validExtendDays,
            },
          ]}
          initialValue={10}
        >
          <InputNumber style={{ width: "100%" }} precision={0} onKeyPress={preventNonNumericalInput} step={1} min={1} />
        </Form.Item>
      </Col>
    </>
  );

  return (
    <Spin spinning={isLoadingData} size="large">
      <ConfigProvider form={{ validateMessages }}>
        <Form form={form} validateTrigger={"onBlur"} layout="vertical" className={styles.saveOfferForm}>
          <div className={styles.subtitle}>
            <span>Application rules</span>
          </div>
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item label="Apllied cancel reason" name="cancelReasonId" rules={[{ required: true }]}>
                <Select
                  allowClear
                  optionFilterProp="label"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  showArrow
                  suffixIcon={<DropDown />}
                  placeholder="Please select applied cancel reason"
                  options={lists.cancelReasonList}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Applied stage" name="appliedStages" rules={[{ required: true }]}>
                <Select
                  allowClear
                  optionFilterProp="label"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  showArrow
                  suffixIcon={<DropDown />}
                  mode="multiple"
                  placeholder="Please select applied stage"
                  options={Object.values(AppliedStage).map((stage) => ({
                    label: stage,
                    value: stage,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col span={24}>
            <Form.Item
              label="Applied base price"
              name="appliedBasePriceIds"
              rules={[{ required: true, type: "array" }]}
            >
              <Select
                allowClear
                optionFilterProp="label"
                getPopupContainer={(trigger) => trigger.parentNode}
                showArrow
                suffixIcon={<DropDown />}
                mode="multiple"
                placeholder="Please select applied base price"
                onChange={() => {
                  form.resetFields(["basePricePromotionId"]);
                  setSelectedPromotionByBasePrice({} as PromotionDetailModel);
                  setPromotionListByBasePrice([]);
                  handleFetchPromotionsByBasePrice();
                }}
                options={lists.basePriceList.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
              />
            </Form.Item>
          </Col>
          <Divider className={styles.divider} />
          <div className={styles.subtitle}>
            <span>Save offer details</span>
          </div>
          <Col className={styles.tabCardContainer} span={18}>
            <Tabs type="card">
              {contents.map((item: SaveOfferContent) => (
                <TabPane tab={item.label} key={item.key}>
                  <Row>
                    <Col span={18}>
                      <Form.Item
                        label="Save offer title"
                        name={`${item.locale}Title`}
                        rules={[{ type: "string", max: 100, required: item.required }]}
                      >
                        <Input onChange={(e) => updateTitle(e.target.value, item.locale)} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        style={{ margin: 0 }}
                        label="Save offer description"
                        name={`${item.locale}Description`}
                        rules={[
                          {
                            required: item.required,
                            transform: (value) => (isDeltaEmpty(value) ? "" : JSON.stringify(value)),
                            type: "string",
                            max: DESCRIPTION_MAX_LENGTH,
                          },
                        ]}
                      >
                        <DescriptionContentInput
                          bounds={item.key}
                          editable={true}
                          value={item.description}
                          onChange={(value) => updateDescription(value, item.locale)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
              ))}
            </Tabs>
          </Col>
          <Divider />
          <Col span={24}>
            <Form.Item
              label="Save offer type"
              name="saveOfferType"
              initialValue={Object.values(SaveOfferType)[0]}
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Space size={12}>
                  {Object.values(SaveOfferType).map((type) => (
                    <Radio key={type} value={type}>
                      {type}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Form.Item noStyle shouldUpdate>
            {() => {
              const selectedType = form.getFieldValue("saveOfferType");
              const shouldRenderSwitchPlanItems = selectedType === SaveOfferType.SWITCH_PLAN;
              const shouldRenderPromoInfo =
                selectedType === SaveOfferType.SWITCH_PLAN || selectedType === SaveOfferType.PROMOTION;
              const shouldRenderExtendDays = selectedType === SaveOfferType.EXTEND_BILLING_DATE;

              return (
                <>
                  {shouldRenderSwitchPlanItems && renderSwitchPlanItems()}
                  {shouldRenderPromoInfo && renderSaveOfferInfo()}
                  {shouldRenderExtendDays && renderExtendDays()}
                </>
              );
            }}
          </Form.Item>
        </Form>
      </ConfigProvider>
    </Spin>
  );
}
