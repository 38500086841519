import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import * as organizationAPI from "services/organization";
import { RootState } from "app/store";
import { SortOrder } from "antd/es/table/interface";
import { OrganizationModal } from "types/model/organization";
import { OrganizationListResponse } from "../../types/dto/response/organization";
import Page from "../../types/page";
import { OrganizationListParams } from "../../types/dto/request/organization";
import { getOrder } from "../../utils";

type Record = OrganizationModal;

interface IState {
  currentPage: number;
  currentSize: number;
  totalElements: number;
}

export const fetchOrganizations = createAsyncThunk<
  Page<OrganizationListResponse>,
  Omit<OrganizationListParams, "sort" | "size"> & {
    sortKey?: string;
    order?: SortOrder;
  }
>("organization/fetchAll", ({ sortKey, order, ...params }) => {
  let sort;
  if (sortKey && order) {
    sort = sortKey + "," + getOrder(order);
  } else {
    sort = "lastModifiedDate,DESC";
  }

  return organizationAPI.fetchAll({
    sort,
    size: 10,
    ...params,
    page: params.page ? params.page - 1 : undefined,
  });
});

export const createOrganization = createAsyncThunk(
  "organization/createOrganization",
  organizationAPI.createOrganization
);

export const fetchOrganization = createAsyncThunk("organization/fetchOrganization", async (id: string) => {
  return await organizationAPI.fetchOrganization(id);
});

export const updateOrganization = createAsyncThunk("organization/update", organizationAPI.updateOrganization);

export const removeOrganization = createAsyncThunk("organization/delete", organizationAPI.removeOrganization);

export const sendEmail = createAsyncThunk("organization/sendEmail", async (params: any) => {
  return await organizationAPI.sendEmail(params.id, params.email);
});

const organizationsAdapter = createEntityAdapter<Record>();

const organizationsSlice = createSlice({
  name: "organization",
  initialState: organizationsAdapter.getInitialState<IState>({
    currentPage: 1,
    currentSize: 10,
    totalElements: 0,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizations.fulfilled, (state, { payload }) => {
      const { content, totalElements, page, size } = payload;
      organizationsAdapter.setAll(state, content);
      state.currentPage = page + 1;
      state.currentSize = size;
      state.totalElements = totalElements;
    });
    builder.addCase(fetchOrganization.fulfilled, (state, { payload }) => {
      organizationsAdapter.setOne(state, payload);
    });
  },
});

export default organizationsSlice.reducer;

export const organizationsSelectors = organizationsAdapter.getSelectors<RootState>((state) => state.organization);
