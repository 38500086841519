import { ConfigProvider, Form, Input, Select, message } from "antd";
import { useEffect, useState } from "react";
import { BillingAddress } from "../../../../../types/model/paymentMethod";
import { EComModal } from "../../../../../components/EComModal";
import { validateMessages } from "../../../../../constants/common";
import modalStyles from "../../../../../components/EComModal/index.module.scss";
import { fetchAllCountries } from "../../../../../services/basePrice";
import { Country } from "../../../../../types/model/price";
import * as CustomerAPI from "../../../../../services/customer";
import { transformToBillingAddressUpdateRequest } from "../../../../../types/transformers/user/paymentMethod";

interface IEditBillingAddressModalProps {
  auth0Id: string;
  visible: boolean;
  billingAddress: BillingAddress;
  onFinish: () => void;
  closeModal: () => void;
}

const canadaProvinces = [
  { label: "Alberta", value: "AB" },
  { label: "British Columbia", value: "BC" },
  { label: "Manitoba", value: "MB" },
  { label: "New Brunswick", value: "NB" },
  { label: "Newfoundland and Labrador", value: "NL" },
  { label: "Nova Scotia", value: "NS" },
  { label: "Northwest Territories", value: "NT" },
  { label: "Nunavut", value: "NU" },
  { label: "Ontario", value: "ON" },
  { label: "Prince Edward Island", value: "PE" },
  { label: "Quebec", value: "QC" },
  { label: "Saskatchewan", value: "SK" },
  { label: "Yukon", value: "YT" },
];

export default function EditBillingAddressModal({
  auth0Id,
  visible,
  billingAddress,
  onFinish,
  closeModal,
}: IEditBillingAddressModalProps) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([] as Country[]);
  const [selectedCountry, setSelectedCountry] = useState(billingAddress.country);
  const [codeLabel, setCodeLabel] = useState<string>("ZIP Code");

  useEffect(() => {
    if (selectedCountry === "US" || selectedCountry === "PH" || !selectedCountry) {
      setCodeLabel("ZIP Code");
    } else {
      setCodeLabel("Postcode");
    }
    form.validateFields(["postalCode"]).then();
  }, [selectedCountry]);

  useEffect(() => {
    fetchAllCountries().then((data) => setCountries(data));
  }, []);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const values = await form.validateFields();
      const billingAddress: BillingAddress = {
        ...values,
      };
      await CustomerAPI.updateUserDefaultPaymentMethodBillingAddress(
        auth0Id,
        transformToBillingAddressUpdateRequest(billingAddress)
      );
      message.success("Update successful");
      onFinish();
      closeModal();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EComModal
      title="Edit billing address"
      visible={visible}
      okText="Confirm"
      onCancel={closeModal}
      onOk={onSubmit}
      style={modalStyles.modal}
      confirmLoading={isLoading}
    >
      <ConfigProvider form={{ validateMessages }}>
        <Form
          className={modalStyles.form}
          form={form}
          initialValues={{
            ...billingAddress,
          }}
        >
          <Form.Item
            label="Country/region:"
            name="country"
            className={modalStyles.formItem}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              options={countries.map((country) => {
                return {
                  value: country.iso2.toUpperCase(),
                  label: country.displayName,
                };
              })}
              onChange={(value: string) => setSelectedCountry(value)}
            />
          </Form.Item>
          {selectedCountry === "CA" && (
            <Form.Item label="Province:" name="district" className={modalStyles.formItem} rules={[{ required: true }]}>
              <Select
                options={canadaProvinces.map((province) => {
                  return { value: province.value, label: province.label };
                })}
              />
            </Form.Item>
          )}
          <Form.Item
            label={codeLabel}
            name="postalCode"
            required
            className={modalStyles.formItem}
            rules={[
              { required: true },
              {
                validator: (_, val) => {
                  if (val && selectedCountry === "US" && !/^[0-9-]+$/.test(val)) {
                    return Promise.reject(new Error(`Please input a valid ${codeLabel}.`));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </ConfigProvider>
    </EComModal>
  );
}
