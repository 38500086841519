import React, { useState } from "react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import styles from "./NamiSupportCheckbox.module.scss";

function NamiSupportCheckbox({
  checked,
  disabled,
  onCheckboxChange,
}: {
  checked: boolean | undefined;
  disabled?: boolean;
  onCheckboxChange?: (isChecked: boolean) => void;
}) {
  const [isChecked, setIsChecked] = useState(checked || false);

  const onCheckBoxClick = (e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked);
    onCheckboxChange && onCheckboxChange(e.target.checked);
  };

  return (
    <>
      <div className={styles.namiCheck}>
        <Checkbox
          className={styles.namiCheckTitle}
          disabled={disabled}
          defaultChecked={isChecked}
          onChange={onCheckBoxClick}
        >
          Paywall is supported by Nami
        </Checkbox>
        {isChecked && (
          <span className={styles.namiCheckReminder}>
            Reminder: make sure you have configured the placement label, paywall, product group and product on Nami.
          </span>
        )}
      </div>
    </>
  );
}

export default NamiSupportCheckbox;
