import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isEmpty, join } from "lodash";
import Icon from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { message, Modal } from "../index";
import styles from "./MiniCourseVerification.module.scss";
import { verifyMiniCourse } from "../../features/miniCourse/miniCourseSlice";
import { Copy, Fail, Success } from "../../assets/icons";
import { useAppDispatch } from "../../app/store";

export const useVerifyMiniCourse = (id: number) => {
  const [verifyForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleCopy = () => {
    message.success("Copied", 1);
  };

  const handleVerify = () => {
    Modal.confirm({
      title: "Confirm",
      content: (
        <>
          <div>Are you sure you want to verify this mini course?</div>
          <Form className={styles.verifyForm} form={verifyForm}>
            <Form.Item label="Comments" name="comments" rules={[{ max: 200 }]}>
              <Input />
            </Form.Item>
          </Form>
        </>
      ),
      okText: "Yes",
      cancelText: "No",
      onOk: () =>
        verifyForm.validateFields().then(() => {
          const { comments } = verifyForm.getFieldsValue();
          dispatch(verifyMiniCourse({ id, comments }))
            .then(unwrapResult)
            .then((data) => {
              if (data?.succeeded) {
                Modal.info({
                  className: styles.modal,
                  title: "Verify Successful",
                  content: (
                    <div className={styles.content}>
                      {data?.updatedFiles.map((messageInfo, index) => (
                        <div key={index}>{messageInfo}</div>
                      ))}
                    </div>
                  ),
                  icon: <Icon component={Success} />,
                  onOk: () => {
                    history.go(0);
                  },
                });
              } else {
                const mismatchedAndIncorrect = !isEmpty(data?.mismatchedItems) && !isEmpty(data?.incorrectFormatItems);
                const mismatchedOnly = !isEmpty(data?.mismatchedItems) && isEmpty(data?.incorrectFormatItems);
                const incorrectOnly = !isEmpty(data?.incorrectFormatItems) && isEmpty(data?.mismatchedItems);
                Modal.info({
                  title: (
                    <>
                      {mismatchedAndIncorrect && "Mismatched Items And Incorrect Formats"}
                      {mismatchedOnly && "Mismatched Items"}
                      {incorrectOnly && "Incorrect Formats"}
                    </>
                  ),
                  icon: <Icon component={Fail} />,
                  className: styles.modal,
                  content: (
                    <>
                      <div className={styles.content}>
                        {mismatchedAndIncorrect && (
                          <>
                            {data?.mismatchedItems.map((messageInfo, index) => (
                              <div key={index}>{messageInfo}</div>
                            ))}
                            <br />
                            {data?.incorrectFormatItems.map((messageInfo, index) => (
                              <div key={index}>{messageInfo}</div>
                            ))}
                          </>
                        )}
                        {mismatchedOnly &&
                          data?.mismatchedItems.map((messageInfo, index) => <div key={index}>{messageInfo}</div>)}
                        {incorrectOnly &&
                          data?.incorrectFormatItems.map((messageInfo, index) => <div key={index}>{messageInfo}</div>)}
                      </div>
                      <div className={styles.clipboard}>
                        <Copy />
                        <CopyToClipboard
                          text={
                            mismatchedAndIncorrect
                              ? join(data?.mismatchedItems.concat(data?.incorrectFormatItems), "\n")
                              : mismatchedOnly
                              ? join(data?.mismatchedItems, "\n")
                              : join(data?.incorrectFormatItems, "\n")
                          }
                        >
                          <Button type={"link"} onClick={handleCopy}>
                            Copy to clipboard
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </>
                  ),
                });
              }
            })
            .catch((err) => {
              message.error(err).then(() => {
                history.go(0);
              });
            });
        }),
    });
  };
  return { handleVerify };
};
