import moment from "moment";
import omit from "lodash/omit";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Card, Input, Row, Space } from "antd";
import { ArrayParam, NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { SorterResult } from "antd/lib/table/interface";
import { TableProps } from "antd/lib/table";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./index.module.scss";
import { DropdownInput } from "../../../../components";
import { ActiveBasePriceModel } from "../../../../types/model/price";
import * as eComProductAPI from "../../../../services/eComProduct";
import * as basePriceAPI from "../../../../services/basePrice";
import { EComProductListRequest } from "../../../../types/dto/request/eComProduct";
import {
  BillingPlan,
  EComProductListModel,
  EComProductModel,
  EComProductStatus,
  EComProductStatusType,
} from "../../../../types/model/eComProduct";
import { updateSortOrder } from "../../../../types/transformers/updateSortOrder";
import { getOffset } from "../../../../utils/getPagination";
import EComProductAdvancedSearch from "./ProductAdvancedSearch";
import { DAY_BEGIN, DAY_END } from "../../../../constants/common";
import * as contentProductAPI from "../../../../services/product";
import { DisplayLanguage } from "../AddNewProduct/ProductInfo";
import DisplayTags from "./DisplayTags";
import EComProductTable from "./ProductTable";
import useAppAuth0 from "../../../../hooks/useAppAuth0";
import AddButton from "../../../../components/AddButton";

const statusOptions = [
  { label: "Draft", value: EComProductStatus.Draft },
  {
    label: "Ready for publish",
    value: EComProductStatus.ReadyForPublish,
  },
  { label: "Active", value: EComProductStatus.Active },
  { label: "Inactive", value: EComProductStatus.Inactive },
];

function EComProductList() {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
    pid: withDefault(StringParam, undefined),
    order: withDefault(StringParam, "-updated_at"),
    status: withDefault(ArrayParam, undefined),
    lastModifiedDateFrom: withDefault(NumberParam, undefined),
    lastModifiedDateTo: withDefault(NumberParam, undefined),
    basePriceIds: withDefault(ArrayParam, undefined),
    languageId: withDefault(NumberParam, undefined),
    courseType: withDefault(StringParam, undefined),
    billingPlanId: withDefault(StringParam, undefined),
  });
  const [selectStatus, setSelectStatus] = useState<EComProductStatusType[]>();
  const [pid, setPid] = useState(query.pid);
  const [isEComProductListPending, setIsEComProductListPending] = useState(false);
  const [productList, setProductList] = useState<EComProductListModel>({} as EComProductListModel);
  const history = useHistory();
  const { path } = useRouteMatch();
  const [languages, setLanguages] = useState<Omit<DisplayLanguage, "usedCourseTypes">[]>([]);
  const [basePriceList, setBasePriceList] = useState<ActiveBasePriceModel[]>([]);
  const [isPublishPlansDisabled, setIsPublishPlansDisabled] = useState(true);
  const [billingPlanList, setBillingPlanList] = useState<BillingPlan[]>([]);

  useEffect(() => {
    Promise.all([
      contentProductAPI.fetchLanguagesIncludeAllAccess(),
      basePriceAPI.fetchActiveBasePriceList(),
      eComProductAPI.fetchProductList({
        status: "Ready for publish",
        limit: 1,
        offset: 0,
      }),
      eComProductAPI.fetchBillingPlans(),
    ]).then(([languages, basePriceList, readyForPublishProductList, billingPlanList]) => {
      const displayLanguages = languages.content.map(({ id, name }) => ({
        languageId: id,
        name,
      }));
      setLanguages(displayLanguages);
      setBasePriceList(basePriceList);
      setIsPublishPlansDisabled(readyForPublishProductList.total === 0);
      setBillingPlanList(billingPlanList);
    });
  }, []);

  useEffect(() => {
    setIsEComProductListPending(true);
    eComProductAPI
      .fetchProductList({
        ...omit(query, ["page"]),
        offset: getOffset(query.page, query.limit),
      } as EComProductListRequest)
      .then((res) => {
        setProductList(res);
      })
      .finally(() => {
        setIsEComProductListPending(false);
      });
    setPid(query.pid);
    if (query.status) {
      setSelectStatus(query.status as EComProductStatusType[]);
    }
  }, [query]);
  const { user } = useAppAuth0();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPid(e.target.value);
  };

  const handleInputPressEnter = () => {
    setQuery({
      ...query,
      pid: pid?.trim(),
      page: pid?.trim() ? 1 : query.page,
    });
  };

  const handleTableChange: TableProps<EComProductModel>["onChange"] = (pagination, filters, sorter, extra) => {
    const { order } = sorter as SorterResult<EComProductModel>;
    if (extra.action === "sort") {
      setQuery({
        ...query,
        order: updateSortOrder[order as string],
        page: 1,
        limit: pagination.pageSize,
      });
    }
  };

  const handleAdvancedSearch = (values: EComProductListRequest) => {
    const { lastModifiedDateTo, lastModifiedDateFrom } = values;
    // @ts-ignore
    setQuery({
      ...query,
      ...values,
      lastModifiedDateFrom: lastModifiedDateFrom ? moment(lastModifiedDateFrom).set(DAY_BEGIN).valueOf() : undefined,
      lastModifiedDateTo: lastModifiedDateTo ? moment(lastModifiedDateTo).set(DAY_END).valueOf() : undefined,
      page: 1,
    });
  };

  const handleTagsChange = (patchQuery: EComProductListRequest) => {
    // @ts-ignore
    setQuery({
      ...query,
      ...patchQuery,
      page: 1,
    });
  };

  const handleOnRowClick: TableProps<EComProductModel>["onRow"] = (record) => ({
    onClick: () =>
      history.push({
        pathname: `${path}/${record.productId}`,
        state: { pid: record.pid },
      }),
  });

  const renderTitleButtons = () => {
    return (
      <Space size={8} className={styles.buttons}>
        {user.canPublishEcomProduct && (
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              history.push(`${path}/publish`);
            }}
            disabled={isPublishPlansDisabled}
          >
            Publish
          </Button>
        )}
        {user.canCreateEcomProduct && <AddButton text={"+ Add new product"} />}
      </Space>
    );
  };

  return (
    <Card title="eCom Product list" extra={renderTitleButtons()} className="page-container">
      <Row justify={"space-between"} className={styles.filter}>
        <Space>
          <DropdownInput
            options={statusOptions}
            value={selectStatus}
            onChange={(status) => {
              setSelectStatus(status as EComProductStatusType[]);
              setQuery({
                ...query,
                status: status as EComProductStatusType[],
                page: 1,
              });
            }}
            placeholder="All statuses"
          />
          <Input
            allowClear
            value={pid}
            onChange={handleInputChange}
            onBlur={() => setPid((prevState) => prevState?.trim())}
            onPressEnter={handleInputPressEnter}
            className={styles.basePriceNameInput}
            prefix={<SearchOutlined />}
            placeholder="Search PID"
          />
          <EComProductAdvancedSearch
            initialValues={query as EComProductListRequest}
            languages={languages}
            basePriceList={basePriceList}
            billingPlanList={billingPlanList}
            onSubmit={handleAdvancedSearch}
          />
        </Space>
      </Row>

      <DisplayTags
        query={query as EComProductListRequest}
        languages={languages}
        basePriceList={basePriceList}
        billingPlanList={billingPlanList}
        onTagsChange={handleTagsChange}
      />

      <EComProductTable
        isLoading={isEComProductListPending}
        productData={productList}
        page={query.page}
        size={query.limit}
        onPaginationChange={(page, pageSize) => {
          setQuery({
            ...query,
            page,
            limit: pageSize,
            order: query.order,
          });
        }}
        onTableChange={handleTableChange}
        onRawChange={handleOnRowClick}
      />
    </Card>
  );
}

export default EComProductList;
