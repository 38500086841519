import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Space } from "antd";
import { isEmpty } from "lodash";
import styles from "./index.module.scss";
import { AddNewPlanContext } from "../../AddNewProduct";
import { ProductPlan } from "../../../../../types/model/eComProduct";
import AddNewPlanForm from "../AddNewPlanForm";
import { message } from "../../../../../components";
import { ProductDetailContext } from "../../ProductDetails";
import * as eComProductAPI from "../../../../../services/eComProduct";
import { convertToProductPlan } from "../../../../../types/transformers/eComProduct";
import useAppAuth0 from "../../../../../hooks/useAppAuth0";

interface AddNewPlanProps {
  setNewPlanFieldsValue?: any;
  resetEditingPlan?: () => void;
  fetchData?: (pid: string) => void;
}

function AddNewPlan({ setNewPlanFieldsValue, fetchData, resetEditingPlan }: AddNewPlanProps) {
  const productInfo = useContext(AddNewPlanContext);
  const { user } = useAppAuth0();
  const editProductInfo = useContext(ProductDetailContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (!isModalVisible) {
      form.resetFields();
    }
  }, [form, isModalVisible]);

  const handleAddNewPlan = () => {
    if (isEmpty(productInfo) && isEmpty(editProductInfo)) {
      message.warning("Please complete the product info first").then();
    } else {
      resetEditingPlan?.();
      setIsModalVisible(true);
    }
  };

  const handleFormChange = (values: any) => {
    form.setFieldsValue(values);
  };

  const handleSave = async () => {
    form.validateFields().then(() => {
      const value = form.getFieldsValue();
      if (value) {
        setNewPlanFieldsValue((prevPlans: ProductPlan[]) => [...prevPlans, value]);
        setIsModalVisible(false);
      }
    });
  };

  const handleSaveOnEditPage = async () => {
    form.validateFields().then(() => {
      const value = form.getFieldsValue();
      if (value && editProductInfo?.productId) {
        setConfirmLoading(true);
        const newProductPlan = convertToProductPlan(value);
        eComProductAPI
          .createVariant(editProductInfo.productId, newProductPlan)
          .then((r) => {
            message.success("Plan added successfully.");
            if (fetchData) {
              fetchData(r.pid);
            }
          })
          .finally(() => {
            setConfirmLoading(false);
            setIsModalVisible(false);
          });
      }
    });
  };

  return (
    <div>
      {user.canCreateEcomPlan && (
        <Button type="default" shape="round" data-testid="add-new-plan-btn" onClick={handleAddNewPlan}>
          + Add new plan
        </Button>
      )}
      <Modal
        bodyStyle={{ overflowY: "auto", maxHeight: "70vh" }}
        centered={true}
        data-testid="add-new-plan-modal"
        title="Add new plan"
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        onOk={productInfo ? handleSave : handleSaveOnEditPage}
        okText="Save"
        maskClosable={false}
        cancelButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
        okButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
        className={styles.modal}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
      >
        <Space direction="vertical" size={12}>
          <AddNewPlanForm form={form} onFormChange={handleFormChange} />
        </Space>
      </Modal>
    </div>
  );
}

export default AddNewPlan;
