import CryptoJS from "crypto-js";
import React, { useEffect, useMemo, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { ConfigProvider, Form, Input, message, Select } from "antd";
import modalStyles from "../../../../components/EComModal/index.module.scss";
import { EComModal } from "../../../../components/EComModal";
import { EMPTY, validateMessages } from "../../../../constants/common";
import { Country } from "../../../../types/model/price";
import ModalContent from "../../../../components/EComModal/ModalContent";
import ModalListItem from "../../../../components/EComModal/ModalListItem";
import { DropDown } from "../../../../assets/icons";
import DebounceSearchInput from "../../../../components/DebounceSearchInput";
import styles from "../../../marketing/SaveOffer/SaveOfferDetails/SaveOfferForm.module.scss";
import * as eComProductApi from "../../../../services/eComProduct";
import { DiscountType } from "../../../../types/model/promotion";
import { sendCheckoutLink } from "../../../../services/checkout";
import selectStyle from "../../Subscriptions/Subscription.module.scss";
import { fetchAllCountries } from "../../../../services/basePrice";

interface ISentCheckoutLinkModalProps {
  visible: boolean;
  onClose: () => void;
}

interface Option {
  label: string;
  value: string;
}

export default function SentCheckoutLinkModal({ visible, onClose }: ISentCheckoutLinkModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [planList, setPlanList] = useState<any>([]);
  const [planOptionList, setPlanOptionList] = useState<Option[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [countries, setCountries] = useState([] as Country[]);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [promotions, setPromotions] = useState<any[]>([]);
  const [selectedPromotion, setSelectedPromotion] = useState<any>(null);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchCountries().then();
  }, []);

  useEffect(() => {
    form.resetFields(["country", "promoCode"]);
    setSelectedCountry(null);
    setSelectedPromotion(null);
  }, [selectedPlan]);

  useEffect(() => {
    form.resetFields(["promoCode"]);
    setSelectedPromotion(null);
    if (!selectedCountry) {
      return;
    }

    const promotions = selectedPlan.promotions.filter((promotion: any) => {
      return promotion.countries.length === 0
        ? true
        : promotion.countries.find((country: any) => {
            return country.iso2 === selectedCountry.iso2;
          });
    });

    setPromotions(promotions);
  }, [selectedCountry]);

  async function fetchCountries() {
    try {
      const allCountries = await fetchAllCountries();
      setCountries(allCountries);
    } finally {
    }
  }

  async function onSubmit() {
    setIsLoading(true);
    try {
      const values = await form.validateFields();
      await sendCheckoutLink(values.email, generateDeeplink(), selectedPlan.title);
      message.success("Send successfully");
      onClose();
    } finally {
      setIsLoading(false);
    }
  }

  const handleVariantsSearch = async (search: string): Promise<Option[]> => {
    setPlanOptionList([]);
    const res = await eComProductApi.fetchProductVariantsByFuzzySearch(search);
    setPlanList(res);
    const options = res
      ? res.map(({ id, title, pid, billingPlan }) => ({
          key: id,
          label: billingPlan ? `${pid} ${title} / ${billingPlan.name}` : `${pid} ${title}`,
          value: id,
        }))
      : [];
    setPlanOptionList(options);
    return options;
  };

  const generateDeeplink = () => {
    const iso2 = selectedCountry?.iso2;
    const pid = selectedPlan.pid;
    const promoCode = selectedPromotion?.code;
    const basePriceCountries = selectedPlan.basePriceWithCountries
      .map((item: any) => item.countries.map((country: any) => country.iso2))
      .flat();

    if (!basePriceCountries.includes(iso2)) {
      return `${process.env.REACT_APP_ECOM_BASE_URL}/checkout?pid=${pid}${promoCode ? `&promotion=${promoCode}` : ""}`;
    }

    const cipherText = CryptoJS.RC4.encrypt(JSON.stringify({ country: iso2 }), "").toString();
    const encodedCipherText = encodeURIComponent(cipherText);
    return `${process.env.REACT_APP_ECOM_BASE_URL}/checkout?pid=${pid}&data=${encodedCipherText}${
      promoCode ? `&promotion=${promoCode}` : ""
    }`;
  };

  const selectedBasePrice = useMemo(() => {
    if (!selectedPlan || !selectedCountry) {
      return null;
    }

    return (
      selectedPlan.basePriceWithCountries.find((item: any) =>
        item.countries.find((country: any) => {
          return country.iso2 === selectedCountry.iso2;
        })
      ) || selectedPlan.basePriceWithCountries.find((item: any) => item.currency.code === "usd")
    );
  }, [selectedPlan, selectedCountry]);

  const price = () => {
    if (!selectedPlan || !selectedCountry || !selectedBasePrice) {
      return EMPTY;
    }

    return `${selectedBasePrice.currency.symbol}${selectedBasePrice.amount}`;
  };

  const discount = () => {
    if (!selectedPromotion) {
      return EMPTY;
    }

    return selectedPromotion.discountType === DiscountType.AMOUNT
      ? `${selectedPromotion.currencyCode.toUpperCase()} ${selectedPromotion.amountDiscount}`
      : ` ${selectedPromotion.percentageDiscount}%`;
  };

  return (
    <EComModal
      title="Send checkout link"
      okText="Send"
      onCancel={onClose}
      onOk={onSubmit}
      visible={visible}
      confirmLoading={isLoading}
    >
      <ConfigProvider form={{ validateMessages }}>
        <Form className={modalStyles.form} form={form}>
          <Form.Item
            label="Email"
            name="email"
            required
            className={modalStyles.formItem}
            rules={[
              { required: true },
              {
                type: "email",
                message: "Please check the format of your email and try again.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Plan"
            name="plan"
            required
            className={`${modalStyles.formItem} ${selectStyle.customSelect}`}
            rules={[{ required: true }]}
          >
            <DebounceSearchInput
              showSearch
              onChange={(value) => {
                if (!planList) {
                  return;
                }
                const plan = planList.find((plan: any) => plan.id === value);
                setSelectedPlan(plan);
              }}
              fetchOptions={handleVariantsSearch}
              onClear={() => {
                setPlanOptionList([]);
                setSelectedPlan(null);
              }}
              allowClear
              placeholder="Search PID or plan name"
              suffixIcon={<SearchOutlined className={styles.searchIcon} />}
              existedOptions={planOptionList}
            />
          </Form.Item>
          {selectedPlan && countries.length > 0 && (
            <Form.Item
              label="Country"
              name="country"
              required
              dependencies={["plan"]}
              className={modalStyles.formItem}
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                optionFilterProp="label"
                suffixIcon={<DropDown />}
                onClear={() => {
                  setSelectedCountry(null);
                }}
                allowClear
                onChange={(value) => {
                  setSelectedCountry(countries.find((country) => country.iso2 === value) || null);
                }}
                options={countries.map((country) => ({
                  value: country.iso2,
                  label: country.displayName,
                }))}
              />
            </Form.Item>
          )}
          {selectedCountry && (
            <Form.Item
              label="Apply promo code"
              name="promoCode"
              dependencies={["country"]}
              className={modalStyles.formItem}
            >
              <Select
                allowClear
                suffixIcon={<DropDown />}
                options={promotions.map((promotion) => ({
                  value: promotion.id,
                  label: promotion.code,
                }))}
                onChange={(value) => {
                  setSelectedPromotion(promotions.find((promotion) => promotion.id === value));
                }}
              />
            </Form.Item>
          )}
        </Form>
      </ConfigProvider>
      {selectedPlan && selectedCountry && (
        <>
          <span>Please verify the information below with the user. A checkout link will be sent to the user.</span>
          <ModalContent>
            <ModalListItem label="Plan name:" value={selectedPlan.title} />
            {selectedPlan.billingPlan && <ModalListItem label="Billing plan:" value={selectedPlan.billingPlan.name} />}
            <ModalListItem label="Price:" value={price()} />
            {selectedPromotion && (
              <>
                <ModalListItem label="Discount:" value={discount()} />
                <ModalListItem label="Applied Billing cycle:" value={`1 to ${selectedPromotion.billingCycle}`} />
              </>
            )}
          </ModalContent>
        </>
      )}
    </EComModal>
  );
}
