import { useHistory } from "react-router";
import React, { useState } from "react";
import { Button, Card, Form, Space } from "antd";
import { Prompt } from "react-router-dom";
import { UpsellDowngradeForm } from "../UpsellDowngradeDetails/UpsellDowngradeForm";
import * as upsellDowngradeAPI from "../../../../services/upsellDowngrade";
import { LEAVE_CONFIRMATION } from "../../../../constants/common";
import { message } from "../../../../components";

function AddNewUpsellDowngrade() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [formIsHalfFilledOut, setFormIsHalfFilledOut] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const handleCreate = () => {
    form.validateFields().then((values) => {
      setIsSaveLoading(true);
      upsellDowngradeAPI
        .createUpsellDowngrade(values)
        .then(() => {
          setFormIsHalfFilledOut(false);
          history.push("/marketing/upsell-downgrade");
          message.success("Upsell & downgrade added successfully.");
        })
        .catch(() => setIsSaveLoading(false));
    });
  };

  return (
    <Card
      title={"Add new Upsell & Downgrade"}
      extra={
        <Space size={10}>
          <Button type="default" shape="round" onClick={() => history.push("/marketing/upsell-downgrade")}>
            Cancel
          </Button>
          <Button type="primary" shape="round" onClick={handleCreate} loading={isSaveLoading}>
            Save
          </Button>
        </Space>
      }
      className="page-container"
    >
      <Prompt when={formIsHalfFilledOut} message={LEAVE_CONFIRMATION} />
      <UpsellDowngradeForm form={form} />
    </Card>
  );
}

export default AddNewUpsellDowngrade;
