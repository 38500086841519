import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { groupBy, join, sortBy } from "lodash";
import { RootState, useAppDispatch } from "app/store";
import { useEffect } from "react";
import {
  fetchGenericProducts,
  fetchIndividualProducts,
  fetchOrganizations,
  fetchProducts,
} from "features/voucher/voucherSlice";
import { message } from "components";

export default function useVoucherOptions() {
  const dispatch = useAppDispatch();
  const { organizations, products, genericProducts, individualProducts } = useSelector(
    (state: RootState) => state.voucher
  );

  useEffect(() => {
    // TODO: refactor with Promise.all()
    dispatch(fetchOrganizations()).then(unwrapResult).catch(message.error);
    dispatch(fetchProducts()).then(unwrapResult).catch(message.error);
    dispatch(fetchGenericProducts()).then(unwrapResult).catch(message.error);
    dispatch(fetchIndividualProducts()).then(unwrapResult).catch(message.error);
  }, [dispatch]);

  function getIndividualCourseOptions(languageName?: string) {
    const latestOptions =
      languageName == null
        ? []
        : individualProducts
            .find((item) => item.languageName === languageName)
            ?.latestVersions?.map((item) => ({
              value: item.productId,
              label: `${item.productName} - ${item.isbn}`,
            })) || [];
    const historicalOptions =
      languageName == null
        ? []
        : individualProducts
            .find((item) => item.languageName === languageName)
            ?.historicalVersions?.map((item) => ({
              value: item.productId,
              label: `${item.productName} - ${item.isbn}`,
            })) || [];

    const options: {
      label: string;
      options: { value: number; label: string }[];
    }[] = [];
    if (latestOptions.length > 0) {
      options.push({
        label: "Latest version",
        options: latestOptions,
      });
    }
    if (historicalOptions.length > 0) {
      options.push({
        label: "Historical versions",
        options: historicalOptions,
      });
    }
    return options;
  }

  const options = {
    organization: sortBy(
      organizations.map((organization) => ({
        value: organization.id,
        label: organization.name,
      })),
      ["label"]
    ),
    voucherType: [
      {
        value: "SINGLE",
        label: "Single",
      },
      {
        value: "MASTER",
        label: "Master",
      },
      { value: "SEAT", label: "Seat" },
    ],
    DealType: [
      { value: "OPEN_ENDED", label: "Open ended" },
      { value: "FIXED_TERM", label: "Fixed term" },
    ],
    entitlementDealType: [
      { value: "OPEN_ENDED", label: "Open ended" },
      { value: "FIXED_TERM", label: "Fixed term" },
      { value: "FIXED_ENDED", label: "Fixed expiration date" },
    ],
    isbnSelection: [
      {
        value: "GENERIC",
        label: "Generic ISBN",
      },
      {
        value: "INDIVIDUAL",
        label: "Individual ISBNs",
      },
    ],
    genericProduct: genericProducts.map((product) => ({
      value: product.isbn,
      label: join([product.name, product.isbn], " - "),
    })),
    language: sortBy(
      Object.keys(groupBy(products, "languageName")).map((language) => ({
        label: language,
        value: language,
      })),
      ["label"]
    ),
    progressReport: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    isPrepaidVoucher: [
      { label: "Prepaid", value: true },
      { label: "Billing later", value: false },
    ],
    unlimitedTerm: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    allowCreateSubGroup: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  };

  return {
    options,
    getIndividualCourseOptions,
  };
}
