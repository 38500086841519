import React from "react";
import moment from "moment";
import { capitalize } from "lodash";
import { ColumnType } from "antd/lib/table";
import { CustomerModel } from "types/model/customer";
import { DATETIME_FORMAT, EMPTY } from "constants/common";
import { SortOrder } from "antd/lib/table/interface";
import CustomerAction from "./CustomerAction";
import { CustomerTab } from "./CustomerList";

function useColumns(tab: CustomerTab, sortOrder: SortOrder) {
  const columnsB2C: ColumnType<CustomerModel>[] = [
    {
      title: "Customer email",
      dataIndex: "email",
      key: "email",
      width: "25%",
      ellipsis: true,
      render: (_, record) => record.email || EMPTY,
    },
    {
      title: "Customer name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      ellipsis: true,
      render: (_, record) => record.name || EMPTY,
    },
    {
      title: "Connection",
      dataIndex: "connection",
      key: "connection",
      width: 130,
      render: (_, { connection }) => capitalize(connection ?? EMPTY),
    },
    {
      title: "Created at",
      dataIndex: "createdDate",
      key: "createdDate",
      defaultSortOrder: "descend",
      sortOrder: sortOrder,
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (_, { createdDate }) => moment(createdDate).format(DATETIME_FORMAT),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => <CustomerAction record={record} />,
    },
  ];

  const columnsB2B: ColumnType<CustomerModel>[] = [
    {
      title: "Customer email",
      dataIndex: "email",
      key: "email",
      width: "25%",
      ellipsis: true,
      render: (_, record) => record.email || EMPTY,
    },
    {
      title: "Customer name",
      dataIndex: "name",
      width: "20%",
      ellipsis: true,
      render: (_, record) => record.name || EMPTY,
    },
    {
      title: "Organization",
      dataIndex: "organization",
      width: "20%",
      ellipsis: true,
      render: (_, { organization }) => organization ?? EMPTY,
    },
    {
      title: "Created at",
      dataIndex: "createdDate",
      key: "createdDate",
      defaultSortOrder: "descend",
      sortOrder: sortOrder,
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (_, { createdDate }) => moment(createdDate).format(DATETIME_FORMAT),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => <CustomerAction record={record} />,
    },
  ];

  const columnMap = {
    B2B: columnsB2B,
    B2C: columnsB2C,
  };

  return columnMap[tab];
}

export default useColumns;
