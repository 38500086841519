import { isEmpty } from "lodash";
import {
  UpsellDowngradeDetailResponse,
  UpsellDowngradeListResponse,
  UpsellDowngradeOption,
  UpsellDowngradeRequest,
  UpsellDowngradeResponse,
  UpsellDowngradeType,
  UpsellOption,
} from "../dto/response/upsellDowngradeResponse";
import {
  PRODUCT_TYPE_UPSELL,
  UpsellDowngradeDetailViewFields,
  UpsellDowngradeFormValues,
  UpsellDowngradeListModel,
  UpsellDowngradeModel,
} from "../model/upsellDowngrade";
import { EMPTY } from "../../constants/common";
import {
  multiLevelCourseLevelOptions,
  singleLevelCourseLevelOptions,
} from "../../features/marketing/UpsellDowngrade/UpsellDowngradeDetails/UpsellDowngrade";

const findBasePriceNameByType = (type: UpsellDowngradeType, options: UpsellDowngradeOption[]) =>
  options.find((option) => option.option_type === type)?.base_price.name;

export function transformUpsellDowngrade(response: UpsellDowngradeResponse): UpsellDowngradeModel {
  return {
    id: response.id,
    originalBasePriceName: response.original_base_price.name,
    primaryUpsellBasePriceName:
      findBasePriceNameByType(UpsellDowngradeType.PRIMARY_UPSELL, response.upsell_downgrade_options) ?? EMPTY,
    secondaryUpsellBasePriceName:
      findBasePriceNameByType(UpsellDowngradeType.SECONDARY_UPSELL, response.upsell_downgrade_options) ?? EMPTY,
    downgradeUpsellBasePriceName:
      findBasePriceNameByType(UpsellDowngradeType.DOWNGRADE, response.upsell_downgrade_options) ?? EMPTY,
    updatedAt: response.updated_at,
  };
}

export function transformUpsellDowngradeList(response: UpsellDowngradeListResponse): UpsellDowngradeListModel {
  return {
    list: response.data.map(transformUpsellDowngrade),
    total: response.count,
  };
}

export function createUpsellDowngradeRequest(data: UpsellDowngradeFormValues): UpsellDowngradeRequest {
  const upsellDowngradeOptions: UpsellOption[] = [];
  const addOption = (
    type: UpsellDowngradeType,
    {
      basePriceId,
      productType,
      courseLevels,
      billingPlanId,
      tag,
      slogan,
    }: {
      basePriceId?: string;
      productType?: string;
      courseLevels?: string;
      billingPlanId?: string;
      tag?: string;
      slogan?: string;
    }
  ) => {
    if (basePriceId && productType) {
      upsellDowngradeOptions.push({
        option_type: type,
        base_price_id: basePriceId,
        product_type: productType,
        course_levels: courseLevels?.split(",").map(Number),
        billing_plan_id: billingPlanId,
        upsell_tag: tag,
        slogan: slogan,
      });
    }
  };

  addOption(UpsellDowngradeType.PRIMARY_UPSELL, {
    basePriceId: data.primaryUpsellBasePriceId,
    productType: data.primaryUpsellProductType,
    courseLevels: data.primaryUpsellCourseLevel,
    billingPlanId: data.primaryUpsellBillingPlanId,
    tag: data.primaryUpsellTag,
    slogan: data.primaryUpsellSlogan,
  });

  addOption(UpsellDowngradeType.SECONDARY_UPSELL, {
    basePriceId: data.secondaryUpsellBasePriceId,
    productType: data.secondaryUpsellProductType,
    courseLevels: data.secondaryUpsellCourseLevel,
    billingPlanId: data.secondaryUpsellBillingPlanId,
    tag: data.secondaryUpsellTag,
    slogan: data.secondaryUpsellSlogan,
  });

  addOption(UpsellDowngradeType.DOWNGRADE, {
    basePriceId: data.downgradeBasePriceId,
    productType: data.downgradeProductType,
    courseLevels: data.downgradeCourseLevel,
    billingPlanId: data.downgradeBillingPlanId,
  });

  return {
    original_base_price_id: data.originalBasePriceId,
    product_type: data.originalProductType,
    course_levels: data.originalCourseLevel?.split(",").map(Number),
    billing_plan_id: data.originalBillingPlanId,
    ...(!isEmpty(upsellDowngradeOptions) && {
      upsell_downgrade_options: upsellDowngradeOptions,
    }),
  };
}

function transformCourseLevelToCourseLevelName(productType?: string, courseLevels?: number[]) {
  if (productType === PRODUCT_TYPE_UPSELL.SINGLE_LEVEL) {
    return singleLevelCourseLevelOptions.find(({ value }) => value === courseLevels?.join(","))?.label;
  }
  if (productType === PRODUCT_TYPE_UPSELL.MULTI_LEVEL) {
    return multiLevelCourseLevelOptions.find(({ value }) => value === courseLevels?.join(","))?.label;
  }
  return EMPTY;
}

export function transformUpsellDowngradeDetail({
  original_base_price,
  product_type,
  course_levels,
  billing_plan,
  upsell_downgrade_options,
}: UpsellDowngradeDetailResponse): UpsellDowngradeDetailViewFields {
  const primaryUpsellOption = upsell_downgrade_options.find(
    ({ option_type }) => option_type === UpsellDowngradeType.PRIMARY_UPSELL
  );
  const secondaryUpsellOption = upsell_downgrade_options.find(
    ({ option_type }) => option_type === UpsellDowngradeType.SECONDARY_UPSELL
  );
  const downgradeOption = upsell_downgrade_options.find(
    ({ option_type }) => option_type === UpsellDowngradeType.DOWNGRADE
  );

  return {
    originalBasePriceId: original_base_price.id,
    originalBasePriceName: original_base_price.name,
    originalProductType: product_type as PRODUCT_TYPE_UPSELL,
    originalCourseLevel: course_levels?.join(","),
    originalCourseLevelName: transformCourseLevelToCourseLevelName(product_type, course_levels),
    originalBillingPlanId: billing_plan?.id,
    originalBillingPlanName: billing_plan?.name,
    primaryUpsellBasePriceId: primaryUpsellOption?.base_price.id,
    primaryUpsellBasePriceName: primaryUpsellOption?.base_price.name,
    primaryUpsellProductType: primaryUpsellOption?.product_type,
    primaryUpsellCourseLevel: primaryUpsellOption?.course_levels?.join(","),
    primaryUpsellCourseLevelName: transformCourseLevelToCourseLevelName(
      primaryUpsellOption?.product_type,
      primaryUpsellOption?.course_levels
    ),
    primaryUpsellBillingPlanId: primaryUpsellOption?.billing_plan?.id,
    primaryUpsellBillingPlanName: primaryUpsellOption?.billing_plan?.name,
    primaryUpsellTag: primaryUpsellOption?.upsell_tag,
    primaryUpsellSlogan: primaryUpsellOption?.slogan,
    secondaryUpsellBasePriceId: secondaryUpsellOption?.base_price.id,
    secondaryUpsellBasePriceName: secondaryUpsellOption?.base_price.name,
    secondaryUpsellProductType: secondaryUpsellOption?.product_type,
    secondaryUpsellCourseLevel: secondaryUpsellOption?.course_levels?.join(","),
    secondaryUpsellCourseLevelName: transformCourseLevelToCourseLevelName(
      secondaryUpsellOption?.product_type,
      secondaryUpsellOption?.course_levels
    ),
    secondaryUpsellBillingPlanId: secondaryUpsellOption?.billing_plan?.id,
    secondaryUpsellBillingPlanName: secondaryUpsellOption?.billing_plan?.name,
    secondaryUpsellTag: secondaryUpsellOption?.upsell_tag,
    secondaryUpsellSlogan: secondaryUpsellOption?.slogan,
    downgradeBasePriceId: downgradeOption?.base_price.id,
    downgradeBasePriceName: downgradeOption?.base_price.name,
    downgradeProductType: downgradeOption?.product_type,
    downgradeCourseLevel: downgradeOption?.course_levels?.join(","),
    downgradeCourseLevelName: transformCourseLevelToCourseLevelName(
      downgradeOption?.product_type,
      downgradeOption?.course_levels
    ),
    downgradeBillingPlanId: downgradeOption?.billing_plan?.id,
    downgradeBillingPlanName: downgradeOption?.billing_plan?.name,
  };
}
