import { parseContent } from "../../../utils/richTextEditor";
import {
  DisclosureDetailModel,
  DisclosureFormData,
  DisclosureListModel,
  DisclosureModel,
} from "../../model/disclosure";
import { DisclosureListResponse, DisclosureResponse } from "../../dto/response/disclosure";
import { transformCountry } from "../basePrice";
import { DisclosureFormDataRequest } from "../../dto/request/disclosure";
import { supportLocalTabs } from "../../../utils/locale";

export function transformDisclosure(response: DisclosureResponse): DisclosureModel {
  return {
    id: response.id,
    name: response.name,
    status: response.status,
    appliedBasePrice: response.base_prices,
    appliedBillingPlan: response.billing_plan,
    appliedCountryOrRegion: transformCountry(response.countries),
    updatedAt: response.updated_at,
  };
}

export function transformDisclosureList(response: DisclosureListResponse): DisclosureListModel {
  return {
    list: response.data.map(transformDisclosure),
    total: response.count,
  };
}

export function transformDisclosureFormDataRequest({
  name,
  basePriceIds,
  billingPlanId,
  countryIds,
  enContent,
  esContent,
}: DisclosureFormData): DisclosureFormDataRequest {
  return {
    name,
    base_price_ids: basePriceIds,
    billing_plan_id: billingPlanId,
    country_ids: countryIds,
    multi_language_content: {
      en: enContent,
      es: esContent,
    },
  };
}

export function transformDisclosureDetail(response: any): DisclosureDetailModel {
  return {
    id: response.id,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    name: response.name,
    status: response.status,
    billingPlanId: response.billing_plan_id,
    contents: supportLocalTabs.map((tab) => {
      return {
        label: tab.label,
        key: tab.key,
        locale: tab.locale,
        content: parseContent(response.multi_language_content?.[tab.locale] ?? ""),
        required: tab.required,
      };
    }),
    basePrices: response.base_prices,
    countries: response.countries.map((country: any) => ({
      id: country.id,
      displayName: country.display_name,
    })),
    billingPlan: response.billing_plan,
  };
}
