import { eComInstance } from "../utils/instance";
import EComPage from "../types/eComPage";
import { EComProductInfoListRequest, EComProductListRequest } from "../types/dto/request/eComProduct";
import {
  BillingPlansResponse,
  EComProductLanguageResponse,
  EComProductLogListResponse,
} from "../types/dto/response/eComProduct";
import { DEFAULT_MAX_PAGINATION } from "../constants/common";
import {
  transformComProductLanguages,
  transformComProductList,
  transformCreateVariantRequest,
  transformEComProductListRequest,
  transformProduct,
  transformProductCreateRequest,
  transformProductLogList,
  transformProductVariantForSaveOffer,
} from "../types/transformers/eComProduct";
import { EComProductInfo, ProductPlan, VariantsImage } from "../types/model/eComProduct";
import { BasicPromotion } from "../types/model/itemsForAddProduct";
import { PromotionStatus } from "../types/model/promotion";

export async function fetchProductList(params: EComProductListRequest) {
  const url = "/variants";
  return eComInstance
    .get(url, { params: transformEComProductListRequest(params) })
    .then((res) => transformComProductList(res));
}

export async function fetchVariantsImages() {
  const url = "/variants/images";
  return eComInstance.get<VariantsImage[]>(url).then((res) => res);
}

export async function fetchProductLanguages() {
  const url = "/products";
  return eComInstance
    .get<EComPage<EComProductLanguageResponse>>(url, {
      params: { limit: DEFAULT_MAX_PAGINATION.size },
    })
    .then((res) =>
      transformComProductLanguages(
        // @ts-ignore
        res["products"] || []
      )
    );
}

export async function fetchProductById(id: string) {
  const url = `/products/${id}`;
  return eComInstance.get(url).then((res) => transformProduct(res["product"]));
}

export async function fetchProductLogListById(id: string, params: EComProductInfoListRequest) {
  const url = `/products/${id}/logs`;
  return eComInstance
    .get<EComProductLogListResponse>(url, { params: params })
    .then((res) => transformProductLogList(res));
}

export async function fetchBillingPlans() {
  const url = "/billing-plans";
  return eComInstance.get<BillingPlansResponse>(url);
}

export async function batchPublishPlans() {
  const url = "/variants/active";
  return eComInstance.put(url).then((res) => res);
}

export async function createProduct(productInfo: EComProductInfo, productPlans: ProductPlan[]) {
  const url = "/products";
  return eComInstance.post(url, transformProductCreateRequest(productInfo, productPlans)).then((res) => res);
}

export async function deleteProduct(id: string) {
  const url = `/products/${id}`;
  return eComInstance.delete(url);
}

export async function createVariant(id: string, productPlan: ProductPlan) {
  const url = `/products/${id}/variants`;
  return eComInstance.post(url, transformCreateVariantRequest(productPlan)).then((res) => res);
}

export async function updateProductPlan(id: string, variantId: string, productPlan: ProductPlan) {
  const url = `/products/${id}/variants/${variantId}`;
  return eComInstance.post(url, transformCreateVariantRequest(productPlan)).then((res) => res);
}

export async function deleteProductPlan(id: string, variantId: string) {
  const url = `/products/${id}/variants/${variantId}`;
  return eComInstance.delete(url);
}

export async function updateStatusPlan(id: string, variantId: string, status: string) {
  const url = `/products/${id}/variants/${variantId}/${status}`;
  return eComInstance.put(url);
}

export async function fetchPromotionCodeByBasePriceId(basePricesId: string) {
  const url = `/base-prices/${basePricesId}/promotions`;
  return eComInstance.get(url).then((res) => {
    return res.map(
      (item: any): BasicPromotion => ({
        code: item.code,
        status: item.status as PromotionStatus,
        isSubscription: item.is_subscription,
      })
    );
  });
}

export async function fetchProductVariantsByFuzzySearch(keyword: string) {
  const url = "/variants/search";
  return (
    keyword &&
    eComInstance.get(url, { params: { q: keyword } }).then((res) => {
      return transformProductVariantForSaveOffer(res);
    })
  );
}
