import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Space, Table } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import styles from "./index.module.scss";
import EditableCell from "../../../components/EditableCell";
import { message } from "../../../components";
import { EComModal } from "../../../components/EComModal";
import { GiftCardBalanceFieldValue } from "../../../types/model/customer";
import { CurrencyModel } from "../../../types/model/price";
import { RootState, useAppDispatch } from "../../../app/store";
import { fetchCurrencyAssociation, fetchGiftCardBalance, updateGiftCardBalance } from "../customerSlice";
import useAppAuth0 from "../../../hooks/useAppAuth0";

function GiftCardBalance({ auth0Id }: { auth0Id?: string }) {
  const dispatch = useAppDispatch();
  const { user } = useAppAuth0();
  const [editForm] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [currencyAssociation, setCurrencyAssociation] = useState<CurrencyModel[]>([]);
  const [currentGiftCardBalance, setCurrentGiftCardBalance] = useState<GiftCardBalanceFieldValue>({});
  const [originalGiftCardBalance, setOriginalGiftCardBalance] = useState<GiftCardBalanceFieldValue>({});
  const { customersGiftCardBalance } = useSelector((state: RootState) => state.customer);
  const getDataSource = useMemo(() => {
    const findAmountByCurrency = (upperCurrencyCode: string) => {
      const giftCardBalance = customersGiftCardBalance.find(
        (item) => item.currency.toUpperCase() === upperCurrencyCode
      );
      return giftCardBalance?.amount ?? 0;
    };

    const currencyBalances = currencyAssociation.reduce((acc, currency) => {
      const amount = findAmountByCurrency(currency.code.toUpperCase());
      return { ...acc, [currency.code]: amount };
    }, {});

    return [currencyBalances];
  }, [currencyAssociation, customersGiftCardBalance]);

  const updatedGiftCardBalance = useMemo(
    () => Object.entries(currentGiftCardBalance).filter(([key, value]) => originalGiftCardBalance[key] !== value),
    [currentGiftCardBalance, originalGiftCardBalance]
  );

  const giftCardBalanceDeltaAmount = useMemo(
    () =>
      updatedGiftCardBalance.map(([key, value]) => ({
        currency: key,
        deltaAmount: Number(value) - Number(originalGiftCardBalance[key]),
      })),
    [updatedGiftCardBalance, originalGiftCardBalance]
  );

  useEffect(() => {
    auth0Id && dispatch(fetchGiftCardBalance(auth0Id)).then(unwrapResult);
  }, [dispatch, auth0Id]);

  useEffect(() => {
    dispatch(fetchCurrencyAssociation())
      .then(unwrapResult)
      .then((res) => {
        setCurrencyAssociation(res);
      });
  }, [dispatch]);

  const columnConfig = [
    ...currencyAssociation.map((currency) => ({
      title: currency.code.toUpperCase(),
      key: currency.code,
      width: 100,
      dataIndex: currency.code,
      inputType: "number",
      editable: true,
    })),
    {
      title: "Actions",
      key: "actions",
      width: 80,
      fixed: "right",
      render: (val: any) => {
        if (!user.canUpdateGiftCardBalance) {
          return "-";
        }
        return isEditing ? (
          <Space size={6} direction={"horizontal"}>
            <Button type="link" style={{ padding: "0" }} size="small" onClick={save}>
              Save
            </Button>
            <Button type="link" style={{ padding: "0" }} size="small" onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
          </Space>
        ) : (
          <Button type="link" style={{ padding: "0" }} size="small" onClick={() => edit(val)}>
            Edit
          </Button>
        );
      },
    },
  ] as any[];

  const EditColumns = columnConfig.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        key: record.id,
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing,
        maxInputNumber: 1000000,
      }),
    };
  });

  const edit = (val: GiftCardBalanceFieldValue) => {
    editForm.setFieldsValue(val);
    setOriginalGiftCardBalance(val);
    setIsEditing(true);
  };

  const save = () => {
    editForm.validateFields().then((row) => {
      setCurrentGiftCardBalance(row);
      setIsEditing(false);
      setIsUpdateModalOpen(true);
    });
  };

  const onConfirmUpdate = async () => {
    if (isEmpty(giftCardBalanceDeltaAmount) || !auth0Id) {
      setIsUpdateModalOpen(false);
      return;
    }
    setConfirmLoading(true);
    try {
      for (const { currency, deltaAmount } of giftCardBalanceDeltaAmount) {
        await dispatch(updateGiftCardBalance({ currency, id: auth0Id, deltaAmount }));
      }
      unwrapResult(await dispatch(fetchGiftCardBalance(auth0Id)));
      message.success("Gift card balance updated successfully.");
    } finally {
      setConfirmLoading(false);
      setIsUpdateModalOpen(false);
    }
  };

  return (
    <Space size={24} direction={"vertical"} className={styles.giftCardBalanceSection}>
      <header className={styles.subtitle}>Gift Card Balance</header>
      <Form form={editForm} component={false}>
        <Table
          scroll={{ x: "max-content" }}
          className={styles.table}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          // @ts-ignore
          columns={EditColumns}
          dataSource={getDataSource}
          pagination={false}
        />
      </Form>
      <EComModal
        visible={isUpdateModalOpen}
        title={"Confirm Gift Card Balance Change"}
        okText="Confirm"
        onCancel={() => {
          setIsUpdateModalOpen(false);
        }}
        onOk={onConfirmUpdate}
        confirmLoading={confirmLoading}
      >
        <>
          <p>
            Please confirm the updated balances for the following currencies: <br />
            {updatedGiftCardBalance.map(([key, value]) => (
              <span key={key}>
                - {key.toUpperCase()}: {value}
                <br />
              </span>
            ))}
          </p>
          <p>Are you sure you want to proceed with these changes?</p>
        </>
      </EComModal>
    </Space>
  );
}

export default GiftCardBalance;
