import React, { useEffect, useState } from "react";
import { ConfigProvider, Form, Input, message, Select, Spin } from "antd";
import { ISubscriptionModalProps } from "./types";
import { EComModal } from "../../../../components/EComModal";
import styles from "../Subscription.module.scss";
import { validateMessages } from "../../../../constants/common";
import modalStyles from "../../../../components/EComModal/index.module.scss";
import { fetchDowngradePlanList, submitSwitchPlan } from "../../../../services/subscription";
import { DropDown } from "../../../../assets/icons";

interface IDowngradeSubscriptionModalProps extends ISubscriptionModalProps {
  email: string;
}

export default function DowngradeSubscriptionModal({
  auth0Id,
  email,
  subscription,
  visible,
  closeModal,
}: IDowngradeSubscriptionModalProps) {
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [optionList, setOptionList] = useState<{ label: string; value: string }[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchOptionList().then();
  }, []);

  async function fetchOptionList() {
    try {
      setIsFetching(true);
      const list = await fetchDowngradePlanList(subscription.pid);
      const options = list.map((item) => {
        const billingPlanName = item.billingPlan ? ` / ${item.billingPlan?.name}` : "";
        return {
          label: `${item.pid} ${item.planTitle}` + billingPlanName,
          value: item.pid,
          className: styles.customSelect,
        };
      });
      setOptionList(options);
      if (options.length > 0) {
        form.setFieldsValue({ downgradeTo: options[0].value });
      }
    } finally {
      setIsFetching(false);
    }
  }

  async function onSubmit() {
    setIsLoading(true);
    try {
      const values = await form.validateFields();
      await submitSwitchPlan(auth0Id, values.email, subscription.subscriptionId, subscription.pid, values.downgradeTo);
      message.success("An email containing the downgrade link has been sent to the user.");
      closeModal();
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <EComModal
      title="Downgrade"
      visible={visible}
      okText="Send"
      cancelText="Cancel"
      onCancel={closeModal}
      onOk={onSubmit}
      confirmLoading={isLoading}
    >
      <Spin size="large" spinning={isFetching}>
        <div className={styles.subscriptionModal}>
          <p>Please verify the information below with the user. A confirmation email will be sent to the user.</p>
          <ConfigProvider form={{ validateMessages }}>
            <Form className={modalStyles.form} form={form}>
              <Form.Item
                label="Email:"
                name="email"
                required
                initialValue={email}
                className={modalStyles.formItem}
                rules={[
                  { required: true },
                  {
                    type: "email",
                    message: "Please check the format of your email and try again.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Downgrade to:"
                name="downgradeTo"
                required
                className={`${modalStyles.formItem} ${styles.customSelect}`}
                rules={[{ required: true }]}
              >
                <Select options={optionList} loading={isFetching} suffixIcon={<DropDown />} />
              </Form.Item>
            </Form>
          </ConfigProvider>
        </div>
      </Spin>
    </EComModal>
  );
}
