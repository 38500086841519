// @ts-nocheck
import { useGetLanguagesQuery } from "services/language";

function useLanguagesOptions() {
  const { data } = useGetLanguagesQuery({
    size: 10000,
    sort: "name,ASC",
  });
  return data?.content?.map((language) => ({
    label: language.name!,
    value: language.id!,
  }));
}

export default useLanguagesOptions;
