import React, { useState } from "react";
import { Button, Card, Form, Space } from "antd";
import { Prompt } from "react-router-dom";
import { LEAVE_CONFIRMATION } from "../../../../constants/common";
import { SaveOfferForm } from "../SaveOfferDetails/SaveofferForm";
import * as eComSaveOfferAPI from "../../../../services/saveOffer";
import message from "../../../../components/message";
import { SaveOfferContent, SaveOfferDetailModel } from "../../../../types/model/saveOffer";
import { EComModal } from "../../../../components/EComModal";

function EditSaveOffer({
  saveOfferDetail,
  onFinish,
  onCancel,
}: {
  saveOfferDetail: SaveOfferDetailModel;
  onCancel: () => void;
  onFinish: () => void;
}) {
  const [form] = Form.useForm();
  const [formIsHalfFilledOut, setFormIsHalfFilledOut] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [contents, setContents] = useState<SaveOfferContent[]>(saveOfferDetail.contents);

  const handleUpdate = () =>
    form
      .validateFields()
      .then((value) => {
        setIsSaveLoading(true);
        eComSaveOfferAPI
          .updateSaveOffer(saveOfferDetail.id, value, contents)
          .then(() => {
            setFormIsHalfFilledOut(false);
            message.success("Save offer updated successfully.").then();
            onFinish();
          })
          .finally(() => setIsSaveLoading(false));
      })
      .catch(() => {
        setShowSaveModal(false);
        message.error(new Error("Please fill in all required fields."));
      });

  return (
    <>
      <Card
        title={"Edit save offer"}
        extra={
          <Space size={10}>
            <Button type="default" shape="round" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                setShowSaveModal(true);
              }}
            >
              Save
            </Button>
          </Space>
        }
        className="page-container"
      >
        <Prompt when={formIsHalfFilledOut} message={LEAVE_CONFIRMATION} />
        <SaveOfferForm form={form} saveOfferDetail={saveOfferDetail} contents={contents} setContents={setContents} />
      </Card>
      {showSaveModal && (
        <EComModal
          title="Confirm change"
          okText="confirm"
          visible={showSaveModal}
          onCancel={() => {
            setShowSaveModal(false);
          }}
          onOk={handleUpdate}
          confirmLoading={isSaveLoading}
        >
          Confirm to make it happen? The change will be live instantly.
        </EComModal>
      )}
    </>
  );
}

export default EditSaveOffer;
