export type ProductStatus = "ACTIVE" | "STAGING" | "READY_FOR_REVIEW" | "ARCHIVED";

export const PRODUCT_TYPES = [
  "DVD",
  "FREE",
  "LESSON_PACK",
  "MULTI_LEVEL",
  "SINGLE_LEVEL",
  "SUBSCRIPTION",
  "UPGRADE",
  "UPSELL",
  "ALL_ACCESS",
] as const;
export type ProductType = typeof PRODUCT_TYPES[number];

export function isProductType(x: any): x is ProductType {
  return PRODUCT_TYPES.includes(x);
}

export const PRODUCT_TYPES_CAPITAL = [
  "Single level",
  "Multi-level",
  "Subscription",
  "Lesson pack",
  "Free",
  "Upgrade",
  "Upsell",
  "DVD",
  "All Access",
] as const;
export type ProductTypeCapital = typeof PRODUCT_TYPES_CAPITAL[number];

export function isProductTypeCapital(x: any): x is ProductTypeCapital {
  return PRODUCT_TYPES_CAPITAL.includes(x);
}

export interface ProductUpsell {
  upsellType: ProductType;

  upsellEcomPid?: string;

  upsellInAppId: number;

  upsellInAppIsbn?: string;

  upsellInAppName?: string;

  hasEComPromotionCode: boolean;

  upsellPromotionCode?: string;
}

type ProductModel = {
  id?: number;
  scheduledPublishTime?: number;
  productId: number;
  productName: string;
  name: string;
  isbn: string;
  status: ProductStatus;
  isPremium: boolean;
  languageName: string | null;
  courseNameUpsellCardLibrary: string | null;
  productType: ProductType;
  lastModifiedDate: number;
  premiumISBN?: { label: string; value: number };
  bundledProducts?: any[];
  lessonFrom?: number;
  lessonTo?: number;
  languageId: number;
  hasHistoricalActiveStatus: boolean;
  course?: {
    courseId: number | number[];
    courseLevel?: number;
    courseName: string | string[];
    courseVersion?: string;
  };
  lessonRange: { from?: number; to?: number };
  upsellName: string;
  learnName: string;
  upsellDescription: string;
  productUpsells: ProductUpsell[];
  upgradeId: number;
  upgradeIsbn: string;
  upgradeEcomPid?: string;
  upgradePromotionCode?: string;
  upgradeName: string;
  deepLinkPromotion?: {
    promotionTitle?: string;
    promotionContent?: string;
    promotionComment?: string;
  };
  deepLink?: string;
  isUpsellSupportedByNami?: boolean;
  isDeeplinkSupportedByNami?: boolean;
  isEffectiveImmediately?: boolean;
  namiGroups: string[];
};

export default ProductModel;

export enum PRODUCT_TYPE_FOR_ADD {
  SINGLE_LEVEL = "Single level",
  FREE = "Free",
  SUBSCRIPTION = "Subscription",
  MULTI_LEVEl = "Multi-level",
  LESSON_PACK = "Lesson pack",
  UPGRADE = "Upgrade",
  All_ACCESS = "All Access",
}

export const PRODUCT_CODE = ["UK", "US", "AU", "CA"];

export enum COURSE_TYPE {
  STANDARD = "Standard",
  PREMIUM = "Premium",
}

export const COURSES_FOR_SINGLE_LEVEL = [
  { courseLevelValue: 1, courseLevelName: "Level 1" },
  { courseLevelValue: 2, courseLevelName: "Level 2" },
  { courseLevelValue: 3, courseLevelName: "Level 3" },
  { courseLevelValue: 4, courseLevelName: "Level 4" },
  { courseLevelValue: 5, courseLevelName: "Level 5" },
];

export interface VerifyResult {
  incorrectFormatItems: string[];
  mismatchedItems: string[];
  succeeded: boolean;
  updatedFiles: string[];
}

export interface ProductChangeLog {
  id: number;

  action: string;

  comments: string;

  createdBy: string;

  createdDate: number;
}
