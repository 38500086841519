import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Card, Form, Space, Spin } from "antd";
import { message, Modal } from "components";
import { RootState, useAppDispatch } from "app/store";
import { LEAVE_CONFIRMATION } from "constants/common";
import useAppAuth0 from "hooks/useAppAuth0";
import { fetchVoucherGroup, removeVoucher, updateVoucher, voucherSelectors } from "../voucherSlice";
import VoucherForm, { toVoucherGroupCreateRequest, VoucherFormProps } from "../VoucherForm";
import VoucherInfoForm from "./VoucherInfoForm";
import { renderDeletionMessage } from "../configs";

const { REACT_APP_B2B_WEB_URL } = process.env;

function VoucherDetail() {
  const dispatch = useAppDispatch();
  const { user } = useAppAuth0();
  const { groupId } = useParams<{ groupId: string }>();
  const isLoading = useSelector((state: RootState) => state.loading);
  const voucherGroup = useSelector((state: RootState) => voucherSelectors.selectById(state, groupId));
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchVoucherGroup(groupId)).then(unwrapResult).catch(message.error);
  }, [dispatch, groupId]);

  function showConfirmModal() {
    Modal.confirm({
      title: "Confirm",
      content: LEAVE_CONFIRMATION,
      onOk: () => {
        setEditing(false);
      },
    });
  }

  function toggleEdit() {
    setEditing(!editing);
  }

  const handleSubmit: VoucherFormProps["onFinish"] = (values) => {
    const request = toVoucherGroupCreateRequest(values);
    if (voucherGroup != null) {
      dispatch(updateVoucher({ ...request, groupId: voucherGroup.voucherGroupId }))
        .then(unwrapResult)
        .then(() => setEditing(false))
        .catch(message.error);
    }
  };

  const handleDelete = () =>
    Modal.confirm({
      title: "Confirm",
      content: voucherGroup?.description && renderDeletionMessage(voucherGroup.description),
      okText: "Confirm",
      onOk: () =>
        dispatch(removeVoucher(groupId))
          .then(unwrapResult)
          .then(() => history.push("/vouchers"))
          .catch(message.error),
    });

  const buttonGroups = editing ? (
    <Space size={12}>
      <Button type="default" shape="round" onClick={showConfirmModal}>
        Cancel
      </Button>
      <Button type="primary" shape="round" onClick={() => form.submit()}>
        Save
      </Button>
    </Space>
  ) : (
    <Space size={12}>
      {user.canDeleteVoucherGroup && (
        <Button type="default" shape="round" onClick={handleDelete}>
          Delete
        </Button>
      )}
      {user.canEditVoucherGroup && (
        <Button type="default" shape="round" onClick={toggleEdit}>
          Edit
        </Button>
      )}
      {user.canCreateVoucherGroup && (
        <Button
          type="default"
          shape="round"
          onClick={() =>
            history.push({
              pathname: "/vouchers/add",
              state: voucherGroup,
            })
          }
        >
          Copy Me
        </Button>
      )}
      <Button
        type="primary"
        shape="round"
        target="_blank"
        href={`${REACT_APP_B2B_WEB_URL}/${voucherGroup?.organizationId}/assignment/${groupId}`}
      >
        View in B2B
      </Button>
    </Space>
  );

  return (
    <Card title={voucherGroup?.description} extra={buttonGroups} className="page-container">
      <Spin spinning={isLoading} size={"large"}>
        {editing ? (
          <VoucherForm isEditing={true} form={form} data={voucherGroup} onFinish={handleSubmit} />
        ) : (
          <VoucherInfoForm data={voucherGroup} />
        )}
      </Spin>
    </Card>
  );
}

export default VoucherDetail;
