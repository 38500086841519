import { DatePicker, DatePickerProps } from "antd";
import moment from "moment";
import React from "react";

type Props = DatePickerProps & { expirationDate?: number };

export default function StartDateInput(props: Props) {
  const { value, expirationDate } = props;
  return (
    <DatePicker
      {...props}
      value={value ? moment(value) : undefined}
      disabledDate={(date) => {
        const _expirationDate = moment(expirationDate ?? "9999-01-01");

        return date.isBefore(moment(), "day") || date.isAfter(_expirationDate, "day");
      }}
    />
  );
}
