import React, { forwardRef, useEffect, useState } from "react";
import { Form, Select } from "antd";
import * as basePriceAPI from "../../../../services/basePrice";
import { Country, Currency, CurrencyFormValue, CurrencyModel } from "../../../../types/model/price";
import { DropDown } from "../../../../assets/icons";
import GenericConfigureForm from "../../../../components/GenericConfigureForm";

const { Option } = Select;

export interface CountryOption {
  label: string;
  value: number;
}

const CurrencyConfigureForm = forwardRef<{
  validate: () => Promise<boolean>;
  update: () => Promise<void>;
  refresh: () => void;
}>((_, ref) => {
  const [form] = Form.useForm();
  const [allCurrencies, setAllCurrencies] = useState<Currency[]>([]);
  const [allCountries, setAllCountries] = useState<Country[]>([]);
  const [selectedCurrencies, setSelectedCurrencies] = useState<string[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<CountryOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const defaultCurrencies = ["USD", "GBP", "AUD", "CAD"];

  useEffect(() => {
    Promise.all([
      basePriceAPI.fetchAllCurrencies({ limit: 999 }),
      basePriceAPI.fetchAllCountries(),
      basePriceAPI.fetchCurrencyAssociation(),
    ])
      .then(([currencies, countries, associations]) => {
        setAllCurrencies(currencies);
        setAllCountries(countries);
        form.setFieldsValue(getInitialFieldsValue(associations));
        setSelectedCurrencies(associations.map((item) => item.code.toUpperCase()));
        setSelectedCountries(
          associations.flatMap((currency) =>
            currency.countries.map((country: Country) => ({
              label: country.displayName,
              value: country.id,
            }))
          )
        );
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [form]);

  const refresh = async () => {
    await basePriceAPI.fetchCurrencyAssociation().then((res) => {
      form.setFieldsValue(getInitialFieldsValue(res));
      setSelectedCurrencies(res.map((item) => item.code.toUpperCase()));
      setSelectedCountries(
        res.flatMap((currency) =>
          currency.countries.map((country: Country) => ({
            label: country.displayName,
            value: country.id,
          }))
        )
      );
    });
  };

  const getInitialFieldsValue = (associations: CurrencyModel[] | undefined): CurrencyFormValue => {
    return {
      fields:
        associations?.map(({ code, countries }) => ({
          currency: code.toUpperCase(),
          country: countries.map((country) => ({
            label: country.displayName,
            value: country.id,
          })),
        })) ?? [],
    };
  };

  const handleFieldChange = (
    fieldName: string,
    setState: React.Dispatch<React.SetStateAction<CountryOption[]>> | React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    const fieldsValue = form.getFieldValue("fields").filter(Boolean);
    const updatedValues = fieldsValue.filter((item: any) => item[fieldName]).flatMap((item: any) => item[fieldName]);
    setState(updatedValues);
  };

  return (
    <GenericConfigureForm
      ref={ref}
      form={form}
      headerTitles={["Currency", "Applied Country/Region"]}
      buttonTitle="Currency"
      successMessage="Currency updated successfully."
      isLoading={isLoading}
      updateData={(values: any) => basePriceAPI.updateCurrencyAssociations(values, allCountries)}
      refreshData={refresh}
      renderField={(index) => (
        <>
          <Form.Item
            name={[index, "currency"]}
            rules={[
              {
                required: true,
                message: "'Currency' is required",
              },
            ]}
            shouldUpdate
          >
            <Select
              allowClear
              showSearch
              aria-label="currency"
              suffixIcon={<DropDown />}
              style={{ width: 160, marginRight: 12 }}
              placeholder="Select Currency"
              disabled={defaultCurrencies.includes(form.getFieldValue(["fields", index, "currency"]))}
              onChange={() => handleFieldChange("currency", setSelectedCurrencies)}
            >
              {allCurrencies
                .filter((currency: Currency) => !selectedCurrencies.includes(currency.code?.toUpperCase()))
                .map((currency: Currency) => (
                  <Option key={currency.code} value={currency.code.toUpperCase()}>
                    {currency.code.toUpperCase()}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={[index, "country"]}
            rules={[
              {
                required: true,
                message: "'Country/Region' is required",
              },
            ]}
            shouldUpdate
          >
            <Select
              allowClear
              optionFilterProp="label"
              mode="multiple"
              aria-label="country"
              labelInValue
              suffixIcon={<DropDown />}
              style={{ width: 276 }}
              placeholder="Select Applied Country"
              showArrow={true}
              onChange={() => handleFieldChange("country", setSelectedCountries)}
              options={allCountries
                .map((country) => ({
                  label: country.displayName,
                  value: country.id,
                }))
                .filter(
                  (country) => !selectedCountries.map((countryOption) => countryOption.value).includes(country.value)
                )}
            />
          </Form.Item>
        </>
      )}
      allowRemove={(index) => !defaultCurrencies.includes(form.getFieldValue(["fields", index, "currency"]))}
    />
  );
});
export default CurrencyConfigureForm;
