import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { DAY_FORMAT, EMPTY } from "constants/common";
import { Dropdown, Menu } from "antd";
import React from "react";
import { GiftUserInfo, GivenGiftListItem, ReceivedGiftListItem } from "../../../types/model/customer";
import { More } from "../../../assets/icons";

export const formatUTCTimeToLocalDate = (time?: string | null) =>
  time ? moment(time).local().format(DAY_FORMAT) : EMPTY;

function getSubscriptionDuration(gift: ReceivedGiftListItem) {
  return `${formatUTCTimeToLocalDate(gift.subscriptionStartDate)} - ${formatUTCTimeToLocalDate(
    gift.subscriptionEndDate
  )}`;
}

const getRecipientOrGiverName = (userInfo: GiftUserInfo) => `${userInfo.firstName} ${userInfo.lastName}`;

export const givenGiftColumnConfig = (
  canUpdateGift: boolean,
  setIsUpdateRecipientEmailModalVisible: (value: boolean) => void,
  setIsSendEmailModalVisible: (value: boolean) => void,
  setGivenEmail: (value: { id: string; email: string }) => void
): ColumnType<GivenGiftListItem>[] => [
  {
    title: "Gift name",
    dataIndex: "title",
    width: 260,
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Recipient name",
    width: 180,
    dataIndex: "recipient",
    ellipsis: true,
    render: (recipient) => getRecipientOrGiverName(recipient),
  },
  {
    title: "Recipient email",
    dataIndex: ["recipient", "email"],
    width: 262,
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Gift delivery date",
    dataIndex: "deliveryDate",
    width: 160,
    ellipsis: true,
    render: (value) => formatUTCTimeToLocalDate(value),
  },
  {
    title: "Status",
    width: 120,
    dataIndex: "status",
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Redeemed email",
    dataIndex: "redeemedEmail",
    width: 262,
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Action",
    width: 80,
    render: (gift: GivenGiftListItem) =>
      ["Pending", "Sent"].includes(gift.status) && canUpdateGift ? (
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu>
              <Menu.Item
                key="updateRecipientEmail"
                onClick={() => {
                  setGivenEmail({ id: gift.id, email: gift.recipient.email });
                  setIsUpdateRecipientEmailModalVisible(true);
                }}
              >
                Update recipient email
              </Menu.Item>
              <Menu.Item
                key="sendEmail"
                onClick={() => {
                  setGivenEmail({ id: gift.id, email: gift.recipient.email });
                  setIsSendEmailModalVisible(true);
                }}
              >
                Send email
              </Menu.Item>
            </Menu>
          }
        >
          <More />
        </Dropdown>
      ) : (
        EMPTY
      ),
  },
];

export const receivedGiftColumnConfig: ColumnType<ReceivedGiftListItem>[] = [
  {
    title: "Gift name",
    dataIndex: "title",
    width: 260,
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Giver name",
    dataIndex: "giver",
    width: 180,
    ellipsis: true,
    render: (giver) => getRecipientOrGiverName(giver),
  },
  {
    title: "Giver email",
    dataIndex: ["giver", "email"],
    width: 222,
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Status",
    width: 120,
    dataIndex: "status",
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Redeemed email",
    dataIndex: "redeemedEmail",
    width: 262,
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Gift redeemed date",
    dataIndex: "redeemDate",
    width: 180,
    ellipsis: true,
    render: (value) => formatUTCTimeToLocalDate(value),
  },
  {
    title: "Subscription Duration",
    width: 180,
    ellipsis: true,
    render: (gift: ReceivedGiftListItem) => getSubscriptionDuration(gift),
  },
];
