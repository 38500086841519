import MiniCourseRequest, { IMIniCourseChangeLogParams } from "../types/dto/request/MiniCourseRequest";
import instance from "../utils/instance";
import Page from "../types/page";
import MiniCourseModel, { IMiniCourseLog, VerifyResult } from "../types/model/miniCourse";

export async function fetchAll(params: MiniCourseRequest) {
  const url = "/mini-courses";
  return instance.get<Page<MiniCourseModel>>(url, { params });
}

export async function verifyMiniCourse(id: number, comments: string) {
  const url = `/mini-courses/${id}/verification`;
  return instance.post<VerifyResult>(url, { comments });
}

export async function publishMiniCourse(id: number) {
  const url = `/mini-courses/${id}/publishing`;
  return instance.post<void>(url);
}

export function scheduledPublishMiniCourse(id: number, scheduledPublishTime: number) {
  const url = `/mini-courses/${id}/scheduled-publishing`;
  return instance.post<void>(url, { scheduledPublishTime });
}

export function removeSchedule(id: number) {
  const url = `/mini-courses/${id}/scheduled-publishing`;
  return instance.delete<void>(url);
}

export function fetchMiniCourseChangeLogs(params: IMIniCourseChangeLogParams) {
  const url = `/mini-course-change-logs`;
  return instance.get<Page<IMiniCourseLog>>(url, { params });
}
