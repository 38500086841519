import { Checkout, CheckoutResponse } from "../model/checkout";
import { transformPaymentMethod } from "./user/paymentMethod";
import { PaymentMethodDto } from "../dto/response/paymentMethod";

export function transformCheckout(dto: CheckoutResponse): Checkout {
  return {
    paymentMethod: transformPaymentMethod(
      dto.payment_methods.find((paymentMethod) => paymentMethod.default) || ({} as PaymentMethodDto)
    ),
    orderSummary: {
      name: dto.order_summary.name,
      amount: dto.order_summary.amount,
      interval: dto.order_summary.interval,
      total: dto.order_summary.total,
      type: dto.order_summary.type,
      tax: dto.order_summary.tax,
      currency: dto.order_summary.currency,
    },
    subscription: {
      id: dto.subscription.id,
      isActive: dto.subscription.is_active,
    },
  };
}
