export enum STATUS_TYPE {
  ACTIVE = "active",
  NOT_STARTED = "not started",
  EXPIRED = "expired",
  DRAFT = "draft",
  INACTIVE = "inactive",
  READY_FOR_PUBLISH = "ready for publish",
}

export const presetStatusColors: Record<PresetStatusColorType, string> = {
  [STATUS_TYPE.ACTIVE]: "#00a854",
  [STATUS_TYPE.NOT_STARTED]: "#f8a832",
  [STATUS_TYPE.EXPIRED]: "#9c9c9c",
  [STATUS_TYPE.DRAFT]: "#f8a832",
  [STATUS_TYPE.INACTIVE]: "#9c9c9c",
  [STATUS_TYPE.READY_FOR_PUBLISH]: "#4267F9",
};

export const PresetStatusColorTypes = Object.values(STATUS_TYPE);

export type PresetStatusColorType = typeof PresetStatusColorTypes[number];

export function isPresetStatusColor(color?: any): color is PresetStatusColorType {
  return PresetStatusColorTypes.includes(color?.toLowerCase());
}
