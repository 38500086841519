import React from "react";
import { useSelector } from "react-redux";
import { FormInstance } from "antd/es/form";
import { Button, Form, Input, InputNumber, Select, Space } from "antd";
import Icon from "@ant-design/icons";
import { join, range } from "lodash";
import { COURSES_FOR_SINGLE_LEVEL, PRODUCT_CODE, PRODUCT_TYPE_FOR_ADD } from "types/model/product";
import { DropDown, Minus, Plus } from "assets/icons";
import { RootState } from "app/store";
import { SEPARATOR } from "constants/common";
import { preventNonNumericalInput } from "utils/preventNonNumericalInput";

import styles from "./AddNewProduct.module.scss";

function CourseLevel(props: { form: FormInstance; setFieldsDefaultValues: () => void }) {
  const { form, setFieldsDefaultValues } = props;
  const { type } = form.getFieldsValue();
  const { singleLevelProducts, premiumProducts } = useSelector((state: RootState) => state.addProduct);

  const validLessonRange = () => {
    const { selectedCourse } = form.getFieldsValue();
    const { lessonStart, lessonEnd } = selectedCourse;
    const lessonFrom = parseInt(lessonStart);
    const lessonTo = parseInt(lessonEnd);
    if (Number.isNaN(lessonFrom) || Number.isNaN(lessonTo)) {
      return Promise.reject("'Lesson range' is required");
    }
    if (![lessonFrom, lessonTo].every((x) => range(1, 31).includes(x))) {
      return Promise.reject("'Lesson range' must be between 1 and 30");
    } else if (lessonFrom > lessonTo) {
      return Promise.reject("start lesson must be smaller than end lesson");
    } else {
      return Promise.resolve();
    }
  };

  const validLessonRangeTotal = () => {
    const { newCourse } = form.getFieldsValue();
    const { totalLessons } = newCourse;
    const value = parseInt(totalLessons);
    if (Number.isNaN(value) || !value) {
      return Promise.reject("'Lesson range' is required");
    } else if (Number(value) > 30 || Number(value) < 1) {
      return Promise.reject("'Lesson range' must be between 1 and 30");
    } else if (Number(value) === 1) {
      return Promise.reject("start lesson must be smaller than end lesson");
    } else {
      return Promise.resolve();
    }
  };

  const handleBlur = () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      newCourse: {
        ...form.getFieldsValue().newCourse,
        version: form.getFieldsValue().newCourse.version.trim(),
      },
    });
  };

  const getMultiSelectCourseLevel = () => {
    return (
      <Form.Item
        label="Single level product"
        name="bundledProductIds"
        rules={[{ required: true }]}
        className={styles.bigFormItem}
      >
        <Select
          allowClear
          listHeight={160}
          optionFilterProp={"label"}
          getPopupContainer={(trigger) => trigger.parentNode}
          showArrow
          suffixIcon={<DropDown />}
          onChange={setFieldsDefaultValues}
          mode="multiple"
          placeholder="Please select course level"
          options={singleLevelProducts.map((product) => ({
            label: `${product.productCode} | ${product.course.courseName} ${product.course.courseVersion}`,
            value: product.productId,
          }))}
        />
      </Form.Item>
    );
  };

  switch (type) {
    case PRODUCT_TYPE_FOR_ADD.SINGLE_LEVEL:
      return (
        <Space size={32}>
          <Form.Item
            label="Course level"
            name={["newCourse", "level"]}
            rules={[{ required: true }]}
            className={styles.smallFormItem}
          >
            <Select
              allowClear
              showSearch
              listHeight={160}
              optionFilterProp={"label"}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={setFieldsDefaultValues}
              suffixIcon={<DropDown />}
              placeholder={"Please choose course level"}
              options={COURSES_FOR_SINGLE_LEVEL.map((course) => ({
                label: course.courseLevelName,
                value: course.courseLevelValue,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Lesson range"
            rules={[
              {
                required: true,
                validator: validLessonRangeTotal,
              },
            ]}
            name="lessonRange"
            className={styles.smallFormItem}
          >
            <Space size={22} align={"baseline"} className={styles.littleInput}>
              <Form.Item noStyle>
                <Input disabled={true} value={1} />
              </Form.Item>
              <span>to</span>
              <Form.Item noStyle name={["newCourse", "totalLessons"]}>
                <InputNumber precision={0} onChange={setFieldsDefaultValues} onKeyPress={preventNonNumericalInput} />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item
            label="Version"
            name={["newCourse", "version"]}
            initialValue={"orig"}
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject("'Version' is required");
                  } else {
                    if (value !== "orig" && !value.match(/^\d{4}$/)) {
                      return Promise.reject("'Version' must be 4-digit number or orig");
                    }
                    return Promise.resolve();
                  }
                },
              },
            ]}
            className={styles.smallFormItem}
          >
            <Input onBlur={handleBlur} />
          </Form.Item>
        </Space>
      );
    case PRODUCT_TYPE_FOR_ADD.FREE:
      return (
        <Space size={32}>
          <Form.Item
            label="Course level"
            name={["selectedCourse", "id"]}
            rules={[{ required: true }]}
            className={styles.smallFormItem}
          >
            <Select
              allowClear
              showSearch
              listHeight={160}
              optionFilterProp={"label"}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={setFieldsDefaultValues}
              suffixIcon={<DropDown />}
              placeholder={"Please choose course level"}
              options={singleLevelProducts.map((product) => ({
                label: join(["Level", product.course.courseLevel, product.course.courseVersion], " "),
                value: product.course.courseId,
              }))}
            />
          </Form.Item>
          <Form.Item label="Lesson range" rules={[{ required: true }]} className={styles.smallFormItem}>
            <Space size={22} align={"baseline"} className={styles.littleInput}>
              <Form.Item noStyle name={["selectedCourse", "lessonStart"]} initialValue={1}>
                <InputNumber disabled />
              </Form.Item>
              <span>to</span>
              <Form.Item noStyle name={["selectedCourse", "lessonEnd"]} initialValue={1}>
                <InputNumber disabled />
              </Form.Item>
            </Space>
          </Form.Item>
        </Space>
      );
    case PRODUCT_TYPE_FOR_ADD.MULTI_LEVEl:
      return getMultiSelectCourseLevel();
    case PRODUCT_TYPE_FOR_ADD.LESSON_PACK:
      return (
        <Space size={32}>
          <Form.Item
            label="Course level"
            name={["selectedCourse", "id"]}
            rules={[{ required: true }]}
            className={styles.smallFormItem}
          >
            <Select
              allowClear
              showSearch
              listHeight={160}
              optionFilterProp={"label"}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={setFieldsDefaultValues}
              suffixIcon={<DropDown />}
              placeholder={"Please choose course level"}
              options={singleLevelProducts.map((product) => ({
                label: join(["Level", product.course.courseLevel, product.course.courseVersion], " "),
                value: product.course.courseId,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Lesson range"
            name="lessons"
            rules={[
              {
                validator: validLessonRange,
              },
            ]}
            className={styles.smallFormItem}
          >
            <Space size={22} align={"baseline"} className={styles.littleInput}>
              <Form.Item noStyle name={["selectedCourse", "lessonStart"]}>
                <InputNumber precision={0} onChange={setFieldsDefaultValues} onKeyPress={preventNonNumericalInput} />
              </Form.Item>
              <span>to</span>
              <Form.Item noStyle name={["selectedCourse", "lessonEnd"]}>
                <InputNumber precision={0} onChange={setFieldsDefaultValues} onKeyPress={preventNonNumericalInput} />
              </Form.Item>
            </Space>
          </Form.Item>
        </Space>
      );
    case PRODUCT_TYPE_FOR_ADD.UPGRADE:
      return (
        <Form.Item rules={[{ required: true }]} label="Premium ISBN" name="premiumISBN" className={styles.formItem}>
          <Select
            allowClear
            showSearch
            listHeight={160}
            optionFilterProp={"label"}
            getPopupContainer={(trigger) => trigger.parentNode}
            suffixIcon={<DropDown />}
            placeholder={"Please choose ISBN"}
            options={premiumProducts.map((product) => ({
              label: join([product.productCode, product.productName], SEPARATOR),
              value: product.productId,
            }))}
          />
        </Form.Item>
      );
    default:
      return null;
  }
}

export default CourseLevel;
