import { Form, Input, message } from "antd";
import React, { useState } from "react";
import { EComModal } from "../../../../components/EComModal";
import modalStyles from "../../../../components/EComModal/index.module.scss";
import * as CustomerAPI from "../../../../services/customer";

interface IUpdateRecipientEmailModalProps {
  visible: boolean;
  givenEmail: { id: string; email: string };
  onFinish: () => void;
  closeModal: () => void;
}

export default function UpdateRecipientEmailModal({
  visible,
  givenEmail: { id, email: originEmail },
  onFinish,
  closeModal,
}: IUpdateRecipientEmailModalProps) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onUpdate() {
    form.validateFields().then(() => {
      setIsLoading(true);
      const { id, email } = form.getFieldsValue();
      CustomerAPI.updateGivenGiftRecipientEmail(id, email)
        .then(() => {
          message.success("Email updated successfully.");
          onFinish();
          closeModal();
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  }

  return (
    <EComModal
      title="Update recipient email"
      visible={visible}
      okText="Update"
      onCancel={closeModal}
      onOk={onUpdate}
      confirmLoading={isLoading}
    >
      <Form layout="vertical" form={form} initialValues={{ email: originEmail }}>
        <Form.Item name="id" noStyle hidden initialValue={id} />
        <Form.Item
          label="Recipient email:"
          name="email"
          required
          className={modalStyles.formItem}
          rules={[
            { required: true },
            {
              type: "email",
              message: "Invalid email format.",
            },
            () => ({
              validator(_, value) {
                if (!value || value !== originEmail) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Please enter a new email."));
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </EComModal>
  );
}
