import _ from "lodash";
import React, { ChangeEvent } from "react";
import { Col, Divider, Form, Input, Row } from "antd";
import { FormInstance } from "antd/es/form";
import { DisplayInput } from "../../../../../components";
import styles from "./ProductDeeplinkForm.module.scss";
import NamiSupportCheckbox from "../../../../../components/NamiSupportCheckbox";

const ProductDeeplinkForm = ({ isCreate, form }: { isCreate?: boolean; form?: FormInstance }) => {
  const isSupportedByNami = form && form.getFieldValue("isDeeplinkSupportedByNami");
  const disableEnterInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    let titleValue = event.target.value;
    event.target.value = _.replace(titleValue, /[\n]/g, "");
  };

  return (
    <div className={styles.deeplinkForm}>
      <Row style={{ width: "776px", marginBottom: "34px" }}>
        <Col span={24}>
          <Form.Item
            className={styles.titleItem}
            name={["deepLinkPromotion", "promotionTitle"]}
            label={"Promotion title"}
          >
            <Input.TextArea showCount maxLength={30} onInput={disableEnterInput} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name={["deepLinkPromotion", "promotionContent"]} label={"Promotion content"}>
            <Input.TextArea showCount maxLength={150} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row style={{ width: "776px", marginBottom: "12px" }}>
        <Col span={24}>
          <Form.Item
            className={styles.commentItem}
            name={["deepLinkPromotion", "promotionComment"]}
            label={"Comment"}
            rules={[{ max: 2000 }]}
          >
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>

      {!isCreate && (
        <div>
          <Divider />
          <Row style={{ width: "1024" }}>
            <Col span={24}>
              <Form.Item name={"deepLink"} label={"Deeplink"}>
                <DisplayInput />
              </Form.Item>
            </Col>
          </Row>
        </div>
      )}
      <Row>
        <Form.Item name={"isDeeplinkSupportedByNami"} noStyle>
          <NamiSupportCheckbox
            checked={isSupportedByNami}
            onCheckboxChange={(isChecked) => form?.setFieldsValue({ isDeeplinkSupportedByNami: isChecked })}
          />
        </Form.Item>
      </Row>
    </div>
  );
};
export default ProductDeeplinkForm;
