import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import VoucherList from "./VoucherList";
import VoucherDetail from "./VoucherDetail";
import VoucherCreationContainer from "./VoucherForm/VoucherCreationContainer";

function Voucher() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <VoucherList />
      </Route>
      <Route exact path={`${path}/add`}>
        <VoucherCreationContainer />
      </Route>
      <Route exact path={`${path}/:groupId`}>
        <VoucherDetail />
      </Route>
    </Switch>
  );
}

export default Voucher;
